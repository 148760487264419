import {Component, Inject, OnInit} from '@angular/core';
import {InventarioEmisiones} from '../../model/inventarioemisiones';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Petition} from '../../model/petition';
import {CreateInventarioDialog} from '../inventario/createinventario.component';
import {TranslateService} from '@ngx-translate/core';
import {MunicipioService} from '../../services/municipio.service';
import {Router} from '@angular/router';
import {InventarioService} from '../../services/inventario.service';
import {PlanAccion} from '../../model/planaccion';
import {PlanaccionService} from '../../services/planaccion.service';
import {DatosInventario} from '../../model/datosinventario';
import {CreatePlanAccionDialog} from './createplanaccion.component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AdminzonaclimaticaformComponent} from '../../admin/adminmunicipio/adminzonaclimaticaform/adminzonaclimaticaform.component';
import {ZonaClimatica} from '../../model/zonaclimatica';
import {Sorter} from '../../components/list/Util';

@Component({
  selector: 'app-planaccion',
  templateUrl: './planaccion.component.html',
  styleUrls: ['./planaccion.component.scss']
})
export class PlanaccionHomeComponent implements OnInit {
  cols = [
    {
      id: 'anyo',
      title: 'planaccion.home.col-anyo',
      type: 'text',
      data: {
        prop: 'ANYO',
        filter: ['ANYO']
      },
      sort: 'desc',
      tooltip: 'planaccion.home.tooltip-anyo'
    },
    {
      id: 'anyoinventario',
      title: 'planaccion.home.col-anyoinventario',
      type: 'text',
      data: {
        prop: 'ANYOREFERENCIAINVENTARIO',
        filter: ['ANYOREFERENCIAINVENTARIO']
      },
      tooltip: 'planaccion.home.tooltip-anyoinventario'
    },
    {
      id: 'anyoriesgos',
      title: 'planaccion.home.col-anyoriesgos',
      type: 'text',
      data: {
        prop: 'ANYOREFERENCIARIESGOS',
      },
      tooltip: 'planaccion.home.tooltip-anyoriesgos'
    },
    {
      id: 'anyoobjetivo',
      title: 'planaccion.home.col-anyoobjetivo',
      type: 'text',
      data: {
        prop: 'ANYOOBJETIVO'
      },
      tooltip: 'planaccion.home.tooltip-anyoobjetivo'
    },
    {
      id: 'objetivoconsumo',
      title: 'planaccion.home.col-objetivoconsumo',
      type: 'text',
      data: {
        prop: 'OBJETIVOCONSUMO'
      },
    },
    {
      id: 'objetivoproduccion',
      title: 'planaccion.home.col-objetivoproduccion',
      type: 'text',
      data: {
        prop: 'OBJETIVOPRODUCCION'
      },
    },
    {
      id: 'objetivoemision',
      title: 'planaccion.home.col-objetivoemision',
      type: 'text',
      data: {
        prop: 'OBJETIVOEMISION'
      },
    },
    {
      id: 'hcoaccion',
      title: 'planaccion.home.col-hcoaccion',
      type: 'icon',
      data: {
        badge: (obj: PlanAccion) => obj.HCO_ACCION.length,
        callback: (obj: PlanAccion) => console.log(obj),
      }
    },
    {
      id: 'delete',
      title: '',
      type: 'icon',
      width: '40px',
      data: {
        icon: 'delete',
        callback: (obj: PlanAccion) => this.openDeleteDialog(obj),
      }
    },
    {
      id: 'select',
      title: '',
      type: 'icon',
      width: '40px',
      data: {
        icon: 'search',
        callback: (obj: PlanAccion) => this.setActive(obj),
        color: 'accent'
      },
      showonhover: true
    },
  ];

  search_props = ['ANYO'];
  search_placeholder = 'planaccion.home.list.searchplaceholder';
  sorters = [new Sorter('models.planaccion.ANYO.label', 'ANYO')];
  add_text = 'planaccion.home.list.addnewtext';
  data_fetch = new Petition();
  planesaccion: PlanAccion[] = [];
  primer_pa: PlanAccion;
  constructor(private router: Router, private planSrv: PlanaccionService, private munSrv: MunicipioService, private snackBar: MatSnackBar,
              private translateService: TranslateService, private dialog: MatDialog) {
    this.fetch();
  }

  fetch() {
    this.data_fetch.load();
    this.planSrv.getAll().subscribe(value => {
        if (value) {
          this.add_text = 'planaccion.home.list.addrevisiontext';
          this.planesaccion = value;
          this.primer_pa = value.sort((a,b) => a.ANYO - b.ANYO)[0];

        }
        this.data_fetch.ok(value);
      },
      error2 =>
        this.data_fetch.ko(error2)
    );
  }

  setActive(p) {
    this.planSrv.setActive(p);
    this.router.navigateByUrl('planaccion/' + p.ID);
  }

  ngOnInit() {
  }

  openCreateDialog(): void {
    let dialogRef = this.dialog.open(CreatePlanAccionDialog, {
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.create(result);
    });
  }

  create(plan) {
    plan.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    this.planSrv.create(new PlanAccion(plan).toDB()).subscribe(value => {
      if (value) {
        this.translateService.get('planaccion.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetch();
      }
    }, error2 => console.log(error2));
  }

  clone(plan,anyo) {
    plan.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    this.planSrv.clone(new PlanAccion(plan).toDB(),anyo).subscribe(value => {
      if (value) {
        this.translateService.get('planaccion.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetch();
      }
    }, error2 => console.log(error2));
  }

  openDeleteDialog(inventario): void {
    let dialogRef = this.dialog.open(DeletePlanAccionDialog, {
      data: {year: inventario.ANYO},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.delete(inventario);
    });
  }

  openRevisionDialog(): void {
    let dialogRef = this.dialog.open(ClonePlanAccionDialog, {
      data: {plan: this.primer_pa},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.clone(result.PLAN,result.ANYOrevision);
      }
    });
  }
  delete(inventario) {
    this.planSrv.delete(inventario.ID).subscribe(value => {
      this.translateService.get('planaccion.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.fetch();
    }, error2 => console.log(error2));
  }
}

@Component({
  selector: 'deleteplanacciondialog',
  templateUrl: 'deleteplanaccion.dialog.html',
})
export class DeletePlanAccionDialog {
  constructor(public dialogRef: MatDialogRef<DeletePlanAccionDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

@Component({
  selector: 'cloneplanacciondialog',
  templateUrl: 'cloneplanaccion.dialog.html',
})
export class ClonePlanAccionDialog {
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<ClonePlanAccionDialog>, private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = this.fb.group({
        PLAN: null,
        ANYOrevision: null
      }
    );
    this.form.get('PLAN').setValue(data.plan);
  }
}

<div class="full-height border rounded">
  <div *ngIf="fetch_data.pending && !fetch_data.finished"
       class="d-flex flex-column full-height justify-content-center align-items-center "
       style="background: white;">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
  <div *ngIf="fetch_data.finished && fetch_data.success" class="d-flex flex-column full-height"
       style="background: white;">
    <div id="topbar" class="d-flex bg-grey-lightest" style="min-height: 48px;" fxLayoutGap="5px">
      <div class="d-flex justify-content-center align-items-center pl-2">
        <button size="small" *ngIf="form.dirty && editable" class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-4 rounded-full border inline-flex items-center outline-none ng-star-inserted" (click)="save()">
          <i class="material-icons mr-2 text-md">save</i>
          <span class="text-sm">{{'analisis.riesgos.form.save' | translate }}</span>
        </button>
      </div>
      <div class="flex-grow-1"></div>
      <div class="d-flex justify-content-center align-items-center pr-1">
        <button *ngIf="this.userSrv.isEditable()" size="small" class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2" [matMenuTriggerFor]="menu">
          <i class="material-icons text-md">more_horiz</i>
        </button>
        <button size="small" class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2" (click)="closeForm()">
          <i class="material-icons text-md">close</i>
        </button>
      </div>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="delete.next(dato)">
          <mat-icon>delete</mat-icon>
          {{'analisis.riesgos.form.delete' | translate }}
        </button>
      </mat-menu>
    </div>
    <form [formGroup]="form" novalidate class="pl-4 pr-4 pb-4" style="overflow-y: auto;">
      <div fxLayout="column" class="pt-2 mb-4">
        <mat-form-field class="col-md-12 title-input">
          <input id="NOMBRE" matInput formControlName="NOMBRE"/>
        </mat-form-field>
        <div class="d-flex justify-content-start align-items-center pl-2 pr-2" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="10px">
            <!--<div fxLayout="column" class="align-items-start justify-content-center" fxLayoutGap="2px">
              <span class="text-sm color-grey-500">{{'analisis.riesgos.list.item.periodo' | translate }}</span>
              <span class="text-sm-m color-grey-700">{{getMarcoTemporal()}}</span>
            </div>-->
            <div fxLayout="row" fxLayoutGap="10px" class="align-items-center">
              <div fxLayout="row" fxLayoutGap="5px" style="min-width: 130px;" class="align-items-center">
                <div class="d-flex justify-content-center align-items-center icono-cambio border-all">
                  <mat-icon>{{getCambioIcon(getFrecuencia().ID)}}</mat-icon>
                </div>
                <div fxLayout="column" class="align-items-start justify-content-center">
                  <span class="text-sm color-grey-500">{{'analisis.riesgos.list.item.frecuencia' | translate}}</span>
                  <span class="text-sm-m color-grey-700">{{getFrecuencia().NOMBRE}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="5px" style="min-width: 130px;" class="align-items-center">
                <div class="d-flex justify-content-center align-items-center icono-cambio border-all">
                  <mat-icon>{{getCambioIcon(getIntensidad().ID)}}</mat-icon>
                </div>
                <div fxLayout="column" class="align-items-start justify-content-center">
                  <span class="text-sm color-grey-500">{{'analisis.riesgos.list.item.intensidad' | translate}}</span>
                  <span class="text-sm-m color-grey-700">{{getIntensidad().NOMBRE}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-grow-1"></div>
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="15px" class="justify-content-center" style="min-width: 220px;">
              <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
                <button mat-mini-fab class="mat-elevation-z1"
                        [style.background]="getNivelColor(form.get('IDNIVEL').value)"
                        [style.color]="'white'">
                  <mat-icon>priority_high</mat-icon>
                </button>
                <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
                  <span
                    class="text-sm color-grey-600">{{'analisis.vulnerabilidades.list.item.nivelactual' | translate}}</span>
                  <span class="text-sm-m color-grey-700">{{getNivelByID(form.get('IDNIVEL').value)}}</span>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <mat-icon class="fecha-icono">arrow_forward</mat-icon>
              </div>
              <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
                <button mat-mini-fab class="mat-elevation-z1"
                        [style.background]="getNivelColor(form.get('IDNIVELPREVISTO').value)"
                        [style.color]="'white'">
                  <mat-icon>priority_high</mat-icon>
                </button>
                <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
                  <span class="text-sm color-grey-600">{{'analisis.vulnerabilidades.list.item.nivelprevisto'| translate}}</span>
                  <span class="text-sm-m color-grey-700">{{getNivelByID(form.get('IDNIVELPREVISTO').value)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <!--<p class="full-width section-title">{{'analisis.riesgos.form.seccion-datos' | translate}}</p>-->
        <div class="row m-0">
          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="SECTOR">{{'analisis.riesgos.form.SECTOR' | translate }}</mat-label>
            <textarea id="SECTOR" matInput formControlName="SECTOR"></textarea>
          </mat-form-field>
          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="OBSERVACIONES">{{'analisis.riesgos.form.OBSERVACIONES' | translate }}</mat-label>
            <textarea id="OBSERVACIONES" matInput formControlName="OBSERVACIONES"></textarea>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDNIVEL">{{'analisis.riesgos.form.IDNIVEL' | translate }}</mat-label>
            <mat-select id="IDNIVEL" formControlName="IDNIVEL">
              <mat-option *ngFor="let m of niveles" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDNIVELPREVISTO">{{'analisis.riesgos.form.IDNIVELPREVISTO' | translate }}</mat-label>
            <mat-select id="IDNIVELPREVISTO" formControlName="IDNIVELPREVISTO">
              <mat-option *ngFor="let m of niveles" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDPERIODO">{{'analisis.riesgos.form.IDPERIODO' | translate }}</mat-label>
            <mat-select id="IDPERIODO" formControlName="IDPERIODO">
              <mat-option *ngFor="let m of periodos" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column">
        <p class="full-width section-title">{{'analisis.riesgos.form.seccion-cambios' | translate}}</p>
        <div class="row m-0">
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDCAMBIOINTENSIDAD">{{'analisis.riesgos.form.IDCAMBIOINTENSIDAD' | translate }}</mat-label>
            <mat-select id="IDCAMBIOINTENSIDAD" formControlName="IDCAMBIOINTENSIDAD">
              <mat-option *ngFor="let m of cambios" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDCAMBIOFRECUENCIA">{{'analisis.riesgos.form.IDCAMBIOFRECUENCIA' | translate }}</mat-label>
            <mat-select id="IDCAMBIOFRECUENCIA" formControlName="IDCAMBIOFRECUENCIA">
              <mat-option *ngFor="let m of cambios" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column" class="pt-3" [style.max-height.px]="500">
        <div class="d-flex align-items-center mb-3">
          <div>
            <span class="full-width section-title">{{'analisis.riesgos.form.seccion-indicadores' | translate}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div *ngIf="editable" class="flex-shrink-0">
            <button mat-icon-button (click)="openIndicadorDialog(null)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <app-list [scrollableY]="false" [datos]="dato.INDICADORRIESGO">
          <mat-list-item *ngFor="let item of dato.INDICADORRIESGO" class="p-2 flex-grow-1 list-item"
                         (click)="openIndicadorDialog(item)">
            <div class="d-flex justify-content-start align-items-center">
              <span class="nombre">{{item.NOMBRE}}</span>
            </div>
            <div class="flex-grow-1"></div>
            <div class="d-flex flex-column justify-content-end align-items-right text-right">
              <span class="dark dato">{{item.VALOR}}</span>
              <span class="mat-caption grey">{{item.MAGNITUD}}</span>
            </div>
          </mat-list-item>
          <div *ngIf="dato.INDICADORRIESGO.length==0"
               class="full-height justify-content-center align-items-center p-4" fxLayout="column" fxLayoutGap="15px">
            <mat-icon class="large-icon color-grey-400">inbox</mat-icon>
            <span class="mat-caption">{{'analisis.riesgos.form.indicadores.empty_text' | translate}}</span>
            <button class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center" (click)="openIndicadorDialog(null)">
              <span class="text-md">{{'analisis.riesgos.form.indicadores.create_text' | translate}}</span>
            </button>
          </div>
        </app-list>
      </div>
    </form>
  </div>
</div>

import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from './services/authentication.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(translate: TranslateService, private authService: AuthenticationService, private router: Router) {
    // this language will be used as a fallback when a translation isn't found in the current language
    if (!authService.isAuthenticated()) {
      router.navigateByUrl('/login');
    }
    translate.setDefaultLang('es');

    translate.use('es');
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {forbiddenValuesValidator, maxValueValidator, minValueValidator} from '../../validators/customvalidators';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-createplanaccion',
  templateUrl: './createplanaccion.dialog.html'
})
export class CreatePlanAccionDialog  {
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.form = this.formBuilder.group({
      ANYO: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(1999)
        ])
      ],
      ANYOREFERENCIAINVENTARIO: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(1999)
        ])
      ],
      ANYOREFERENCIARIESGOS: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(1999)
        ])
      ],
      ANYOOBJETIVO: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(1999)
        ])
      ],
      OBJETIVOCONSUMO: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(0)
        ])
      ],
      OBJETIVOPRODUCCION: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(0)
        ])
      ],
      OBJETIVOEMISION: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(0)
        ])
      ],
    });
  }
}

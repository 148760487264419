<div fxLayout="column" style="height: 100vh;">
  <div fxLayout="column">
    <nav mat-tab-nav-bar class="pl-5 pr-5"  style="background: white;">
      <a mat-tab-link routerLink="/municipio/datosgenerales"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'municipio.datosgenerales.title' | translate}} </a>
      <a mat-tab-link routerLink="/municipio/centros"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'municipio.centros.title' | translate}}</a>
    </nav>
  </div>
  <div fxFlex fxLayout="column" class="pl-5 pr-5 full-height">
    <router-outlet></router-outlet>
  </div>
</div>


<h2 mat-dialog-title>{{'inventario.datosinventario.deletemodal.title' | translate}}</h2>
<div mat-dialog-content>
  <span class="mat-caption">{{'inventario.datosinventario.deletemodal.text' | translate:{number: data.number, plural: data.number > 1 ? 's' : ''} }}</span>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{'inventario.datosinventario.deletemodal.cancel' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="true">
    {{'inventario.datosinventario.deletemodal.ok' | translate }}
  </button>
</div>


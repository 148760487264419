import {BehaviorSubject} from 'rxjs/index';
import {PENDING} from '@angular/forms/src/model';

export class Petition {
  static NONE = 0;
  static LOAD = 1;
  static OK = 2;
  static KO = 3;

  subject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  id: number;
  pending: boolean;
  success: boolean;
  error: boolean;
  data: any;
  finished: boolean;
  empty: boolean = true;

  constructor(id = -1) {
    this.id = id;
  }

  ok(data) {
    this.pending = false;
    this.success = true;
    this.error = false;
    this.data = data;
    this.finished = true;
    this.empty = !data || data.length == 0;
    this.subject.next(Petition.OK);
  }

  ko(data) {
    this.pending = false;
    this.success = false;
    this.error = true;
    this.data = data;
    this.finished = true;
    this.empty = true;
    this.subject.next(Petition.KO);
  }

  load() {
    this.pending = true;
    this.success = false;
    this.error = false;
    this.data = null;
    this.finished = false;
    this.empty = true;
    this.subject.next(Petition.LOAD);
  }

  reset() {
    this.pending = false;
    this.error = false;
    this.data = null;
    this.success = false;
    this.empty = true;
    this.subject.next(Petition.NONE);
  }
}

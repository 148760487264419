import { MatSnackBar } from '@angular/material';
import { Petition } from '../model/petition';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@ngx-translate/core";
var AlertbarService = /** @class */ (function () {
    function AlertbarService(snackBar, translateSrv) {
        this.snackBar = snackBar;
        this.translateSrv = translateSrv;
    }
    AlertbarService.prototype.applyToPetition = function (petition) {
        var _this = this;
        var load_snack = null;
        petition.subject.subscribe(function (value) {
            switch (value) {
                case Petition.LOAD:
                    _this.translateSrv.get('generico.petition_snack.load').subscribe(function (value2) {
                        load_snack = _this.snackBar.open(value2);
                    });
                    break;
                case Petition.OK:
                    if (load_snack) {
                        load_snack.dismiss();
                    }
                    _this.translateSrv.get('generico.petition_snack.ok').subscribe(function (value2) {
                        _this.snackBar.open(value2, null, { duration: 2500 });
                    });
                    break;
                case Petition.KO:
                    if (load_snack) {
                        load_snack.dismiss();
                    }
                    _this.translateSrv.get('generico.petition_snack.ko').subscribe(function (value2) {
                        _this.snackBar.open(value2, null, { duration: 2500 });
                    });
                    break;
            }
        });
    };
    AlertbarService.prototype.open = function (text, duration) {
        var _this = this;
        if (duration === void 0) { duration = 2; }
        this.translateSrv.get('generico.petition.ok').subscribe(function (value2) {
            _this.snackBar.open(value2, null, { duration: duration });
        });
    };
    AlertbarService.ngInjectableDef = i0.defineInjectable({ factory: function AlertbarService_Factory() { return new AlertbarService(i0.inject(i1.MatSnackBar), i0.inject(i2.TranslateService)); }, token: AlertbarService, providedIn: "root" });
    return AlertbarService;
}());
export { AlertbarService };

<form [formGroup]="dataForm.form">
  <app-admin-form [dataForm]="dataForm" [title]="'admin.inventario.ambito.dialog_title'">
    <ng-template let-form="form">
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.ambito.NOMBRE.label' | translate }}</mat-label>
          <input type="TEXT" matInput formControlName="NOMBRE" required>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-radio-group class="col-lg-12 col-sm-12" formControlName="DEPENDE_AYTO">
          <mat-radio-button [value]="true">{{'admin.inventario.ambito.dependeayto' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'admin.inventario.ambito.nodependeayto' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.ambito.IDSUPERAMBITO.label' | translate }}
          </mat-label>
          <mat-select formControlName="IDSUPERAMBITO" [compareWith]="compareByID">
            <mat-option *ngFor="let t of superambitosDisponibles()" [value]="t.ID">{{t.NOMBRE}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.ambito.IDSUBAMBITO.label' | translate }}
          </mat-label>
          <mat-select formControlName="IDSUBAMBITO" [compareWith]="compareByID">
            <mat-option *ngFor="let t of subambitos" [value]="t.ID">{{t.NOMBRE}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
  </app-admin-form>
</form>

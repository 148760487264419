import {Injectable} from '@angular/core';

@Injectable()
export class FormsService {

  public inventario_create_dirty = false;
  public municipio_datos_dirty = false;

  constructor() {
  }

  setInventarioDatosGeneralesDirty(bool) {
    this.inventario_create_dirty = bool;
  }

  isInventarioDatosGeneralesDirty() {
    return this.inventario_create_dirty;
  }
}

var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { Superambito } from '../../../model/superambito';
import { DatosInventario } from '../../../model/datosinventario';
import { AmbitoService } from '../../../services/ambito.service';
import { InventarioService } from '../../../services/inventario.service';
import { MunicipioService } from '../../../services/municipio.service';
import { DatosInventarioService } from '../../../services/datosinventario.service';
import { InventarioEmisiones } from '../../../model/inventarioemisiones';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as uniqBy from 'lodash/uniqBy';
import { InvDatosEspecificosComponent } from '../inv-datos-especificos/inv-datos-especificos.component';
import { Petition } from '../../../model/petition';
var InvDatosComponent = /** @class */ (function () {
    function InvDatosComponent(dialog, invsrv, munsvc, ambsrv, disrv, translateService, snackBar) {
        var _this = this;
        this.dialog = dialog;
        this.invsrv = invsrv;
        this.munsvc = munsvc;
        this.ambsrv = ambsrv;
        this.disrv = disrv;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.inventarioActivo = new InventarioEmisiones();
        this.ambitos = [];
        this.ambitosSeleccionables = [];
        this.datosInventarios = [];
        this.superambitos = [];
        this.superambitoActivo = new Superambito();
        this.ambitosActivos = [];
        this.factores = {};
        this.cols = [
            {
                id: 'icon',
                title: '',
                type: 'icon',
                width: '60px',
                data: {
                    icon: 'account_balance',
                    badge: function (obj) { return obj.DATOS_CENTRO.length; },
                    show: function (obj) { return obj.AMBITO.SUPERAMBITO.DEPENDE_AYTO; },
                    callback: function (obj) { return _this.openDatosCentroDialog(obj); },
                },
                editable: false,
                tooltip: 'inventario.datosinventario.tooltip-depende_ayto'
            },
            {
                id: 'ambito',
                title: 'inventario.datosinventario.col-ambito',
                type: 'text',
                data: {
                    prop: 'AMBITO.SUPERAMBITO.NOMBRE',
                    filter: ['AMBITO.SUPERAMBITO.NOMBRE']
                },
                editable: false,
                sort: 'desc',
                tooltip: 'inventario.datosinventario.tooltip-ambito'
            },
            {
                id: 'fuente',
                title: 'inventario.datosinventario.col-fuente',
                type: 'text',
                data: {
                    prop: 'AMBITO.SUBAMBITO.FUENTE.NOMBRE',
                    filter: ['AMBITO.SUBAMBITO.FUENTE.NOMBRE']
                },
                editable: false,
                sort: 'desc',
                tooltip: 'inventario.datosinventario.tooltip-fuente'
            },
            {
                id: 'valor',
                title: 'inventario.datosinventario.col-valor',
                data: {
                    prop: 'VALOR'
                },
                type: 'number',
                editable: true,
                scope: 'row',
                tooltip: 'inventario.datosinventario.col-valor'
            },
            {
                id: 'factorconversion',
                title: 'inventario.datosinventario.col-factorconversion',
                data: {
                    prop: 'FACTORCONVERSION'
                },
                type: 'number',
                editable: true,
                tooltip: 'inventario.datosinventario.tooltip-factorconversion'
            },
            {
                id: 'valormwh',
                title: 'inventario.datosinventario.col-mwh',
                type: 'function',
                data: {
                    func: function (obj) { return Math.round((obj.VALOR * (obj.FACTORCONVERSION ? obj.FACTORCONVERSION : 1)) * 1000) / 1000; }
                },
                editable: false
            },
            {
                id: 'factoremision',
                title: 'inventario.datosinventario.col-factoremision',
                type: 'number',
                data: {
                    prop: 'FACTOREMISION'
                },
                editable: true,
                tooltip: 'inventario.datosinventario.tooltip-factoremision'
            },
            {
                id: 'emisiones',
                title: 'inventario.datosinventario.col-emisiones',
                type: 'function',
                data: {
                    func: function (obj) { return Math.round((obj.VALOR * (obj.FACTORCONVERSION ? obj.FACTORCONVERSION : 1) * obj.FACTOREMISION) * 1000) / 1000; }
                },
                editable: false,
                tooltip: 'inventario.datosinventario.tooltip-emisiones'
            }
        ];
        this.data_update = new Petition();
        this.data_fetch = new Petition();
        this.invsrv.sel_inventario.subscribe(function (value) {
            if (value) {
                _this.inventarioActivo = value;
                _this.loadDatosInventario();
            }
        }, function (error2) { return console.log(error2); });
    }
    InvDatosComponent.prototype.ngOnInit = function () {
        this.initialize();
    };
    InvDatosComponent.prototype.obtenerSuperambitos = function (ambitos) {
        return ambitos.map(function (a) { return a.SUPERAMBITO; });
    };
    InvDatosComponent.prototype.onChangeSuperambito = function (superambito) {
        var _this = this;
        this.ambitosSeleccionables = this.ambitos.filter(function (a) { return (a.IDSUPERAMBITO === superambito.ID &&
            _this.datosInventarios.filter(function (d) { return d.IDAMBITO === a.ID; }).length === 0); }).map(function (a) {
            return __assign({}, a, { SUPERAMBITO: superambito });
        });
        this.superambitoActivo = superambito;
    };
    InvDatosComponent.prototype.update_item = function (item) {
        var _this = this;
        this.disrv.getById(item.ID).subscribe(function (value) {
            var index = _this.datosInventarios.findIndex(function (a) { return a.ID == value.ID; });
            _this.datosInventarios[index] = value;
        });
    };
    InvDatosComponent.prototype.update_dato_inventario = function (data) {
        var _this = this;
        var dato_inventario = new DatosInventario(data).toDB();
        if (this.inventarioActivo.ID) {
            this.data_update.load();
            this.disrv.update(dato_inventario).subscribe(function (value) {
                _this.data_update.ok(value);
                _this.translateService.get('inventario.datosinventario.updated').subscribe(function (d) { return _this.snackBar.open(d, null, { duration: 3000 }); });
                _this.update_item(value);
            }, function (error2) {
                _this.data_update.ko(error2);
                _this.translateService.get('inventario.datosinventario.error').subscribe(function (d) { return _this.snackBar.open(d, null, { duration: 3000 }); });
            });
        }
    };
    InvDatosComponent.prototype.update_datos_inventario = function (data) {
        var _this = this;
        var datos_inventario = data.map(function (d) { return new DatosInventario(d).toDB(); });
        if (this.inventarioActivo.ID) {
            this.data_update.load();
            this.disrv.updateAll(datos_inventario).subscribe(function (value) {
                _this.data_update.ok(value);
                _this.translateService.get('inventario.datosinventario.updated').subscribe(function (d) { return _this.snackBar.open(d, null, { duration: 3000 }); });
                _this.loadDatosInventario();
            }, function (error2) {
                _this.data_update.ko(error2);
                _this.translateService.get('inventario.datosinventario.error').subscribe(function (d) { return _this.snackBar.open(d, null, { duration: 3000 }); });
                _this.loadDatosInventario();
            });
        }
    };
    InvDatosComponent.prototype.loadDatosInventario = function () {
        var _this = this;
        this.data_fetch.load();
        this.disrv.getAll().subscribe(function (value) {
            if (value) {
                _this.datosInventarios = value;
                var factores = value.map(function (d) {
                    return { ambito: d.AMBITO.SUBAMBITO, fconversion: d.FACTORCONVERSION, femision: d.FACTOREMISION };
                });
                _this.factores = factores.filter(function (obj, pos, arr) {
                    return arr.map(function (mapObj) { return mapObj.ambito.ID; }).indexOf(obj.ambito.ID) === pos;
                });
                _this.data_fetch.ok(value);
            }
        }, function (error2) {
            _this.data_fetch.ko(error2);
        });
    };
    InvDatosComponent.prototype.onlyUnique = function (value, index, self) {
        return self.indexOf(value) === index;
    };
    InvDatosComponent.prototype.addDatoInventario = function (data) {
        var _this = this;
        var datos_inventario = this.datosInventarios.slice();
        data.forEach(function (a) {
            var tdatosinventario = new DatosInventario({});
            tdatosinventario.IDAMBITO = a.ID;
            tdatosinventario.IDINVENTARIO = _this.inventarioActivo.ID;
            tdatosinventario.VALOR = null;
            tdatosinventario.FACTORCONVERSION = null;
            tdatosinventario.FACTOREMISION = null;
            tdatosinventario.AMBITO = a;
            tdatosinventario.FACTOREMISION = a.SUBAMBITO.DEFAULTFEMISION;
            tdatosinventario.FACTORCONVERSION = a.SUBAMBITO.DEFAULTFCONVERSION;
            datos_inventario.push(tdatosinventario);
        });
        this.datosInventarios = datos_inventario;
        this.update_datos_inventario(this.datosInventarios);
    };
    InvDatosComponent.prototype.deleteDatosInventario = function (indexes) {
        var data = this.datosInventarios.slice();
        var sortedIndexes = indexes.sort(function (a, b) { return b - a; });
        sortedIndexes.forEach(function (index) { return data.splice(index, 1); });
        this.datosInventarios = data;
        this.update_datos_inventario(this.datosInventarios);
    };
    InvDatosComponent.prototype.initialize = function () {
        var _this = this;
        this.ambitos = [];
        this.superambitos = [];
        this.superambitoActivo = new Superambito();
        this.ambitosActivos = [];
        this.datosInventarios = [];
        this.loadDatosInventario();
        this.ambsrv.getAll().subscribe(function (value) {
            if (value) {
                _this.ambitos = value;
                _this.superambitos = _this.obtenerSuperambitos(value);
            }
        }, function (error2) {
            return console.log(error2);
        });
    };
    InvDatosComponent.prototype.openNuevoDatoInventarioDialog = function () {
        var _this = this;
        var ambitos_en_tabla = this.datosInventarios.map(function (di) { return di.IDAMBITO; });
        var ambitos_no_en_tabla = this.ambitos.filter(function (a) { return !ambitos_en_tabla.includes(a.ID); });
        var superambitos_no_en_tabla = ambitos_no_en_tabla.map(function (a) { return a.IDSUPERAMBITO; });
        var superambitos = uniqBy(this.superambitos.filter(function (sa) { return superambitos_no_en_tabla.includes(sa.ID); }), 'ID');
        if (superambitos_no_en_tabla.length > 0 && ambitos_no_en_tabla.length > 0) {
            var dialogRef = this.dialog.open(NuevoDatoInventarioDialog, {
                data: { superambitos: superambitos, ambitos: ambitos_no_en_tabla, datosInventarios: this.datosInventarios },
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(function (data) {
                if (data) {
                    _this.addDatoInventario(data);
                }
            });
        }
        else {
            this.translateService.get('inventario.datosinventario.cantadd').subscribe(function (d) { return _this.snackBar.open(d, null, { duration: 3000 }); });
        }
    };
    InvDatosComponent.prototype.openDatosCentroDialog = function (datoinventario) {
        var _this = this;
        var dialogRef = this.dialog.open(InvDatosEspecificosComponent, {
            data: { dato_inventario: datoinventario },
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe(function (ambitos) {
            if (ambitos)
                _this.loadDatosInventario();
        });
    };
    InvDatosComponent.prototype.openFactoresDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FactoresDialog, {
            data: { factores: this.factores },
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe(function (factores) {
            if (factores) {
                _this.datosInventarios.forEach(function (di) {
                    var factor = factores.find(function (f) { return f.ambito.ID == di.AMBITO.SUBAMBITO.ID; });
                    di.FACTOREMISION = factor.femision;
                    di.FACTORCONVERSION = factor.fconversion;
                });
                _this.update_datos_inventario(_this.datosInventarios);
            }
        });
    };
    InvDatosComponent.prototype.openDeleteDialog = function (data) {
        var _this = this;
        if (data) {
            var indexes_1 = data;
            var dialogRef = this.dialog.open(DeleteDatoInventarioDialog, {
                data: { number: indexes_1.length },
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(function (bool) {
                if (bool) {
                    _this.deleteDatosInventario(indexes_1);
                }
            });
        }
    };
    return InvDatosComponent;
}());
export { InvDatosComponent };
// noinspection TsLint
var NuevoDatoInventarioDialog = /** @class */ (function () {
    function NuevoDatoInventarioDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        data.depende_ayto = true;
        data.ambitosActivos = [];
        data.ambitosSeleccionables = [];
        data.superambitosSeleccionables = [];
        this.onChangeTipo();
    }
    NuevoDatoInventarioDialog.prototype.onChangeSuperambito = function () {
        var _this = this;
        this.data.ambitosSeleccionables = this.data.ambitos
            .filter(function (a) { return (a.IDSUPERAMBITO === _this.data.superambitoActivo.ID); });
    };
    NuevoDatoInventarioDialog.prototype.onChangeTipo = function () {
        var _this = this;
        this.data.superambitosSeleccionables = this.data.superambitos.filter(function (a) { return (a.DEPENDE_AYTO == _this.data.depende_ayto); });
        if (this.data.superambitosSeleccionables) {
            this.data.superambitoActivo = this.data.superambitosSeleccionables[0];
        }
        this.onChangeSuperambito();
    };
    return NuevoDatoInventarioDialog;
}());
export { NuevoDatoInventarioDialog };
// noinspection TsLint
var FactoresDialog = /** @class */ (function () {
    function FactoresDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.cols = [
            {
                id: 'ambito',
                title: 'inventario.datosinventario.col-ambito',
                type: 'text',
                data: {
                    prop: 'ambito.FUENTE.NOMBRE',
                },
                width: '200px',
                editable: false,
                sort: 'desc',
                tooltip: 'inventario.datosinventario.tooltip-ambito'
            },
            {
                id: 'factorconversion',
                title: 'inventario.datosinventario.col-factorconversion',
                data: {
                    prop: 'fconversion'
                },
                type: 'number',
                editable: true,
                tooltip: 'inventario.datosinventario.tooltip-factorconversion'
            },
            {
                id: 'factoremision',
                title: 'inventario.datosinventario.col-factoremision',
                type: 'number',
                data: {
                    prop: 'femision'
                },
                editable: true,
                tooltip: 'inventario.datosinventario.tooltip-factoremision'
            }
        ];
    }
    return FactoresDialog;
}());
export { FactoresDialog };
// noinspection TsLint
var DeleteDatoInventarioDialog = /** @class */ (function () {
    function DeleteDatoInventarioDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    return DeleteDatoInventarioDialog;
}());
export { DeleteDatoInventarioDialog };

<h2 mat-dialog-title>{{'admin.usuario.dialog.title' | translate}}</h2>
<form [formGroup]="dataForm.form" *ngIf="dataForm" novalidate>
  <div id="formulario">
    <div class="p-2 pl-3 pr-3">
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.usuario.USERID.label' | translate }}</mat-label>
          <input type="text" matInput formControlName="USERID" required>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.usuario.PASSWORD.label' | translate }}</mat-label>
          <div class="flex">
            <input class="flex-grow" [type]="showPassword ? 'text' : 'password'" matInput formControlName="PASSWORD"
                   required>
            <i
              [class]="!showPassword ? 'text-grey cursor-pointer material-icons' : 'cursor-pointer text-grey-darkest material-icons'"
              (click)="togglePassword()">remove_red_eye</i>
          </div>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.usuario.NOMBRE.label' | translate }}</mat-label>
          <input type="text" matInput formControlName="NOMBRE" required>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.usuario.APELLIDOS.label' | translate }}</mat-label>
          <input type="text" matInput formControlName="APELLIDOS" required>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.usuario.EMAIL.label' | translate }}</mat-label>
          <input type="email" matInput formControlName="EMAIL" required>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4" *ngIf="authSrv.getCurrentUser().userid != dataForm.form.controls['USERID'].value">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.usuario.ROL.label' | translate }}</mat-label>
          <mat-select formControlName="ROL">
            <mat-option *ngFor="let t of roles" [value]="t.ID">{{'extra.rolusuario.'+t.ID | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-lg-6 col-sm-12">
          <div class="flex flex-column justify-center align-start">
            <span class="text-sm color-grey-600">{{'admin.usuario.item.permiso' | translate}}</span>
            <div class="flex items-center justify-start">
              <span *ngIf="getCanEdit()" class="text-lg text-grey-darkest font-weight-bold">{{'admin.usuario.item.permisoescritura' | translate}}</span>
              <span *ngIf="!getCanEdit()" class="text-lg text-grey-darkest font-weight-bold">{{'admin.usuario.item.permisolectura' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="warn" [mat-dialog-close]="closeDelete()">{{'forms.actions.delete' | translate}}
        </button>
        <button mat-button [mat-dialog-close]="">{{'forms.actions.cancel' | translate}}</button>
        <button mat-button color="primary" [mat-dialog-close]="closeOk()"
                [disabled]="!dataForm.form.valid || !dataForm.form.dirty">
          {{'forms.actions.save' | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
<div *ngIf="!dataForm" class="flex justify-center align-center">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

import {Component, OnInit} from '@angular/core';
import * as FileSaver from 'file-saver';
import {DocumentosService} from '../../services/documentos.service';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.scss']
})
export class InformesComponent implements OnInit {
  informes = [
    {
      id: 'seguimientopaces',
      nombre: 'Informe seguimiento',
      img: 'assets/img/informes/infsegpaces.png',
      url: '6'
    },
    {
      id: 'fichasacciones',
      nombre: 'Fichas acciones',
      img: 'assets/img/informes/fichaccpaces.png',
      url: '1'
    },
    {
      id: 'informeresumeninventario',
      nombre: 'Informe resumen inventario de emisiones',
      img: 'assets/img/informes/infresinvemi.png',
      url: '2'
    },
    {
      id: 'informeresumentarvcc',
      nombre: 'Informe resumen ARVCC',
      img: 'assets/img/informes/infresarvcc.png',
      url: '3'
    }
  ];

  constructor(private docSrv: DocumentosService) {
  }

  ngOnInit() {
  }

  download(item) {
    const url = 'http://www.azigrene-energiza.es:7080/Files/images/docs/';
    this.docSrv.downloadInforme(url+item.url+'.pdf').subscribe((res: Blob) => {
      const myBlob: Blob = new Blob([res], {
        type: res.type
      });
      var filename = item.nombre +'.pdf';
      FileSaver.saveAs(myBlob, filename);
    }, error2 => {

    });
  }
}

import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ListatiposService} from '../../../services/listatipos.service';
import {PlanaccionService} from '../../../services/planaccion.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {AuthenticationService} from '../../../services/authentication.service';
import {IndicadorVulnerabilidad} from '../../../model/Analisis';

@Component({
  selector: 'app-indicadorvulnerabilidadform',
  templateUrl: './indicadorform.component.html',
  styleUrls: ['./indicadorform.component.scss']
})
export class IndicadorVulnerabilidadformComponent implements OnInit, OnChanges {
  form: FormGroup;
  editable = false;

  ngOnInit(): void {
    if ((this.data.action == 'edit' || this.data.action == 'view') && this.data.INDICADORVULNERABILIDAD) {
      const dato: IndicadorVulnerabilidad = this.data.INDICADORVULNERABILIDAD;
      this.form.reset(dato);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  constructor(public dialogRef: MatDialogRef<IndicadorVulnerabilidadformComponent>, private userSrv: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any, private tipoSrv: ListatiposService, private formBuilder: FormBuilder, private translateService: TranslateService, private snackBar: MatSnackBar) {
    this.form = this.formBuilder.group(new IndicadorVulnerabilidad());
    this.editable = this.userSrv.isEditable();
    if (!this.editable)
      this.form.disable();
  }

  setIndicadorPred(value) {
    this.form.patchValue({
      NOMBRE: value.NOMBRE
    });
  }

  save() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

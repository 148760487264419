import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
var ListComponent = /** @class */ (function () {
    function ListComponent(authSrv) {
        this.authSrv = authSrv;
        this.title = '';
        this.simple = true;
        this.scrollableY = true;
        this.maxHeight = null;
        this.loading = false;
        this.add = false;
        this.search = false;
        this.fullHeight = true;
        this.searchCallback = new EventEmitter();
        this.searchPlaceholder = '';
        this.addCallback = new EventEmitter();
        this.searchText = '';
        this.activeOrder = {
            pos: -1,
            order: null,
            dir: 0
        };
    }
    ListComponent.prototype.ngOnInit = function () {
    };
    ListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.datos) {
            this.selectOrder(this.activeOrder.order, this.activeOrder.pos, this.activeOrder.dir);
        }
    };
    ListComponent.prototype.onClearSearch = function (input) {
        input.value = '';
        this.searchCallback.next('');
    };
    ListComponent.prototype.onSearch = function (input) {
        this.searchText = input.target.value;
        this.searchCallback.next(input.target.value);
    };
    ListComponent.prototype.nestedObj = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            }
            else {
                return;
            }
        }
        return o;
    };
    ListComponent.prototype.selectOrder = function (order, pos, dir) {
        var _this = this;
        var actual_order = {
            pos: pos,
            order: order,
            dir: dir
        };
        if (this.activeOrder && actual_order.pos == this.activeOrder.pos && actual_order.dir == this.activeOrder.dir) {
            this.activeOrder = {
                pos: -1,
                order: null,
                dir: 0
            };
        }
        else {
            this.activeOrder = actual_order;
            this.datos.sort(function (a, b) {
                var propertyA = '';
                var propertyB = '';
                _a = [_this.nestedObj(a, actual_order.order.prop), _this.nestedObj(b, actual_order.order.prop)], propertyA = _a[0], propertyB = _a[1];
                var valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                var valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                return (valueA < valueB ? -1 : 1) * actual_order.dir;
                var _a;
            });
        }
    };
    return ListComponent;
}());
export { ListComponent };
var Order = /** @class */ (function () {
    function Order() {
    }
    return Order;
}());

<form [formGroup]="form" novalidate>
  <h2 mat-dialog-title>{{'planaccion.createmodal.title' | translate}}</h2>
  <div mat-dialog-content fxLayout="column">
    <div fxLayout="row" class="mb-4" fxLayoutGap="10px">
      <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.anyo.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="ANYO" required>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYO').hasError('required') && (form.get('ANYO').dirty || form.get('ANYO').touched)">
          {{'planaccion.createmodal.anyo.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYO').hasError('validMinValue') && (form.get('ANYO').dirty || form.get('ANYO').touched)">
          {{'planaccion.createmodal.anyo.minValue' | translate:{value: 1999} }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%"  [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.anyoinventario.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="ANYOREFERENCIAINVENTARIO" required>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYOREFERENCIAINVENTARIO').hasError('required') && (form.get('ANYOREFERENCIAINVENTARIO').dirty || form.get('ANYOREFERENCIAINVENTARIO').touched)">
          {{'planaccion.createmodal.anyoinventario.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYOREFERENCIAINVENTARIO').hasError('validMinValue') && (form.get('ANYOREFERENCIAINVENTARIO').dirty || form.get('ANYOREFERENCIAINVENTARIO').touched)">
          {{'planaccion.createmodal.anyoinventario.minValue' | translate:{value: 1999} }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%"  [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.anyoriesgos.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="ANYOREFERENCIARIESGOS" required>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYOREFERENCIARIESGOS').hasError('required') && (form.get('ANYOREFERENCIARIESGOS').dirty || form.get('ANYOREFERENCIARIESGOS').touched)">
          {{'planaccion.createmodal.anyoriesgos.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYOREFERENCIARIESGOS').hasError('validMinValue') && (form.get('ANYOREFERENCIARIESGOS').dirty || form.get('ANYOREFERENCIARIESGOS').touched)">
          {{'planaccion.createmodal.anyoriesgos.minValue' | translate:{value: 1999} }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%"  [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.anyoobjetivo.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="ANYOOBJETIVO" required>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYOOBJETIVO').hasError('required') && (form.get('ANYOOBJETIVO').dirty || form.get('ANYOOBJETIVO').touched)">
          {{'planaccion.createmodal.anyoobjetivo.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('ANYOOBJETIVO').hasError('validMinValue') && (form.get('ANYOOBJETIVO').dirty || form.get('ANYOOBJETIVO').touched)">
          {{'planaccion.createmodal.anyoobjetivo.minValue' | translate:{value: 1999} }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="mb-4">
      <mat-form-field class="medium-field"  fxFlex.gt-md="33%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.objetivoconsumo.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="OBJETIVOCONSUMO" required>
        <mat-error class="error-message"
                   *ngIf="form.get('OBJETIVOCONSUMO').hasError('required') && (form.get('OBJETIVOCONSUMO').dirty || form.get('OBJETIVOCONSUMO').touched)">
          {{'planaccion.createmodal.objetivoconsumo.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('OBJETIVOCONSUMO').hasError('validMinValue') && (form.get('OBJETIVOCONSUMO').dirty || form.get('OBJETIVOCONSUMO').touched)">
          {{'planaccion.createmodal.objetivoconsumo.minValue' | translate:{value: 0} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="medium-field"  fxFlex.gt-md="33%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.objetivoproduccion.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="OBJETIVOPRODUCCION" required>
        <mat-error class="error-message"
                   *ngIf="form.get('OBJETIVOPRODUCCION').hasError('required') && (form.get('OBJETIVOPRODUCCION').dirty || form.get('OBJETIVOPRODUCCION').touched)">
          {{'planaccion.createmodal.objetivoproduccion.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('OBJETIVOPRODUCCION').hasError('validMinValue') && (form.get('OBJETIVOPRODUCCION').dirty || form.get('OBJETIVOPRODUCCION').touched)">
          {{'planaccion.createmodal.objetivoproduccion.minValue' | translate:{value: 0} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="medium-field"  fxFlex.gt-md="33%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
        <mat-label>{{'planaccion.createmodal.objetivoemision.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="OBJETIVOEMISION" required>
        <mat-error class="error-message"
                   *ngIf="form.get('OBJETIVOEMISION').hasError('required') && (form.get('OBJETIVOEMISION').dirty || form.get('OBJETIVOEMISION').touched)">
          {{'planaccion.createmodal.objetivoemision.requiredMessage' | translate }}
        </mat-error>
        <mat-error class="error-message"
                   *ngIf="form.get('OBJETIVOEMISION').hasError('validMinValue') && (form.get('OBJETIVOEMISION').dirty || form.get('OBJETIVOEMISION').touched)">
          {{'planaccion.createmodal.objetivoemision.minValue' | translate:{value: 0} }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="">
      {{'planaccion.createmodal.cancel' | translate }}
    </button>
    <button color="primary" mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">
      {{'planaccion.createmodal.ok' | translate }}
    </button>
  </div>
</form>


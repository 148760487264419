import { Injectable } from "@angular/core";
import { Municipio, MunicipioDB } from "../model/municipio";
import { appConfig } from "../app.config";
import { HttpClient } from "@angular/common/http";
import { Superambito } from "../model/superambito";
import { Fuente, Subambito } from "../model/subambito";
import { Accion } from "../model/accion";
import { ZonaClimatica } from "../model/zonaclimatica";
import { RiesgoPred, SectorRiesgoPred } from "../model/Analisis";
import { Session } from "../model/session";

@Injectable({
  providedIn: "root"
})
export class AdminService {
  municipioURL = "";

  constructor(private http: HttpClient) {}
  //MUNICIPIO
  createMunicipio(municipio: Municipio) {
    return this.http.post(
      appConfig.apiUrl + "MUNICIPIO",
      new MunicipioDB(municipio)
    );
  }

  updateMunicipio(municipio: Municipio) {
    return this.http.put(
      appConfig.apiUrl + "MUNICIPIO/" + municipio.ID,
      new MunicipioDB(municipio)
    );
  }

  deleteMunicipio(municipio: Municipio) {
    return this.http.delete(appConfig.apiUrl + "MUNICIPIO/" + municipio.ID);
  }
  //SUPERAMBITO
  createSuperambito(d: Superambito) {
    return this.http.post(appConfig.apiUrl + "ADMIN/SuperAmbito", d);
  }

  updateSuperambito(d: Superambito) {
    return this.http.put(appConfig.apiUrl + "ADMIN/SuperAmbito/" + d.ID, d);
  }

  deleteSuperambito(d: Superambito) {
    return this.http.delete(appConfig.apiUrl + "ADMIN/SuperAmbito/" + d.ID);
  }
  //SUBAMBITO
  createSubambito(d: Subambito) {
    return this.http.post(appConfig.apiUrl + "ADMIN/SubAmbito", d);
  }

  updateSubambito(d: Subambito) {
    return this.http.put(appConfig.apiUrl + "ADMIN/SubAmbito/" + d.ID, d);
  }

  deleteSubambito(d: Subambito) {
    return this.http.delete(appConfig.apiUrl + "ADMIN/SubAmbito/" + d.ID);
  }
  //FUENTE
  createFuente(d: Fuente) {
    return this.http.post(appConfig.apiUrl + "ADMIN/Fuente", d);
  }

  updateFuente(d: Fuente) {
    return this.http.put(appConfig.apiUrl + "ADMIN/Fuente/" + d.ID, d);
  }

  deleteFuente(d: Fuente) {
    return this.http.delete(appConfig.apiUrl + "ADMIN/Fuente/" + d.ID);
  }
  //ACCION
  createAccion(d: Accion) {
    return this.http.post(appConfig.apiUrl + "ADMIN/Accion", d);
  }

  updateAccion(d: Accion) {
    return this.http.put(appConfig.apiUrl + "ADMIN/Accion/" + d.ID, d);
  }

  deleteAccion(d: Accion) {
    return this.http.delete(appConfig.apiUrl + "ADMIN/Accion/" + d.ID);
  }
  //ZONACLIMATICA
  createZonaClimatica(d: ZonaClimatica) {
    return this.http.post(appConfig.apiUrl + "ADMIN/ZonaClimatica", d);
  }

  updateZonaClimatica(d: ZonaClimatica) {
    return this.http.put(appConfig.apiUrl + "ADMIN/ZonaClimatica/" + d.ID, d);
  }

  deleteZonaClimatica(d: ZonaClimatica) {
    return this.http.delete(appConfig.apiUrl + "ADMIN/ZonaClimatica/" + d.ID);
  }
  //RIESGOSPRED
  createRiesgoPred(d: RiesgoPred) {
    return this.http.post(appConfig.apiUrl + "ADMIN/RiesgoPred", d);
  }

  updateRiesgoPred(d: RiesgoPred) {
    return this.http.put(appConfig.apiUrl + "ADMIN/RiesgoPred/" + d.ID, d);
  }

  deleteRiesgoPred(d: RiesgoPred) {
    return this.http.delete(appConfig.apiUrl + "ADMIN/RiesgoPred/" + d.ID);
  }
  //SECTOR RIESGO
  createSectorRiesgoPred(d: SectorRiesgoPred) {
    return this.http.post(appConfig.apiUrl + "ADMIN/SectorRiesgoPred", d);
  }

  updateSectorRiesgoPred(d: SectorRiesgoPred) {
    return this.http.put(
      appConfig.apiUrl + "ADMIN/SectorRiesgoPred/" + d.ID,
      d
    );
  }

  deleteSectorRiesgoPred(d: SectorRiesgoPred) {
    return this.http.delete(
      appConfig.apiUrl + "ADMIN/SectorRiesgoPred/" + d.ID
    );
  }

  // SESSIONES
  getSessionsActiva() {
    return this.http.get<Session[]>(appConfig.apiUrl + "SESION/admin/activas");
  }
  closeSessionActiva(id: number) {
    return this.http.delete<Session>(appConfig.apiUrl + "SESION/admin/" + id);
  }
}

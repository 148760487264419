import { Component, HostListener, OnInit } from "@angular/core";
import { MunicipioService } from "../../services/municipio.service";
import { InventarioService } from "../../services/inventario.service";
import { InventarioEmisiones } from "../../model/inventarioemisiones";
import { Municipio } from "../../model/municipio";
import { Petition } from "../../model/petition";
import { DatosInventarioService } from "../../services/datosinventario.service";
import { PlanAccion } from "../../model/planaccion";
import { PlanaccionService } from "../../services/planaccion.service";
import { CentroService } from "../../services/centro.service";
import { Centro } from "../../model/centro";
import { AccionesService } from "../../services/acciones.service";
import { Graficas } from "../../model/dashboard";
import { Analisis } from "../../model/Analisis";
import { AnalisisService } from "../../services/analisis.service";
import { TranslateService } from "@ngx-translate/core";
import { DecimalPipe } from "@angular/common";
import { DocumentosService } from "../../services/documentos.service";
import { Documento } from "../../model/documento";

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  mobile: boolean;
  documentos: Documento[] = [];
  estadosacciones = [
    {
      name: "Propuesta",
      value: 20
    },
    {
      name: "En proceso",
      value: 7
    },
    {
      name: "Finalizada",
      value: 3
    }
  ];

  formatting = {
    tco2: a => {
      return a + " tCO2";
    }
  };

  getDocumentoCheckColor(id) {
    const value = this.documentos.filter(d => d.IDTIPO == id).length;
    let color = "#E0E0E0";
    if (value > 0) {
      color = "#6FCF97";
    }
    return color;
  }

  getDocumentoTextColor(id) {
    const value = this.documentos.filter(d => d.IDTIPO == id).length;
    let color = "#BDBDBD";
    if (value > 0) {
      color = "#4F4F4F";
    }
    return color;
  }

  config() {
    return {
      dots: true,
      arrows: false,
      infinite: false,
      variableWidth: true,
      swipeToSlide: true
    };
  }

  ngOnInit(): void {
    if (window.innerWidth < 1390) {
      this.mobile = true;
    }
    if (window.innerWidth > 1390) {
      this.mobile = false;
    }
    this.munSrv
      .getById(this.munSrv.getMunicipioActivo().ID)
      .subscribe(value => {
        this.municipio = value;
        this.initialize();
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 1390) {
      this.mobile = true;
    }
    if (event.target.innerWidth > 1390) {
      this.mobile = false;
    }
  }

  inventarios: InventarioEmisiones[] = [];
  planesaccion: PlanAccion[] = [];
  centros: Centro[] = [];
  ultimo_plan: PlanAccion;
  ultimo_inventario: InventarioEmisiones;
  inventario_referencia: InventarioEmisiones;
  ultimo_analisis: Analisis;
  municipio: Municipio;
  inv_fetch = new Petition();
  datinv_fetch = new Petition();
  plan_fetch = new Petition();
  acciones_fetch = new Petition();
  centros_fetch = new Petition();
  ana_fetch = new Petition();
  graficas_fetch = new Petition();
  graficos: Graficas;

  totalemisiones_ref = 0;
  totalemisiones_last = 0;
  totalconsumo_ref = 0;
  totalconsumo_last = 0;

  chart_plan_estados = [];
  chart_inventario_ambitos = [];

  constructor(
    private munSrv: MunicipioService,
    private invSrv: InventarioService,
    private planSrv: PlanaccionService,
    private centrosSrv: CentroService,
    private anaSrv: AnalisisService,
    private translateService: TranslateService,
    private docSrv: DocumentosService
  ) {
    this.munSrv.sel_municipio.subscribe(value => {
      this.municipio = value;
      this.initialize();
    });
  }

  initialize() {
    this.fetchInventarios();
    this.fetchPlanAccion();
    this.fetchCentros();
    this.fetchGraficas();
    this.fetchAnalisis();
    this.fetchDocumentos();
  }

  fetchDocumentos() {
    this.docSrv.getAll().subscribe(value => {
      this.documentos = value;
    });
  }

  getAmbitosDependientes() {
    return this.ultimo_inventario.DATOS_INVENTARIO.filter(d =>
      d.AMBITO ? d.AMBITO.SUPERAMBITO.DEPENDE_AYTO : false
    ).length;
  }

  getDatosCentro() {
    const datoscentro = this.ultimo_inventario.DATOS_INVENTARIO.filter(
      d => d.DATOS_CENTRO.length > 0
    ).map(d => d.DATOS_CENTRO.length);
    if (datoscentro.length > 0)
      return datoscentro.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      );
    else return 0;
  }

  fetchGraficas() {
    this.graficas_fetch.load();
    this.munSrv.getGraficos().subscribe(
      value => {
        if (value) {
          this.graficos = value;
          let totalemisiones = 0, totalconsumo = 0;
          if (this.graficos.EMISIONESDPE_AYTO_REF) {
            this.graficos.EMISIONESDPE_AYTO_REF.forEach(
              s => (totalemisiones += s.value)
            );
            this.totalemisiones_ref = totalemisiones;
            this.graficos.EMISIONESDPE_AYTO_REF.forEach(
              s =>
                (s.name =
                  new DecimalPipe(this.translateService.currentLang).transform(
                    (s.value / totalemisiones) * 100
                  ) + " %")
            );
          }
          if (this.graficos.CONSUMOSDPE_AYTO_REF) {
            this.graficos.CONSUMOSDPE_AYTO_REF.forEach(
              s => (totalconsumo += s.value)
            );
            this.totalconsumo_ref = totalconsumo;
            this.graficos.CONSUMOSDPE_AYTO_REF.forEach(
              s =>
                (s.name =
                  new DecimalPipe(this.translateService.currentLang).transform(
                    (s.value / totalconsumo) * 100
                  ) + " %")
            );
          }

          totalemisiones = 0, totalconsumo = 0;
          if (this.graficos.EMISIONESDPE_AYTO_LAST) {
            this.graficos.EMISIONESDPE_AYTO_LAST.forEach(
              s => (totalemisiones += s.value)
            );
            this.totalemisiones_last = totalemisiones;
            this.graficos.EMISIONESDPE_AYTO_LAST.forEach(
              s =>
                (s.name =
                  new DecimalPipe(this.translateService.currentLang).transform(
                    (s.value / totalemisiones) * 100
                  ) + " %")
            );
          }
          if (this.graficos.CONSUMOSDPE_AYTO_LAST) {
            this.graficos.CONSUMOSDPE_AYTO_LAST.forEach(
              s => (totalconsumo += s.value)
            );
            this.totalconsumo_last = totalconsumo;
            this.graficos.CONSUMOSDPE_AYTO_LAST.forEach(
              s =>
                (s.name =
                  new DecimalPipe(this.translateService.currentLang).transform(
                    (s.value / totalconsumo) * 100
                  ) + " %")
            );
          }
        }
        this.graficas_fetch.ok(value);
      },
      error2 => {
        this.graficas_fetch.ko(error2);
      }
    );
  }
  fetchCentros() {
    this.centros_fetch.load();
    this.centrosSrv.getAll().subscribe(
      value => {
        if (value) {
          this.centros = value;
        }
        this.centros_fetch.ok(value);
      },
      error2 => {
        this.centros_fetch.ko(error2);
      }
    );
  }

  fetchAnalisis() {
    this.ana_fetch.load();
    this.anaSrv.getAll().subscribe(
      value => {
        this.ultimo_analisis = value.sort((a, b) => b.ANYO - a.ANYO)[0];
        this.ana_fetch.ok(value);
      },
      error2 => {
        this.ana_fetch.ko(error2);
      }
    );
  }

  fetchInventarios() {
    this.inv_fetch.load();
    this.invSrv.getAll().subscribe(
      value => {
        this.inventarios = value;
        this.ultimo_inventario = this.inventarios
          .filter(i => i.TIPOINVENTARIO.NOMBRE != "REFERENCIA")
          .sort((a, b) => b.ANYO - a.ANYO)[0];
        this.inventario_referencia = this.inventarios
          .filter(i => i.TIPOINVENTARIO.NOMBRE == "REFERENCIA")
          .sort((a, b) => b.ANYO - a.ANYO)[0];
        this.inv_fetch.ok(value);
      },
      error2 => {
        this.inv_fetch.ko(error2);
      }
    );
  }

  fetchPlanAccion() {
    this.plan_fetch.load();
    this.planSrv.getAll().subscribe(
      value => {
        this.planesaccion = value;
        this.ultimo_plan = this.planesaccion.sort((a, b) => b.ANYO - a.ANYO)[0];
        this.plan_fetch.ok(value);
      },
      error2 => {
        this.inv_fetch.ko(error2);
      }
    );
  }

  nestedObj(o, s) {
    if (!o) {
      return;
    }
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    let a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  groupByCount(xs, key) {
    let result = [];
    const grouped = xs.reduce((rv, x) => {
      rv[this.nestedObj(x, key)] =
        (rv[this.nestedObj(x, key)] = rv[this.nestedObj(x, key)] || 0) + 1;
      return rv;
    }, {});
    for (var prop in grouped) {
      if (grouped.hasOwnProperty(prop)) {
        result.push({ name: prop, value: grouped[prop] });
      }
    }
    return result;
  }

  getPlanEstados(value) {
    this.chart_plan_estados = this.groupByCount(value, "ESTADOACCION.NOMBRE");
  }

  getInventarioAmbitos(value) {
    this.chart_inventario_ambitos = this.groupByCount(
      value,
      "AMBITO.SUPERAMBITO.NOMBRE"
    );
  }
}

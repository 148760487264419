﻿import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {RoleGuard} from './guards/role.guard';
import {AdminComponent} from './admin/admin.component';
import {IniComponent} from './ini/ini.component';
import {RedirectRoleGuard} from './guards/redirectrole.guard';
import {InventarioComponent} from './home/inventario/inventario.component';
import {InvCreateComponent} from './home/inventario/inv-create/inv-create.component';
import {InvDatosComponent} from './home/inventario/inv-datos/inv-datos.component';
import {MunicipioComponent} from './home/municipio/municipio.component';
import {MunDatosgeneralesComponent} from './home/municipio/mun-datosgenerales/mun-datosgenerales.component';
import {MunCentrosComponent} from './home/municipio/mun-centros/mun-centros.component';
import {InvHomeComponent} from './home/inventario/inv-home/inv-home.component';
import {DashboardComponent} from './home/dashboard/dashboard.component';
import {ImportacionplantillaComponent} from './home/importacionplantilla/importacionplantilla.component';
import {PlanaccionHomeComponent} from './home/planaccion/planaccionhome.component';
import {PlanaccionComponent} from './home/planaccion/planaccion/planaccion.component';
import {PlanaccionDatosgeneralesComponent} from './home/planaccion/planaccion-datosgenerales/planaccion-datosgenerales.component';
import {PlanaccionAccionesComponent} from './home/planaccion/planaccion-acciones/planaccion-acciones.component';
import {AnalisisComponent} from './home/analisis/analisis.component';
import {AnalisisdetalleComponent} from './home/analisis/analisisdetalle/analisisdetalle.component';
import {RiesgosComponent} from './home/analisis/riesgos/riesgos.component';
import {VulnerabilidadesComponent} from './home/analisis/vulnerabilidades/vulnerabilidades.component';
import {VariablesclimaticasComponent} from './home/analisis/variablesclimaticas/variablesclimaticas.component';
import {DocumentosComponent} from './home/documentos/documentos.component';
import {InformesComponent} from './home/informes/informes.component';
import {AdminmunicipioComponent} from './admin/adminmunicipio/adminmunicipio.component';
import {AdminusuarioComponent} from './admin/adminusuario/adminusuario.component';
import {AdminmunicipiohomeComponent} from './admin/adminmunicipio/adminmunicipiohome/adminmunicipiohome.component';
import {AdminusuariohomeComponent} from './admin/adminusuario/adminusuariohome/adminusuariohome.component';
import {AdmininventarioComponent} from './admin/admininventario/admininventario.component';
import {AdminambitoslistComponent} from './admin/admininventario/adminambitoslist/adminambitoslist.component';
import {AdminsuperambitoslistComponent} from './admin/admininventario/adminsuperambitoslist/adminsuperambitoslist.component';
import {AdminsubambitolistComponent} from './admin/admininventario/adminsubambitolist/adminsubambitolist.component';
import {AdminfuentelistComponent} from './admin/admininventario/adminfuentelist/adminfuentelist.component';
import {AdminplanaccionComponent} from './admin/adminplanaccion/adminplanaccion.component';
import {AdminaccioneslistComponent} from './admin/adminplanaccion/adminaccioneslist/adminaccioneslist.component';
import {AdminzonaclimaticalistComponent} from './admin/adminmunicipio/adminzonaclimaticalist/adminzonaclimaticalist.component';
import {AdminanalisisComponent} from './admin/adminanalisis/adminanalisis.component';
import {AdminriesgoslistComponent} from './admin/adminanalisis/adminriesgoslist/adminriesgoslist.component';
import {AdminsectorlistComponent} from './admin/adminanalisis/adminsectorlist/adminsectorlist.component';
import {BiComponent} from './bi/bi.component';
import {AdminsesionesComponent} from './admin/adminsesiones/adminsesiones.component';

const appRoutes: Routes = [
  {
    path: 'admin', component: AdminComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ADMIN']
    }, children: [
      {
        path: '', pathMatch: 'full', redirectTo: 'municipio'
      },
      {
        path: 'municipio', component: AdminmunicipiohomeComponent, children: [
          {path: '', component: AdminmunicipioComponent},
          {path: 'zonaclimatica', component: AdminzonaclimaticalistComponent}
        ]
      },
      {
        path: 'usuario', component: AdminusuariohomeComponent, children: [
          {path: '', component: AdminusuarioComponent}
        ]
      },
      {
        path: 'inventario', component: AdmininventarioComponent, children: [
          {path: '', component: AdminambitoslistComponent},
          {path: 'spambito', component: AdminsuperambitoslistComponent},
          {path: 'sbambito', component: AdminsubambitolistComponent},
          {path: 'fuente', component: AdminfuentelistComponent}
        ]
      },
      {
        path: 'planaccion', component: AdminplanaccionComponent, children: [
          {path: '', component: AdminaccioneslistComponent},
        ]
      },
      {
        path: 'analisis', component: AdminanalisisComponent, children: [
          {path: '', component: AdminriesgoslistComponent},
          {path: 'sector', component: AdminsectorlistComponent}
        ]
      },
      {
        path: 'sesiones', component: AdminsesionesComponent
      }
    ]
  },
  {path: 'bi', component: BiComponent},
  {
    path: '', component: HomeComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['USER', 'ADMIN', 'CONSULTOR']
    },
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      {
        path: 'dashboard', component: DashboardComponent
      },
      {
        path: 'informes', component: InformesComponent
      },
      {
        path: 'inventarios', component: InvHomeComponent
      },
      {
        path: 'inventarios/:id', component: InventarioComponent,// pathMatch: 'full', redirectTo: 'datosgenerales'},
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'datosinventario'},
          {
            path: 'datosgenerales', component: InvCreateComponent
          },
          {
            path: 'datosinventario', component: InvDatosComponent
          },
        ]
      }
      ,
      {
        path: 'municipio', component: MunicipioComponent,
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'datosgenerales'},
          {
            path: 'datosgenerales', component: MunDatosgeneralesComponent
          },
          {
            path: 'centros', component: MunCentrosComponent
          },
        ]
      },
      {
        path: 'planaccion', component: PlanaccionHomeComponent
      },
      {
        path: 'planaccion/:id', component: PlanaccionComponent,
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'acciones'},
          {
            path: 'datosgenerales', component: PlanaccionDatosgeneralesComponent
          },
          {
            path: 'acciones', component: PlanaccionAccionesComponent
          },
        ]
      },
      {
        path: 'import', component: ImportacionplantillaComponent
      },
      {
        path: 'documentos', component: DocumentosComponent
      },
      {
        path: 'analisis', component: AnalisisComponent
      },
      {
        path: 'analisis/:id', component: AnalisisdetalleComponent,
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'riesgos'},
          {
            path: 'riesgos', component: RiesgosComponent
          },
          {
            path: 'vulnerabilidades', component: VulnerabilidadesComponent
          },
          {
            path: 'variablesclimaticas', component: VariablesclimaticasComponent
          }
        ]
      }
    ]
  },
  {
    path: '', component: IniComponent,
    canActivate: [AuthGuard, RedirectRoleGuard]
  },
  {path: 'login', component: LoginComponent},

  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(appRoutes);

<mat-toolbar fxLayout="row wrap" id="topbar" class="pl-md-5 pr-md-5 pl-sm-3 pr-sm-3"
             style="background: white;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex>
    <span>{{'inventario.home.title' | translate}}</span>
  </div>
  <div class="toolbar-spacer"></div>
</mat-toolbar>
<div class="my-5" id="panel">
  <div class="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
    <app-advancedlist [datos]="inventarios" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canAdd]="true" (onAdd)="openCreateDialog()"   [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending" [canClick]="true" (onClick)="selectInventario($event)"
    >
      <ng-template let-item="item">
        <div class="d-flex full-width" fxLayoutGap="10px">
          <div class="d-flex flex-column justify-content-start align-items-center">
            <span class="grey label align-self-start"> {{'inventario.home.list.item.anyo' | translate}} </span>
            <span class="dark text-lg-m">{{item.ANYO}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex justify-content-end align-items-center" fxLayoutGap="10px">
            <div class="d-flex flex-column justify-content-start align-items-center">
              <span class="grey label align-self-start"> {{'inventario.home.list.item.tipo' | translate}} </span>
              <span class="text-md color-grey-700">{{item.TIPOINVENTARIO.NOMBRE}}</span>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center" fxLayoutGap="10px">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'inventario.home.list.item.datosinventario' | translate}} </span>
              <span class="text-md color-grey-700">{{item.DATOS_INVENTARIO.length}} </span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {Superambito} from '../../../model/superambito';
import {DialogResponse} from '../../../model/Utils';
import {AdminService} from '../../../services/admin.service';
import {Petition} from '../../../model/petition';
import {AdminsuperambitosformComponent} from '../../admininventario/adminsuperambitosform/adminsuperambitosform.component';
import {ListatiposService} from '../../../services/listatipos.service';
import {Accion} from '../../../model/accion';
import {AdminaccionesformComponent} from '../adminaccionesform/adminaccionesform.component';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminaccioneslist',
  templateUrl: './adminaccioneslist.component.html',
  styleUrls: ['./adminaccioneslist.component.scss']
})
export class AdminaccioneslistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE','TIPOACCION.NOMBRE','SUPERAMBITO.NOMBRE','CODIGO'];
  datos: Accion[];
  form = AdminaccionesformComponent;
  list_title = 'admin.planaccion.acciones.title';
  search_placeholder = 'admin.planaccion.acciones.searchplaceholder';

  constructor(private dataSrv: ListatiposService,private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.fetchAccion().subscribe(value => {
        value.sort((a, b) => ('' + a.IDTIPO).localeCompare('' + b.IDTIPO));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createAccion(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateAccion(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteAccion(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

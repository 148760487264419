/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adminsuperambitoslist.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../components/apartado-admin/apartado-admin.component.ngfactory";
import * as i7 from "../../../components/apartado-admin/apartado-admin.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "./adminsuperambitoslist.component";
import * as i10 from "../../../services/listatipos.service";
import * as i11 from "../../../services/admin.service";
import * as i12 from "../../../services/alertbar.service";
var styles_AdminsuperambitoslistComponent = [i0.styles];
var RenderType_AdminsuperambitoslistComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminsuperambitoslistComponent, data: {} });
export { RenderType_AdminsuperambitoslistComponent as RenderType_AdminsuperambitoslistComponent };
function View_AdminsuperambitoslistComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex full-width"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(1, 1785856, null, 0, i2.LayoutGapDirective, [i3.MediaMonitor, i1.ElementRef, [8, null], i1.NgZone, i4.Directionality, i3.StyleUtils], { gap: [0, "gap"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "d-flex flex-column justify-content-center items-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "text-grey-darkest text-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "flex-grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "text-grey-dark text-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "10px"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.item.NOMBRE; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform((_v.context.item.DEPENDE_AYTO ? "models.ambito.dependeayto" : "models.ambito.nodependeayto"))); _ck(_v, 8, 0, currVal_2); }); }
export function View_AdminsuperambitoslistComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-apartado-admin", [], null, [[null, "onAdd"], [null, "onDelete"], [null, "onEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAdd" === en)) {
        var pd_0 = (_co.onAdd($event) !== false);
        ad = (pd_0 && ad);
    } if (("onDelete" === en)) {
        var pd_1 = (_co.onDelete($event) !== false);
        ad = (pd_1 && ad);
    } if (("onEdit" === en)) {
        var pd_2 = (_co.onEdit($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_ApartadoAdminComponent_0, i6.RenderType_ApartadoAdminComponent)), i1.ɵdid(1, 114688, null, 1, i7.ApartadoAdminComponent, [i8.MatDialog], { datos: [0, "datos"], loading: [1, "loading"], empty: [2, "empty"], filterProps: [3, "filterProps"], list_title: [4, "list_title"], search_placeholder: [5, "search_placeholder"], formDialog: [6, "formDialog"] }, { onAdd: "onAdd", onDelete: "onDelete", onEdit: "onEdit" }), i1.ɵqud(335544320, 1, { itemTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_AdminsuperambitoslistComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datos; var currVal_1 = _co.datos_peticion.pending; var currVal_2 = _co.datos_peticion.empty; var currVal_3 = _co.filter_props; var currVal_4 = _co.list_title; var currVal_5 = _co.search_placeholder; var currVal_6 = _co.form; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_AdminsuperambitoslistComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-adminsuperambitoslist", [], null, null, null, View_AdminsuperambitoslistComponent_0, RenderType_AdminsuperambitoslistComponent)), i1.ɵdid(1, 114688, null, 0, i9.AdminsuperambitoslistComponent, [i10.ListatiposService, i11.AdminService, i12.AlertbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminsuperambitoslistComponentNgFactory = i1.ɵccf("app-adminsuperambitoslist", i9.AdminsuperambitoslistComponent, View_AdminsuperambitoslistComponent_Host_0, {}, {}, []);
export { AdminsuperambitoslistComponentNgFactory as AdminsuperambitoslistComponentNgFactory };

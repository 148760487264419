<h2 mat-dialog-title>{{action.title | translate}}</h2>
<div mat-dialog-content>
  <div *ngIf="!file_upload.finished" class="text-center py-2 lg:px-2">
    <div class="p-2 background-primary items-center text-white leading-none lg:rounded-full flex lg:inline-flex shadow"
         role="alert">
      <span class="flex rounded-full background-accent uppercase px-2 py-1 text-xs font-bold mr-3">!</span>
      <span class="font-semibold mr-2 text-left flex-auto">{{'generico.import.infotext' | translate}}</span>
    </div>
  </div>
  <div *ngIf="!file_upload.finished" class="text-center">
    <mat-icon class="ionicon-large ion-ios-cloud-upload m-4" color="accent"></mat-icon>
    <p class="mat-body-2">{{'generico.import.text' | translate}}</p>
    <div class="justify-content-center align-items-center mb-2" fxLayout="column" fxLayoutGap="15px">
                <span class="mat-body-2 color-grey-400" *ngFor="let file of fileInput.files">
                {{file.name}}
              </span>
      <button type="button" mat-button color="primary" (click)="fileInput.click()">
        {{(fileInput.files.length >0 ?
        'generico.import.changefile' : 'generico.import.selectfile') | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="file_upload.error" class="text-center mb-2">
    <mat-icon color="warn" class="large-icon ion-md-close-circle">
    </mat-icon>
    <p class="mat-body-2">{{'generico.import.import_ko' | translate}}</p>
    <div class="border bg-grey-lightest p-2">
      <p class="text-sm">{{import_response.error}}</p>
    </div>
  </div>
  <div *ngIf="file_upload.success" class="text-center mb-2">
    <mat-icon color="accent" class="large-icon">
      check_circle
    </mat-icon>
    <p class="mat-body-2">{{'generico.import.import_ok' | translate}}</p>
  </div>
  <div *ngIf="file_upload.pending" class="flex justify-center items-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <div *ngIf="fileInput.files.length == 1 && !file_upload.finished" class="text-center">
    <button mat-raised-button color="accent" (click)="importarPlantilla()">{{'generico.import.import' |
      translate}}
    </button>
  </div>
  <input type="file" hidden #fileInput (change)="onFileChange($event)" [max]="1"/>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="import_response">{{'generico.import.cancel' | translate}}</button>
  <button mat-button color="primary" [mat-dialog-close]="import_response" [disabled]="!file_upload.finished">
    {{'generico.import.ok' | translate}}
  </button>
</div>

import {Component, ContentChild, Inject, Input, OnInit, TemplateRef} from '@angular/core';
import {DialogAction, DialogResponse} from '../../model/Utils';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.scss']
})
export class AdminFormComponent implements OnInit {
  @Input() dataForm: any;
  @Input() title: string = '';
  @ContentChild(TemplateRef)
  @Input() formTemplate: TemplateRef<any>;
  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction) { }

  ngOnInit() {
  }

  closeDelete() {
    switch (this.action.action) {
      case DialogAction.EDIT: {
        return new DialogResponse(DialogResponse.DELETE, this.dataForm.form.value);
      }
    }
  }

  close(){
    return new DialogResponse(DialogResponse.NONE);
  }

  closeOk() {
    switch (this.action.action) {
      case DialogAction.CREATE: {
        return new DialogResponse(DialogResponse.ADD, this.dataForm.form.value);
      }
      case DialogAction.EDIT: {
        return new DialogResponse(DialogResponse.EDIT, this.dataForm.form.value);
      }
      case DialogAction.VIEW: {
        return new DialogResponse(DialogResponse.NONE);
      }
    }
  }
}

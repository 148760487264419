import {Municipio} from './municipio';

export class Documento {
  ID: number = 0;
  IDMUNICIPIO: number;
  MUNICIPIO: Municipio;
  NOMBRE: string;
  IDTIPO: number = 1;
  TIPODOCUMENTO: TipoDocumento;
  FILENAME: string;
  FECHA_SUBIDA: Date;
  USUARIO: string;

  constructor(obj) {
    Object.assign(this, obj);
  }
}

export class DocumentoDB {
  ID: number;
  IDMUNICIPIO: number;
  NOMBRE: string;
  IDTIPO: number;
  FILENAME: string;
  FECHA_SUBIDA: Date;
  USUARIO: string;

  constructor(d: Documento) {
    this.ID = d.ID;
    this.IDMUNICIPIO = d.IDMUNICIPIO;
    this.NOMBRE = d.NOMBRE;
    this.IDTIPO = d.IDTIPO;
    this.FECHA_SUBIDA = d.FECHA_SUBIDA;
    this.USUARIO = d.USUARIO;
    this.FILENAME = d.FILENAME
  }
}

export class TipoDocumento {
  ID: number;
  NOMBRE: string;
}

<mat-toolbar fxLayout="row wrap" id="topbar" class="pl-md-5  pr-md-5 pl-sm-3 pr-sm-3 full-height"
             style="background: white;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex>
    <span>{{'planaccion.home.title' | translate}}</span>
  </div>
  <div class="toolbar-spacer"></div>
</mat-toolbar>
<div class="my-5" id="panel">
  <div class="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
    <app-advancedlist [datos]="planesaccion" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canAdd]="true" (onAdd)="openRevisionDialog()"   [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending" [canClick]="true" (onClick)="setActive($event)"
    >
      <ng-template let-item="item">
        <div class="d-flex full-width" fxLayoutGap="10px">
          <div class="d-flex justify-content-start align-items-center">

            <button *ngIf="item.ID != primer_pa.ID" class="rounded-full py-2 px-2 w-10 h-10 border bg-white text-grey-dark flex justify-center items-center">
              <mat-icon>history</mat-icon>
            </button>
            <button *ngIf="item.ID == primer_pa.ID" class="rounded-full py-2 px-2 w-10 h-10 border-red-darkest bg-red-dark text-white flex justify-center items-center">
              <span class="text-sm">Orig.</span>
            </button>
          </div>
          <div class="d-flex flex-column justify-content-center  align-items-center">
            <span
              class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-anyo' | translate}} </span>
            <span class="text-lg-m color-grey-800" id="year">{{item.ANYO}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex flex-wrap justify-content-center" fxLayoutGap="20px">
            <div class="d-flex flex-wrap justify-content-center align-items-center" fxLayoutGap="15px">
              <div class="d-flex flex-column justify-content-center align-items-end">
                <span class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-anyoinventario' | translate}} </span>
                <span id="anyoinventario" class="text-lg-m color-grey-700">{{item.ANYOREFERENCIAINVENTARIO}} </span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-end">
              <span
                class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-anyoriesgos' | translate}} </span>
                <span id="anyoanalisis" class="text-lg-m color-grey-700">{{item.ANYOREFERENCIARIESGOS}} </span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-end">
              <span
                class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-anyoobjetivo' | translate}} </span>
                <span id="anyoobjetivo" class="text-lg-m color-grey-700">{{item.ANYOOBJETIVO}} </span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-center align-items-center" fxLayoutGap="15px">
              <div class="d-flex flex-column justify-content-center align-items-end">
                <span class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-objetivoconsumo' | translate}} </span>
                <span id="objetivoconsumo"
                      class="text-lg-m color-grey-700">{{item.OBJETIVOCONSUMO | localizedNumber:'MWh'}} </span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-end">
              <span
                class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-objetivoproduccion' | translate}} </span>
                <span id="objetivoproduccion"
                      class="text-lg-m color-grey-700">{{item.OBJETIVOPRODUCCION | localizedNumber:'MWh'}} </span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-end">
                <span class="text-sm color-grey-600 align-self-start"> {{'planaccion.home.col-objetivoemision' | translate}} </span>
                <span id="objetivoemisiones"
                      class="text-lg-m color-grey-700">{{item.OBJETIVOEMISION | localizedNumber:'tCO2'}} </span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
    <div *ngIf="data_fetch.finished && !data_fetch.empty" class="d-flex justify-content-end">
      <span class="mat-caption" style="color:grey; ">{{'planaccion.home.tablelegend' | translate}}</span>
    </div>
  </div>
</div>

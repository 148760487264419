import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {appConfig} from '../app.config';
import {AlertService} from './alert.service';
import {InventarioEmisiones, InventarioEmisionesDB, TipoInventario} from '../model/inventarioemisiones';
import {BehaviorSubject} from 'rxjs';
import {MunicipioService} from './municipio.service';

@Injectable()
export class InventarioService {

  sel_inventario: BehaviorSubject<InventarioEmisiones> = new BehaviorSubject<InventarioEmisiones>(null);
  inventario_activo_id: number;

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService) {
  }

  getInventarioActivo() {
    return this.sel_inventario.getValue();
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/InventarioEmisiones/';
  }

  getAPIUrlImport() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/InventarioEmisiones/' + this.inventario_activo_id + '/import/';
  }
  getAPIUrlExport() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/InventarioEmisiones/' + this.inventario_activo_id + '/export/';
  }
  getAll() {
    return this.http.get<InventarioEmisiones[]>(this.getAPIUrl());
  }

  getById(_id: number) {
    return this.http.get<InventarioEmisiones>(this.getAPIUrl() + _id);
  }

  create(inventario: InventarioEmisionesDB) {
    return this.http.post<InventarioEmisiones>(this.getAPIUrl(), inventario);
  }

  update(inventario: InventarioEmisionesDB) {
    return this.http.put<InventarioEmisiones>(this.getAPIUrl() + inventario.ID, inventario);
  }

  replace(inventario: InventarioEmisionesDB) {
    return this.http.put<InventarioEmisiones>(this.getAPIUrl() + 'replace', inventario);
  }

  delete(_id: number) {
    return this.http.delete(this.getAPIUrl() + _id);
  }

  setSelectedInventario(inventario) {
    this.inventario_activo_id = inventario.ID;
    this.sel_inventario.next(inventario as InventarioEmisiones);
  }

  getTiposInventario() {
    return this.http.get<TipoInventario[]>(appConfig.apiUrl + '/TipoInventario');
  }

  initialize() {
    this.sel_inventario.next(new InventarioEmisiones());
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MunicipioService} from '../../../services/municipio.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogAction, DialogResponse} from '../../../model/Utils';
import {Petition} from '../../../model/petition';
import {DeleteAccionDialog} from '../../../home/planaccion/planaccion-acciones/planaccion-acciones.component';
import {Municipio} from '../../../model/municipio';
import {AmbitoForm, FuenteForm, MunicipioForm} from '../../Forms';
import {AmbitoService} from '../../../services/ambito.service';
import {Ambito} from '../../../model/ambito';
import {ListatiposService} from '../../../services/listatipos.service';
import {Fuente} from '../../../model/subambito';

@Component({
  selector: 'app-adminambitosform',
  templateUrl: './adminambitosform.component.html',
  styleUrls: ['./adminambitosform.component.scss']
})
export class AdminambitosformComponent implements OnInit {
  dato: Ambito;
  datos_peticion: Petition = new Petition();
  fuente_peticion = new Petition();
  superambito_peticion = new Petition();
  subambito_peticion = new Petition();
  dataForm: AmbitoForm;
  fuentes = [];
  superambitos = [];
  subambitos = [];

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, public dataSrv: ListatiposService) {
    this.fetchSuperambitos();
    this.fetchSubambitos();
    this.fetchFuentes();
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new AmbitoForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new AmbitoForm(action);
    }
  }
  superambitosDisponibles() {
    return this.superambitos.filter(c => c.DEPENDE_AYTO == this.dataForm.form.controls['DEPENDE_AYTO'].value);
  }

  fetchFuentes() {
    this.fuente_peticion.load();
    this.dataSrv.fetchFuente().subscribe(value => {
      this.fuente_peticion.ok(value);
      this.fuentes = value;
    }, error2 => {
      this.fuente_peticion.ko(error2);
    });
  }

  fetchSuperambitos() {
    this.superambito_peticion.load();
    this.dataSrv.fetchSuperambito().subscribe(value => {
      this.superambito_peticion.ok(value);
      this.superambitos = value;
    }, error2 => {
      this.superambito_peticion.ko(error2);
    });
  }

  fetchSubambitos() {
    this.subambito_peticion.load();
    this.dataSrv.fetchSubambito().subscribe(value => {
      this.subambito_peticion.ok(value);
      this.subambitos = value;
    }, error2 => {
      this.subambito_peticion.ko(error2);
    });
  }


  ngOnInit() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

export class Sorter {
  prop: string;
  text: string;

  constructor(text, prop) {
    this.prop = prop;
    this.text = text;
  }
}
export class Filter {
  options: object;
  text: string;
  type: string;

  constructor(text, type, options) {
    this.type = type;
    this.options = options;
    this.text = text;
  }
}
export class DialogAction {
  action: number;
  data: any;
  static readonly CREATE = 0;
  static readonly EDIT = 1;
  static readonly VIEW = 2;
  static readonly DETAIL = 3;
  static readonly DELETE = 4;
  static readonly NONE = -1;

  constructor(action: number, data?: any) {
    this.action = action;
    this.data = data;
  }
}

export class DialogResponse {
  action: number;
  data: any;
  static readonly ADD = 0;
  static readonly EDIT = 1;
  static readonly DELETE = 2;
  static readonly NONE = -1;

  constructor(action: number, data?: any) {
    this.action = action;
    this.data = data;
  }
}


export function removeDiacritics(text: string) {
  return (''+text).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function nestedObj(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  let a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (!o)
      return;
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

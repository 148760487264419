<div fxLayout="column" fxFlexFill>
  <div style="height: calc(100% - 100px);"
       class="col-sm-12 col-md-12  col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 p-4">
    <div id="datosgenerales" *ngIf="data_fetch.success">
      <form [formGroup]="form" (ngSubmit)="form.valid && guardar(form.value)" novalidate class="border rounded p-4 flex-wrap"
            style="background: white;">
        <mat-form-field class="col-lg-6 col-md-12 col-sm-12 col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.anyo.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="ANYO" required>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYO').hasError('required') && (form.get('ANYO').dirty || form.get('ANYO').touched)">
            {{'planaccion.createmodal.anyo.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYO').hasError('validMinValue') && (form.get('ANYO').dirty || form.get('ANYO').touched)">
            {{'planaccion.createmodal.anyo.minValue' | translate:{value: 1999} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.anyoinventario.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="ANYOREFERENCIAINVENTARIO" required>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYOREFERENCIAINVENTARIO').hasError('required') && (form.get('ANYOREFERENCIAINVENTARIO').dirty || form.get('ANYOREFERENCIAINVENTARIO').touched)">
            {{'planaccion.createmodal.anyoinventario.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYOREFERENCIAINVENTARIO').hasError('validMinValue') && (form.get('ANYOREFERENCIAINVENTARIO').dirty || form.get('ANYOREFERENCIAINVENTARIO').touched)">
            {{'planaccion.createmodal.anyoinventario.minValue' | translate:{value: 1999} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" fxFlex.gt-md="50%" fxFlex.lt-lg="100%"
                        [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.anyoriesgos.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="ANYOREFERENCIARIESGOS" required>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYOREFERENCIARIESGOS').hasError('required') && (form.get('ANYOREFERENCIARIESGOS').dirty || form.get('ANYOREFERENCIARIESGOS').touched)">
            {{'planaccion.createmodal.anyoriesgos.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYOREFERENCIARIESGOS').hasError('validMinValue') && (form.get('ANYOREFERENCIARIESGOS').dirty || form.get('ANYOREFERENCIARIESGOS').touched)">
            {{'planaccion.createmodal.anyoriesgos.minValue' | translate:{value: 1999} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" fxFlex.gt-md="50%" fxFlex.lt-lg="100%"
                        [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.anyoobjetivo.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="ANYOOBJETIVO" required>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYOOBJETIVO').hasError('required') && (form.get('ANYOOBJETIVO').dirty || form.get('ANYOOBJETIVO').touched)">
            {{'planaccion.createmodal.anyoobjetivo.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('ANYOOBJETIVO').hasError('validMinValue') && (form.get('ANYOOBJETIVO').dirty || form.get('ANYOOBJETIVO').touched)">
            {{'planaccion.createmodal.anyoobjetivo.minValue' | translate:{value: 1999} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.objetivoconsumo.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="OBJETIVOCONSUMO" required>
          <span matSuffix>MWh</span>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOCONSUMO').hasError('required') && (form.get('OBJETIVOCONSUMO').dirty || form.get('OBJETIVOCONSUMO').touched)">
            {{'planaccion.createmodal.objetivoconsumo.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOCONSUMO').hasError('validMinValue') && (form.get('OBJETIVOCONSUMO').dirty || form.get('OBJETIVOCONSUMO').touched)">
            {{'planaccion.createmodal.objetivoconsumo.minValue' | translate:{value: 0} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.objetivoproduccion.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="OBJETIVOPRODUCCION" required>
          <span matSuffix>MWh</span>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOPRODUCCION').hasError('required') && (form.get('OBJETIVOPRODUCCION').dirty || form.get('OBJETIVOPRODUCCION').touched)">
            {{'planaccion.createmodal.objetivoproduccion.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOPRODUCCION').hasError('validMinValue') && (form.get('OBJETIVOPRODUCCION').dirty || form.get('OBJETIVOPRODUCCION').touched)">
            {{'planaccion.createmodal.objetivoproduccion.minValue' | translate:{value: 0} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.objetivoemision.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="OBJETIVOEMISION" required>
          <span matSuffix>tCO2</span>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOEMISION').hasError('required') && (form.get('OBJETIVOEMISION').dirty || form.get('OBJETIVOEMISION').touched)">
            {{'planaccion.createmodal.objetivoemision.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOEMISION').hasError('validMinValue') && (form.get('OBJETIVOEMISION').dirty || form.get('OBJETIVOEMISION').touched)">
            {{'planaccion.createmodal.objetivoemision.minValue' | translate:{value: 0} }}
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.objetivoreduccionconsumo.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="OBJETIVOREDUCCIONCONSUMO" required>
          <span matSuffix>%</span>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOREDUCCIONCONSUMO').hasError('required') && (form.get('OBJETIVOCONSUMO').dirty || form.get('OBJETIVOCONSUMO').touched)">
            {{'planaccion.createmodal.objetivoreduccionconsumo.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOREDUCCIONCONSUMO').hasError('validMinValue') && (form.get('OBJETIVOCONSUMO').dirty || form.get('OBJETIVOCONSUMO').touched)">
            {{'planaccion.createmodal.objetivoreduccionconsumo.minValue' | translate:{value: 0} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.objetivoreduccionproduccion.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="OBJETIVOREDUCCIONPRODUCCION" required>
          <span matSuffix>%</span>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOREDUCCIONPRODUCCION').hasError('required') && (form.get('OBJETIVOREDUCCIONPRODUCCION').dirty || form.get('OBJETIVOREDUCCIONPRODUCCION').touched)">
            {{'planaccion.createmodal.objetivoreduccionproduccion.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOREDUCCIONPRODUCCION').hasError('validMinValue') && (form.get('OBJETIVOREDUCCIONPRODUCCION').dirty || form.get('OBJETIVOREDUCCIONPRODUCCION').touched)">
            {{'planaccion.createmodal.objetivoreduccionproduccion.minValue' | translate:{value: 0} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'planaccion.createmodal.objetivoreduccionemision.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="OBJETIVOREDUCCIONEMISION" required>
          <span matSuffix>%</span>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOREDUCCIONEMISION').hasError('required') && (form.get('OBJETIVOREDUCCIONEMISION').dirty || form.get('OBJETIVOREDUCCIONEMISION').touched)">
            {{'planaccion.createmodal.objetivoreduccionemision.requiredMessage' | translate }}
          </mat-error>
          <mat-error class="error-message"
                     *ngIf="form.get('OBJETIVOREDUCCIONEMISION').hasError('validMinValue') && (form.get('OBJETIVOREDUCCIONEMISION').dirty || form.get('OBJETIVOREDUCCIONEMISION').touched)">
            {{'planaccion.createmodal.objetivoreduccionemision.minValue' | translate:{value: 0} }}
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center  mt-2">
          <button *ngIf="authSrv.isEditable()" class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center"  type="submit"
                  [disabled]="!form.valid || !form.dirty">
            <i *ngIf="!data_update.pending" class="material-icons text-md mr-2">save</i>
            <mat-spinner color="warn" class=" mr-2" *ngIf="data_update.pending" [diameter]="20" value="indeterminate"></mat-spinner>
            <span class="text-md">{{'forms.actions.save' | translate}}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

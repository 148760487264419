<form [formGroup]="dataForm.form">
  <app-admin-form [dataForm]="dataForm" [title]="'admin.municipio.zonaclimatica.dialog_title'">
    <ng-template let-form="form">
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.zonaclimatica.NOMBRE.label' | translate }}</mat-label>
          <input type="text" matInput formControlName="NOMBRE" required>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.zonaclimatica.REFERENCIA.label' | translate }}</mat-label>
          <input type="text" matInput formControlName="REFERENCIA" required>
        </mat-form-field>
      </div>
    </ng-template>
  </app-admin-form>
</form>

<h2 mat-dialog-title>{{(this.data.action == 'create' ? 'analisis.riesgos.form.indicadores.create_text' :
  'analisis.riesgos.form.indicadores.view') | translate}}</h2>
<form [formGroup]="form">
  <div mat-dialog-content fxLayout="row" fxLayoutGap="15px">
    <div [fxFlex]="'100%'" class="p-2">
      <mat-form-field class="col-md-12" [floatLabel]="'always'">
        <mat-label for="NOMBRE">{{'analisis.riesgos.form.indicadores.NOMBRE' | translate }}
        </mat-label>
        <input id="NOMBRE" matInput formControlName="NOMBRE" [attr.disabled]="!editable"/>
      </mat-form-field>
      <mat-form-field class="col-md-12" [floatLabel]="'always'">
        <mat-label for="MAGNITUD">{{'analisis.riesgos.form.indicadores.MAGNITUD' | translate
          }}
        </mat-label>
        <input id="MAGNITUD" matInput formControlName="MAGNITUD" [attr.disabled]="!editable"/>
      </mat-form-field>
      <mat-form-field class="col-md-12 mb-2" [floatLabel]="'always'">
        <mat-label for="VALOR">{{'analisis.riesgos.form.indicadores.VALOR' | translate }}
        </mat-label>
        <input id="VALOR" matInput formControlName="VALOR" [attr.disabled]="!editable"/>
        <mat-hint align="end">{{form.get('MAGNITUD').value || null}}</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-md-12" [floatLabel]="'always'">
        <mat-label for="OBSERVACIONES">{{'analisis.riesgos.form.indicadores.OBSERVACIONES' | translate }}
        </mat-label>
        <textarea id="OBSERVACIONES" matInput formControlName="OBSERVACIONES" [attr.disabled]="!editable"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="data.action !== 'view'" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="data.action=='edit'" color="warn" mat-button
            [mat-dialog-close]="{data:form.value,action:'delete'}">
      {{'analisis.riesgos.form.indicadores.delete' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="null">{{'analisis.riesgos.form.indicadores.cancel' | translate}}</button>
    <button color="accent" mat-button [mat-dialog-close]="{data:form.value,action:data.action}">{{(this.data.action ==
      'create' ?
      'analisis.riesgos.form.indicadores.create_text' : 'analisis.riesgos.form.indicadores.edit') | translate}}
    </button>
  </div>
  <div *ngIf="data.action === 'view'" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="null">{{'analisis.riesgos.form.indicadores.close' | translate}}</button>
  </div>
</form>

<app-apartado-admin [datos]="datos" [filterProps]="filter_props" [search_placeholder]="search_placeholder"
                    [list_title]="list_title" [empty]="datos_peticion.empty" [loading]="datos_peticion.pending"
                    [formDialog]="form" (onAdd)="onAdd($event)" (onDelete)="onDelete($event)" (onEdit)="onEdit($event)"
>
  <ng-template let-item="item">
    <div class="d-flex full-width" fxLayoutGap="10px">
      <div class="d-flex flex-column justify-content-center items-start flex-grow">
        <span class="text-grey-darkest text-md">{{item.NOMBRE}}</span>
      </div>
    </div>
  </ng-template>
</app-apartado-admin>

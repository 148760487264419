import {ZonaClimatica} from './zonaclimatica';
import {DatosInventario, DatosInventarioDB} from './datosinventario';

export class InventarioEmisiones {
  ID: number;
  IDMUNICIPIO: number;
  ANYO: number;
  IDTIPO: number;
  FECHAULTIMAMODIFICACION: Date = new Date();
  DATOS_INVENTARIO: DatosInventario[] = [];
  NUMHABITANTES: number;
  NUMVEHICULOSGASOLINA: number;
  NUMVEHICULOSDIESEL: number;
  IMPLANTACIONGN: number;
  TIPOINVENTARIO: TipoInventario;
  
  constructor(obj = {}) {
    Object.assign(this, obj);
  }

  toDB() {
    return new InventarioEmisionesDB(this);
  }
}

export class InventarioEmisionesDB {
  ID: number;
  IDMUNICIPIO: number;
  ANYO: number;
  IDTIPO: number;
  FECHAULTIMAMODIFICACION: Date = new Date();
  DATOS_INVENTARIO: DatosInventarioDB[] = [];
  NUMHABITANTES: number;
  NUMVEHICULOSGASOLINA: number;
  NUMVEHICULOSDIESEL: number;
  IMPLANTACIONGN: number;

  constructor(inventario: InventarioEmisiones) {
    this.ID = inventario.ID;
    this.IDMUNICIPIO = inventario.IDMUNICIPIO;
    this.ANYO = inventario.ANYO;
    this.IDTIPO = inventario.IDTIPO;
    this.FECHAULTIMAMODIFICACION = inventario.FECHAULTIMAMODIFICACION;
    this.DATOS_INVENTARIO = inventario.DATOS_INVENTARIO.map(d => new DatosInventario(d).toDB());
    this.NUMHABITANTES = inventario.NUMHABITANTES;
    this.NUMVEHICULOSGASOLINA = inventario.NUMVEHICULOSGASOLINA;
    this.NUMVEHICULOSDIESEL = inventario.NUMVEHICULOSDIESEL;
    this.IMPLANTACIONGN = inventario.IMPLANTACIONGN;
  }
}


export class TipoInventario {
  ID: number;
  NOMBRE: string;
}

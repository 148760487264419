import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user';
import {appConfig} from '../app.config';
import {Usuario} from '../model/usuario';
import {UsuarioMunicipio} from '../model/municipio';

@Injectable()
export class PermisosService {
  constructor(private http: HttpClient) {
  }

  create(usumun: UsuarioMunicipio) {
    return this.http.post(appConfig.apiUrl + 'USUARIO/permisos/' + usumun.USERID + '/' + usumun.IDMUNICIPIO, {});
  }

  delete(usumun: UsuarioMunicipio) {
    return this.http.delete(appConfig.apiUrl + 'USUARIO/permisos/' + usumun.USERID + '/' + usumun.IDMUNICIPIO);
  }
}

import {Ambito} from './ambito';
import {DatosCentro, DatosCentroDB} from './datoscentro';

export class DatosInventario {
  ID: number;
  IDINVENTARIO: number;
  IDAMBITO: number;
  VALOR: number;
  FACTOREMISION: number;
  FACTORCONVERSION: number;
  AMBITO: Ambito;
  DATOS_CENTRO: DatosCentro[] = [];

  constructor(obj = {}) {
    Object.assign(this, obj);
  }

  getEmisiones() {
    return (this.VALOR ? this.VALOR : 0) * (this.FACTORCONVERSION ? this.FACTORCONVERSION : 0) * (this.FACTOREMISION ? this.FACTOREMISION : 0);
  }

  toDB() {
    return new DatosInventarioDB(this);
  }
}

export class DatosInventarioDB {
  ID: number;
  IDINVENTARIO: number;
  IDAMBITO: number;
  VALOR: number;
  FACTOREMISION: number;
  FACTORCONVERSION: number;
  DATOS_CENTRO: DatosCentroDB[] = [];

  constructor(di: DatosInventario) {
    this.ID = di.ID;
    this.IDINVENTARIO = di.IDINVENTARIO;
    this.IDAMBITO = di.IDAMBITO;
    this.VALOR = di.VALOR;
    this.FACTOREMISION = di.FACTOREMISION;
    this.FACTORCONVERSION = di.FACTORCONVERSION;
    this.DATOS_CENTRO = di.DATOS_CENTRO.map(d => new DatosCentro(d).toDB());
  }
}

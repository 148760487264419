import {Component, Inject, OnInit} from '@angular/core';
import * as FileSaver from 'file-saver';
import {Petition} from '../../model/petition';
import {FormBuilder} from '@angular/forms';
import {DialogAction, ImportAction, ImportResponse} from '../../model/Utils';
import {ImportService} from '../../services/import.service';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {
  data_export = new Petition();
  export_response: ImportResponse;

  constructor(@Inject(MAT_DIALOG_DATA) public action: ImportAction, private fb: FormBuilder, private impSrv: ImportService) {
    this.export();
  }

  ngOnInit() {
  }

  export() {
    this.data_export.load();
    this.impSrv.exportGeneric(this.action.url).subscribe((data) => {
      if (data) {
        const myBlob: Blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le'
        });
        FileSaver.saveAs(myBlob, 'Exportacion.xlsx');
        this.data_export.ok(data);
        this.export_response = new ImportResponse(true, data);
      }
    }, error2 => {
      this.data_export.ko(error2);
      this.export_response = new ImportResponse(false, error2);
    });
  }
}

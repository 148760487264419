<div *ngIf="activo" fxLayout="column" fxFlexFill>
  <app-secciontopbar [title]="'analisis.seleccionado' | translate:{year: activo.ANYO}" back_url="/analisis"
                     [delete_text]="'analisis.delete' | translate" (onImport)="openImportDialog($event)"
                     (onExport)="openExportDialog($event)" (onDelete)="openDeleteDialog($event)"
  >
  </app-secciontopbar>
  <nav mat-tab-nav-bar class="pl-md-5 pr-md-5 pl-sm-3 pr-sm-3 d-flex justify-content-center" style="background: white;">
    <a mat-tab-link [routerLink]="'/analisis/'+this.id_activo+'/riesgos'"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'analisis.riesgos.title' | translate}}</a>
    <a mat-tab-link [routerLink]="'/analisis/'+this.id_activo+'/vulnerabilidades'" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'analisis.vulnerabilidades.title' | translate}} </a>
    <a mat-tab-link [routerLink]="'/analisis/'+this.id_activo+'/variablesclimaticas'" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'analisis.variablesclimaticas.title' | translate}} </a>
  </nav>
  <div fxFlex fxLayout="column" class="full-height" style="overflow-y: auto;">
    <router-outlet *ngIf="id_activo"></router-outlet>
  </div>
</div>


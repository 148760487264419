import {Component, Inject, OnInit} from '@angular/core';
import {DialogResponse} from '../../model/Utils';
import {AdminService} from '../../services/admin.service';
import {Petition} from '../../model/petition';
import {Accion} from '../../model/accion';
import {AdminaccionesformComponent} from '../adminplanaccion/adminaccionesform/adminaccionesform.component';
import {ListatiposService} from '../../services/listatipos.service';
import {Sorter} from '../../components/advancedlist/util';
import {Session} from '../../model/session';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {NoSessionsDialog} from '../../bi/bi.component';
import {AlertbarService} from '../../services/alertbar.service';

@Component({
  selector: 'app-adminsesiones',
  templateUrl: './adminsesiones.component.html',
  styleUrls: ['./adminsesiones.component.scss']
})
export class AdminsesionesComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  search_props = ['USERID', 'QVUSER'];
  search_placeholder = 'admin.sesionesbi.searchplaceholder';
  sorters = [new Sorter('models.sesion.FECHA_CADUCIDAD.label', 'FECHA_CADUCIDAD'),
    new Sorter('models.sesion.FECHA_CREACION.label', 'FECHA_CREACION')];
  datos: Session[];

  constructor(private adminSrv: AdminService, private dialog: MatDialog, private alert: AlertbarService) {
    this.load();
    this.alert.applyToPetition(this.delete_peticion);
  }

  load() {
    this.datos_peticion.load();
    this.adminSrv.getSessionsActiva().subscribe(value => {
        value.sort((a, b) => ('' + a.FECHA_CREACION).localeCompare('' + b.FECHA_CREACION));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }


  openDialog(session: Session): void {
    const dialogRef = this.dialog.open(CloseSessionDialog, {});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete_peticion.load();
        this.adminSrv.closeSessionActiva(session.ID).subscribe(value => {
          this.delete_peticion.ok(value);
          this.load();
        }, error2 => {
          this.delete_peticion.ko(error2);
        });
      }
    });
  }

  ngOnInit() {
  }

}

@Component({
  selector: 'closesession_dialog',
  templateUrl: 'closesession.dialog.html',
})
export class CloseSessionDialog {
  constructor(public dialogRef: MatDialogRef<CloseSessionDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

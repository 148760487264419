import { AlertService } from './alert.service';
import { BehaviorSubject } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { MunicipioService } from './municipio.service';
import { appConfig } from '../app.config';
import { PlanAccion } from '../model/planaccion';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
import * as i3 from "./municipio.service";
var PlanaccionService = /** @class */ (function () {
    function PlanaccionService(http, alertsvc, munsrv) {
        this.http = http;
        this.alertsvc = alertsvc;
        this.munsrv = munsrv;
        this.selected = new BehaviorSubject(null);
    }
    PlanaccionService.prototype.getInventarioActivo = function () {
        return this.selected.getValue();
    };
    PlanaccionService.prototype.getAPIUrl = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/';
    };
    PlanaccionService.prototype.getAPIUrlHcoAccion = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.selected_id + '/Accion/';
    };
    PlanaccionService.prototype.getAPIUrlImport = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.selected_id + '/import/';
    };
    PlanaccionService.prototype.getAPIUrlExport = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.selected_id + '/export/';
    };
    PlanaccionService.prototype.getAll = function () {
        return this.http.get(this.getAPIUrl());
    };
    PlanaccionService.prototype.getById = function (_id) {
        return this.http.get(this.getAPIUrl() + _id);
    };
    PlanaccionService.prototype.create = function (d) {
        return this.http.post(this.getAPIUrl(), d);
    };
    PlanaccionService.prototype.clone = function (d, year) {
        return this.http.post(this.getAPIUrl() + d.ANYO + '/clone/' + year, {});
    };
    PlanaccionService.prototype.update = function (d) {
        return this.http.put(this.getAPIUrl() + d.ID, d);
    };
    PlanaccionService.prototype.replace = function (d) {
        return this.http.put(this.getAPIUrl() + 'replace', d);
    };
    PlanaccionService.prototype.delete = function (_id) {
        return this.http.delete(this.getAPIUrl() + _id);
    };
    PlanaccionService.prototype.setActive = function (d) {
        this.selected_id = d.ID;
        this.selected.next(d);
    };
    PlanaccionService.prototype.initialize = function () {
        this.selected.next(new PlanAccion());
    };
    PlanaccionService.prototype.getAccionById = function (_id) {
        return this.http.get(this.getAPIUrlHcoAccion() + _id);
    };
    PlanaccionService.prototype.updateAccion = function (d) {
        return this.http.put(this.getAPIUrlHcoAccion() + d.ID, d);
    };
    PlanaccionService.prototype.deleteAccion = function (d) {
        return this.http.delete(this.getAPIUrlHcoAccion() + d.ID);
    };
    PlanaccionService.prototype.createAccion = function (d) {
        return this.http.post(this.getAPIUrlHcoAccion(), d);
    };
    PlanaccionService.ngInjectableDef = i0.defineInjectable({ factory: function PlanaccionService_Factory() { return new PlanaccionService(i0.inject(i1.HttpClient), i0.inject(i2.AlertService), i0.inject(i3.MunicipioService)); }, token: PlanaccionService, providedIn: "root" });
    return PlanaccionService;
}());
export { PlanaccionService };

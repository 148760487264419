import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IndicadorAccionPred} from '../../../model/indicadoraccionpred';
import {ListatiposService} from '../../../services/listatipos.service';
import {EstadoAccion} from '../../../model/estadoaccion';
import {PrioridadAccion} from '../../../model/prioridadaccion';
import {HCOAccion, HCOAccionDB} from '../../../model/hcoaccion';
import {PlanaccionService} from '../../../services/planaccion.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Petition} from '../../../model/petition';
import {TablaImportacionDialog} from '../../importacionplantilla/importacionplantilla.component';
import {IndicadorAccion} from '../../../model/indicadoraccion';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-indicadorform',
  templateUrl: './indicadorform.component.html',
  styleUrls: ['./indicadorform.component.scss']
})
export class IndicadorformComponent implements OnInit, OnChanges {
  indicadores_accion_pred: IndicadorAccionPred[] = [];
  form: FormGroup;
  fetch_accion: Petition = new Petition();
  editable = false;

  ngOnInit(): void {
    if ((this.data.action == 'edit' || this.data.action == 'view') && this.data.INDICADORACCION) {
      const dato: IndicadorAccion = this.data.INDICADORACCION;
      this.form.reset(dato);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  constructor(public dialogRef: MatDialogRef<IndicadorformComponent>, private userSrv: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any, private tipoSrv: ListatiposService, private paSrv: PlanaccionService, private formBuilder: FormBuilder, private translateService: TranslateService, private snackBar: MatSnackBar) {
    tipoSrv.fetchIndicadorAccionPred().subscribe(value => {
      this.indicadores_accion_pred = value;
    });
    this.form = this.formBuilder.group(new IndicadorAccion());
    this.editable = this.userSrv.isEditable();
    if (!this.editable)
      this.form.disable();
  }

  setIndicadorPred(value) {
    this.form.patchValue({
      MAGNITUD: value.MAGNITUD,
      NOMBRE: value.NOMBRE,
      VALOR: value.VALOR,
      OBSERVACIONES: value.OBSERVACIONES,
    });
  }

  save() {
    /*const new_dato: HCOAccion = Object.assign(this.dato, this.form.value);
    new_dato.IDESTADOACCION = new_dato.ESTADOACCION.ID;
    new_dato.IDPRIORIDADACCION = new_dato.PRIORIDADACCION.ID;
    this.paSrv.updateAccion(new HCOAccionDB(new_dato)).subscribe(value => {
        if (value) {
          this.translateService.get('planaccion.acciones.form.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.form.markAsPristine();
        }
      }
      ,
      error2 =>
        this.translateService.get('planaccion.acciones.form.update_error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}))
    );*/
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

<mat-toolbar fxLayout="row wrap" id="topbar" class="pl-md-5  pr-md-5 pl-sm-3 pr-sm-3 full-height"
             style="background: white;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex>
    <span>{{'analisis.home.title' | translate}}</span>
  </div>
  <div class="toolbar-spacer"></div>
  <div class="flex" fxLayoutGap="10px">
  </div>
</mat-toolbar>
<div class="my-5 row m-0 d-flex flex-grow-1" id="panel">
  <div class="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
    <app-advancedlist [datos]="analisis" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canAdd]="true" (onAdd)="openCreateDialog()"   [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending" [canClick]="true" (onClick)="setActive($event)"
    >
      <ng-template let-item="item">
        <div class="d-flex full-width" fxLayoutGap="10px">
          <div class="d-flex flex-column justify-content-start align-items-center">
            <span class="grey label align-self-start"> {{'analisis.home.list.item.anyo' | translate}} </span>
            <span class="dark text-lg-m" id="year">{{item.ANYO}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex justify-content-end align-items-center" fxLayoutGap="10px">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.home.list.item.riesgos' | translate}} </span>
              <span id="riesgos">{{item.RIESGOS.length}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <span
                  class="grey label align-self-start"> {{'analisis.home.list.item.vulnerabilidades' | translate}} </span>
              <span id="vulnerabilidades">{{item.VULNERABILIDADES.length }}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
  </div>
</div>

<div class="full-height row m-0" id="panel">
  <div *ngIf="datos" [class]="activeFormID ? 'col-md-7 p-3' : 'col-md-8 p-3 offset-md-2'">
    <app-advancedlist [datos]="datos" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canAdd]="true" (onAdd)="openNuevoDatoDialog()"   [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending" [canClick]="true" (onClick)="selectAccion($event)"
    >
      <ng-template let-item="item">
        <div class="full-width align-items-center" fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="column">
              <span class="sector-title">{{item.TIPOVULNERABILIDAD.NOMBRE}}</span>
              <span class="text-lg-m color-grey-800">{{item.NOMBRE | capitalize}}</span>
            </div>
            <div class="flex-grow-1"></div>
            <div *ngIf="item.INDICADORVULNERABILIDAD.length > 0">
              <span class="indicador-title">{{item.INDICADORVULNERABILIDAD.length}} {{'analisis.vulnerabilidades.list.item.indicadores' | translate}}</span>
            </div>
          </div>
          <div class="flex-grow-1"></div>
          <div fxLayout="row" fxLayoutGap="15px" class="justify-content-center" style="min-width: 220px;">
            <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
              <button mat-mini-fab class="mat-elevation-z1"
                      [style.background]="getNivelColor(item.IDNIVELACTUAL)"
                      [style.color]="'white'">
                <mat-icon>healing</mat-icon>
              </button>
              <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
                <span class="text-sm color-grey-600">{{'analisis.vulnerabilidades.list.item.nivelactual' | translate}}</span>
                <span class="text-sm-m color-grey-700">{{item.NIVELACTUAL.NOMBRE}}</span>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <mat-icon class="fecha-icono">arrow_forward</mat-icon>
            </div>
            <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
              <button mat-mini-fab class="mat-elevation-z1"
                      [style.background]="getNivelColor(item.IDNIVELPREVISTO)"
                      [style.color]="'white'">
                <mat-icon>healing</mat-icon>
              </button>
              <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
                <span class="text-sm color-grey-600">{{'analisis.vulnerabilidades.list.item.nivelprevisto'| translate}}</span>
                <span class="text-sm-m color-grey-700">{{item.NIVELPREVISTO.NOMBRE}}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
  </div>
  <div id="side-form" *ngIf="datos && activeFormID" class="col-md-5 p-3">
    <app-vulnerabilidadform [dato]="getActiveDato()" (close)="closeForm()" (updated)="updateVulnerabilidad($event)"
                            (delete)="openDeleteDialog($event)">
    </app-vulnerabilidadform>
  </div>
</div>


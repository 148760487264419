import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class RedirectRoleGuard implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const user = this.auth.currentUser;
    // decode the token to get its payload
    if (
      !this.auth.isAuthenticated()
    ) {
      this.router.navigate(['login']);
      return false;
    }
    switch (user.role) {
      case 'USER': {
        this.router.navigate(['home']);
        return true;
      }
      case 'ADMIN': {
        this.router.navigate(['admin']);
        return true;
      }
      default: {
        this.router.navigate(['home']);
        return false;
      }
    }
  }
}

<div class="tcontainer border rounded" style="background: white;">
  <div id="buttons-div" class="d-flex p-2 pl-4 pr-4 align-items-center bg-grey-lightest border-b" fxLayoutGap="10px">
    <div class="ml-1 flex bg-grey-lightest" fxLayoutGap="15px">
      <button *ngIf="hasAdd && selection.isEmpty() && authSrv.isEditable()"
              class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
              id="add"
              (click)="addCallback.next()">
        <i class="material-icons text-md">add</i>
      </button>
      <button *ngIf="hasDelete && !selection.isEmpty() && authSrv.isEditable()" mat-button color="accent" (click)="removeRows()">
        <mat-icon>delete</mat-icon>
        {{'editable.remove' | translate:{number: getSelectedCount() } }}
      </button>
      <button *ngIf="hasDelete && !selection.isEmpty() && authSrv.isEditable()" mat-button color="accent" (click)="selection.clear()">
        {{'editable.cancel' |translate }}
      </button>
    </div>
    <div class="shadow-inner bg-white rounded-full flex -mx-2 py-1 px-2 border">
      <mat-icon class="text-grey">search</mat-icon>
      <div>
        <input #filter matInput [placeholder]="'editable.filterplaceholder' | translate:{datatype:datatypePlural}">
      </div>
    </div>
    <div class="toolbar-spacer ml-4 mr-4">
    </div>
    <div fxLayoutGap="15px">
      <button *ngIf="hasExport && authSrv.isEditable()"
              class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
              (click)="exportCallback.next()">
        <mat-spinner *ngIf="exporting" [diameter]="20"></mat-spinner>
        <span class="text-md">{{'editable.export' |translate}}</span>
      </button>
      <button *ngIf="hasImport && authSrv.isEditable()"
              class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
              (click)="importCallback.next()">
        <mat-spinner *ngIf="importing" [diameter]="20"></mat-spinner>
        <span class="text-md">{{'editable.import' |translate}}</span>
      </button>
    </div>
  </div>
  <div class="table-container" style="position: relative;">
    <mat-table #table [dataSource]="dataSource" matSort>
      <ng-container *ngIf="selectable  && authSrv.isEditable() " matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="masterToggle()"
                        [checked]="isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(getDataIndex(element)) : null"
                        [checked]="selection.isSelected(getDataIndex(element))">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container *ngFor="let col of cols" [matColumnDef]="col.id">
        <mat-header-cell *matHeaderCellDef [mat-sort-header]="col.id" [start]="col.sort"
                         [fxFlex]="col.width ? col.width : ''">
          <span *ngIf="col.title">{{col.title | translate}}</span>
          <mat-icon #tooltip="matTooltip" class="col-info" *ngIf="col.tooltip" [matTooltip]="col.tooltip | translate"
                    matTooltipPosition="above">info
          </mat-icon>
        </mat-header-cell>
        <mat-cell [ngSwitch]="col.type" *matCellDef="let element"
                  [fxFlex]="col.width ? col.width  : ''"
                  [style.width]="col.type == 'icon' ? 'flex: 0 0 100px !important;white-space: unset !important;' : ''">
          <div *ngSwitchCase="'icon'">
            <button mat-icon-button (click)="col.data.callback ? col.data.callback(element) : null">
              <mat-icon
                *ngIf="col.data.badge && col.data.badge(element)>0 && (col.data.show ? col.data.show(element) : true)"
                [matBadge]="col.data.badge(element)" matBadgeColor="accent"
                matBadgeSize="medium" matBadgePosition="below">
                {{col.data.icon}}
              </mat-icon>
              <mat-icon
                *ngIf="col.data.badge && col.data.badge(element)==0 && (col.data.show ? col.data.show(element) : true)">
                {{col.data.icon}}
              </mat-icon>
              <mat-icon *ngIf="!col.data.badge && (col.data.show ? col.data.show(element) : true)">
                {{col.data.icon}}
              </mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="'text'">
            <mat-form-field *ngIf="col.editable && element == active_row_edit" [floatLabel]="'always'" cdkTrapFocus
                            [style.width]="'calc('+col.width+' - 10px)'">
              <input matInput [type]="col.type"
                     [(ngModel)]="element[col.data.prop]"
                     [name]="col.data.prop"/>
            </mat-form-field>
            <span *ngIf="!col.data.subprop && col.type != 'icon' && (!col.editable || element != active_row_edit)"
                  (click)="element != active_row_edit ? editRow(element) : null">
          {{nestedObj(element,col.data.prop)}}
        </span>
            <div *ngIf="col.data.subprop">
          <span *ngIf="col.type != 'icon' && (!col.editable || element != active_row_edit)"
                (click)="element != active_row_edit ? editRow(element) : null">
            {{nestedObj(element,col.data.prop)}}
          </span>
              <br>
              <span *ngIf="col.data.subprop && (!col.editable || element != active_row_edit)" class="subprop"
                    (click)="element != active_row_edit ? editRow(element) : null">
            {{nestedObj(element,col.data.subprop)}}
          </span>
            </div>
          </div>
          <div *ngSwitchCase="'number'">
            <mat-form-field *ngIf="col.editable && element == active_row_edit" [floatLabel]="'always'" cdkTrapFocus
                            [style.width]="'calc('+col.width+' - 10px)'">
              <input matInput [type]="col.type"
                     [(ngModel)]="element[col.data.prop]"
                     [name]="col.data.prop"/>
            </mat-form-field>
            <span *ngIf="col.type != 'icon' && (!col.editable || element != active_row_edit)"
                  (click)="element != active_row_edit ? editRow(element) : null">
          {{nestedObj(element,col.data.prop) | localizedNumber}}
          </span>
          </div>

          <div *ngSwitchCase="'date'">
            <mat-form-field *ngIf="col.editable && element == active_row_edit" [floatLabel]="'always'" cdkTrapFocus
                            [style.width]="'calc('+col.width+' - 10px)'">
              <input matInput [type]="'text'"
                     [(ngModel)]="element[col.data.prop]"
                     [name]="col.data.prop"/>
            </mat-form-field>
            <span *ngIf="col.type != 'icon' && (!col.editable || element != active_row_edit)"
                  (click)="element != active_row_edit ? editRow(element) : null">
          {{nestedObj(element,col.data.prop) | localizedDate}}
          </span>
          </div>
          <div *ngSwitchCase="'function'">
        <span *ngIf="(!col.editable || element != active_row_edit)"
              (click)="element != active_row_edit ? editRow(element) : null">
          {{col.data.func(element) | localizedNumber}}
        </span>
          </div>
        </mat-cell>

      </ng-container>
      <ng-container matColumnDef="acciones" *ngIf="isEditable()">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <button mat-icon-button *ngIf="active_row_edit == element" (click)="finishEditRow(element,i)">
            <mat-icon color="accent">check</mat-icon>
          </button>
          <button mat-icon-button *ngIf="active_row_edit != element" (click)="editRow(element,i)" class="coloronhover">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button *ngIf="active_row_edit == element" (click)="cancelEditRow(element,i)">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;"
               (dblclick)="!isEditable() ? null : (row != active_row_edit ? editRow(row) : null)"
               [style.background]="active_row_edit == row ? 'rgba(0, 0, 0, 0.02)' : ''"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="d-flex flex-column justify-content-center align-items-center p-4">
      <mat-icon class="large-icon color-grey-400">
        inbox
      </mat-icon>
      <span class="mat-caption p-2">{{'editable.empty-table' | translate}}</span>
    </div>
  </div>
  <div *ngIf="loading" class="full-height d-flex flex-column justify-content-center align-items-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <mat-paginator id="paginator-div" class="bg-grey-lightest" #paginator
                 [length]="dataSource?.filteredData.length"
                 [pageIndex]="0"
                 [pageSize]="50"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 style="border-top: 1px solid rgba(0, 0, 0, 0.12);"
  >
  </mat-paginator>
</div>

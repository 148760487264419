/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bi.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./bi.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../services/bi.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../services/authentication.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/common/http";
import * as i11 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/platform";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "@angular/platform-browser/animations";
var styles_BiComponent = [i0.styles];
var RenderType_BiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BiComponent, data: {} });
export { RenderType_BiComponent as RenderType_BiComponent };
export function View_BiComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "flex flex-col full-vheight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["topbar", 1]], null, 9, "div", [["class", "bg-white border-b pl-2 pr-2 flex h-16 items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "button", [["class", "hover:shadow-sm bg-grey-lightest font-semibold text-grey-dark hover:text-orange-dark px-3 h-10 rounded-full border inline-flex items-center outline-none"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "material-icons mr-2 text-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["arrow_back"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "text-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "text-2xl ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "bg-grey-lightest"], ["style", "height:calc(100% - 68px)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "text-2xl ml-3 p-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("generico.atras")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("bi.title")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("bi.sesionActiva")); _ck(_v, 13, 0, currVal_2); }); }
export function View_BiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bi", [], null, null, null, View_BiComponent_0, RenderType_BiComponent)), i1.ɵdid(1, 245760, null, 0, i3.BiComponent, [i4.Router, i5.Location, i6.BiService, i7.DomSanitizer, i8.AuthenticationService, i9.MatDialog, i10.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BiComponentNgFactory = i1.ɵccf("app-bi", i3.BiComponent, View_BiComponent_Host_0, {}, {}, []);
export { BiComponentNgFactory as BiComponentNgFactory };
var styles_NoSessionsDialog = [];
var RenderType_NoSessionsDialog = i1.ɵcrt({ encapsulation: 2, styles: styles_NoSessionsDialog, data: {} });
export { RenderType_NoSessionsDialog as RenderType_NoSessionsDialog };
function View_NoSessionsDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["color", "primary"], ["mat-button", ""], ["routerLink", "/admin/sesiones"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.Platform, i14.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(2, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(3, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 1, 0, currVal_3); var currVal_4 = true; _ck(_v, 2, 0, currVal_4); var currVal_5 = "/admin/sesiones"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = i1.ɵnov(_v, 2).ariaLabel; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("bi.versesionesactivas")); _ck(_v, 4, 0, currVal_6); }); }
export function View_NoSessionsDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "p-5 flex justify-center items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "text-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i9.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoSessionsDialog_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["color", "primary"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.Platform, i14.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(10, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(11, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.authSrv.isAdmin(); _ck(_v, 7, 0, currVal_1); var currVal_5 = "primary"; _ck(_v, 9, 0, currVal_5); var currVal_6 = false; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("bi.nosession")); _ck(_v, 2, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_4 = i1.ɵnov(_v, 10).ariaLabel; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); var currVal_7 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("forms.actions.ok")); _ck(_v, 11, 0, currVal_7); }); }
export function View_NoSessionsDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nosessions_dialog", [], null, null, null, View_NoSessionsDialog_0, RenderType_NoSessionsDialog)), i1.ɵdid(1, 49152, null, 0, i3.NoSessionsDialog, [i9.MatDialogRef, i8.AuthenticationService, i9.MAT_DIALOG_DATA], null, null)], null, null); }
var NoSessionsDialogNgFactory = i1.ɵccf("nosessions_dialog", i3.NoSessionsDialog, View_NoSessionsDialog_Host_0, {}, {}, []);
export { NoSessionsDialogNgFactory as NoSessionsDialogNgFactory };

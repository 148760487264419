import {Component, Inject, OnInit} from '@angular/core';
import {AlertService} from '../../../services/alert.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {DeleteInventarioDialog, FormChangedDialog} from '../../inventario/inventario.component';
import {MunicipioService} from '../../../services/municipio.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormsService} from '../../../services/forms.service';
import {Municipio} from '../../../model/municipio';
import {PlanaccionService} from '../../../services/planaccion.service';
import {PlanAccion} from '../../../model/planaccion';
import {ImportDialogComponent} from '../../../components/import-dialog/import-dialog.component';
import {ImportAction, ImportResponse} from '../../../model/Utils';
import {ExportDialogComponent} from '../../../components/export-dialog/export-dialog.component';

@Component({
  selector: 'app-planaccion',
  templateUrl: './planaccion.component.html',
  styleUrls: ['./planaccion.component.scss']
})
export class PlanaccionComponent implements OnInit {
  municipio_activo: Municipio;
  datos: PlanAccion[] = [];
  id_activo: number = null;
  activo: PlanAccion = null;
  paramsSub;


  openImportDialog(){
    let dialogRef = this.dialog.open(ImportDialogComponent, {
      data: new ImportAction(this.dataSrv.getAPIUrlImport()),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: ImportResponse)  => {
      if(result.success){
        this.router.navigateByUrl('/planaccion/'+result.data.ID);
      }
    });
  }
  openExportDialog(){
    let dialogRef = this.dialog.open(ExportDialogComponent, {
      data: new ImportAction(this.dataSrv.getAPIUrlExport()),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: ImportResponse)  => {
    });
  }

  ngOnInit() {
    this.paramsSub = this.route.params.subscribe(params => {
      this.id_activo = +params['id'];
      this.fetch(this.id_activo);
    });
    this.munsrv.sel_municipio.subscribe(value => {
      if (value) {
        this.municipio_activo = value;
      }
    }, error2 => console.log(error2.messageText));
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.municipio_activo = null;
    this.datos = [];
    this.id_activo = null;
    this.activo = null;
  }

  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute, private munsrv: MunicipioService, private translateService: TranslateService,
              private alertsrv: AlertService, private dataSrv: PlanaccionService, public snackBar: MatSnackBar, public formService: FormsService) {

  }

  fetch(id) {
    this.dataSrv.getById(id).subscribe(value => {
        if (value) {
          this.activo = value;
          this.dataSrv.setActive(this.activo);
        }
      },
      error2 => this.alertsrv.error(error2));
  }

  openDeleteDialog(): void {
    let dialogRef = this.dialog.open(DeleteInventarioDialog, {
      data: {year: this.dataSrv.selected.getValue().ANYO},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.deleteInventario();
    });
  }

  deleteInventario() {
    this.dataSrv.delete(this.dataSrv.selected.getValue().ID).subscribe(value => {
      this.translateService.get('planaccion.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.router.navigateByUrl('/planaccion');
    }, error2 => console.log(error2));
  }
}


@Component({
  selector: 'deleteplanacciondialog',
  templateUrl: 'deleteplanaccion.dialog.html',
})
export class DeletePlanDialog {
  constructor(public dialogRef: MatDialogRef<DeletePlanDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

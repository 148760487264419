import {Component, Inject, OnInit} from '@angular/core';
import {SubambitoForm, SuperambitoForm} from '../../Forms';
import {Ambito} from '../../../model/ambito';
import {DialogAction} from '../../../model/Utils';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Fuente, Subambito, TipoSubambito} from '../../../model/subambito';
import {Petition} from '../../../model/petition';
import {ListatiposService} from '../../../services/listatipos.service';

@Component({
  selector: 'app-adminsubambitoform',
  templateUrl: './adminsubambitoform.component.html',
  styleUrls: ['./adminsubambitoform.component.scss']
})
export class AdminsubambitoformComponent implements OnInit {
  dato: Subambito;
  dataForm: SubambitoForm;
  fuentes : Fuente[] =  [];
  fuentes_peticion = new Petition();
  tipo_subambito : TipoSubambito[] =  [];
  tipos_peticion = new Petition();

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, public dataSrv: ListatiposService) {
    this.fetchFuentes();
    this.fetchTiposSubambito();
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new SubambitoForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new SubambitoForm(action);
    }
  }

  fetchFuentes(){
    this.fuentes_peticion.load();
    this.dataSrv.fetchFuente().subscribe(value => {
      this.fuentes = value;
      this.fuentes_peticion.ok(value);
    }, error2 => {
      this.fuentes_peticion.ko(error2);
    })
  }

  fetchTiposSubambito(){
    this.tipos_peticion.load();
    this.dataSrv.fetchTiposSubambito().subscribe(value => {
      this.tipo_subambito = value;
      this.tipos_peticion.ok(value);
    }, error2 => {
      this.tipos_peticion.ko(error2);
    })
  }
  ngOnInit() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Petition} from '../../model/petition';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MunicipioService} from '../../services/municipio.service';
import {Documento, DocumentoDB} from '../../model/documento';
import {DocumentosService} from '../../services/documentos.service';
import {UploadComponentDialog} from './upload.component';
import * as FileSaver from 'file-saver';
import {DatosInventario} from '../../model/datosinventario';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  data_fetch = new Petition();
  documentos: Documento[] = [];
  tipos = [];
  downloading_file_id;

  constructor(private router: Router, private dataSrv: DocumentosService, private munSrv: MunicipioService, private snackBar: MatSnackBar,
              private translateService: TranslateService, private dialog: MatDialog) {
    this.fetch();
  }

  nestedObj(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[this.nestedObj(x, key)] = rv[this.nestedObj(x, key)] || []).push(x);
      return rv;
    }, {});
  };

  fetch() {
    this.data_fetch.load();
    this.dataSrv.getAll().subscribe(value => {
        if (value) {
          this.documentos = value;
          this.tipos = [];
          const tipos = this.groupBy(value, 'TIPODOCUMENTO.NOMBRE');
          for (let p in tipos) {
            if (tipos.hasOwnProperty(p)) {
              this.tipos.push({name: p, value: tipos[p]});
            }
          }
        }
        this.data_fetch.ok(value);
      },
      error2 =>
        this.data_fetch.ko(error2)
    );
  }

  ngOnInit() {
  }

  openEditDialog(item): void {
    let dialogRef = this.dialog.open(UploadComponentDialog, {
      data: {action: 'edit', DOCUMENTO: item},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.edit(new Documento(result), result.file);
    });
  }

  openCreateDialog(): void {
    let dialogRef = this.dialog.open(UploadComponentDialog, {
      data: {action: 'create'},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.create(new Documento(result), result.file);
    });
  }


  downloadFile(item) {
    this.downloading_file_id = item.ID;
    this.dataSrv.download(item.ID).subscribe((res: Blob) => {
      const myBlob: Blob = new Blob([res], {
        type: res.type
      });
      var filename = item.FILENAME ? item.FILENAME : item.NOMBRE;
      FileSaver.saveAs(myBlob, filename);
      this.downloading_file_id = null;
    }, error2 => {

    });
  }

  edit(doc, file) {
    doc.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    this.dataSrv.update(new DocumentoDB(doc), file).subscribe(value => {
      if (value) {
        this.translateService.get('documentos.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetch();
      }
    }, error2 => console.log(error2));
  }

  create(doc, file) {
    doc.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    this.dataSrv.create(new DocumentoDB(doc), file).subscribe(value => {
      if (value) {
        this.translateService.get('documentos.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetch();
      }
    }, error2 => console.log(error2));
  }

  openDeleteDialog(doc): void {
    let dialogRef = this.dialog.open(DeleteDocumentoDialog, {
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.delete(doc);
    });
  }

  delete(analisis) {
    this.dataSrv.delete(analisis.ID).subscribe(value => {
      this.translateService.get('documentos.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.fetch();
    }, error2 => console.log(error2));
  }
}

@Component({
  selector: 'deletedocumentodialog',
  templateUrl: 'delete.dialog.html',
})
export class DeleteDocumentoDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDocumentoDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

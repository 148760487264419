import {Component, OnInit, ViewChild} from '@angular/core';
import {Municipio} from '../../../model/municipio';
import {MunicipioService} from '../../../services/municipio.service';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../services/forms.service';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter, MatSnackBar} from '@angular/material';
import {Petition} from '../../../model/petition';
import {ListatiposService} from '../../../services/listatipos.service';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-mun-datosgenerales',
  templateUrl: './mun-datosgenerales.component.html',
  styleUrls: ['./mun-datosgenerales.component.scss']
})
export class MunDatosgeneralesComponent implements OnInit {

  municipio: Municipio;
  municipio_orig: Municipio;
  id_area_municipio = '';
  form: FormGroup;
  data_update = new Petition();
  zonas_fetch = new Petition();
  comarca_fetch = new Petition();
  provincia_fetch = new Petition();
  municipio_fetch = new Petition();
  masks = {
    date: {
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    }
  };
  comarcas = [];
  zonasclimaticas = [];
  provincias = [];

  constructor(private munSrv: MunicipioService, private http: HttpClient, private formBuilder: FormBuilder, private formService: FormsService,
              private translateService: TranslateService, public snackBar: MatSnackBar, private adapter: DateAdapter<any>, private tipoSrv: ListatiposService, public auth: AuthenticationService) {
    this.adapter.setLocale('es');

    munSrv.sel_municipio.subscribe(m => {
      if (m) {
        this.fetchProvincias();
        this.fetchComarcas();
        this.fetchZonasClimaticas();
      }
    });
    this.form = this.formBuilder.group({
      idprovincia: [null],
      idcomarca: [null],
      latitud: [null],
      longitud: [null],
      fechaadhesionpacto: [null],
      idzonaclimatica: [null],
      NUMHABITANTES: [null],
      SUPERFICIE: [null]
    });
    if (!this.auth.isEditable()) {
      this.form.disable();
    }
  }


  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  fetchComarcas() {
    this.comarca_fetch.load();
    this.tipoSrv.fetchComarca().subscribe(value => {
      this.comarca_fetch.ok(value);
      this.comarcas = value;
    }, error2 => {
      this.comarca_fetch.ko(error2);
    });
  }

  fetchProvincias() {
    this.provincia_fetch.load();
    this.tipoSrv.fetchProvincia().subscribe(value => {
      this.provincia_fetch.ok(value);
      this.provincias = value;
    }, error2 => {
      this.provincia_fetch.ko(error2);
    });
  }

  fetchZonasClimaticas() {
    this.zonas_fetch.load();
    this.tipoSrv.fetchZonaClimatica().subscribe(value => {
      this.zonas_fetch.ok(value);
      this.zonasclimaticas = value;
    }, error2 => {
      this.zonas_fetch.ko(error2);
    });
  }

  comarcasDisponibles() {
    return this.comarcas.filter(c => c.IDPROVINCIA == this.form.controls['idprovincia'].value);
  }

  filterData(data, prop, value) {
    return data.filter(function (o) {
      if (o.children) o.children = this.filterData(o.children, prop, value);
      return o[prop] != value;
    });
  }

  guardar(values) {
    this.data_update.load();
    this.municipio.FECHAADHESIONPACTO = values.fechaadhesionpacto;
    this.municipio.LATITUD = values.latitud;
    this.municipio.LONGITUD = values.longitud;
    this.municipio.IDCOMARCA = values.idcomarca;
    this.municipio.IDZONACLIMATICA = values.idzonaclimatica;
    this.municipio.NUMHABITANTES = values.NUMHABITANTES;
    this.municipio.SUPERFICIE = values.SUPERFICIE;
    this.munSrv.update(this.municipio).subscribe(value => {
        this.translateService.get('municipio.datosgenerales.updated').subscribe(d => this.snackBar.open(d, 'Ok', {duration: 3000}));
        this.loadForm();
        this.data_update.ok(value);
      }
      , error2 => {
        this.translateService.get('municipio.datosgenerales.error').subscribe(d => this.snackBar.open(d, 'Ok', {duration: 3000}));
        this.data_update.ko(error2);
      });
  }

  loadForm() {
    this.munSrv.getById(this.munSrv.sel_municipio.getValue().ID).subscribe(value => {
      if (value) {
        this.municipio = value;
        this.municipio_orig = value;
        this.form.setValue({
          idprovincia: this.municipio.COMARCA.IDPROVINCIA,
          idcomarca: this.municipio.IDCOMARCA,
          latitud: this.municipio.LATITUD,
          longitud: this.municipio.LONGITUD,
          fechaadhesionpacto: new Date(this.municipio.FECHAADHESIONPACTO),
          idzonaclimatica: this.municipio.IDZONACLIMATICA,
          NUMHABITANTES: this.municipio.NUMHABITANTES,
          SUPERFICIE: this.municipio.SUPERFICIE
        });
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.formService.municipio_datos_dirty = false;
      }
    }, error2 => console.log(error2));
  }

  initialize() {
    this.comarcas = [];
    this.zonasclimaticas = [];
    this.loadForm();
  }

  displayProvincia(c): string {
    return c ? c.NOMBRE : c;
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.formService.municipio_datos_dirty = true;
      } else {
        this.formService.municipio_datos_dirty = false;
      }
    });
    this.initialize();
  }

}

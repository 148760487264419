import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IndicadorAccionPred} from '../../../model/indicadoraccionpred';
import {ListatiposService} from '../../../services/listatipos.service';
import {EstadoAccion} from '../../../model/estadoaccion';
import {Accion} from '../../../model/accion';
import {TipoAccion} from '../../../model/tipoaccion';
import {Observable} from 'rxjs/index';
import {map, startWith} from 'rxjs/internal/operators';
import {NuevaAccionDialog} from './planaccion-acciones.component';
import {PrioridadAccion} from '../../../model/prioridadaccion';
import {HCOAccion, HCOAccionDB} from '../../../model/hcoaccion';
import {PlanaccionService} from '../../../services/planaccion.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Petition} from '../../../model/petition';
import {IndicadorformComponent} from './indicadorform.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {load} from '@angular/core/src/render3/instructions';
import {IndicadorRiesgo} from '../../../model/Analisis';
import {IndicadorAccion} from '../../../model/indicadoraccion';

@Component({
  selector: 'app-accionform',
  templateUrl: './accionform.component.html',
  styleUrls: ['./accionform.component.scss']
})
export class AccionformComponent implements OnInit, OnChanges {
  @Input() dato: HCOAccion;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  estados_accion: EstadoAccion[] = [];
  prioridades_accion: PrioridadAccion[] = [];
  indicadores_accion_pred: IndicadorAccionPred[] = [];
  form: FormGroup;
  fetch_data: Petition = new Petition();
  editable = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dato) {
      this.load(changes.dato.currentValue);
    }
  }

  getRentabilidadColor() {
    const rentabilidad = (this.form.get('INVERSIONESTIMADA').value ? (this.form.get('REDUCCIONEMISIONES').value / this.form.get('INVERSIONESTIMADA').value) * 1000 : 0);
    if (rentabilidad <= 1) {
      return '#e1715f';
    } else if (rentabilidad > 1 && rentabilidad <= 10) {
      return '#d6d127';
    } else {
      return '#52d25e';
    }
  }

  getRentabilidadText() {
    const rentabilidad = (this.form.get('INVERSIONESTIMADA').value ? (this.form.get('REDUCCIONEMISIONES').value / this.form.get('INVERSIONESTIMADA').value) * 1000 : 0);
    if (rentabilidad <= 1) {
      return 'extra.pa.rentabilidad.baja';
    } else if (rentabilidad > 1 && rentabilidad <= 10) {
      return 'extra.pa.rentabilidad.media';
    } else {
      return 'extra.pa.rentabilidad.alta';
    }
  }

  load(dato: HCOAccion = this.dato) {
    this.fetch_data.load();
    this.paSrv.getAccionById(dato.ID).subscribe(value => {
        this.dato = value;
        this.form.reset({
          ESTADOACCION: value.ESTADOACCION,
          PRIORIDADACCION: value.PRIORIDADACCION,
          ANYOINICIO: value.ANYOINICIO,
          CODIGOADICIONAL: value.CODIGOADICIONAL,
          ANYOOBJETIVO: value.ANYOOBJETIVO,
          INVERSIONESTIMADA: value.INVERSIONESTIMADA,
          AHORROESTIMADO: value.AHORROESTIMADO,
          REDUCCIONEMISIONES: value.REDUCCIONEMISIONES,
          AHORROENERGETICOESTIMADO: value.AHORROENERGETICOESTIMADO,
          PESOEMISIONESAMBITO: value.PESOEMISIONESAMBITO,
          PESOEMISIONESTOTALES: value.PESOEMISIONESTOTALES,
          PRODUCCIONENERGIAESTIMADA: value.PRODUCCIONENERGIAESTIMADA,
          INDICADORACCION: value.INDICADORACCION,
          OBSERVACIONES: value.OBSERVACIONES,
          AYUDAS: value.AYUDAS
        });
        this.fetch_data.ok(value);
      }
      , error2 => this.fetch_data.ko(error2));
  }

  getEstado() {
    return this.form.get('ESTADOACCION').value || new EstadoAccion();
  }

  getPrioridad() {
    return this.form.get('PRIORIDADACCION').value || new PrioridadAccion();
  }

  constructor(private tipoSrv: ListatiposService, private paSrv: PlanaccionService, public userSrv: AuthenticationService, private formBuilder: FormBuilder, private translateService: TranslateService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    tipoSrv.fetchEstadoAccion().subscribe(value => {
      this.estados_accion = value;
    });
    tipoSrv.fetchPrioridadAccion().subscribe(value => {
      this.prioridades_accion = value;
    });
    tipoSrv.fetchIndicadorAccionPred().subscribe(value => {
      this.indicadores_accion_pred = value;
    });
    this.form = this.formBuilder.group({
      ESTADOACCION: null,
      PRIORIDADACCION: null,
      ANYOINICIO: null,
      ANYOOBJETIVO: null,
      CODIGOADICIONAL: null,
      INVERSIONESTIMADA: null,
      AHORROESTIMADO: null,
      REDUCCIONEMISIONES: null,
      AHORROENERGETICOESTIMADO: null,
      PESOEMISIONESAMBITO: null,
      PRODUCCIONENERGIAESTIMADA: null,
      PESOEMISIONESTOTALES: null,
      INDICADORACCION: [],
      OBSERVACIONES: null,
      AYUDAS: null
    });
    this.editable = this.userSrv.isEditable();
    if (!this.editable)
      this.form.disable();
  }

  openIndicadorDialog(dato): void {
    let accion = 'view';
    if (this.editable) {
      if (dato)
        accion = 'edit';
      else
        accion = 'create';
    }
    const dialogRef = this.dialog.open(IndicadorformComponent, {
      data: {INDICADORACCION: dato, action: accion},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const indicador: IndicadorAccion = data.data;
        let v = this.form.get('INDICADORACCION').value;
        switch (data.action) {
          case 'edit':
            v[this.form.get('INDICADORACCION').value.findIndex(i => i.ID == data.data.ID)] = data.data;
            break;
          case 'create':
            indicador.ID = 0;
            indicador.IDHCOACCION = this.dato.ID;
            v.push(data.data);
            break;
          case 'delete':
            v.splice(this.dato.INDICADORACCION.findIndex(i => i.ID == data.data.ID), 1);
            break;
        }
        this.form.controls['INDICADORACCION'].setValue(v);
        this.form.markAsDirty();
        // this.load();
      }
    }, error2 => this.snackBar.open(error2, null, {duration: 3000}));
  }

  save() {
    const new_dato: HCOAccion = Object.assign(this.dato, this.form.value);
    new_dato.IDESTADOACCION = new_dato.ESTADOACCION.ID;
    new_dato.IDPRIORIDADACCION = new_dato.PRIORIDADACCION.ID;
    this.paSrv.updateAccion(new HCOAccionDB(new_dato)).subscribe(value => {
        if (value) {
          this.translateService.get('planaccion.acciones.form.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.form.markAsPristine();
          this.updated.next(new_dato);
        }
      }
      ,
      error2 =>
        this.translateService.get('planaccion.acciones.form.update_error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}))
    );
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  closeForm() {
    this.close.next();
  }
}

export class Sorter {
  prop: string;
  text: string;

  constructor(text, prop) {
    this.prop = prop;
    this.text = text;
  }
}
export class Filter {
  options: object;
  text: string;
  type: string;

  constructor(text, type, options) {
    this.type = type;
    this.options = options;
    this.text = text;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adminanalisis.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "./adminanalisis.component";
var styles_AdminanalisisComponent = [i0.styles];
var RenderType_AdminanalisisComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminanalisisComponent, data: {} });
export { RenderType_AdminanalisisComponent as RenderType_AdminanalisisComponent };
export function View_AdminanalisisComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "bg-white border-b pl-4 pr-4 flex flex-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "text-2xl mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 18, "ul", [["class", "list-reset flex mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "li", [["class", "tablink mr-6 active:font-bold pb-2"], ["routerLink", "/admin/analisis"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, [[1, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(7, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(10, { exact: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "text-grey-dark font-semibold text-uppercase text-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 8, "li", [["class", "tablink mr-6 active:font-bold pb-2"], ["routerLink", "/admin/analisis/sector"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[3, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(16, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpod(19, { exact: 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 2, "span", [["class", "text-grey-dark font-semibold text-uppercase text-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(24, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_1 = "/admin/analisis"; _ck(_v, 6, 0, currVal_1); var currVal_2 = _ck(_v, 10, 0, true); var currVal_3 = "active"; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = "/admin/analisis/sector"; _ck(_v, 15, 0, currVal_5); var currVal_6 = _ck(_v, 19, 0, false); var currVal_7 = "active"; _ck(_v, 16, 0, currVal_6, currVal_7); _ck(_v, 24, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("admin.analisis.title")); _ck(_v, 2, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("admin.analisis.riesgo.title")); _ck(_v, 12, 0, currVal_4); var currVal_8 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("admin.analisis.sector.title")); _ck(_v, 21, 0, currVal_8); }); }
export function View_AdminanalisisComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-adminanalisis", [], null, null, null, View_AdminanalisisComponent_0, RenderType_AdminanalisisComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdminanalisisComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminanalisisComponentNgFactory = i1.ɵccf("app-adminanalisis", i4.AdminanalisisComponent, View_AdminanalisisComponent_Host_0, {}, {}, []);
export { AdminanalisisComponentNgFactory as AdminanalisisComponentNgFactory };

import {Component, Inject, OnInit} from '@angular/core';
import {HCOAccion} from '../../../model/hcoaccion';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Petition} from '../../../model/petition';
import {Sorter} from '../../../components/list/Util';
import {ListatiposService} from '../../../services/listatipos.service';
import {MunicipioService} from '../../../services/municipio.service';
import {AnalisisService} from '../../../services/analisis.service';
import {Analisis, Vulnerabilidad} from '../../../model/Analisis';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-vulnerabilidades',
  templateUrl: './vulnerabilidades.component.html',
  styleUrls: ['./vulnerabilidades.component.scss']
})
export class VulnerabilidadesComponent implements OnInit {
  search_props = ['NIVELACTUAL.NOMBRE','NIVELPREVISTO.NOMBRE','NOMBRE','TIPOVULNERABILIDAD.NOMBRE'];
  search_placeholder = 'analisis.vulnerabilidades.list.searchplaceholder';
  sorters = [new Sorter('models.vulnerabilidad.NIVELACTUAL.label', 'NIVELACTUAL.ID'),
    new Sorter('models.vulnerabilidad.NIVELPREVISTO.label', 'NIVELPREVISTO.ID')];
  dato: Analisis = new Analisis();
  datos: Vulnerabilidad[] = [];
  activeFormID: number = null;

  orders = [];
  data_update = new Petition();
  data_fetch = new Petition();

  constructor(private dialog: MatDialog, private dataSrv: AnalisisService, private munsvc: MunicipioService,
              private translateService: TranslateService, public snackBar: MatSnackBar) {
    this.dataSrv.selected.subscribe(value => {
      if (value) {
        this.dato = value;
        this.load();
      }
    }, error2 => console.log(error2));
  }

  ngOnInit() {
    this.initialize();
    this.orders = [new Sorter('analisis.vulnerabilidades.list.emisiones', 'REDUCCIONEMISIONES'),
      new Sorter('analisis.vulnerabilidades.list.inversion', 'INVERSIONESTIMADA')
    ];
  }

  update(data) {
    const datos = data.map(d => new HCOAccion(d).toDB());
    if (this.dato.ID) {
      this.data_update.load();
      this.dataSrv.update(datos).subscribe(value => {
          this.data_update.ok(value);
          this.translateService.get('analisis.vulnerabilidades.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
        }
        , error2 => {
          this.data_update.ko(error2);
          this.translateService.get('analisis.vulnerabilidades.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
        });
    }
  }

  selectAccion(item) {
    this.activeFormID = item.ID;
  }

  getActiveDato() {
    return this.datos.filter(d => d.ID == this.activeFormID)[0];
  }

  load() {
    this.data_fetch.load();
    this.dataSrv.getVulnerabilidades().subscribe(value => {
      if (value) {
        this.datos = value;
        this.data_fetch.ok(value);
      }
    }, error2 => {
      this.data_fetch.ko(error2);
    });
  }

  updateVulnerabilidad(item) {
    const index = this.datos.findIndex(a => a.ID == item.ID);
    this.datos[index] = item;
  }

  getNivelColor(value) {
    let color = '#bdbdbd';
    switch (value) {
      case 2:
        color = '#94C14A';
        break;
      case 3:
        color = '#e3b24a';
        break;
      case 4:
        color = '#E31E1E';
        break;
      default:
        color = '#bdbdbd';
        break;
    }
    return color;
  }

  getCambioIcon(value) {
    let icon = 'help';
    switch (value) {
      case 4:
        icon = 'trending_flat';
        break;
      case 3:
        icon = 'trending_down';
        break;
      case 2:
        icon = 'trending_up';
        break;
      default:
        icon = 'help_outline';
        break;
    }
    return icon;
  }

  addDato(data) {
    const tdato = new Vulnerabilidad();
    tdato.NOMBRE = data.NOMBRE;
    tdato.IDANALISIS = this.dataSrv.selected_id;
    tdato.IDTIPO = 1;
    tdato.IDNIVELPREVISTO = 1;
    tdato.IDNIVELACTUAL = 1;
    this.dataSrv.createVulnerabilidad(tdato).subscribe(value => {
        this.data_update.ok(value);
        this.activeFormID = value.ID;
        this.translateService.get('analisis.vulnerabilidades.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      }
      , error2 => {
        this.data_update.ko(error2);
        this.translateService.get('analisis.vulnerabilidades.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      });
  }

  deleteDatos(dato) {
    this.dataSrv.deleteVulnerabilidad(dato).subscribe(value => {
        if (value) {
          this.translateService.get('analisis.vulnerabilidades.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
          this.activeFormID = null;
        }
      }
      , error2 => {
        this.translateService.get('analisis.vulnerabilidades.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      });
  }

  initialize() {
    this.datos = [];
    this.load();
  }

  closeForm() {
    this.activeFormID = null;
  }

  openNuevoDatoDialog(): void {
    const dialogRef = this.dialog.open(NuevaVulnerabilidadDialog, {
      data: {datos: this.datos},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.addDato(data);
      }
    });
  }

  openDeleteDialog(data): void {
    if (data) {
      const dato = data;
      const dialogRef = this.dialog.open(DeleteVulnerabilidadDialog, {
        data: {number: 0},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(bool => {
        if (bool) {
          this.deleteDatos(dato);
        }
      });
    }
  }
}

// noinspection TsLint
@Component({
  selector: 'app-nuevavulnerabilidaddialog',
  templateUrl: 'nuevavulnerabilidad.dialog.html',
})
export class NuevaVulnerabilidadDialog {
  form: FormGroup;
  editable = false;

  constructor(public dialogRef: MatDialogRef<NuevaVulnerabilidadDialog>, private tipoSrv: ListatiposService, private formBuilder: FormBuilder, private authSrv: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = formBuilder.group({
      NOMBRE: null
    });
    this.editable = this.authSrv.isEditable();
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  displayAccion(c): string {
    return c ? c.NOMBRE : c;
  }
}


// noinspection TsLint
@Component({
  selector: 'deletevulnerabilidaddialog',
  templateUrl: 'deletevulnerabilidad.dialog.html',
})
export class DeleteVulnerabilidadDialog {
  constructor(public dialogRef: MatDialogRef<DeleteVulnerabilidadDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

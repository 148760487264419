import {DecimalPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false
})
export class LocalizedNumberPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, units?: string, digits?: string): any {
    const numberPipe: DecimalPipe = new DecimalPipe(this.translateService.currentLang);
    if (value && units)
      return numberPipe.transform(value) + ' ' + units;
    else if (value && digits) {
      return numberPipe.transform(value, digits);
    } else {
      return numberPipe.transform(value);
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-secciontopbar',
  templateUrl: './secciontopbar.component.html',
  styleUrls: ['./secciontopbar.component.scss']
})
export class SecciontopbarComponent implements OnInit {
  @Input() title;
  @Input() back_url;
  @Input() delete_text;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onImport: EventEmitter<any> = new EventEmitter();
  @Output() onExport: EventEmitter<any> = new EventEmitter();

  constructor(public authSrv : AuthenticationService) { }

  ngOnInit() {
  }

}

<div>
  <h2 mat-dialog-title>{{'admin.sesionesbi.closedialog.title' | translate}}</h2>
  <mat-dialog-content>
    <p>{{'admin.sesionesbi.closedialog.text' | translate}}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">{{'admin.sesionesbi.closedialog.cancel' | translate}}</button>&nbsp;
    <button mat-button [mat-dialog-close]="true">{{'admin.sesionesbi.closedialog.ok' | translate}}</button>
  </mat-dialog-actions>
</div>

<mat-toolbar fxLayout="row wrap" id="topbar" class="pl-md-5 pr-md-5 pl-sm-3 pr-sm-3"
             style="background: white;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex>
    <span>{{'informes.home.title' | translate}}</span>
  </div>
</mat-toolbar>
<div fxLayout="row" class="flex-wrap p-5" id="panel" fxLayoutGap="20px">
  <div *ngFor="let inf of informes" class="my-2">
    <div class="informe-item justify-content-center border rounded" fxLayout="column" fxLayoutGap="5px">
      <div
        class="div-imagen flex-grow-1 position-relative d-flex flex-column align-items-center justify-content-center mb-0 p-2">
        <div class="inf-imagen" width="200px" [style.background-image]="'url('+inf.img+')'"></div>
        <div class="full-height full-width back-dark"></div>
        <button class="z-10 bg-grey-lightest font-semibold text-grey-dark hover:text-orange-dark px-2 py-2 justify-center rounded-full border inline-flex items-center outline-none"
          (click)="download(inf)">
          <i class="material-icons mr-2 text-md">vertical_align_bottom</i>
          <span class="text-sm">{{'informes.home.item.descargar' | translate}}</span>
        </button>
      </div>
      <div class="p-2 bg-white text-center d-flex justify-content-center align-items-center"
           style="height: 60px;border-top:1px solid #e9e9e9">
        <span class="text-md-b color-grey-700">{{inf.nombre}}</span>
      </div>
    </div>
  </div>
</div>

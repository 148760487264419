<div class="p-5 flex justify-center items-center">
  <span class="text-lg">{{'bi.nosession' | translate}}</span>
</div>
<div mat-dialog-actions>
  <button *ngIf="authSrv.isAdmin()" mat-button color="primary" [mat-dialog-close]="true" routerLink="/admin/sesiones">
    {{'bi.versesionesactivas' | translate}}
  </button>
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{'forms.actions.ok' | translate}}
  </button>
</div>

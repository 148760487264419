import {Component, Inject, OnInit} from '@angular/core';
import {HCOAccion, HCOAccionDB} from '../../../model/hcoaccion';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {MunicipioService} from '../../../services/municipio.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Petition} from '../../../model/petition';
import {Sorter} from '../../../components/list/Util';
import {ListatiposService} from '../../../services/listatipos.service';
import {Analisis, Riesgo, RiesgoDB, RiesgoPred, SectorRiesgoPred} from '../../../model/Analisis';
import {AnalisisService} from '../../../services/analisis.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {Observable} from 'rxjs/index';
import {Municipio} from '../../../model/municipio';
import {map, startWith} from 'rxjs/internal/operators';

@Component({
  selector: 'app-riesgos',
  templateUrl: './riesgos.component.html',
  styleUrls: ['./riesgos.component.scss']
})
export class RiesgosComponent implements OnInit {
  search_props = ['NOMBRE','SECTOR','CAMBIOFRECUENCIA.NOMBRE','CAMBIOINTENSIDAD.NOMBRE','NIVELRIESGO.NOMBRE','NIVELRIESGOPREVISTO.NOMBRE','PERIODORIESGO.NOMBRE'];
  search_placeholder = 'analisis.riesgos.list.searchplaceholder';
  sorters = [new Sorter('models.riesgo.CAMBIOINTENSIDAD.label', 'CAMBIOINTENSIDAD.ID'),
    new Sorter('models.riesgo.CAMBIOFRECUENCIA.label', 'CAMBIOFRECUENCIA.ID'),
    new Sorter('models.riesgo.NIVELRIESGO.label', 'NIVELRIESGO.ID'),
    new Sorter('models.riesgo.NIVELRIESGOPREVISTO.label', 'NIVELRIESGOPREVISTO.ID')];
  dato: Analisis = new Analisis();
  datos: Riesgo[] = [];
  activeFormID: number = null;

  orders = [];
  data_update = new Petition();
  data_fetch = new Petition();

  constructor(private dialog: MatDialog, private dataSrv: AnalisisService, private munsvc: MunicipioService,
              private translateService: TranslateService, public snackBar: MatSnackBar) {
    this.dataSrv.selected.subscribe(value => {
      if (value) {
        this.dato = value;
        this.load();
      }
    }, error2 => console.log(error2));
  }


  ngOnInit() {
    this.initialize();
    this.orders = [new Sorter('analisis.riesgos.list.emisiones', 'REDUCCIONEMISIONES'),
      new Sorter('analisis.riesgos.list.inversion', 'INVERSIONESTIMADA')
    ];
  }

  update(data) {
    const datos = data.map(d => new RiesgoDB(d));
    if (this.dato.ID) {
      this.data_update.load();
      this.dataSrv.update(datos).subscribe(value => {
          this.data_update.ok(value);
          this.translateService.get('analisis.riesgos.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
        }
        , error2 => {
          this.data_update.ko(error2);
          this.translateService.get('analisis.riesgos.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
        });
    }
  }

  selectAccion(item) {
    this.activeFormID = item.ID;
  }

  getActiveDato() {
    return this.datos.filter(d => d.ID == this.activeFormID)[0];
  }

  load() {
    this.data_fetch.load();
    this.dataSrv.getRiesgos().subscribe(value => {
      if (value) {
        this.datos = value;
        this.data_fetch.ok(value);
      }
    }, error2 => {
      this.data_fetch.ko(error2);
    });
  }

  updateRiesgo(item) {
    this.dataSrv.getRiesgoById(item.ID).subscribe(value => {
      const index = this.datos.findIndex(a => a.ID == value.ID);
      this.datos[index] = value;
    });
  }

  getNivelColor(value) {
    let color = '#bdbdbd';
    switch (value) {
      case 2:
        color = '#94C14A';
        break;
      case 3:
        color = '#e3b24a';
        break;
      case 4:
        color = '#E31E1E';
        break;
      default:
        color = '#bdbdbd';
        break;
    }
    return color;
  }

  getCambioIcon(value) {
    let icon = 'help';
    switch (value) {
      case 4:
        icon = 'trending_flat';
        break;
      case 3:
        icon = 'trending_down';
        break;
      case 2:
        icon = 'trending_up';
        break;
      default:
        icon = 'help_outline';
        break;
    }
    return icon;
  }

  getPeriodoColor(value) {
    let color = '#a4afb5';
    switch (value) {
      case 3:
        color = '#d54946';
        break;
      case 4:
        color = '#d5900f';
        break;
      case 5:
        color = '#53c220';
        break;
      default:
        color = '#a4afb5';
        break;
    }
    return color;
  }

  addDato(data) {
    const tdato = new Riesgo();
    tdato.NOMBRE = data.NOMBRE;
    tdato.SECTOR = data.SECTOR;
    tdato.IDANALISIS = this.dataSrv.selected_id;
    tdato.IDNIVEL = 1;
    tdato.IDNIVELPREVISTO = 1;
    tdato.IDPERIODO = 1;
    tdato.IDCAMBIOFRECUENCIA = 1;
    tdato.IDCAMBIOINTENSIDAD = 1;
    this.dataSrv.createRiesgo(tdato).subscribe(value => {
        this.data_update.ok(value);
        this.activeFormID = value.ID;
        this.translateService.get('analisis.riesgos.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      }
      , error2 => {
        this.data_update.ko(error2);
        this.translateService.get('analisis.riesgos.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      });
  }

  deleteDatos(dato) {
    this.dataSrv.deleteRiesgo(dato).subscribe(value => {
        if (value) {
          this.translateService.get('analisis.riesgos.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
          this.activeFormID = null;
        }
      }
      , error2 => {
        this.translateService.get('analisis.riesgos.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      });
  }

  initialize() {
    this.datos = [];
    this.load();
  }

  closeForm() {
    this.activeFormID = null;
  }

  openNuevoDatoDialog(): void {
    const dialogRef = this.dialog.open(NuevoRiesgoDialog, {
      data: {datos: this.datos},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.addDato(data);
      }
    });
  }

  openDeleteDialog(data): void {
    if (data) {
      const dato = data;
      const dialogRef = this.dialog.open(DeleteRiesgoDialog, {
        data: {number: 0},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(bool => {
        if (bool) {
          this.deleteDatos(dato);
        }
      });
    }
  }
}

// noinspection TsLint
@Component({
  selector: 'app-nuevoriesgodialog',
  templateUrl: 'nuevoriesgo.dialog.html',
})
export class NuevoRiesgoDialog {
  form: FormGroup;
  editable: boolean = false;
  riesgospred: RiesgoPred[] = [];
  sectorpred: SectorRiesgoPred[] = [];
  riesgoCtrl: FormControl = new FormControl();
  sectorCtrl: FormControl = new FormControl();
  filtered: Observable<any[]>;
  filteredSECTOR: Observable<any[]>;

  constructor(public dialogRef: MatDialogRef<NuevoRiesgoDialog>, private tipoSrv: ListatiposService, private formBuilder: FormBuilder, private authSrv: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fetchRiesgosPred();
    this.fetchSectorPred();
    this.form = formBuilder.group({
      NOMBRE: null,
      SECTOR: null,
    });
    this.filtered = this.riesgoCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filter(state) : this.riesgospred.slice())
      );

    this.filteredSECTOR = this.sectorCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterSECTOR(state) : this.sectorpred.slice())
      );

    this.editable = this.authSrv.isEditable();
    if (!this.editable)
      this.form.disable();
  }

  filterSECTOR(name: string | SectorRiesgoPred) {
    if (typeof name == 'string') {
      this.form.patchValue({SECTOR: name});
      return this.sectorpred.filter(state =>
        state.NOMBRE.toLowerCase().indexOf(name.toLowerCase()) >= 0);
    } else {
      this.form.patchValue({SECTOR: name.NOMBRE});
    }
  }

  filter(name: string | RiesgoPred) {
    if (typeof name == 'string') {
      this.form.patchValue({NOMBRE: name});
      return this.riesgospred.filter(state =>
        state.NOMBRE.toLowerCase().indexOf(name.toLowerCase()) >= 0);
    } else {
      this.form.patchValue({NOMBRE: name.NOMBRE});
    }
  }

  getFormValues() {
    return {
      NOMBRE: typeof this.form.get('NOMBRE').value == 'string' ? this.form.get('NOMBRE').value : this.form.get('NOMBRE').value.NOMBRE,
      SECTOR: typeof this.form.get('NOMBRE').value == 'string' ? this.form.get('SECTOR').value : this.form.get('SECTOR').value.NOMBRE
    };
  }

  display(c): string {
    return c ? c.NOMBRE : c;
  }

  fetchRiesgosPred() {
    this.tipoSrv.fetchRiesgoPred().subscribe(value => this.riesgospred = value);
  }

  fetchSectorPred() {
    this.tipoSrv.fetchSectorRiesgoPred().subscribe(value => this.sectorpred = value);
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  displayAccion(c): string {
    return c ? c.NOMBRE : c;
  }
}


// noinspection TsLint
@Component({
  selector: 'deleteriesgodialog',
  templateUrl: 'deleteriesgo.dialog.html',
})
export class DeleteRiesgoDialog {
  constructor(public dialogRef: MatDialogRef<DeleteRiesgoDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

<div class="p-5 row m-0 d-flex flex-grow-1" id="panel">
  <div class="container-fluid">
    <app-advancedlist [datos]="variablesclimaticas" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending"
    >
      <ng-template let-item="item">
        <div class="d-flex full-width" fxLayoutGap="10px">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span
              class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.anyo' | translate}} </span>
            <span class="dark text-m" id="year">{{item.ANYO}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex flex-wrap justify-content-center align-items-center" fxLayoutGap="10px">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.temperatura' | translate}} </span>
              <span id="temperatura" class="text-m color-grey-500">{{item.TEMPERATURA}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.precipitaciones' | translate}} </span>
              <span id="precipitaciones" class="text-m color-grey-500">{{item.PRECIPITACIONES}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span
                class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.viento' | translate}} </span>
              <span id="viento" class="text-m color-grey-500">{{item.VIENTO}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.humedad' | translate}} </span>
              <span id="humedad" class="text-m color-grey-500">{{item.HUMEDAD}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.diasextremos' | translate}} </span>
              <span id="diasextremos" class="text-m color-grey-500">{{item.DIASEXTREMOSTEMPERATURA}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.lluviasdebiles' | translate}} </span>
              <span id="lluviasdebiles" class="text-m color-grey-500">{{item.DIASLLUVIASDEBILES}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.lluviasmoderadas' | translate}} </span>
              <span id="lluviasmoderadas" class="text-m color-grey-500">{{item.DIASLLUVIASMODERADAS}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.lluviasintensas' | translate}} </span>
              <span id="lluviasintensas" class="text-m color-grey-500">{{item.DIASLLUVIASINTENSAS}} </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.lluviastorrenciales' | translate}} </span>
              <span id="lluviastorrenciales" class="text-m color-grey-500">{{item.DIASLLUVIASTORRENCIALES}}</span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="grey label align-self-start"> {{'analisis.variablesclimaticas.list.item.diasnieve' | translate}} </span>
              <span id="diasnieve" class="text-m color-grey-500">{{item.DIASNIEVE}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
  </div>
</div>

<script src="../services/import.service.spec.ts"></script>
<div class="flex flex-col full-vheight">
  <div #topbar class="bg-white border-b pl-2 pr-2 flex h-16 items-center">
    <button class="hover:shadow-sm bg-grey-lightest font-semibold text-grey-dark hover:text-orange-dark px-3 h-10 rounded-full border inline-flex items-center outline-none"
            (click)="goBack()">
      <i class="material-icons mr-2 text-md">arrow_back</i>
      <span class="text-sm">{{'generico.atras' | translate}}</span>
    </button>
    <span class="text-2xl ml-3">{{'bi.title' | translate}}</span>
  </div>
  <div class="bg-grey-lightest" style="height:calc(100% - 68px)">
    <!-- <iframe id="qvFrame" *ngIf="qvActive" [src]="url" frameborder="0" style="height: 100%;width: 100%;"></iframe> -->
    <span class="text-2xl ml-3 p-8">{{'bi.sesionActiva' | translate}}</span>
  </div>
</div>

<div fxLayout="column" fxFlexFill>
  <div style="height: calc(100% - 100px);"
       class="col-sm-12 col-md-12  col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 p-4">
    <div id="datosgenerales" *ngIf="inv_fetch.success">
      <!--<div class="mb-2">-->
        <!--<span class="title-active"-->
              <!--[innerHTML]="'inventario.datosgenerales.title-active' | translate:{year: inventario.ANYO}"></span>-->
      <!--</div>-->
      <!--<div class="mb-4">-->
        <!--<span class="titulo-apartado">{{'inventario.datosgenerales.title' | translate}}</span>-->
      <!--</div>-->
      <form [formGroup]="form" (ngSubmit)="form.valid && guardar(form.value)" novalidate class="border rounded" style="background: white;">
        <div class="pt-4 pb-4">
          <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
            <mat-label class="mat-input">{{'inventario.datosgenerales.form.tipoinventario.label' | translate }}
            </mat-label>
            <mat-select formControlName="tipoinventario" name="IDTIPO">
              <mat-option *ngFor="let t of tiposinventario" [value]="t.ID">{{t.NOMBRE}}</mat-option>
            </mat-select>
            <mat-error class="error-message"
                       *ngIf="form.get('tipoinventario').hasError('required') && (form.get('tipoinventario').dirty || form.get('tipoinventario').touched)">
              {{'inventario.datosgenerales.form.tipoinventario.requiredMessage' |translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-lg-6 col-md-12 col-sm-12" [floatLabel]="'always'">
            <mat-label>{{'inventario.datosgenerales.form.nhabitantes.label' | translate }}</mat-label>
            <input matInput type="number" formControlName="nhabitantes" name="habitantes" min="0">
            <mat-error class="error-message"
                       *ngIf="form.get('nhabitantes').hasError('validMinValue') && (form.get('nhabitantes').dirty || form.get('nhabitantes').touched)">
              {{'inventario.datosgenerales.form.nhabitantes.minMessage' |translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-4 col-md-12 col-sm-12" [floatLabel]="'always'">
            <mat-label>{{'inventario.datosgenerales.form.nvehiculosgasolina.label' | translate }}</mat-label>
            <input matInput type="number" formControlName="nvehiculosgasolina" name="nvehiculosgasolina" min="0">
            <mat-error class="error-message"
                       *ngIf="form.get('nvehiculosgasolina').hasError('validMinValue') && (form.get('nvehiculosgasolina').dirty || form.get('nvehiculosgasolina').touched)">
              {{'inventario.datosgenerales.form.nvehiculosgasolina.minMessage' |translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-lg-4 col-md-12 col-sm-12" [floatLabel]="'always'">
            <mat-label>{{'inventario.datosgenerales.form.nvehiculosdiesel.label' | translate }}</mat-label>
            <input matInput type="number" formControlName="nvehiculosdiesel" name="nvehiculosdiesel" min="0">
            <mat-error class="error-message"
                       *ngIf="form.get('nvehiculosdiesel').hasError('validMinValue') && (form.get('nvehiculosdiesel').dirty || form.get('nvehiculosdiesel').touched)">
              {{'inventario.datosgenerales.form.nvehiculosdiesel.minMessage' |translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-lg-4 col-md-12 col-sm-12" [floatLabel]="'always'">
            <mat-label>{{'inventario.datosgenerales.form.implantaciongas.label' | translate }}</mat-label>
            <input matInput type="number" formControlName="implantaciongas" name="implantaciongas" min="0" max="100">
            <mat-error class="error-message"
                       *ngIf="form.get('implantaciongas').hasError('validBetweenValue') && (form.get('implantaciongas').dirty || form.get('implantaciongas').touched)">
              {{'inventario.datosgenerales.form.implantaciongas.betweenMessage' |translate }}
            </mat-error>
          </mat-form-field>
          <div id="formchanged">
            <span *ngIf="form.dirty">{{'inventario.datosgenerales.form.dirty' | translate}}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center  mt-2">
            <button *ngIf="authSrv.isEditable()" class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center" type="submit" [disabled]="!form.valid || !form.dirty">
              <i *ngIf="!data_update.pending" class="material-icons text-md mr-2">save</i>
              <mat-spinner color="warn" class=" mr-2" *ngIf="data_update.pending" [diameter]="20" value="indeterminate"></mat-spinner>
              <span class="text-md">{{'forms.actions.save' | translate}}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adminmunicipiohome',
  templateUrl: './adminmunicipiohome.component.html',
  styleUrls: ['./adminmunicipiohome.component.scss']
})
export class AdminmunicipiohomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import {AnalisisService} from '../../../services/analisis.service';
import {TranslateService} from '@ngx-translate/core';
import {Analisis, VariableClimatica} from '../../../model/Analisis';
import {Petition} from '../../../model/petition';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {MunicipioService} from '../../../services/municipio.service';
import {Sorter} from '../../../components/list/Util';

@Component({
  selector: 'app-variablesclimaticas',
  templateUrl: './variablesclimaticas.component.html',
  styleUrls: ['./variablesclimaticas.component.scss']
})
export class VariablesclimaticasComponent implements OnInit {
  search_props = ['ANYO'];
  search_placeholder = 'analisis.variablesclimaticas.list.searchplaceholder';
  sorters = [new Sorter('models.variableclimatica.ANYO.label', 'ANYO')];
  data_fetch = new Petition();
  variablesclimaticas: VariableClimatica[] = [];

  constructor(private router: Router, private anaSrv: AnalisisService, private munSrv: MunicipioService, private snackBar: MatSnackBar,
              private translateService: TranslateService, private dialog: MatDialog) {
    this.fetch();
  }

  fetch() {
    this.data_fetch.load();
    this.anaSrv.getVariableClimatica().subscribe(value => {
        if (value) {
          this.variablesclimaticas = value;
        }
        this.data_fetch.ok(value);
      },
      error2 =>
        this.data_fetch.ko(error2)
    );
  }

  ngOnInit(){

  }
}

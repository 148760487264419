import { Component, OnInit } from '@angular/core';
import {DialogResponse} from '../../../model/Utils';
import {AdminService} from '../../../services/admin.service';
import {Petition} from '../../../model/petition';
import {Accion} from '../../../model/accion';
import {AdminaccionesformComponent} from '../../adminplanaccion/adminaccionesform/adminaccionesform.component';
import {ListatiposService} from '../../../services/listatipos.service';
import {ZonaClimatica} from '../../../model/zonaclimatica';
import {AdminzonaclimaticaformComponent} from '../adminzonaclimaticaform/adminzonaclimaticaform.component';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminzonaclimaticalist',
  templateUrl: './adminzonaclimaticalist.component.html',
  styleUrls: ['./adminzonaclimaticalist.component.scss']
})
export class AdminzonaclimaticalistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE','REFERENCIA'];
  datos: ZonaClimatica[];
  form = AdminzonaclimaticaformComponent;
  list_title = 'admin.municipio.zonaclimatica.title';
  search_placeholder = 'admin.municipio.zonaclimatica.searchplaceholder';

  constructor(private dataSrv: ListatiposService,private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.fetchZonaClimatica().subscribe(value => {
        value.sort((a, b) => ('' + a.NOMBRE).localeCompare('' + b.NOMBRE));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createZonaClimatica(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateZonaClimatica(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteZonaClimatica(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

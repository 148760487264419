<h2 mat-dialog-title>{{'analisis.riesgos.createmodal.title' | translate}}</h2>
<form [formGroup]="form">
  <div mat-dialog-content>
    <!--<mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label for="tipoaccion">{{'analisis.riesgos.createmodal.tipoaccion' | translate }}</mat-label>
      <mat-select id="tipoaccion" formControlName="TIPOACCION" name="tipoaccion">
        <mat-option selected value="-1">{{'home.select' | translate}}</mat-option>
        <mat-option *ngFor="let m of tipos_accion" [value]="m">{{m.NOMBRE}}</mat-option>
      </mat-select>
    </mat-form-field>-->

    <mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label class="mat-input">{{'analisis.riesgos.createmodal.NOMBRE'  | translate }}
      </mat-label>
      <input matInput [matAutocomplete]="auto" [formControl]="riesgoCtrl">
      <mat-autocomplete #auto="matAutocomplete"  [displayWith]="display" [autoActiveFirstOption]="true">
        <mat-option *ngFor="let t of filtered | async" [value]="t">
          {{t.NOMBRE}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label class="mat-input">{{'analisis.riesgos.createmodal.SECTOR'  | translate }}
      </mat-label>
      <input matInput [matAutocomplete]="autoSECTOR" [formControl]="sectorCtrl">
      <mat-autocomplete #autoSECTOR="matAutocomplete"  [displayWith]="display" [autoActiveFirstOption]="true">
        <mat-option *ngFor="let t of filteredSECTOR | async" [value]="t">
          {{t.NOMBRE}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="">{{'analisis.riesgos.createmodal.cancel' | translate}}</button>
    <button color="accent" mat-button [mat-dialog-close]="form.value">{{'analisis.riesgos.createmodal.ok' | translate}}
    </button>
  </div>
</form>

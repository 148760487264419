import {MunicipioService} from '../services/municipio.service';

export class Centro {
  ID: number;
  NOMBRE: string;
  IDMUNICIPIO: number;
  CUPS_ELECTRICO: string;
  CUPS_GAS: string;
  CUPS_GLP: string;
  CUPS_GASOLEO: string;
  CUPS_OTROS: string;

  constructor(idmunicipio = null) {
    this.IDMUNICIPIO = idmunicipio;
  }
}

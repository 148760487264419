import { EventEmitter, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { DialogAction, DialogResponse, nestedObj, removeDiacritics } from './util';
import { MatDialog } from '@angular/material';
import { AuthenticationService } from '../../services/authentication.service';
var AdvancedlistComponent = /** @class */ (function () {
    function AdvancedlistComponent(dialog, authSrv) {
        this.dialog = dialog;
        this.authSrv = authSrv;
        this.scrollableY = true;
        this.fullHeight = true;
        this.loading = false;
        this.canSort = false;
        this.sorters = [];
        this.canClick = true;
        this.onClick = new EventEmitter();
        this.canAdd = false;
        this.onAdd = new EventEmitter();
        this.canEdit = false;
        this.onEdit = new EventEmitter();
        this.canDelete = false;
        this.onDelete = new EventEmitter();
        this.canSearch = false;
        this.onSearch = new EventEmitter();
        this.searchProps = [];
        this.searchPlaceholder = '';
        this.canRefresh = false;
        this.onRefresh = new EventEmitter();
        this.searchText = '';
        this.activeOrder = {
            pos: -1,
            order: null,
            dir: 0
        };
    }
    AdvancedlistComponent.prototype.ngOnInit = function () {
    };
    AdvancedlistComponent.prototype.ngOnChanges = function (changes) {
        if (changes.datos) {
            this.selectOrder(this.activeOrder.order, this.activeOrder.pos, this.activeOrder.dir);
        }
    };
    AdvancedlistComponent.prototype.onSearchChange = function (input) {
        this.searchText = input.target.value;
    };
    AdvancedlistComponent.prototype.filter = function () {
        var _this = this;
        return this.datos.filter(function (d) { return _this.searchProps.filter(function (c) { return nestedObj(d, c) ? removeDiacritics(nestedObj(d, c)).toLowerCase().indexOf(removeDiacritics(_this.searchText).toLowerCase()) >= 0 : false; }).length > 0; });
    };
    AdvancedlistComponent.prototype.getDatos = function () {
        if (this.searchText)
            return this.filter();
        else
            return this.datos;
    };
    AdvancedlistComponent.prototype.onDialogClose = function (data) {
        switch (data.action) {
            case DialogResponse.ADD: {
                if (this.authSrv.isEditable())
                    this.onAdd.emit(data);
                break;
            }
            case DialogResponse.EDIT: {
                if (this.authSrv.isEditable())
                    this.onEdit.emit(data);
                break;
            }
            case DialogResponse.DELETE: {
                if (this.authSrv.isEditable())
                    this.onDelete.emit(data);
                break;
            }
        }
    };
    AdvancedlistComponent.prototype.add = function (item) {
        this.openDialog(DialogAction.CREATE, item);
    };
    AdvancedlistComponent.prototype.select = function (item) {
        this.active_row = item;
        if (this.onClick) {
            this.onClick.emit(item);
        }
        else {
            if (this.authSrv.isEditable())
                this.openDialog(DialogAction.EDIT, item);
            else {
                this.openDialog(DialogAction.VIEW, item);
            }
        }
    };
    AdvancedlistComponent.prototype.openDialog = function (action, data) {
        var _this = this;
        if (this.formDialog) {
            var dialogRef = this.dialog.open(this.formDialog, {
                data: new DialogAction(action, data),
                disableClose: false,
            });
            dialogRef.afterClosed().subscribe(function (data) {
                _this.onDialogClose(data);
            });
        }
    };
    AdvancedlistComponent.prototype.onClearSearch = function (input) {
        input.value = '';
        this.searchText = '';
    };
    AdvancedlistComponent.prototype.selectOrder = function (order, pos, dir) {
        var actual_order = {
            pos: pos,
            order: order,
            dir: dir
        };
        if (this.activeOrder && actual_order.pos == this.activeOrder.pos && actual_order.dir == this.activeOrder.dir) {
            this.activeOrder = {
                pos: -1,
                order: null,
                dir: 0
            };
        }
        else {
            this.activeOrder = actual_order;
            this.datos.sort(function (a, b) {
                var propertyA = '';
                var propertyB = '';
                _a = [nestedObj(a, actual_order.order.prop), nestedObj(b, actual_order.order.prop)], propertyA = _a[0], propertyB = _a[1];
                var valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                var valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                return (valueA < valueB ? -1 : 1) * actual_order.dir;
                var _a;
            });
        }
    };
    return AdvancedlistComponent;
}());
export { AdvancedlistComponent };
var Order = /** @class */ (function () {
    function Order() {
    }
    return Order;
}());

<form [formGroup]="form" novalidate>
  <h2 mat-dialog-title>{{'analisis.createmodal.title' | translate}}</h2>
  <div mat-dialog-content fxLayout="column">
    <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
      <mat-label>{{'analisis.createmodal.anyo.label' | translate }}</mat-label>
      <input type="number" matInput formControlName="ANYO" required>
      <mat-error class="error-message"
                 *ngIf="form.get('ANYO').hasError('required') && (form.get('ANYO').dirty || form.get('ANYO').touched)">
        {{'analisis.createmodal.anyo.requiredMessage' | translate }}
      </mat-error>
      <mat-error class="error-message"
                 *ngIf="form.get('ANYO').hasError('validMinValue') && (form.get('ANYO').dirty || form.get('ANYO').touched)">
        {{'analisis.createmodal.anyo.minValue' | translate:{value: 1999} }}
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="">
      {{'analisis.createmodal.cancel' | translate }}
    </button>
    <button color="primary" mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">
      {{'analisis.createmodal.ok' | translate }}
    </button>
  </div>
</form>


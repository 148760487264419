<h2 mat-dialog-title>{{(this.data.action == 'create' ? 'planaccion.acciones.form.indicadores.create_text' :
  'planaccion.acciones.form.indicadores.view') | translate}}</h2>
<form [formGroup]="form">
  <div mat-dialog-content fxLayout="row" fxLayoutGap="15px">
    <div [fxFlex]="data.action == 'create' ? '60%' :'100%'" class="p-2">
      <mat-form-field class="col-md-12" [floatLabel]="'always'">
        <mat-label for="NOMBRE">{{'planaccion.acciones.form.indicadores.NOMBRE' | translate }}
        </mat-label>
        <input id="NOMBRE" matInput formControlName="NOMBRE" [attr.disabled]="!editable"/>
      </mat-form-field>
      <mat-form-field class="col-md-12" [floatLabel]="'always'">
        <mat-label for="MAGNITUD">{{'planaccion.acciones.form.indicadores.MAGNITUD' | translate
          }}
        </mat-label>
        <input id="MAGNITUD" matInput formControlName="MAGNITUD" [attr.disabled]="!editable"/>
      </mat-form-field>
      <mat-form-field class="col-md-12 mb-2" [floatLabel]="'always'">
        <mat-label for="VALOR">{{'planaccion.acciones.form.indicadores.VALOR' | translate }}
        </mat-label>
        <input id="VALOR" matInput formControlName="VALOR" [attr.disabled]="!editable"/>
        <mat-hint align="end">{{form.get('MAGNITUD').value || null}}</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-md-12" [floatLabel]="'always'">
        <mat-label for="OBSERVACIONES">{{'planaccion.acciones.form.indicadores.OBSERVACIONES' | translate }}
        </mat-label>
        <textarea id="OBSERVACIONES" matInput formControlName="OBSERVACIONES" [attr.disabled]="!editable"></textarea>
      </mat-form-field>
    </div>
    <div fxFlex="40%" *ngIf="data.action == 'create'" class="d-flex flex-column p-3">
      <span
        class="mat-caption flex-shrink-0 mb-2">{{'planaccion.acciones.form.indicadores.indicadorpred' | translate}}</span>
      <app-list [datos]="indicadores_accion_pred" [maxHeight]="200">
        <mat-list-item *ngFor="let item of indicadores_accion_pred" class="p-1 flex-grow-1 list-item"
                       (click)="setIndicadorPred(item)">
          <div class="d-flex justify-content-start align-items-center">
            <span class="dark">{{item.NOMBRE}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex flex-column justify-content-end align-items-right text-right">
            <span class="mat-caption grey">{{item.MAGNITUD}}</span>
          </div>
        </mat-list-item>
      </app-list>
    </div>
  </div>
  <div *ngIf="data.action !== 'view'" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="data.action=='edit'" color="warn" mat-button
            [mat-dialog-close]="{data:form.value,action:'delete'}">
      {{'planaccion.acciones.form.indicadores.delete' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="null">{{'planaccion.acciones.form.indicadores.cancel' | translate}}</button>
    <button color="accent" mat-button [mat-dialog-close]="{data:form.value,action:data.action}">{{(this.data.action ==
      'create' ?
      'planaccion.acciones.form.indicadores.create_text' : 'planaccion.acciones.form.indicadores.edit') | translate}}
    </button>
  </div>
  <div *ngIf="data.action === 'view'" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="null">{{'planaccion.acciones.form.indicadores.close' | translate}}</button>
  </div>
</form>

import {FormBuilder, Validators} from '@angular/forms';
import {Municipio, UsuarioMunicipio} from '../model/municipio';
import {DialogAction} from '../model/Utils';
import {Documento, TipoDocumento} from '../model/documento';

export class DocumentoForm {
  form = new FormBuilder().group({
    file: [null, Validators.required],
    ID: 0,
    IDTIPO: [1, Validators.required],
    NOMBRE: [null, Validators.required],
    IDMUNICIPIO: [null],
    FILENAME: [null],
    FECHA_SUBIDA: [null],
    USUARIO: [null],
  });

  constructor(action: DialogAction, dato?: Documento, file?) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato, file);
    }
  };


  setFormValues(dato: Documento, file?) {
    this.form.patchValue({
      file: file ? file : null,
      ID: dato.ID,
      IDTIPO: dato.IDTIPO,
      NOMBRE: dato.NOMBRE,
      IDMUNICIPIO: dato.IDMUNICIPIO,
      FILENAME: dato.FILENAME,
      FECHA_SUBIDA: dato.FECHA_SUBIDA,
      USUARIO: dato.USUARIO
    });
  }
}

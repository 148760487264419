/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adminusuariomunicipio.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../../components/list/list.component.ngfactory";
import * as i10 from "../../../components/list/list.component";
import * as i11 from "../../../services/authentication.service";
import * as i12 from "@angular/common";
import * as i13 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i14 from "@angular/material/progress-spinner";
import * as i15 from "@angular/cdk/platform";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "./adminusuariomunicipio.component";
import * as i18 from "../../../services/permisos.service";
import * as i19 from "../../../services/user.service";
import * as i20 from "../../../services/municipio.service";
import * as i21 from "@angular/material/dialog";
import * as i22 from "../../../services/alertbar.service";
var styles_AdminusuariomunicipioComponent = [i0.styles];
var RenderType_AdminusuariomunicipioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminusuariomunicipioComponent, data: {} });
export { RenderType_AdminusuariomunicipioComponent as RenderType_AdminusuariomunicipioComponent };
function View_AdminusuariomunicipioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-list-item", [["class", "p-1 flex-grow-1 list-item cursor-default mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 9, "div", [["class", "flex full-width align-center"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(6, 1785856, null, 0, i4.LayoutGapDirective, [i5.MediaMonitor, i1.ElementRef, [8, null], i1.NgZone, i6.Directionality, i5.StyleUtils], { gap: [0, "gap"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "flex flex-column justify-center align-center flex-grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "text-grey-darkest text-lg-b"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "flex justify-center items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "bg-grey-dark rounded shadow-sm w-16 h-10 flex justify-center items-center  cursor-pointer hover:bg-grey-darker"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "mat-icon", [["class", "text-white mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(13, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"]))], function (_ck, _v) { var currVal_2 = "20px"; _ck(_v, 6, 0, currVal_2); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.MUNICIPIO.NOMBRE; _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 13).inline; _ck(_v, 12, 0, currVal_4); }); }
function View_AdminusuariomunicipioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-list", [["class", "shadow-none"]], null, [[null, "addCallback"], [null, "searchCallback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addCallback" === en)) {
        var pd_0 = (_co.add($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchCallback" === en)) {
        var pd_1 = (_co.onSearch($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_ListComponent_0, i9.RenderType_ListComponent)), i1.ɵdid(1, 638976, null, 0, i10.ListComponent, [i11.AuthenticationService], { datos: [0, "datos"], title: [1, "title"], simple: [2, "simple"], add: [3, "add"], search: [4, "search"], fullHeight: [5, "fullHeight"], searchPlaceholder: [6, "searchPlaceholder"] }, { searchCallback: "searchCallback", addCallback: "addCallback" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AdminusuariomunicipioComponent_2)), i1.ɵdid(3, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datos; var currVal_1 = _co.list_title; var currVal_2 = false; var currVal_3 = true; var currVal_4 = true; var currVal_5 = false; var currVal_6 = _co.search_placeholder; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.getDatos(); _ck(_v, 3, 0, currVal_7); }, null); }
function View_AdminusuariomunicipioComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "full-height full-width d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i13.View_MatSpinner_0, i13.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i14.MatSpinner, [i1.ElementRef, i15.Platform, [2, i12.DOCUMENT], [2, i16.ANIMATION_MODULE_TYPE], i14.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 40; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AdminusuariomunicipioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminusuariomunicipioComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminusuariomunicipioComponent_3)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.datos_peticion.empty; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.datos_peticion.pending; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AdminusuariomunicipioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-adminusuariomunicipio", [], null, null, null, View_AdminusuariomunicipioComponent_0, RenderType_AdminusuariomunicipioComponent)), i1.ɵdid(1, 638976, null, 0, i17.AdminusuariomunicipioComponent, [i18.PermisosService, i19.UserService, i20.MunicipioService, i21.MatDialog, i22.AlertbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminusuariomunicipioComponentNgFactory = i1.ɵccf("app-adminusuariomunicipio", i17.AdminusuariomunicipioComponent, View_AdminusuariomunicipioComponent_Host_0, { user: "user" }, { onChange: "onChange" }, []);
export { AdminusuariomunicipioComponentNgFactory as AdminusuariomunicipioComponentNgFactory };

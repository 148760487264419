import {Component, Inject, OnInit} from '@angular/core';
import {MunicipioService} from '../../../../services/municipio.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogAction, DialogResponse} from '../../../../model/Utils';
import {Petition} from '../../../../model/petition';
import {DeleteAccionDialog} from '../../../../home/planaccion/planaccion-acciones/planaccion-acciones.component';
import {Municipio} from '../../../../model/municipio';
import {MunicipioForm, UsuarioMunicipioForm} from '../../../Forms';
import {ListatiposService} from '../../../../services/listatipos.service';

@Component({
  selector: 'app-adminusuariomunicipioform',
  templateUrl: './adminusuariomunicipioform.component.html',
  styleUrls: ['./adminusuariomunicipioform.component.scss']
})
export class AdminusuariomunicipioformComponent implements OnInit {
  dato: Municipio;
  datos_peticion: Petition = new Petition();
  municipios: Municipio[];
  dataForm: UsuarioMunicipioForm;

  constructor(private munSrv: MunicipioService, public dialogRef: MatDialogRef<DeleteAccionDialog>, private tipoSrv: ListatiposService,
              @Inject(MAT_DIALOG_DATA) public action: DialogAction) {
    this.fetchMunicipios();
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new UsuarioMunicipioForm(action,action.data);
    }
  }

  fetchMunicipios(){
    this.datos_peticion.load();
    this.munSrv.getAll().subscribe(value => {
        value.sort((a, b) => ('' + a.NOMBRE).localeCompare(b.NOMBRE));
        this.municipios = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  ngOnInit() {
  }

  closeOk() {
    switch (this.action.action) {
      case DialogAction.CREATE: {
        return new DialogResponse(DialogResponse.ADD, this.dataForm.form.getRawValue());
      }
      case DialogAction.EDIT: {
        return new DialogResponse(DialogResponse.EDIT, this.dataForm.form.value);
      }
      case DialogAction.VIEW: {
        return new DialogResponse(DialogResponse.NONE);
      }
    }
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

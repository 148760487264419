import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Petition} from '../../model/petition';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MunicipioService} from '../../services/municipio.service';
import {Analisis} from '../../model/Analisis';
import {AnalisisService} from '../../services/analisis.service';
import {CreateAnalisisDialog} from './createanalisis.component';
import {ImportDialogComponent} from '../../components/import-dialog/import-dialog.component';
import {ImportAction, ImportResponse} from '../../model/Utils';
import {Sorter} from '../../components/list/Util';

@Component({
  selector: 'app-analisis',
  templateUrl: './analisis.component.html',
  styleUrls: ['./analisis.component.scss']
})
export class AnalisisComponent implements OnInit {
  search_props = ['ANYO'];
  search_placeholder = 'analisis.home.list.searchplaceholder';
  sorters = [new Sorter('models.analisis.ANYO.label', 'ANYO')];
  data_fetch = new Petition();
  analisis: Analisis[] = [];

  constructor(private router: Router, private anaSrv: AnalisisService, private munSrv: MunicipioService, private snackBar: MatSnackBar,
              private translateService: TranslateService, private dialog: MatDialog) {
    this.fetch();
  }

  fetch() {
    this.data_fetch.load();
    this.anaSrv.getAll().subscribe(value => {
        if (value) {
          value.sort((a,b)=>b.ANYO - a.ANYO);
          this.analisis = value;
        }
        this.data_fetch.ok(value);
      },
      error2 =>
        this.data_fetch.ko(error2)
    );
  }

  setActive(p) {
    this.anaSrv.setActive(p);
    this.router.navigateByUrl('analisis/' + p.ID);
  }

  ngOnInit() {
  }

  openCreateDialog(): void {
    let dialogRef = this.dialog.open(CreateAnalisisDialog, {
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.create(result);
    });
  }

  create(analisis) {
    analisis.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    this.anaSrv.create(analisis).subscribe(value => {
      if (value) {
        this.translateService.get('analisis.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetch();
      }
    }, error2 => console.log(error2));
  }

  clone(analisis, anyo) {
    analisis.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    this.anaSrv.clone(analisis, anyo).subscribe(value => {
      if (value) {
        this.translateService.get('analisis.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetch();
      }
    }, error2 => console.log(error2));
  }

  openDeleteDialog(analisis): void {
    let dialogRef = this.dialog.open(DeleteAnalisisDialog, {
      data: {year: analisis.ANYO},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.delete(analisis);
    });
  }

  delete(analisis) {
    this.anaSrv.delete(analisis.ID).subscribe(value => {
      this.translateService.get('analisis.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.fetch();
    }, error2 => console.log(error2));
  }
}

@Component({
  selector: 'deleteanalisisdialog',
  templateUrl: 'deleteanalisis.dialog.html',
})
export class DeleteAnalisisDialog {
  constructor(public dialogRef: MatDialogRef<DeleteAnalisisDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}


import {Component, Inject, OnInit} from '@angular/core';
import {FuenteForm, SubambitoForm} from '../../Forms';
import {DialogAction} from '../../../model/Utils';
import {Fuente, Subambito} from '../../../model/subambito';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ListatiposService} from '../../../services/listatipos.service';

@Component({
  selector: 'app-adminfuenteform',
  templateUrl: './adminfuenteform.component.html',
  styleUrls: ['./adminfuenteform.component.scss']
})
export class AdminfuenteformComponent implements OnInit {
  dato: Fuente;
  dataForm: FuenteForm;

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, public dataSrv: ListatiposService) {
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new FuenteForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new FuenteForm(action);
    }
  }

  ngOnInit() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

<h2 mat-dialog-title>{{'generico.dialog.delete.title' | translate}}</h2>
<div mat-dialog-content>
  <span class="mat-caption">{{'generico.dialog.delete.text' | translate }}</span>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="primary" [mat-dialog-close]="close()">
    {{'generico.dialog.delete.cancel' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="closeDelete()">
    {{'generico.dialog.delete.ok' | translate }}
  </button>
</div>


import {DatosInventario, DatosInventarioDB} from './datosinventario';
import {Centro} from './centro';

export class DatosCentro {
  ID: number;
  IDDATOSINVENTARIO: number;
  IDCENTRO: number;
  VALOR: number;
  CENTRO: Centro;
  DATOS_INVENTARIO: DatosInventario;

  constructor(obj) {
    Object.assign(this, obj);
  }

  toDB() {
    return new DatosCentroDB(this);
  }

}
export class DatosCentroDB {
  ID: number;
  IDDATOSINVENTARIO: number;
  IDCENTRO: number;
  VALOR: number;

  constructor(dc: DatosCentro) {
    this.ID =  dc.ID;
    this.IDDATOSINVENTARIO =  dc.IDDATOSINVENTARIO;
    this.IDCENTRO =  dc.IDCENTRO;
    this.VALOR =  dc.VALOR;
  }
}

<h2 mat-dialog-title>{{'inventario.datosespecificos.title' | translate}}</h2>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="5px">
      <span class="text-sm-m color-grey-500">{{'inventario.datosinventario.col-factorconversion' | translate}}</span>
      <span class="text-sm-b color-grey-700">{{dato_inventario.FACTORCONVERSION | localizedNumber}}</span>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <span class="text-sm-m color-grey-500">{{'inventario.datosinventario.col-factoremision' | translate}}</span>
      <span class="text-sm-b color-grey-700">{{dato_inventario.FACTOREMISION | localizedNumber}}</span>
    </div>
  </div>
  <app-editable [cols]="cols" [data]="datos_centro" (deleteCallback)="openDeleteDialog($event)" (save)="update($event)"
                [hasAdd]="true" (addCallback)="openNuevoDatoCentroDialog()"></app-editable>
</div>

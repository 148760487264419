<div *ngIf="inventario_activo" fxLayout="column" style="height: 100vh;">
  <app-secciontopbar [title]="'inventario.seleccionado' | translate:{year: inventario_activo.ANYO}" back_url="/inventarios"
                     [delete_text]="'inventario.delete' | translate" (onImport)="openImportDialog($event)"
                     (onExport)="openExportDialog($event)" (onDelete)="openDeleteDialog($event)"
  >
  </app-secciontopbar>
  <nav mat-tab-nav-bar class="pl-md-5 pr-md-5 pl-sm-3 pr-sm-3 d-flex justify-content-center" style="background: white;">
    <a mat-tab-link [routerLink]="'/inventarios/'+this.id_inventario_activo+'/datosinventario'"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'inventario.datosinventario.title' | translate}}</a>
    <a mat-tab-link [routerLink]="'/inventarios/'+this.id_inventario_activo+'/datosgenerales'" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'inventario.datosgenerales.title' | translate}} </a>
  </nav>
  <div fxFlex fxLayout="column" class="pl-md-5 pr-md-5 pl-sm-2 pr-sm-2" style="height: 100%;">
    <router-outlet *ngIf="id_inventario_activo"></router-outlet>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import {Superambito} from '../../../model/superambito';
import {DialogResponse} from '../../../model/Utils';
import {AdminService} from '../../../services/admin.service';
import {Petition} from '../../../model/petition';
import {AdminsuperambitosformComponent} from '../adminsuperambitosform/adminsuperambitosform.component';
import {ListatiposService} from '../../../services/listatipos.service';
import {Subambito} from '../../../model/subambito';
import {AdminsubambitoformComponent} from '../adminsubambitoform/adminsubambitoform.component';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminsubambitolist',
  templateUrl: './adminsubambitolist.component.html',
  styleUrls: ['./adminsubambitolist.component.scss']
})
export class AdminsubambitolistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE','FUENTE.NOMBRE','TIPOSUBAMBITO.NOMBRE'];
  datos: Subambito[];
  form = AdminsubambitoformComponent;
  list_title = 'admin.inventario.sbambito.title';
  search_placeholder = 'admin.inventario.sbambito.searchplaceholder';

  constructor(private dataSrv: ListatiposService,private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.fetchSubambito().subscribe(value => {
        value.sort((a, b) => ('' + a.NOMBRE).localeCompare('' + b.NOMBRE));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createSubambito(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateSubambito(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteSubambito(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

<div class="container p-4">
  <app-advancedlist [datos]="datos" [canSearch]="true" [searchProps]="search_props"
                    [searchPlaceholder]="search_placeholder" [canAdd]="false" [canEdit]="false" [canDelete]="false"
                    [canRefresh]="true" (onRefresh)="load()" [canClick]="false"
                    [canSort]="true" [sorters]="sorters" [loading]="datos_peticion.pending">
    <ng-template let-item="item">
      <div class="d-flex full-width items-center" fxLayoutGap="10px">
        <div class="d-flex flex-column justify-content-center  align-items-center">
          <span class="text-sm color-grey-600 align-self-start"> {{'models.sesiones.USERID' | translate}} </span>
          <span class="text-lg-m color-grey-800">{{item.USERID}}</span>
        </div>
        <div class="d-flex flex-column justify-content-center  align-items-center">
          <span class="text-sm color-grey-600 align-self-start"> {{'models.sesiones.QVUSER' | translate}} </span>
          <span class="text-lg-m color-grey-800">{{item.QVUSER}}</span>
        </div>
        <div class="flex-grow-1"></div>
        <div class="d-flex flex-column justify-content-center  align-items-center">
          <span
            class="text-sm color-grey-600 align-self-start"> {{'models.sesiones.FECHA_CREACION' | translate}} </span>
          <span class="text-lg-m color-grey-800">{{item.FECHA_CREACION | localizedDate:'short'}}</span>
        </div>
        <div class="d-flex flex-column justify-content-center  align-items-center">
          <span
            class="text-sm color-grey-600 align-self-start"> {{'models.sesiones.FECHA_CADUCIDAD' | translate}} </span>
          <span class="text-lg-m color-grey-800">{{item.FECHA_CADUCIDAD | localizedDate:'short'}}</span>
        </div>
        <div
          class="w-12 h-12 rounded-full py-1 px-1 flex justify-center items-center cursor-pointer bg-red-lighter shadow-sm border-red-darker  text-red-darker  hover:text-white"
          (click)="openDialog(item)">
          <i class=" w-6 material-icons text-md">transfer_within_a_station</i>
        </div>
      </div>
    </ng-template>
  </app-advancedlist>
</div>

import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../services/alert.service';
import {Municipio} from '../../../model/municipio';
import {MatDialog, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FormsService} from '../../../services/forms.service';
import {DeleteInventarioDialog} from '../../inventario/inventario.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MunicipioService} from '../../../services/municipio.service';
import {Analisis} from '../../../model/Analisis';
import {AnalisisService} from '../../../services/analisis.service';
import {ImportDialogComponent} from '../../../components/import-dialog/import-dialog.component';
import {ImportAction, ImportResponse} from '../../../model/Utils';
import * as FileSaver from 'file-saver';
import {ExportDialogComponent} from '../../../components/export-dialog/export-dialog.component';

@Component({
  selector: 'app-analisisdetalle',
  templateUrl: './analisisdetalle.component.html',
  styleUrls: ['./analisisdetalle.component.scss']
})
export class AnalisisdetalleComponent implements OnInit {
  municipio_activo: Municipio;
  id_activo: number = null;
  activo: Analisis = null;
  paramsSub;

  ngOnInit() {
    this.paramsSub = this.route.params.subscribe(params => {
      this.id_activo = +params['id'];
      this.fetch(this.id_activo);
    });
    this.munsrv.sel_municipio.subscribe(value => {
      if (value) {
        this.municipio_activo = value;
      }
    }, error2 => console.log(error2.messageText));
  }

  openImportDialog() {
    let dialogRef = this.dialog.open(ImportDialogComponent, {
      data: new ImportAction(this.dataSrv.getAPIUrlImport()),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: ImportResponse) => {
      if (result)
        if (result.success) {
          this.router.navigateByUrl('/analisis/' + result.data.ID);
        }
    });
  }

  openExportDialog() {
    let dialogRef = this.dialog.open(ExportDialogComponent, {
      data: new ImportAction(this.dataSrv.getAPIUrlExport()),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: ImportResponse) => {
    });
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.municipio_activo = null;
    this.id_activo = null;
    this.activo = null;
  }

  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute, private munsrv: MunicipioService, private translateService: TranslateService,
              private alertsrv: AlertService, private dataSrv: AnalisisService, public snackBar: MatSnackBar, public formService: FormsService) {

  }

  fetch(id) {
    this.dataSrv.getById(id).subscribe(value => {
        if (value) {
          this.activo = value;
          this.dataSrv.setActive(this.activo);
        }
      },
      error2 => this.alertsrv.error(error2));
  }

  openDeleteDialog(): void {
    let dialogRef = this.dialog.open(DeleteInventarioDialog, {
      data: {year: this.dataSrv.selected.getValue().ANYO},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.delete();
    });
  }

  delete() {
    this.dataSrv.delete(this.dataSrv.selected.getValue().ID).subscribe(value => {
      this.translateService.get('inventario.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.router.navigateByUrl('/analisis');
    }, error2 => console.log(error2));
  }
}

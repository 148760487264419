var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog, MatPaginator, MatPaginatorIntl, MatSort } from "@angular/material";
import { DataSource, SelectionModel } from "@angular/cdk/collections";
import { BehaviorSubject, fromEvent, merge } from "rxjs/index";
import { debounceTime, map } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { TranslateService } from "@ngx-translate/core";
import { nestedObj } from "../../model/Utils";
import { AuthenticationService } from "../../services/authentication.service";
var EditableComponent = /** @class */ (function () {
    function EditableComponent(dialog, pagIntl, translateSrv, authSrv) {
        this.dialog = dialog;
        this.pagIntl = pagIntl;
        this.translateSrv = translateSrv;
        this.authSrv = authSrv;
        this.cols = [];
        this.datatype = "Dato";
        this.datatypePlural = "Datos";
        this.loading = false;
        this.selectable = true;
        this.hasExport = false;
        this.exporting = false;
        this.hasImport = false;
        this.importing = false;
        this.hasDelete = false;
        this.saveone = false;
        this.hasAdd = false;
        this.importCallback = new EventEmitter();
        this.exportCallback = new EventEmitter();
        this.addCallback = new EventEmitter();
        this.deleteCallback = new EventEmitter();
        this.save = new EventEmitter();
        this.columnsToDisplay = [];
        this.selection = new SelectionModel(true, []);
        this.active_row_edit = null;
        this.original_row_edit = null;
    }
    EditableComponent.prototype.ngOnInit = function () { };
    EditableComponent.prototype.log = function (val) { };
    EditableComponent.prototype.hasFilter = function () {
        return this.cols.filter(function (c) { return c.data.filter; }).length > 0;
    };
    EditableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.filter) {
            var filterEvent = fromEvent(this.filter.nativeElement, "keyup");
            filterEvent.pipe(debounceTime(150));
            filterEvent.pipe(distinctUntilChanged());
            filterEvent.subscribe(function (i) {
                if (!_this.dataSource) {
                    return;
                }
                _this.dataSource.filter = _this.filter.nativeElement.value;
            });
        }
    };
    EditableComponent.prototype.isEditable = function () {
        return (this.cols.filter(function (c) { return c.editable == true; }).length > 0 &&
            this.authSrv.isEditable());
    };
    EditableComponent.prototype.isAllSelected = function () {
        if (!this.dataSource.data) {
            return false;
        }
        if (this.selection.isEmpty()) {
            return false;
        }
        return this.selection.selected.length === this.data.length;
    };
    EditableComponent.prototype.masterToggle = function () {
        var _this = this;
        if (!this.dataSource.data) {
            return;
        }
        if (this.isAllSelected()) {
            this.selection.clear();
        }
        else if (this.filter.nativeElement.value) {
            this.dataSource.renderedData.forEach(function (data) {
                return _this.selection.toggle(_this.getDataIndex(data));
            });
        }
        else {
            this.dataSource.data.forEach(function (data) {
                return _this.selection.toggle(_this.getDataIndex(data));
            });
        }
    };
    EditableComponent.prototype.getSelectedCount = function () {
        return this.selection.selected.length;
    };
    EditableComponent.prototype.getDataIndex = function (data) {
        return this.dataSource.data.indexOf(data);
    };
    EditableComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    EditableComponent.prototype.removeRows = function () {
        this.deleteCallback.emit(this.selection.selected);
    };
    EditableComponent.prototype.editRow = function (row, index) {
        if (this.authSrv.isEditable()) {
            this.active_row_edit = row;
            this.original_row_edit = Object.assign({}, row);
        }
        else {
            this.active_row_edit = null;
            this.original_row_edit = Object.assign({}, null);
        }
    };
    EditableComponent.prototype.cancelEditRow = function (row, index) {
        var d = this.data.slice();
        d[index] = this.original_row_edit;
        this.data = d;
        this.active_row_edit = null;
        this.original_row_edit = null;
    };
    EditableComponent.prototype.finishEditRow = function (row, index) {
        var d = this.data.slice();
        d[index] = row;
        this.data = d;
        this.active_row_edit = null;
        this.original_row_edit = null;
        this.save.emit(this.saveone ? row : this.data);
    };
    EditableComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.data) {
            this.selection.clear();
            this.dataSource = new TableDataSource(this.data, this.paginator, this.sort, this.cols
                .filter(function (c) { return c.data.filter; })
                .map(function (c) {
                return c.data.filter;
            })
                .reduce(function (previous, current) {
                if (previous)
                    return previous.concat(current);
                else
                    return [].concat(current);
            }, 0), this.cols);
            this.translateSrv
                .get("editable.pagination.itemsPerPageLabel")
                .subscribe(function (d) { return (_this.pagIntl.itemsPerPageLabel = d); });
            this.translateSrv
                .get("editable.pagination.firstPageLabel")
                .subscribe(function (d) { return (_this.pagIntl.firstPageLabel = d); });
            this.translateSrv
                .get("editable.pagination.lastPageLabel")
                .subscribe(function (d) { return (_this.pagIntl.lastPageLabel = d); });
            this.translateSrv
                .get("editable.pagination.nextPageLabel")
                .subscribe(function (d) { return (_this.pagIntl.nextPageLabel = d); });
            this.translateSrv
                .get("editable.pagination.previousPageLabel")
                .subscribe(function (d) { return (_this.pagIntl.previousPageLabel = d); });
            this.translateSrv.get("editable.pagination.of").subscribe(function (d) {
                return (_this.pagIntl.getRangeLabel = function (page, pageSize, length) {
                    if (length == 0 || pageSize == 0) {
                        return "0 " + d + " " + length;
                    }
                    length = Math.max(length, 0);
                    var startIndex = page * pageSize;
                    var endIndex = startIndex < length
                        ? Math.min(startIndex + pageSize, length)
                        : startIndex + pageSize;
                    return startIndex + 1 + " - " + endIndex + " " + d + " " + length;
                });
            });
        }
        if (changes.cols) {
            var columns = [];
            if (this.selectable && this.authSrv.isEditable()) {
                columns.push("select");
            }
            columns = columns.concat(this.cols.map(function (c) { return c.id; }));
            if (this.authSrv.isEditable()) {
                columns.push("acciones");
            }
            this.columnsToDisplay = columns;
        }
    };
    EditableComponent.prototype.nestedObj = function (o, s) {
        return nestedObj(o, s);
    };
    return EditableComponent;
}());
export { EditableComponent };
var TableDataSource = /** @class */ (function (_super) {
    __extends(TableDataSource, _super);
    function TableDataSource(_data, _paginator, _sort, _filterCols, _cols) {
        var _this = _super.call(this) || this;
        _this._data = _data;
        _this._paginator = _paginator;
        _this._sort = _sort;
        _this._filterCols = _filterCols;
        _this._cols = _cols;
        _this.dataChange = new BehaviorSubject([]);
        _this._filterChange = new BehaviorSubject("");
        _this.filterCols = [];
        _this.filteredData = [];
        _this.renderedData = [];
        _this.dataChange.next(_data);
        _this._filterChange.subscribe(function () { return (_this._paginator.pageIndex = 0); });
        _this.filterCols = _filterCols;
        return _this;
    }
    Object.defineProperty(TableDataSource.prototype, "data", {
        get: function () {
            return this.dataChange.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableDataSource.prototype, "filter", {
        get: function () {
            return this._filterChange.value;
        },
        set: function (filter) {
            this._filterChange.next(filter);
        },
        enumerable: true,
        configurable: true
    });
    TableDataSource.prototype.getColValueByID = function (name, obj) {
        var col = this._cols.find(function (c) { return c.id === name; });
        switch (col.type) {
            case "function":
                return col.data.func(obj);
        }
        return nestedObj(obj, col.data.prop);
    };
    TableDataSource.prototype.getColByID = function (name) {
        var col = this._cols.find(function (c) { return c.id === name; });
        return col.data.prop;
    };
    /** Connect function called by the table to retrieve one stream containing the data to render. */
    TableDataSource.prototype.connect = function () {
        var _this = this;
        // Listen for any changes in the base data, sorting, filtering, or pagination
        var displayDataChanges = [
            this.dataChange,
            this._sort.sortChange,
            this._filterChange,
            this._paginator.page
        ];
        return merge.apply(void 0, displayDataChanges).pipe(map(function () {
            // Filter data
            _this.filteredData = _this.data.slice().filter(function (item) {
                if (!_this.filterCols)
                    return true;
                var searchStr = _this.filterCols
                    .map(function (f) { return nestedObj(item, f); })
                    .join("")
                    .toLowerCase();
                return searchStr.indexOf(_this.filter.toLowerCase()) !== -1;
            });
            // Sort filtered data
            var sortedData = _this.sortData(_this.filteredData.slice());
            // Grab the page's slice of the filtered sorted data.
            var startIndex = _this._paginator.pageIndex * _this._paginator.pageSize;
            _this.renderedData = sortedData.splice(startIndex, _this._paginator.pageSize);
            return _this.renderedData;
        }));
    };
    TableDataSource.prototype.disconnect = function () { };
    /** Returns a sorted copy of the database data. */
    TableDataSource.prototype.sortData = function (data) {
        var _this = this;
        if (!this._sort.active || this._sort.direction === "") {
            return data;
        }
        return data.sort(function (a, b) {
            var propertyA = "";
            var propertyB = "";
            _a = [
                _this.getColValueByID(_this._sort.active, a),
                _this.getColValueByID(_this._sort.active, b)
            ], propertyA = _a[0], propertyB = _a[1];
            var valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            var valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            return ((valueA < valueB ? -1 : 1) * (_this._sort.direction === "asc" ? 1 : -1));
            var _a;
        });
    };
    return TableDataSource;
}(DataSource));
export { TableDataSource };

<h2 mat-dialog-title>{{'home.seleccionmunicipio' | translate}}</h2>
<div mat-dialog-content>
  <!--<mat-form-field class="full-width">
    <mat-label for="municipio">{{'home.municipio' | translate}}</mat-label>
    <mat-select class="full-width" id="municipio" [(value)]="data.id_municipio" name="sel_municipio">
      <mat-option value="-1" *ngIf="!data.id_municipio">{{'home.select' | translate}}</mat-option>
      <mat-option *ngFor="let m of data.municipios" [value]="m.ID">{{m.NOMBRE}}</mat-option>
    </mat-select>
  </mat-form-field>-->

  <mat-form-field *ngIf="!empty" class="full-width" [floatLabel]="'always'">
    <mat-label class="mat-input">{{'home.municipio' | translate }}
    </mat-label>
    <input matInput [matAutocomplete]="auto" [formControl]="munCtrl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
      <mat-option *ngFor="let t of filtered | async" [value]="t">
        {{t.NOMBRE}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <p *ngIf="empty" class="text-md text-grey-darkest">{{'home.no-municipios' | translate}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button color="primary" [mat-dialog-close]="munCtrl.value ? munCtrl.value.ID : null" [disabled]="!munCtrl.valid">OK</button>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {Ambito} from '../../../model/ambito';
import {Superambito} from '../../../model/superambito';
import {DatosInventario} from '../../../model/datosinventario';
import {AmbitoService} from '../../../services/ambito.service';
import {InventarioService} from '../../../services/inventario.service';
import {MunicipioService} from '../../../services/municipio.service';
import {DatosInventarioService} from '../../../services/datosinventario.service';
import {InventarioEmisiones} from '../../../model/inventarioemisiones';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {DeleteInventarioDialog} from '../inventario.component';
import {TranslateService} from '@ngx-translate/core';
import * as uniqBy from 'lodash/uniqBy';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {InvDatosEspecificosComponent, NuevoDatoCentroDialog} from '../inv-datos-especificos/inv-datos-especificos.component';
import {forEach} from '@angular/router/src/utils/collection';
import {Petition} from '../../../model/petition';

@Component({
  selector: 'app-inv-datos',
  templateUrl: './inv-datos.component.html',
  styleUrls: ['./inv-datos.component.scss']
})
export class InvDatosComponent implements OnInit {
  inventarioActivo: InventarioEmisiones = new InventarioEmisiones();
  ambitos: Ambito[] = [];
  ambitosSeleccionables: Ambito[] = [];
  datosInventarios: DatosInventario[] = [];
  superambitos: Superambito[] = [];
  superambitoActivo: Superambito = new Superambito();
  ambitosActivos: Ambito[] = [];
  factores: object = {};

  cols = [
    {
      id: 'icon',
      title: '',
      type: 'icon',
      width: '60px',
      data: {
        icon: 'account_balance',
        badge: (obj: DatosInventario) => obj.DATOS_CENTRO.length,
        show: (obj: DatosInventario) => obj.AMBITO.SUPERAMBITO.DEPENDE_AYTO,
        callback: (obj: DatosInventario) => this.openDatosCentroDialog(obj),
      },
      editable: false,
      tooltip: 'inventario.datosinventario.tooltip-depende_ayto'
    },
    {
      id: 'ambito',
      title: 'inventario.datosinventario.col-ambito',
      type: 'text',
      data: {
        prop: 'AMBITO.SUPERAMBITO.NOMBRE',
        filter: ['AMBITO.SUPERAMBITO.NOMBRE']
      },
      editable: false,
      sort: 'desc',
      tooltip: 'inventario.datosinventario.tooltip-ambito'
    },
    {
      id: 'fuente',
      title: 'inventario.datosinventario.col-fuente',
      type: 'text',
      data: {
        prop: 'AMBITO.SUBAMBITO.FUENTE.NOMBRE',
        filter: ['AMBITO.SUBAMBITO.FUENTE.NOMBRE']
      },
      editable: false,
      sort: 'desc',
      tooltip: 'inventario.datosinventario.tooltip-fuente'
    },
    {
      id: 'valor',
      title: 'inventario.datosinventario.col-valor',
      data: {
        prop: 'VALOR'
      },
      type: 'number',
      editable: true,
      scope: 'row',
      tooltip: 'inventario.datosinventario.col-valor'
    },
    {
      id: 'factorconversion',
      title: 'inventario.datosinventario.col-factorconversion',
      data: {
        prop: 'FACTORCONVERSION'
      },
      type: 'number',
      editable: true,
      tooltip: 'inventario.datosinventario.tooltip-factorconversion'
    },
    {
      id: 'valormwh',
      title: 'inventario.datosinventario.col-mwh',
      type: 'function',
      data: {
        func: (obj: DatosInventario) => Math.round((obj.VALOR * (obj.FACTORCONVERSION ? obj.FACTORCONVERSION : 1)) * 1000) / 1000
      },
      editable: false
    },
    {
      id: 'factoremision',
      title: 'inventario.datosinventario.col-factoremision',
      type: 'number',
      data: {
        prop: 'FACTOREMISION'
      },
      editable: true,
      tooltip: 'inventario.datosinventario.tooltip-factoremision'
    },
    {
      id: 'emisiones',
      title: 'inventario.datosinventario.col-emisiones',
      type: 'function',
      data: {
        func: (obj: DatosInventario) => Math.round((obj.VALOR * (obj.FACTORCONVERSION ? obj.FACTORCONVERSION : 1) * obj.FACTOREMISION) * 1000) / 1000
      },
      editable: false,
      tooltip: 'inventario.datosinventario.tooltip-emisiones'
    }
  ];
  data_update = new Petition();
  data_fetch = new Petition();

  constructor(private dialog: MatDialog, private invsrv: InventarioService, private munsvc: MunicipioService, private ambsrv: AmbitoService
    , private disrv: DatosInventarioService, private translateService: TranslateService, public snackBar: MatSnackBar) {
    this.invsrv.sel_inventario.subscribe(value => {
      if (value) {
        this.inventarioActivo = value;
        this.loadDatosInventario();
      }
    }, error2 => console.log(error2));
  }

  ngOnInit() {
    this.initialize();
  }

  obtenerSuperambitos(ambitos) {
    return ambitos.map(a => a.SUPERAMBITO);
  }

  onChangeSuperambito(superambito) {
    this.ambitosSeleccionables = this.ambitos.filter(a => (a.IDSUPERAMBITO === superambito.ID &&
      this.datosInventarios.filter(d => d.IDAMBITO === a.ID).length === 0)).map(a => {
      return {...a, SUPERAMBITO: superambito};
    });
    this.superambitoActivo = superambito;
  }

  update_item(item) {
    this.disrv.getById(item.ID).subscribe(value => {
      const index = this.datosInventarios.findIndex(a => a.ID == value.ID);
      this.datosInventarios[index] = value;
    });
  }

  update_dato_inventario(data) {
    const dato_inventario = new DatosInventario(data).toDB();
    if (this.inventarioActivo.ID) {
      this.data_update.load();
      this.disrv.update(dato_inventario).subscribe(value => {
          this.data_update.ok(value);
          this.translateService.get('inventario.datosinventario.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.update_item(value);
        }
        , error2 => {
          this.data_update.ko(error2);
          this.translateService.get('inventario.datosinventario.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        });
    }
  }

  update_datos_inventario(data) {
    const datos_inventario = data.map(d => new DatosInventario(d).toDB());
    if (this.inventarioActivo.ID) {
      this.data_update.load();
      this.disrv.updateAll(datos_inventario).subscribe(value => {
          this.data_update.ok(value);
          this.translateService.get('inventario.datosinventario.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.loadDatosInventario();
        }
        , error2 => {
          this.data_update.ko(error2);
          this.translateService.get('inventario.datosinventario.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.loadDatosInventario();
        });
    }
  }

  loadDatosInventario() {
    this.data_fetch.load();
    this.disrv.getAll().subscribe(value => {
      if (value) {
        this.datosInventarios = value;

        const factores = value.map((d: DatosInventario) => {
          return {ambito: d.AMBITO.SUBAMBITO, fconversion: d.FACTORCONVERSION, femision: d.FACTOREMISION};
        });
        this.factores = factores.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.ambito.ID).indexOf(obj.ambito.ID) === pos;
        });

        this.data_fetch.ok(value);
      }
    }, error2 => {
      this.data_fetch.ko(error2);
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  addDatoInventario(data) {
    const datos_inventario = this.datosInventarios.slice();
    data.forEach(a => {
      const tdatosinventario = new DatosInventario({});
      tdatosinventario.IDAMBITO = a.ID;
      tdatosinventario.IDINVENTARIO = this.inventarioActivo.ID;
      tdatosinventario.VALOR = null;
      tdatosinventario.FACTORCONVERSION = null;
      tdatosinventario.FACTOREMISION = null;
      tdatosinventario.AMBITO = a;
      tdatosinventario.FACTOREMISION = a.SUBAMBITO.DEFAULTFEMISION;
      tdatosinventario.FACTORCONVERSION = a.SUBAMBITO.DEFAULTFCONVERSION;
      datos_inventario.push(tdatosinventario);
    });
    this.datosInventarios = datos_inventario;
    this.update_datos_inventario(this.datosInventarios);
  }

  deleteDatosInventario(indexes) {
    const data = this.datosInventarios.slice();
    const sortedIndexes = indexes.sort((a, b) => b - a);
    sortedIndexes.forEach(index => data.splice(index, 1));
    this.datosInventarios = data;
    this.update_datos_inventario(this.datosInventarios);
  }

  initialize() {
    this.ambitos = [];
    this.superambitos = [];
    this.superambitoActivo = new Superambito();
    this.ambitosActivos = [];
    this.datosInventarios = [];

    this.loadDatosInventario();

    this.ambsrv.getAll().subscribe(
      value => {
        if (value) {
          this.ambitos = value;
          this.superambitos = this.obtenerSuperambitos(value);
        }
      }, error2 =>
        console.log(error2)
    );
  }


  openNuevoDatoInventarioDialog(): void {
    const ambitos_en_tabla = this.datosInventarios.map(di => di.IDAMBITO);
    const ambitos_no_en_tabla = this.ambitos.filter(a => !ambitos_en_tabla.includes(a.ID));
    const superambitos_no_en_tabla = ambitos_no_en_tabla.map(a => a.IDSUPERAMBITO);
    const superambitos = uniqBy(this.superambitos.filter(sa => superambitos_no_en_tabla.includes(sa.ID)), 'ID');
    if (superambitos_no_en_tabla.length > 0 && ambitos_no_en_tabla.length > 0) {
      const dialogRef = this.dialog.open(NuevoDatoInventarioDialog, {
        data: {superambitos: superambitos, ambitos: ambitos_no_en_tabla, datosInventarios: this.datosInventarios},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.addDatoInventario(data);
        }
      });
    } else {
      this.translateService.get('inventario.datosinventario.cantadd').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
    }
  }

  openDatosCentroDialog(datoinventario): void {
    const dialogRef = this.dialog.open(InvDatosEspecificosComponent, {
      data: {dato_inventario: datoinventario},
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(ambitos => {
      if (ambitos)
        this.loadDatosInventario();
    });
  }


  openFactoresDialog(): void {
    const dialogRef = this.dialog.open(FactoresDialog, {
      data: {factores: this.factores},
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(factores => {
      if (factores) {
        this.datosInventarios.forEach(di => {
          let factor = factores.find(f => f.ambito.ID == di.AMBITO.SUBAMBITO.ID);
          di.FACTOREMISION = factor.femision;
          di.FACTORCONVERSION = factor.fconversion;
        });
        this.update_datos_inventario(this.datosInventarios);
      }
    });
  }

  openDeleteDialog(data): void {
    if (data) {
      const indexes = data;
      const dialogRef = this.dialog.open(DeleteDatoInventarioDialog, {
        data: {number: indexes.length},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(bool => {
        if (bool) {
          this.deleteDatosInventario(indexes);
        }
      });
    }
  }
}

// noinspection TsLint
@Component({
  selector: 'app-nuevodatoinventariodialog',
  templateUrl: 'nuevodatoinventario.dialog.html',
})
export class NuevoDatoInventarioDialog {

  constructor(public dialogRef: MatDialogRef<NuevoDatoInventarioDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    data.depende_ayto = true;
    data.ambitosActivos = [];
    data.ambitosSeleccionables = [];
    data.superambitosSeleccionables = [];
    this.onChangeTipo();
  }

  onChangeSuperambito() {
    this.data.ambitosSeleccionables = this.data.ambitos
      .filter(a => (a.IDSUPERAMBITO === this.data.superambitoActivo.ID));
  }

  onChangeTipo() {
    this.data.superambitosSeleccionables = this.data.superambitos.filter(a => (a.DEPENDE_AYTO == this.data.depende_ayto));
    if (this.data.superambitosSeleccionables) {
      this.data.superambitoActivo = this.data.superambitosSeleccionables[0];
    }
    this.onChangeSuperambito();
  }
}

// noinspection TsLint
@Component({
  selector: 'app-factoresdialog',
  templateUrl: 'factores.dialog.html',
})
export class FactoresDialog {
  cols = [
    {
      id: 'ambito',
      title: 'inventario.datosinventario.col-ambito',
      type: 'text',
      data: {
        prop: 'ambito.FUENTE.NOMBRE',
        // subprop: 'ambito.TIPOSUBAMBITO.NOMBRE'
      },
      width: '200px',
      editable: false,
      sort: 'desc',
      tooltip: 'inventario.datosinventario.tooltip-ambito'
    },
    {
      id: 'factorconversion',
      title: 'inventario.datosinventario.col-factorconversion',
      data: {
        prop: 'fconversion'
      },
      type: 'number',
      editable: true,
      tooltip: 'inventario.datosinventario.tooltip-factorconversion'
    },
    {
      id: 'factoremision',
      title: 'inventario.datosinventario.col-factoremision',
      type: 'number',
      data: {
        prop: 'femision'
      },
      editable: true,
      tooltip: 'inventario.datosinventario.tooltip-factoremision'
    }
  ];

  constructor(public dialogRef: MatDialogRef<FactoresDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

}

// noinspection TsLint
@Component({
  selector: 'deletedatoinventariodialog',
  templateUrl: 'deletedatoinventario.dialog.html',
})
export class DeleteDatoInventarioDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDatoInventarioDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

import {Injectable} from '@angular/core';
import {MunicipioService} from './municipio.service';
import {appConfig} from '../app.config';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {Centro} from '../model/centro';
import {DatosInventario} from '../model/datosinventario';
import {ResponseContentType} from '@angular/http';

@Injectable()
export class CentroService {

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService) {
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/Centros/';
  }

  getAll() {
    return this.http.get<Centro[]>(this.getAPIUrl());
  }

  getById(_id: string) {
    return this.http.get(this.getAPIUrl() + _id);
  }

  create(obj: Centro) {
    return this.http.post<Centro>(this.getAPIUrl(), obj);
  }

  update(obj: Centro) {
    return this.http.put<Centro>(this.getAPIUrl() + obj.ID, obj);
  }

  updateAll(centros) {
    return this.http.put<Centro[]>(this.getAPIUrl(), centros);
  }
  createAll(centros) {
    return this.http.post<Centro[]>(this.getAPIUrl(), centros);
  }
  delete(_id: string) {
    return this.http.delete(this.getAPIUrl() + _id);
  }

  export() {
    return this.http.get(this.getAPIUrl() + 'export', {'responseType': 'blob'});
  }
}

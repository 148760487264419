<div class="tcontainer border rounded" style="background: white;">
  <div id="filter-div" class="d-flex align-items-center ">
    <div class="pl-4 pr-4 mt-2">
      <div *ngIf="hasFilter()">
        <mat-form-field floatPlaceholder="never" class="full-width">
          <input matInput #filter [placeholder]="'editable.filterplaceholder' | translate:{datatype:datatypePlural}">
        </mat-form-field>
      </div>
    </div>
    <div class="toolbar-spacer"></div>
    <div fxLayoutGap="15px">
      <button *ngIf="hasExport && this.authSrv.isEditable()" mat-button (click)="exportCallback.next()">
        <mat-icon *ngIf="exporting">
          <mat-spinner [diameter]="20"></mat-spinner>
        </mat-icon>
        {{'editable.export' |translate}}
      </button>
      <button *ngIf="hasImport && this.authSrv.isEditable()" mat-button (click)="importCallback.next()">
        <mat-icon *ngIf="importing">
          <mat-spinner [diameter]="20"></mat-spinner>
        </mat-icon>
        {{'editable.import' |translate}}
      </button>
    </div>
  </div>
  <div class="table-container" style="position: relative;">
    <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" style="background: white;">
      <ng-container *ngFor="let col of cols" [matColumnDef]="col.id">
        <mat-header-cell *matHeaderCellDef [mat-sort-header]="col.id" [start]="col.sort"
                         [fxFlex]="col.width ? col.width : ''">
          <span *ngIf="col.title">{{col.title | translate}}</span>
          <mat-icon #tooltip="matTooltip" class="col-info" *ngIf="col.tooltip" [matTooltip]="col.tooltip | translate"
                    matTooltipPosition="above">info
          </mat-icon>
        </mat-header-cell>
        <mat-cell [ngSwitch]="col.type" *matCellDef="let element" (click)="col.data.callback ? null : rowClick(element)"
                  [fxFlex]="col.width ? col.width  : ''"
                  [style.width]="col.type == 'icon' ? 'flex: 0 0 100px !important;white-space: unset !important;' : ''">
          <div *ngSwitchCase="'icon'" [class.showonhover]="col.showonhover">
            <button mat-icon-button (click)="col.data.callback ? col.data.callback(element) : null">
              <mat-icon *ngIf="col.data.badge && (col.data.show ? col.data.show(element) : true)"
                        [matBadge]="col.data.badge(element)" matBadgeColor="accent" [color]="col.data.color"
                        matBadgeSize="small" matBadgePosition="below">
                {{col.data.icon}}
              </mat-icon>
              <mat-icon *ngIf="!col.data.badge && (col.data.show ? col.data.show(element) : true)"
                        [color]="col.data.color">
                {{col.data.icon}}
              </mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="'text'">
          <span *ngIf="!col.data.subprop">
          {{nestedObj(element,col.data.prop)}}
        </span>
            <div *ngIf="col.data.subprop">
          <span>
            {{nestedObj(element,col.data.prop)}}
          </span>
              <br>
              <span *ngIf="col.data.subprop " class="subprop">
            {{nestedObj(element,col.data.subprop)}}
          </span>
            </div>
          </div>
          <div *ngSwitchCase="'number'">
          <span>
          {{nestedObj(element,col.data.prop) | localizedNumber}}
          </span>
          </div>

          <div *ngSwitchCase="'date'">
          <span>
          {{nestedObj(element,col.data.prop) | localizedDate}}
          </span>
          </div>
          <div *ngSwitchCase="'button'">
            <button mat-raised-button [color]="col.data.color" (click)="col.data.callback(element)">
              {{col.data.text | translate}}
            </button>
          </div>
          <div *ngSwitchCase="'function'">
        <span>
          {{col.data.func(element)}}
        </span>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;"
               [style.cursor]="onClickRow ? 'pointer' : 'none'"
               (dblclick)="!isEditable() ? null : (row != active_row_edit ? editRow(row) : null)"
               [style.background]="active_row_edit == row ? 'rgba(0, 150, 136, 0.12)' : ''"></mat-row>
    </mat-table>
    <div *ngIf="loading" class="full-height d-flex flex-column justify-content-center align-items-center">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
    <div *ngIf="!loading && dataSource.data.length == 0" class="d-flex flex-column justify-content-center align-items-center p-4">
      <mat-icon class="large-icon color-grey-400">
        inbox
      </mat-icon>
      <span class="mat-caption p-2">{{'editable.empty-table' | translate}}</span>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MunicipioService} from './municipio.service';
import {appConfig} from '../app.config';
import {AlertService} from './alert.service';
import {InventarioService} from './inventario.service';
import {DatosInventario, DatosInventarioDB} from '../model/datosinventario';

@Injectable()
export class DatosInventarioService {

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService, private invsrv: InventarioService) {
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/InventarioEmisiones/' + this.invsrv.sel_inventario.getValue().ID + '/DatosInventario/';
  }
  getAPIUrlByInventario(idinventario) {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/InventarioEmisiones/' + idinventario + '/DatosInventario/';
  }

  getAll() {
    return this.http.get<DatosInventario[]>(this.getAPIUrl());
  }

  getAllByInventario(id) {
    return this.http.get<DatosInventario[]>(this.getAPIUrlByInventario(id));
  }

  getById(_id: number) {
    return this.http.get<DatosInventario>(this.getAPIUrl() + _id);
  }

  create(datos: DatosInventario) {
    return this.http.post<DatosInventario>(this.getAPIUrl(), datos);
  }

  update(datos: DatosInventarioDB) {
    return this.http.put<DatosInventario>(this.getAPIUrl() + datos.ID, datos);
  }

  updateAll(datos) {
    return this.http.put<DatosInventario[]>(this.getAPIUrl(), datos);
  }

  delete(_id: number) {
    return this.http.delete(this.getAPIUrl() + _id);
  }

}

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {appConfig} from '../app.config';

import {JSON_to_URLEncoded} from '../helpers/formurlencoded';
import {User} from '../model/user';
import {BehaviorSubject} from 'rxjs/index';

@Injectable()
export class AuthenticationService {
  currentUser: User = JSON.parse(localStorage.getItem('currentUser'));

  constructor(private http: HttpClient) {
  }

  headers() {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/x-www-form-urlencoded');
    return {headers: header};
  }

  getCurrentUser() {
    return this.currentUser;
  }

  isEditable() {
    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    return user.canedit == 'true';
  }

  isAdmin(){
    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    return user.role == 'ADMIN';
  }
  public isAuthenticated(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (!user)
      return false;
    const expired = new Date(user['.expires']) > new Date();
    // Check whether the token is expired and return
    // true or false
    return expired;
  }

  login(username: string, password: string) {
    return this.http.post<any>(appConfig.apiUrl + 'token', JSON_to_URLEncoded({
      username: username,
      password: password,
      grant_type: 'password'
    }), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUser = user;
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUser = null;
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {RiesgoPredForm} from '../../Forms';
import {DialogAction} from '../../../model/Utils';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ListatiposService} from '../../../services/listatipos.service';
import {RiesgoPred} from '../../../model/Analisis';

@Component({
  selector: 'app-adminriesgosform',
  templateUrl: './adminriesgosform.component.html',
  styleUrls: ['./adminriesgosform.component.scss']
})
export class AdminriesgosformComponent implements OnInit {
  dato: RiesgoPred;
  dataForm: RiesgoPredForm;

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, public dataSrv: ListatiposService) {
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new RiesgoPredForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new RiesgoPredForm(action);
    }
  }

  ngOnInit() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

import {Injectable} from '@angular/core';
import {AlertService} from './alert.service';
import {PlanAccion, PlanAccionDB} from '../model/planaccion';
import {appConfig} from '../app.config';
import {MunicipioService} from './municipio.service';
import {HttpClient} from '@angular/common/http';
import {Documento, DocumentoDB} from '../model/documento';
import {ImportacionPlantilla} from '../model/importacionplantilla';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService) {
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/Documento/';
  }

  getAPIUrlDownload(id) {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/Documento/' + id + '/download';
  }

  getAll() {
    return this.http.get<Documento[]>(this.getAPIUrl());
  }

  getById(_id: number) {
    return this.http.get<Documento>(this.getAPIUrl() + _id);
  }

  create(d: DocumentoDB, fileItem) {
    const formData: FormData = new FormData();
    formData.append('files', fileItem);
    formData.append('doc', JSON.stringify(d));
    return this.http.post<Documento>(this.getAPIUrl(), formData);
  }

  update(d: DocumentoDB, fileItem) {
    const formData: FormData = new FormData();
    formData.append('files', fileItem);
    formData.append('doc', JSON.stringify(d));
    return this.http.put<Documento>(this.getAPIUrl() + d.ID, formData);
  }

  delete(_id: number) {
    return this.http.delete(this.getAPIUrl() + _id);
  }

  downloadInforme(url){
    return this.http.get(url, {'responseType': 'blob'});
  }
  download(_id: number) {
    return this.http.get(this.getAPIUrlDownload(_id), {'responseType': 'blob'});
  }
}

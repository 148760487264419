<div class="mb-2" [class.full-height]="fullHeight">
  <div id="list-container" class="flex flex-col full-height rounded border bg-white">
    <div class="flex px-1 border-b bg-grey-lightest" style="min-height: 48px;">
      <div class="flex items-center justify-end" fxLayoutGap="10px">
        <div class="flex items-center justify-start" fxLayoutGap="10px">
          <button *ngIf="canAdd && this.authSrv.isEditable()"
                  class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
                  (click)="onAdd.next($event)">
            <i class="material-icons text-md">add</i>
          </button>
        </div>
        <div *ngIf="canSearch" class="shadow-inner bg-white rounded-full flex -mx-2 py-1 px-2 border">
          <mat-icon class="text-grey">search</mat-icon>
          <div>
            <input #searchinput type="search" class="bg-white text-grey-darkest text-sm outline-none" matInput
                   [placeholder]="searchPlaceholder | translate" (change)="onSearchChange($event)">
          </div>
          <mat-icon class="text-grey cursor-pointer" *ngIf="searchText" (click)="onClearSearch(searchinput)">clear
          </mat-icon>
        </div>
        <mat-menu #menuSort="matMenu" [overlapTrigger]="false">
          <div click-stop-propagation fxLayoutGap="5px" *ngFor="let f of sorters; let i = index" [attr.data-index]="i"
               class="d-flex justify-content-center align-items-center p-2 filter-row"
               [class.active]="activeOrder.pos === i">
            <span class="flex-grow-1">{{ f.text | translate}}</span>
            <div>
              <button mat-icon-button (click)="selectOrder(f,i,1)" class="direction-btn text-center"
                      [class.active]="activeOrder.pos === i && activeOrder.dir === 1">
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button mat-icon-button (click)="selectOrder(f,i,-1)" class="direction-btn text-center"
                      [class.active]="activeOrder.pos === i && activeOrder.dir === -1">
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </div>
          </div>
        </mat-menu>
      </div>
      <div class="flex-grow"></div>
      <div class="flex items-center justify-end">
        <button *ngIf="canSort"
                class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-4 rounded-full border inline-flex items-center outline-none"
                [matMenuTriggerFor]="menuSort">
          <i class="material-icons mr-2 text-md">sort</i>
          <span class="text-sm">{{'planaccion.acciones.list.order' | translate}}</span>
        </button>
        <div *ngIf="canRefresh" (click)="onRefresh.emit()"
             class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2 cursor-pointer">
          <i class="material-icons">refresh</i>
        </div>
      </div>
    </div>
    <mat-list id="list" class="flex-grow-1" style="overflow-y: auto;">
      <mat-list-item *ngFor="let item of getDatos()" class="p-2 flex-grow-1 list-item cursor-default" [class.selectable]="canClick"
                     [class.active]="item === active_row"
                     (click)="canClick ? select(item) : null">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }">
        </ng-container>
      </mat-list-item>
      <div *ngIf="!loading && datos.length == 0" class="flex flex-col justify-center items-center p-3">
        <i class="material-icons text-5xl text-grey">inbox</i>
        <span class="text-grey-dark text-sm">{{'generico.advancedlist.empty' | translate}}</span>
      </div>
      <div *ngIf="loading" class="full-height d-flex flex-column justify-content-center align-items-center">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </mat-list>
  </div>
</div>

<h2 mat-dialog-title>{{'analisis.vulnerabilidades.createmodal.title' | translate}}</h2>
<form [formGroup]="form">
  <div mat-dialog-content>
    <!--<mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label for="tipoaccion">{{'analisis.vulnerabilidades.createmodal.tipoaccion' | translate }}</mat-label>
      <mat-select id="tipoaccion" formControlName="TIPOACCION" name="tipoaccion">
        <mat-option selected value="-1">{{'home.select' | translate}}</mat-option>
        <mat-option *ngFor="let m of tipos_accion" [value]="m">{{m.NOMBRE}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label class="mat-input">{{'analisis.vulnerabilidades.createmodal.accion' | translate }}
      </mat-label>
      <input matInput [matAutocomplete]="auto" formControlName="ACCION"  [disabled]="!form.controls['TIPOACCION'].value">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAccion" [autoActiveFirstOption]="true">
        <mat-option *ngFor="let t of filtered_acciones | async" [value]="t">
          {{t.NOMBRE}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>-->
    <mat-form-field class="col-md-12" [floatLabel]="'always'">
      <mat-label for="NOMBRE">{{'analisis.vulnerabilidades.createmodal.NOMBRE' | translate }}
      </mat-label>
      <input id="NOMBRE" matInput formControlName="NOMBRE" [attr.disabled]="!editable"/>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="">{{'analisis.vulnerabilidades.createmodal.cancel' | translate}}</button>
    <button color="accent" mat-button [mat-dialog-close]="form.value">{{'analisis.vulnerabilidades.createmodal.ok' | translate}}
    </button>
  </div>
</form>

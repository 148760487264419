import {Component, OnInit} from '@angular/core';
import {DialogResponse} from '../../../model/Utils';
import {Petition} from '../../../model/petition';
import {ListatiposService} from '../../../services/listatipos.service';
import {Superambito} from '../../../model/superambito';
import {AdminService} from '../../../services/admin.service';
import {AdminsuperambitosformComponent} from '../adminsuperambitosform/adminsuperambitosform.component';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminsuperambitoslist',
  templateUrl: './adminsuperambitoslist.component.html',
  styleUrls: ['./adminsuperambitoslist.component.scss']
})
export class AdminsuperambitoslistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE'];
  datos: Superambito[];
  form = AdminsuperambitosformComponent;
  list_title = 'admin.inventario.spambito.title';
  search_placeholder = 'admin.inventario.spambito.searchplaceholder';

  constructor(private dataSrv: ListatiposService,private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.fetchSuperambito().subscribe(value => {
        value.sort((a, b) => ('' + a.DEPENDE_AYTO).localeCompare('' + b.DEPENDE_AYTO));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createSuperambito(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateSuperambito(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteSuperambito(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

import {Accion} from './accion';
import {IndicadorAccion} from './indicadoraccion';
import {EstadoAccion} from './estadoaccion';
import {PrioridadAccion} from './prioridadaccion';

export class HCOAccion {
  ID: number = 0;
  IDACCION: number = null;
  IDHCOPLANACCION: number = null;
  IDPADRE: number = null;
  IDESTADOACCION: number = 1;
  IDPRIORIDADACCION: number = 1;
  ANYOINICIO: number = null;
  ANYOOBJETIVO: number = null;
  CODIGOADICIONAL: string = null;
  AHORROENERGETICOESTIMADO: number = null;
  REDUCCIONEMISIONES: number = null;
  PRODUCCIONENERGIAESTIMADA: number = null;
  PESOEMISIONESAMBITO: number = null;
  PESOEMISIONESTOTALES: number = null;
  INVERSIONESTIMADA: number = null;
  AHORROESTIMADO: number = null;
  PRS: number = null;
  OBSERVACIONES: string = null;
  AYUDAS: string = null;
  ACCION: Accion = null;
  INDICADORACCION: IndicadorAccion[] = [];
  ESTADOACCION: EstadoAccion = null;
  PRIORIDADACCION: PrioridadAccion = null;

  constructor(obj = {}) {
    Object.assign(this, obj);
  }

  getRentabilidad() {
    return this.INVERSIONESTIMADA ? (this.REDUCCIONEMISIONES / this.INVERSIONESTIMADA) * 1000 : 0;
  }

  toDB() {
    return new HCOAccionDB(this);
  }
}

export class HCOAccionDB {
  ID: number;
  IDACCION: number;
  IDHCOPLANACCION: number;
  IDPADRE: number;
  IDESTADOACCION: number;
  IDPRIORIDADACCION: number;
  ANYOINICIO: number;
  ANYOOBJETIVO: number;
  CODIGOADICIONAL: string;
  AHORROENERGETICOESTIMADO: number;
  PRODUCCIONENERGIAESTIMADA: number = null;
  REDUCCIONEMISIONES: number;
  PESOEMISIONESAMBITO: number;
  PESOEMISIONESTOTALES: number;
  INVERSIONESTIMADA: number;
  AHORROESTIMADO: number;
  INDICADORACCION: IndicadorAccion[];
  PRS: number;
  OBSERVACIONES: string;
  AYUDAS: string;

  constructor(d: HCOAccion) {
    this.ID = d.ID;
    this.IDACCION = d.IDACCION;
    this.IDHCOPLANACCION = d.IDHCOPLANACCION;
    this.IDPADRE = d.IDPADRE;
    this.IDESTADOACCION = d.IDESTADOACCION;
    this.IDPRIORIDADACCION = d.IDPRIORIDADACCION;
    this.ANYOINICIO = d.ANYOINICIO;
    this.ANYOOBJETIVO = d.ANYOOBJETIVO;
    this.CODIGOADICIONAL = d.CODIGOADICIONAL;
    this.AHORROENERGETICOESTIMADO = d.AHORROENERGETICOESTIMADO;
    this.REDUCCIONEMISIONES = d.REDUCCIONEMISIONES;
    this.PESOEMISIONESAMBITO = d.PESOEMISIONESAMBITO;
    this.PESOEMISIONESTOTALES = d.PESOEMISIONESTOTALES;
    this.INVERSIONESTIMADA = d.INVERSIONESTIMADA;
    this.AHORROESTIMADO = d.AHORROESTIMADO;
    this.INDICADORACCION = d.INDICADORACCION;
    this.PRODUCCIONENERGIAESTIMADA = d.PRODUCCIONENERGIAESTIMADA;
    this.PRS = d.PRS;
    this.OBSERVACIONES = d.OBSERVACIONES;
    this.AYUDAS = d.AYUDAS;
  }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MunicipioService} from '../../services/municipio.service';
import {Municipio} from '../../model/municipio';
import {AlertService} from '../../services/alert.service';
import {InventarioEmisiones} from '../../model/inventarioemisiones';
import {InventarioService} from '../../services/inventario.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {forbiddenValuesValidator} from '../../validators/customvalidators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {CreateInventarioDialog} from './createinventario.component';
import {FormsService} from '../../services/forms.service';
import {ImportDialogComponent} from '../../components/import-dialog/import-dialog.component';
import {ImportAction, ImportResponse} from '../../model/Utils';
import {ExportDialogComponent} from '../../components/export-dialog/export-dialog.component';
import {Sorter} from '../../components/list/Util';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.scss']
})
export class InventarioComponent implements OnInit, OnDestroy {
  municipio_activo: Municipio;
  inventarios_emisiones: InventarioEmisiones[] = [];
  id_inventario_activo: number = null;
  inventario_activo: InventarioEmisiones = null;
  years = [];
  paramsSub;


  openImportDialog(){
    let dialogRef = this.dialog.open(ImportDialogComponent, {
      data: new ImportAction(this.dataSrv.getAPIUrlImport()),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: ImportResponse)  => {
      if(result.success){
        this.router.navigateByUrl('/inventarios/'+result.data.ID);
      }
    });
  }


  openExportDialog(){
    let dialogRef = this.dialog.open(ExportDialogComponent, {
      data: new ImportAction(this.dataSrv.getAPIUrlExport()),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: ImportResponse)  => {
    });
  }

  ngOnInit() {
    this.paramsSub = this.route.params.subscribe(params => {
      this.id_inventario_activo = +params['id'];
      this.fetchInventario(this.id_inventario_activo);
    });
    this.munsrv.sel_municipio.subscribe(value => {
      if (value) {
        this.municipio_activo = value;
      }
    }, error2 => console.log(error2.messageText));
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.municipio_activo = null;
    this.inventarios_emisiones = [];
    this.id_inventario_activo = null;
    this.inventario_activo = null;
    this.years = [];
  }

  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute, private munsrv: MunicipioService, private translateService: TranslateService,
              private alertsrv: AlertService, private dataSrv: InventarioService, public snackBar: MatSnackBar, public formService: FormsService) {

  }

  fetchInventario(id) {
    this.dataSrv.getById(id).subscribe(value => {
        if (value) {
          this.inventario_activo = value;
          this.dataSrv.setSelectedInventario(this.inventario_activo);
        }
      },
      error2 => this.alertsrv.error(error2));
  }

  openFormChangedDialog(id_inventario): void {
    const inventario = this.inventarios_emisiones.find(i => i.ID == id_inventario);
    const inventario_anterior = this.inventarios_emisiones.find(i => i.ID == this.id_inventario_activo);
    let dialogRef = this.dialog.open(FormChangedDialog, {
      data: {},
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSrv.setSelectedInventario(inventario);
        this.translateService.get('inventario.cambiar_inventario', {year: inventario.ANYO})
          .subscribe(d => this.snackBar.open(d, null, {duration: 1500}));
        this.router.navigateByUrl('/inventarios/datosgenerales');
      }
    });
  }

  openDeleteDialog(): void {
    let dialogRef = this.dialog.open(DeleteInventarioDialog, {
      data: {year: this.dataSrv.sel_inventario.getValue().ANYO},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.deleteInventario();
    });
  }

  deleteInventario() {
    this.dataSrv.delete(this.dataSrv.sel_inventario.getValue().ID).subscribe(value => {
      this.translateService.get('inventario.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.router.navigateByUrl('/inventarios');
    }, error2 => console.log(error2));
  }

}

@Component({
  selector: 'deleteinventariodialog',
  templateUrl: 'deleteinventario.dialog.html',
})
export class DeleteInventarioDialog {
  constructor(public dialogRef: MatDialogRef<DeleteInventarioDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

@Component({
  selector: 'formchangeddialog',
  templateUrl: 'formchanged.dialog.html',
})
export class FormChangedDialog {
  constructor(public dialogRef: MatDialogRef<FormChangedDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

<form [formGroup]="form" novalidate>
  <h2 mat-dialog-title>{{'inventario.createmodal.title' | translate}}</h2>
  <div mat-dialog-content>
    <mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label>{{'inventario.createmodal.year.label' | translate }}</mat-label>
      <input type="number" matInput formControlName="year" required>
      <mat-error class="error-message"
                 *ngIf="form.get('year').hasError('required') && (form.get('year').dirty || form.get('year').touched)">
        {{'inventario.createmodal.year.requiredMessage' | translate }}
      </mat-error>
      <mat-error class="error-message"
                 *ngIf="form.get('year').hasError('validForbiddenValues') && (form.get('year').dirty || form.get('year').touched)">
        {{'inventario.createmodal.year.forbiddenMessage' | translate }}
      </mat-error>
      <mat-error class="error-message"
                 *ngIf="form.get('year').hasError('validMinValue') && (form.get('year').dirty || form.get('year').touched)">
        {{'inventario.createmodal.year.minValue' | translate:{year: 1999} }}
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="">
      {{'inventario.createmodal.cancel' | translate }}
    </button>
    <button color="primary" mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">
      {{'inventario.createmodal.ok' | translate }}
    </button>
  </div>
</form>


<form [formGroup]="dataForm.form">
  <app-admin-form [dataForm]="dataForm" [title]="'admin.inventario.spambito.dialog_title'">
    <ng-template let-form="form">
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.spambito.NOMBRE.label' | translate }}</mat-label>
          <input type="TEXT" matInput formControlName="NOMBRE" required>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-radio-group formControlName="DEPENDE_AYTO">
          <mat-radio-button [value]="true">{{'admin.inventario.spambito.dependeayto' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'admin.inventario.spambito.nodependeayto' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </ng-template>
  </app-admin-form>
</form>

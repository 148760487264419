export const JSON_to_URLEncoded = (obj) => {
  let paramString = '';
  for (let key in obj) {
    let value = obj[key];
    if (obj[key] instanceof Array || obj[key] instanceof Object) {
      value = encodeURIComponent(JSON.stringify(value));
    }
    if (paramString != '') { paramString += '&'; }
    paramString += key + '=' + encodeURIComponent(value);
  }
  return paramString;
};

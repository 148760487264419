import { MunicipioDB } from "../model/municipio";
import { appConfig } from "../app.config";
import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AdminService = /** @class */ (function () {
    function AdminService(http) {
        this.http = http;
        this.municipioURL = "";
    }
    //MUNICIPIO
    AdminService.prototype.createMunicipio = function (municipio) {
        return this.http.post(appConfig.apiUrl + "MUNICIPIO", new MunicipioDB(municipio));
    };
    AdminService.prototype.updateMunicipio = function (municipio) {
        return this.http.put(appConfig.apiUrl + "MUNICIPIO/" + municipio.ID, new MunicipioDB(municipio));
    };
    AdminService.prototype.deleteMunicipio = function (municipio) {
        return this.http.delete(appConfig.apiUrl + "MUNICIPIO/" + municipio.ID);
    };
    //SUPERAMBITO
    AdminService.prototype.createSuperambito = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/SuperAmbito", d);
    };
    AdminService.prototype.updateSuperambito = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/SuperAmbito/" + d.ID, d);
    };
    AdminService.prototype.deleteSuperambito = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/SuperAmbito/" + d.ID);
    };
    //SUBAMBITO
    AdminService.prototype.createSubambito = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/SubAmbito", d);
    };
    AdminService.prototype.updateSubambito = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/SubAmbito/" + d.ID, d);
    };
    AdminService.prototype.deleteSubambito = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/SubAmbito/" + d.ID);
    };
    //FUENTE
    AdminService.prototype.createFuente = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/Fuente", d);
    };
    AdminService.prototype.updateFuente = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/Fuente/" + d.ID, d);
    };
    AdminService.prototype.deleteFuente = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/Fuente/" + d.ID);
    };
    //ACCION
    AdminService.prototype.createAccion = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/Accion", d);
    };
    AdminService.prototype.updateAccion = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/Accion/" + d.ID, d);
    };
    AdminService.prototype.deleteAccion = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/Accion/" + d.ID);
    };
    //ZONACLIMATICA
    AdminService.prototype.createZonaClimatica = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/ZonaClimatica", d);
    };
    AdminService.prototype.updateZonaClimatica = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/ZonaClimatica/" + d.ID, d);
    };
    AdminService.prototype.deleteZonaClimatica = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/ZonaClimatica/" + d.ID);
    };
    //RIESGOSPRED
    AdminService.prototype.createRiesgoPred = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/RiesgoPred", d);
    };
    AdminService.prototype.updateRiesgoPred = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/RiesgoPred/" + d.ID, d);
    };
    AdminService.prototype.deleteRiesgoPred = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/RiesgoPred/" + d.ID);
    };
    //SECTOR RIESGO
    AdminService.prototype.createSectorRiesgoPred = function (d) {
        return this.http.post(appConfig.apiUrl + "ADMIN/SectorRiesgoPred", d);
    };
    AdminService.prototype.updateSectorRiesgoPred = function (d) {
        return this.http.put(appConfig.apiUrl + "ADMIN/SectorRiesgoPred/" + d.ID, d);
    };
    AdminService.prototype.deleteSectorRiesgoPred = function (d) {
        return this.http.delete(appConfig.apiUrl + "ADMIN/SectorRiesgoPred/" + d.ID);
    };
    // SESSIONES
    AdminService.prototype.getSessionsActiva = function () {
        return this.http.get(appConfig.apiUrl + "SESION/admin/activas");
    };
    AdminService.prototype.closeSessionActiva = function (id) {
        return this.http.delete(appConfig.apiUrl + "SESION/admin/" + id);
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };

import {Injectable} from '@angular/core';
import {AlertService} from './alert.service';
import {BehaviorSubject} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {MunicipioService} from './municipio.service';
import {appConfig} from '../app.config';
import {PlanAccion, PlanAccionDB} from '../model/planaccion';
import {HCOAccion, HCOAccionDB} from '../model/hcoaccion';

@Injectable({
  providedIn: 'root'
})
export class PlanaccionService {
  selected: BehaviorSubject<PlanAccion> = new BehaviorSubject<PlanAccion>(null);
  selected_id: number;

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService) {
  }

  getInventarioActivo() {
    return this.selected.getValue();
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/';
  }

  getAPIUrlHcoAccion() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.selected_id + '/Accion/';
  }

  getAPIUrlImport() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.selected_id + '/import/';
  }
  getAPIUrlExport() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.selected_id + '/export/';
  }
  getAll() {
    return this.http.get<PlanAccion[]>(this.getAPIUrl());
  }

  getById(_id: number) {
    return this.http.get<PlanAccion>(this.getAPIUrl() + _id);
  }

  create(d: PlanAccionDB) {
    return this.http.post<PlanAccion>(this.getAPIUrl(), d);
  }

  clone(d: PlanAccionDB, year: number) {
    return this.http.post<PlanAccion>(this.getAPIUrl() + d.ANYO + '/clone/' + year, {});
  }


  update(d: PlanAccionDB) {
    return this.http.put<PlanAccion>(this.getAPIUrl() + d.ID, d);
  }

  replace(d: PlanAccionDB) {
    return this.http.put<PlanAccion>(this.getAPIUrl() + 'replace', d);
  }

  delete(_id: number) {
    return this.http.delete(this.getAPIUrl() + _id);
  }

  setActive(d) {
    this.selected_id = d.ID;
    this.selected.next(d as PlanAccion);
  }

  initialize() {
    this.selected.next(new PlanAccion());
  }

  getAccionById(_id: number) {
    return this.http.get<HCOAccion>(this.getAPIUrlHcoAccion() + _id);
  }

  updateAccion(d: HCOAccionDB) {
    return this.http.put<HCOAccion>(this.getAPIUrlHcoAccion() + d.ID, d);
  }

  deleteAccion(d: HCOAccionDB) {
    return this.http.delete<HCOAccion>(this.getAPIUrlHcoAccion() + d.ID);
  }

  createAccion(d: HCOAccionDB) {
    return this.http.post<HCOAccion>(this.getAPIUrlHcoAccion(), d);
  }


}

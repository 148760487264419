
<app-list class="shadow-none" *ngIf="!datos_peticion.empty" [datos]="datos" [simple]="false" [title]="list_title" [fullHeight]="false"
          (addCallback)="add($event)" [add]="true" (searchCallback)="onSearch($event)" [search]="true" [searchPlaceholder]="search_placeholder">
  <mat-list-item *ngFor="let item of getDatos()" class="p-1 flex-grow-1 list-item cursor-default">
    <div class="flex full-width align-center" fxLayoutGap="20px">
      <div class="flex flex-column justify-center align-center flex-grow">
        <span class="text-grey-darkest text-lg-b">{{item.MUNICIPIO.NOMBRE}}</span>
      </div>
      <div class="flex justify-center items-center" >
        <div class="bg-grey-dark rounded shadow-sm w-16 h-10 flex justify-center items-center  cursor-pointer hover:bg-grey-darker" (click)="delete(item)">
          <mat-icon class="text-white">delete</mat-icon>
        </div>
      </div>
    </div>
  </mat-list-item>
</app-list>
<div *ngIf="datos_peticion.pending" class="full-height full-width d-flex justify-content-center align-items-center">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

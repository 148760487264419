import {Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {DialogAction, DialogResponse, nestedObj, removeDiacritics} from './util';
import {MatDialog} from '@angular/material';
import {Sorter} from './util';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-advancedlist',
  templateUrl: './advancedlist.component.html',
  styleUrls: ['./advancedlist.component.scss']
})
export class AdvancedlistComponent implements OnInit {
  @Input() datos: any[];
  @Input() scrollableY: boolean = true;
  @Input() fullHeight: boolean = true;
  @Input() loading: boolean = false;

  @Input() canSort: boolean = false;
  @Input() sorters: Sorter[] = [];
  @Input() canClick: boolean = true;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() canAdd: boolean = false;
  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Input() addText: string;
  @Input() addDialog: any;
  @Input() canEdit: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Input() editDialog: any;
  @Input() canDelete: boolean = false;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Input() deleteDialog: any;
  @Input() canSearch: boolean = false;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Input() searchProps: string[] = [];
  @Input() searchPlaceholder: string = '';
  @Input() canRefresh: boolean = false;
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Input() formDialog: any;
  @ContentChild(TemplateRef)
  @Input() itemTemplate: TemplateRef<any>;
  searchText = '';
  active_row;

  activeOrder: Order = {
    pos: -1,
    order: null,
    dir: 0
  };

  constructor(private dialog: MatDialog, public authSrv: AuthenticationService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datos) {
      this.selectOrder(this.activeOrder.order, this.activeOrder.pos, this.activeOrder.dir);
    }
  }

  onSearchChange(input) {
    this.searchText = input.target.value;
  }

  filter() {
    return this.datos.filter(d => this.searchProps.filter(c => nestedObj(d, c) ? removeDiacritics(nestedObj(d, c)).toLowerCase().indexOf(removeDiacritics(this.searchText).toLowerCase()) >= 0 : false).length > 0);
  }

  getDatos() {
    if (this.searchText)
      return this.filter();
    else
      return this.datos;
  }

  onDialogClose(data: DialogResponse) {
    switch (data.action) {
      case DialogResponse.ADD: {
        if (this.authSrv.isEditable())
          this.onAdd.emit(data);
        break;
      }
      case DialogResponse.EDIT: {
        if (this.authSrv.isEditable())
          this.onEdit.emit(data);
        break;
      }
      case DialogResponse.DELETE: {
        if (this.authSrv.isEditable())
          this.onDelete.emit(data);
        break;
      }
    }
  }

  add(item) {
    this.openDialog(DialogAction.CREATE, item);
  }

  select(item) {
    this.active_row = item;
    if (this.onClick) {
      this.onClick.emit(item);
    } else {
      if (this.authSrv.isEditable())
        this.openDialog(DialogAction.EDIT, item);
      else {
        this.openDialog(DialogAction.VIEW, item);
      }
    }
  }

  openDialog(action: number, data: any): void {
    if (this.formDialog) {
      const dialogRef = this.dialog.open(this.formDialog, {
        data: new DialogAction(action, data),
        disableClose: false,
      });
      dialogRef.afterClosed().subscribe(data => {
        this.onDialogClose(data);
      });
    }
  }

  onClearSearch(input) {
    input.value = '';
    this.searchText = '';
  }

  selectOrder(order, pos, dir) {
    const actual_order: Order = {
      pos: pos,
      order: order,
      dir: dir
    };
    if (this.activeOrder && actual_order.pos == this.activeOrder.pos && actual_order.dir == this.activeOrder.dir) {
      this.activeOrder = {
        pos: -1,
        order: null,
        dir: 0
      };
    } else {
      this.activeOrder = actual_order;
      this.datos.sort(
        (a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';
          [propertyA, propertyB] = [nestedObj(a, actual_order.order.prop), nestedObj(b, actual_order.order.prop)];
          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
          return (valueA < valueB ? -1 : 1) * actual_order.dir;
        });
    }
  }
}

class Order {
  pos: number;
  order: Sorter;
  dir: 0;
}

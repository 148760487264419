import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Petition } from '../../../model/petition';
import { minValueValidator } from '../../../validators/customvalidators';
import { MunicipioService } from '../../../services/municipio.service';
import { MatSnackBar } from '@angular/material';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '../../../services/forms.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlanAccion, PlanAccionForm } from '../../../model/planaccion';
import { PlanaccionService } from '../../../services/planaccion.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-planaccion-datosgenerales',
  templateUrl: './planaccion-datosgenerales.component.html',
  styleUrls: ['./planaccion-datosgenerales.component.scss']
})
export class PlanaccionDatosgeneralesComponent implements OnInit {
  dato: PlanAccion = new PlanAccion();
  dato_orig: PlanAccion = new PlanAccion();
  data_update = new Petition();
  data_fetch = new Petition();
  form: FormGroup;

  constructor(
    private dataSrv: PlanaccionService,
    private munsvc: MunicipioService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    public snackBar: MatSnackBar,
    private formService: FormsService,
    public authSrv: AuthenticationService
  ) {
    this.dataSrv.selected.subscribe(
      value => {
        if (value) {
          this.load();
        }
      },
      error2 => console.log(error2)
    );

    this.form = this.formBuilder.group({
      ANYO: [
        null,
        Validators.compose([Validators.required, minValueValidator(1999)])
      ],
      ANYOREFERENCIAINVENTARIO: [
        null,
        Validators.compose([Validators.required, minValueValidator(1999)])
      ],
      ANYOREFERENCIARIESGOS: [
        null,
        Validators.compose([Validators.required, minValueValidator(1999)])
      ],
      ANYOOBJETIVO: [
        null,
        Validators.compose([Validators.required, minValueValidator(1999)])
      ],
      OBJETIVOCONSUMO: [
        null,
        Validators.compose([Validators.required, minValueValidator(0)])
      ],
      OBJETIVOPRODUCCION: [
        null,
        Validators.compose([Validators.required, minValueValidator(0)])
      ],
      OBJETIVOEMISION: [
        null,
        Validators.compose([Validators.required, minValueValidator(0)])
      ],
      OBJETIVOREDUCCIONCONSUMO: [
        null,
        Validators.compose([Validators.required, minValueValidator(0)])
      ],
      OBJETIVOREDUCCIONPRODUCCION: [
        null,
        Validators.compose([Validators.required, minValueValidator(0)])
      ],
      OBJETIVOREDUCCIONEMISION: [
        null,
        Validators.compose([Validators.required, minValueValidator(0)])
      ]
    });
    if (!this.authSrv.isEditable()) {
      this.form.disable();
    }

    router.events.subscribe(val => {
      if (val instanceof NavigationStart) {
        this.initialize();
      }
    });
  }

  guardar() {
    this.data_update.load();
    const d = Object.assign(this.dato, this.form.value);
    this.dataSrv.update(new PlanAccion(d).toDB()).subscribe(
      value => {
        this.translateService
          .get('planaccion.createmodal.updated')
          .subscribe(d => this.snackBar.open(d, 'Ok', { duration: 3000 }));
        this.load();
        this.data_update.ok(value);
      },
      error2 => {
        this.translateService
          .get('planaccion.createmodal.error')
          .subscribe(d => this.snackBar.open(d, 'Ok', { duration: 3000 }));
        this.data_update.ko(error2);
      }
    );
  }

  load() {
    this.data_fetch.load();
    this.dataSrv.getById(this.dataSrv.selected.getValue().ID).subscribe(
      value => {
        if (value) {
          this.dato = value;
          this.dato_orig = value;
          const formValue = Object.assign({}, new PlanAccionForm(value));
          this.form.setValue(formValue);
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.data_fetch.ok(value);
        }
      },
      error2 => this.data_fetch.ko(error2)
    );
  }

  initialize() {
    this.load();
  }

  ngOnInit() {
    this.initialize();
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.formService.setInventarioDatosGeneralesDirty(true);
      } else {
        this.formService.setInventarioDatosGeneralesDirty(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idinventario) {
      this.initialize();
    }
  }
}

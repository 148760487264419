import {Component, Inject, OnInit} from '@angular/core';
import { MunicipioForm} from '../../Forms';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DialogAction} from '../../../model/Utils';
import {Petition} from '../../../model/petition';
import {ListatiposService} from '../../../services/listatipos.service';
import {Accion} from '../../../model/accion';

@Component({
  selector: 'app-adminmunicipioform',
  templateUrl: './adminmunicipioform.component.html',
  styleUrls: ['./adminmunicipioform.component.scss']
})
export class AdminmunicipioformComponent implements OnInit {
  dato: Accion;
  dataForm: MunicipioForm;
  zonas_peticion = new Petition();
  comarca_peticion = new Petition();
  provincia_peticion = new Petition();
  comarcas = [];
  zonasclimaticas = [];
  provincias = [];

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, private dataSrv: ListatiposService) {
    this.fetchComarcas();
    this.fetchProvincias();
    this.fetchZonasClimaticas();
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new MunicipioForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new MunicipioForm(action);
    }
  }

  comarcasDisponibles() {
    return this.comarcas.filter(c => c.IDPROVINCIA == this.dataForm.form.controls['IDPROVINCIA'].value);
  }
  fetchComarcas() {
    this.comarca_peticion.load();
    this.dataSrv.fetchComarca().subscribe(value => {
      this.comarca_peticion.ok(value);
      this.comarcas = value;
    }, error2 => {
      this.comarca_peticion.ko(error2);
    });
  }

  fetchProvincias() {
    this.provincia_peticion.load();
    this.dataSrv.fetchProvincia().subscribe(value => {
      this.provincia_peticion.ok(value);
      this.provincias = value;
    }, error2 => {
      this.provincia_peticion.ko(error2);
    });
  }

  fetchZonasClimaticas() {
    this.zonas_peticion.load();
    this.dataSrv.fetchZonaClimatica().subscribe(value => {
      this.zonas_peticion.ok(value);
      this.zonasclimaticas = value;
    }, error2 => {
      this.zonas_peticion.ko(error2);
    });
  }


  ngOnInit() {
  }


  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

}

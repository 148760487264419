import {Component, EventEmitter, Inject, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {InventarioEmisiones, InventarioEmisionesDB, TipoInventario} from '../../../model/inventarioemisiones';
import {InventarioService} from '../../../services/inventario.service';
import {MunicipioService} from '../../../services/municipio.service';
import {NavigationStart, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {betweenValueValidator, minValueValidator} from '../../../validators/customvalidators';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';
import {FormsService} from '../../../services/forms.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {Petition} from '../../../model/petition';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-inv-create',
  templateUrl: './inv-create.component.html',
  styleUrls: ['./inv-create.component.scss']
})
export class InvCreateComponent implements OnInit, OnChanges {

  inventario: InventarioEmisiones = new InventarioEmisiones();
  inventario_orig: InventarioEmisiones = new InventarioEmisiones();
  tiposinventario: TipoInventario[] = [];
  alerts = [];
  data_update = new Petition();
  inv_fetch = new Petition();
  form: FormGroup;

  constructor(private invsrv: InventarioService, private munsvc: MunicipioService, private router: Router, private formBuilder: FormBuilder, private translateService: TranslateService, public snackBar: MatSnackBar, private formService: FormsService, public authSrv: AuthenticationService) {
    this.invsrv.sel_inventario.subscribe(
      value => {
        if (value) {
          this.loadInventario();
        }
      }, error2 => console.log(error2));

    this.form = this.formBuilder.group({
      tipoinventario: [1, Validators.required],
      nhabitantes: [null, minValueValidator(0)],
      nvehiculosgasolina: [null, minValueValidator(0)],
      nvehiculosdiesel: [null, minValueValidator(0)],
      implantaciongas: [null, betweenValueValidator(0, 100)],
    });
    if(!this.authSrv.isEditable()){
      this.form.disable();
    }

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.initialize();
      }
    });
  }

  guardar(values) {
    this.inventario.NUMHABITANTES = values.nhabitantes;
    this.inventario.NUMVEHICULOSGASOLINA = values.nvehiculosgasolina;
    this.inventario.NUMVEHICULOSDIESEL = values.nvehiculosdiesel;
    this.inventario.IMPLANTACIONGN = values.implantaciongas;
    this.inventario.IDTIPO = values.tipoinventario;
    this.inventario.FECHAULTIMAMODIFICACION = new Date();
    this.data_update.load();
    this.invsrv.update(new InventarioEmisionesDB(this.inventario)).subscribe(value => {
        this.translateService.get('inventario.createmodal.updated').subscribe(d => this.snackBar.open(d, 'Ok', {duration: 3000}));
        this.loadInventario();
        this.data_update.ok(value);
      }
      , error2 => {
        this.translateService.get('inventario.createmodal.error').subscribe(d => this.snackBar.open(d, 'Ok', {duration: 3000}));
        this.data_update.ko(error2);
      });
  }

  loadInventario() {
    this.inv_fetch.load();
    this.invsrv.getById(this.invsrv.sel_inventario.getValue().ID).subscribe(value => {
      if (value) {
        this.inventario = value;
        this.inventario.IDMUNICIPIO = this.munsvc.sel_municipio.getValue().ID;
        this.inventario_orig = value;
        this.inventario_orig.IDMUNICIPIO = this.munsvc.sel_municipio.getValue().ID;
        this.form.setValue({
          tipoinventario: this.inventario.IDTIPO,
          nhabitantes: this.inventario.NUMHABITANTES,
          nvehiculosgasolina: this.inventario.NUMVEHICULOSGASOLINA,
          nvehiculosdiesel: this.inventario.NUMVEHICULOSDIESEL,
          implantaciongas: this.inventario.IMPLANTACIONGN,
        });
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.formService.setInventarioDatosGeneralesDirty(false);
        this.inv_fetch.ok(value);
      }
    }, error2 => this.inv_fetch.ko(error2));
  }

  initialize() {
    this.tiposinventario = [];
    this.alerts = [];
    this.loadInventario();
    this.invsrv.getTiposInventario().subscribe(value => this.tiposinventario = value, error2 =>
      console.log(error2)
    );
  }

  ngOnInit() {
    this.initialize();
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.formService.setInventarioDatosGeneralesDirty(true);
      } else {
        this.formService.setInventarioDatosGeneralesDirty(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idinventario) {
      this.initialize();
    }
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apartado-admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/common";
import * as i5 from "../list/list.component.ngfactory";
import * as i6 from "../list/list.component";
import * as i7 from "../../services/authentication.service";
import * as i8 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i9 from "@angular/material/progress-spinner";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/flex-layout/flex";
import * as i13 from "@angular/flex-layout/core";
import * as i14 from "@angular/cdk/bidi";
import * as i15 from "./apartado-admin.component";
import * as i16 from "@angular/material/dialog";
var styles_ApartadoAdminComponent = [i0.styles];
var RenderType_ApartadoAdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApartadoAdminComponent, data: {} });
export { RenderType_ApartadoAdminComponent as RenderType_ApartadoAdminComponent };
function View_ApartadoAdminComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ApartadoAdminComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-item", [["class", "p-2 flex-grow-1 list-item cursor-default mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = ((_co.clickable ? _co.select(_v.context.$implicit) : null) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵand(16777216, null, 2, 2, null, View_ApartadoAdminComponent_3)), i1.ɵdid(6, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(7, { item: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 7, 0, _v.context.$implicit); var currVal_3 = _co.itemTemplate; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ApartadoAdminComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-list", [], null, [[null, "addCallback"], [null, "searchCallback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addCallback" === en)) {
        var pd_0 = (_co.add($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchCallback" === en)) {
        var pd_1 = (_co.onSearch($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ListComponent_0, i5.RenderType_ListComponent)), i1.ɵdid(1, 638976, null, 0, i6.ListComponent, [i7.AuthenticationService], { datos: [0, "datos"], title: [1, "title"], simple: [2, "simple"], add: [3, "add"], search: [4, "search"], searchPlaceholder: [5, "searchPlaceholder"] }, { searchCallback: "searchCallback", addCallback: "addCallback" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ApartadoAdminComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datos; var currVal_1 = _co.list_title; var currVal_2 = false; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.search_placeholder; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.getDatos(); _ck(_v, 3, 0, currVal_6); }, null); }
function View_ApartadoAdminComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "full-height full-width d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i8.View_MatSpinner_0, i8.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i9.MatSpinner, [i1.ElementRef, i10.Platform, [2, i4.DOCUMENT], [2, i11.ANIMATION_MODULE_TYPE], i9.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 40; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ApartadoAdminComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "flex"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(2, 1785856, null, 0, i12.LayoutGapDirective, [i13.MediaMonitor, i1.ElementRef, [8, null], i1.NgZone, i14.Directionality, i13.StyleUtils], { gap: [0, "gap"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "flex-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApartadoAdminComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApartadoAdminComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "20px"; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.empty; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ApartadoAdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-apartado-admin", [], null, null, null, View_ApartadoAdminComponent_0, RenderType_ApartadoAdminComponent)), i1.ɵdid(1, 114688, null, 1, i15.ApartadoAdminComponent, [i16.MatDialog], null, null), i1.ɵqud(335544320, 1, { itemTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApartadoAdminComponentNgFactory = i1.ɵccf("app-apartado-admin", i15.ApartadoAdminComponent, View_ApartadoAdminComponent_Host_0, { datos: "datos", loading: "loading", empty: "empty", clickable: "clickable", filterProps: "filterProps", list_title: "list_title", search_placeholder: "search_placeholder", itemTemplate: "itemTemplate", formDialog: "formDialog" }, { onAdd: "onAdd", onDelete: "onDelete", onEdit: "onEdit" }, []);
export { ApartadoAdminComponentNgFactory as ApartadoAdminComponentNgFactory };

<h2 mat-dialog-title>{{'inventario.datosinventario.factoresmodal.title' | translate}}</h2>
<div mat-dialog-content>
  <app-editable *ngIf="data.factores" [cols]="cols" [data]="data.factores" [hasAdd]="false" [hasDelete]="false"></app-editable>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="">{{'inventario.datosinventario.factoresmodal.cancel' | translate}}</button>
  <button color="accent" mat-button [mat-dialog-close]="data.factores">{{'inventario.datosinventario.factoresmodal.ok' |
    translate }}
  </button>
</div>

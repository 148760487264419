import {Component, Inject, OnInit} from '@angular/core';
import {MunicipioService} from '../../../services/municipio.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DialogAction, DialogResponse} from '../../../model/Utils';
import {Petition} from '../../../model/petition';
import {DeleteAccionDialog} from '../../../home/planaccion/planaccion-acciones/planaccion-acciones.component';
import {MunicipioForm, UsuarioForm} from '../../Forms';
import {ListatiposService} from '../../../services/listatipos.service';
import {Usuario} from '../../../model/usuario';
import {UserService} from '../../../services/user.service';
import {AdminusuariomunicipioformComponent} from '../adminusuariomunicipio/adminusuariomunicipioform/adminusuariomunicipioform.component';
import {UsuarioMunicipio} from '../../../model/municipio';
import {DeleteDialog} from '../../../components/dialogs/delete.dialog';
import {AdminusuariomunicipioComponent} from '../adminusuariomunicipio/adminusuariomunicipio.component';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-adminusuarioform',
  templateUrl: './adminusuarioform.component.html',
  styleUrls: ['./adminusuarioform.component.scss']
})
export class AdminusuarioformComponent implements OnInit {
  dato: Usuario;
  datos_peticion: Petition = new Petition();
  rol_peticion: Petition = new Petition();
  dataForm: UsuarioForm;
  roles = [];
  showPassword = false;

  constructor(private dataSrv: UserService, public dialogRef: MatDialogRef<DeleteAccionDialog>, private tipoSrv: ListatiposService, private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public action: DialogAction, public authSrv: AuthenticationService) {
    this.fetchRoles();
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.datos_peticion.load();
      this.dataSrv.getById(action.data.ID).subscribe(value => {
          this.dato = value;
          this.datos_peticion.ok(value);
          this.dataForm = new UsuarioForm(action, value);
        },
        error2 => {
          this.datos_peticion.ko(error2);
        }
      );
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new UsuarioForm(action);
    }
  }

  getCanEdit() {
    if(this.roles.length > 0){
      const idrol = this.dataForm.form.controls['ROL'].value;
      return this.roles.find(value => value.ID == idrol).CANEDIT;
    }
    return false;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  fetchRoles() {
    this.rol_peticion.load();
    this.tipoSrv.fetchRolUsuario().subscribe(value => {
      this.rol_peticion.ok(value);
      this.roles = value;
    }, error2 => {
      this.rol_peticion.ko(error2);
    });
  }

  ngOnInit() {
  }

  closeDelete() {
    switch (this.action.action) {
      case DialogAction.EDIT: {
        return new DialogResponse(DialogResponse.DELETE, this.dataForm.form.getRawValue());
      }
    }
  }

  closeOk() {
    switch (this.action.action) {
      case DialogAction.CREATE: {
        return new DialogResponse(DialogResponse.ADD, this.dataForm.form.value);
      }
      case DialogAction.EDIT: {
        return new DialogResponse(DialogResponse.EDIT, this.dataForm.form.getRawValue());
      }
      case DialogAction.VIEW: {
        return new DialogResponse(DialogResponse.NONE);
      }
    }
  }

  openDialog(data: Usuario): void {
    const dialogRef = this.dialog.open(AdminusuariomunicipioComponent, {
      data: new DialogAction(DialogAction.DETAIL, data),
      disableClose: false,
    });
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

import {Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {Sorter} from './Util';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges {
  @Input() datos: any[];
  @Input() orders: any[];
  @Input() title: string = '';
  @Input() simple: boolean = true;
  @Input() scrollableY: boolean = true;
  @Input() maxHeight: number = null;
  @Input() loading: boolean = false;
  @Input() add: boolean = false;
  @Input() search: boolean = false;
  @Input() fullHeight: boolean = true;
  @Output() searchCallback: EventEmitter<any> = new EventEmitter();
  @Input() searchPlaceholder: string = '';
  @Output() addCallback: EventEmitter<any> = new EventEmitter();
  searchText = '';
  activeOrder: Order = {
    pos: -1,
    order: null,
    dir: 0
  };

  constructor(public authSrv: AuthenticationService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datos) {
      this.selectOrder(this.activeOrder.order, this.activeOrder.pos, this.activeOrder.dir);
    }
  }

  onClearSearch(input) {
    input.value = '';
    this.searchCallback.next('');
  }

  onSearch(input) {
    this.searchText = input.target.value;
    this.searchCallback.next(input.target.value);
  }

  nestedObj(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  selectOrder(order, pos, dir) {
    const actual_order: Order = {
      pos: pos,
      order: order,
      dir: dir
    };
    if (this.activeOrder && actual_order.pos == this.activeOrder.pos && actual_order.dir == this.activeOrder.dir) {
      this.activeOrder = {
        pos: -1,
        order: null,
        dir: 0
      };
    } else {
      this.activeOrder = actual_order;
      this.datos.sort(
        (a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';
          [propertyA, propertyB] = [this.nestedObj(a, actual_order.order.prop), this.nestedObj(b, actual_order.order.prop)];
          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
          return (valueA < valueB ? -1 : 1) * actual_order.dir;
        });
    }
  }
}

class Order {
  pos: number;
  order: Sorter;
  dir: 0;
}

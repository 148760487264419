var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatPaginatorIntl, MatSort, Sort } from '@angular/material';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge } from 'rxjs/index';
import { debounceTime, map } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/internal/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../services/authentication.service';
var SimpletableComponent = /** @class */ (function () {
    function SimpletableComponent(dialog, pagIntl, translateSrv, authSrv) {
        this.dialog = dialog;
        this.pagIntl = pagIntl;
        this.translateSrv = translateSrv;
        this.authSrv = authSrv;
        this.cols = [];
        this.datatype = 'Dato';
        this.datatypePlural = 'Datos';
        this.loading = false;
        this.hasExport = false;
        this.exporting = false;
        this.hasImport = false;
        this.importing = false;
        this.importCallback = new EventEmitter();
        this.exportCallback = new EventEmitter();
        this.onClickRow = new EventEmitter();
        this.columnsToDisplay = [];
        this.selection = new SelectionModel(true, []);
        this.active_row_edit = null;
        this.original_row_edit = null;
    }
    SimpletableComponent.prototype.ngOnInit = function () {
    };
    SimpletableComponent.prototype.log = function (val) {
    };
    SimpletableComponent.prototype.hasFilter = function () {
        return this.cols.filter(function (c) { return c.data.filter; }).length > 0;
    };
    SimpletableComponent.prototype.rowClick = function (row) {
        this.onClickRow.next(row);
    };
    SimpletableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var filterEvent = fromEvent(this.filter.nativeElement, 'keyup');
        filterEvent.pipe(debounceTime(150));
        filterEvent.pipe(distinctUntilChanged());
        filterEvent.subscribe(function (i) {
            if (!_this.dataSource) {
                return;
            }
            _this.dataSource.filter = _this.filter.nativeElement.value;
        });
    };
    SimpletableComponent.prototype.nestedObj = function (o, s) {
        if (o && s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, ''); // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                }
                else {
                    return;
                }
            }
            return o;
        }
    };
    SimpletableComponent.prototype.isAllSelected = function () {
        if (!this.data) {
            return false;
        }
        if (this.selection.isEmpty()) {
            return false;
        }
        return this.selection.selected.length === this.data.length;
    };
    SimpletableComponent.prototype.masterToggle = function () {
        var _this = this;
        if (!this.data) {
            return;
        }
        if (this.isAllSelected()) {
            this.selection.clear();
        }
        else if (this.filter) {
            if (this.filter.nativeElement.value)
                this.dataSource.renderedData.forEach(function (data) { return _this.selection.toggle(_this.getDataIndex(data)); });
        }
        else {
            this.dataSource.data.forEach(function (data) { return _this.selection.toggle(_this.getDataIndex(data)); });
        }
    };
    SimpletableComponent.prototype.getDataIndex = function (data) {
        return this.dataSource.data.indexOf(data);
    };
    SimpletableComponent.prototype.getColValueByID = function (name, obj) {
        var col = this.cols.find(function (c) { return c.id === name; });
        return this.nestedObj(obj, col.prop);
    };
    SimpletableComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.data.slice();
        if (!sort.active || sort.direction === '') {
            return;
        }
        this.data = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            return _this.compare(_this.getColValueByID(sort.active, a), _this.getColValueByID(sort.active, b), isAsc);
        });
    };
    SimpletableComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    SimpletableComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.data) {
            this.selection.clear();
            this.dataSource = new SimpleTableDataSource(this.data, this.sort, this.cols
                .filter(function (c) { return c.data.filter; })
                .map(function (c) {
                return c.data.filter;
            })
                .reduce(function (previous, current) {
                if (previous)
                    return previous.concat(current);
                else
                    return [].concat(current);
            }, 0));
            this.translateSrv.get('editable.pagination.itemsPerPageLabel').subscribe(function (d) { return _this.pagIntl.itemsPerPageLabel = d; });
            this.translateSrv.get('editable.pagination.firstPageLabel').subscribe(function (d) { return _this.pagIntl.firstPageLabel = d; });
            this.translateSrv.get('editable.pagination.lastPageLabel').subscribe(function (d) { return _this.pagIntl.lastPageLabel = d; });
            this.translateSrv.get('editable.pagination.nextPageLabel').subscribe(function (d) { return _this.pagIntl.nextPageLabel = d; });
            this.translateSrv.get('editable.pagination.previousPageLabel').subscribe(function (d) { return _this.pagIntl.previousPageLabel = d; });
            this.translateSrv.get('editable.pagination.of').subscribe(function (d) { return _this.pagIntl.getRangeLabel = function (page, pageSize, length) {
                if (length == 0 || pageSize == 0) {
                    return "0 " + d + " " + length;
                }
                length = Math.max(length, 0);
                var startIndex = page * pageSize;
                var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
                return startIndex + 1 + " - " + endIndex + " " + d + " " + length;
            }; });
        }
        if (changes.cols) {
            var columns = [];
            columns = columns.concat(this.cols.map(function (c) { return c.id; }));
            this.columnsToDisplay = columns;
        }
    };
    return SimpletableComponent;
}());
export { SimpletableComponent };
var SimpleTableDataSource = /** @class */ (function (_super) {
    __extends(SimpleTableDataSource, _super);
    function SimpleTableDataSource(_data, _sort, _filterCols) {
        var _this = _super.call(this) || this;
        _this._data = _data;
        _this._sort = _sort;
        _this._filterCols = _filterCols;
        _this.dataChange = new BehaviorSubject([]);
        _this._filterChange = new BehaviorSubject('');
        _this.filterCols = [];
        _this.filteredData = [];
        _this.renderedData = [];
        _this.dataChange.next(_data);
        _this.filterCols = _filterCols;
        return _this;
    }
    Object.defineProperty(SimpleTableDataSource.prototype, "data", {
        get: function () {
            return this.dataChange.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleTableDataSource.prototype, "filter", {
        get: function () {
            return this._filterChange.value;
        },
        set: function (filter) {
            this._filterChange.next(filter);
        },
        enumerable: true,
        configurable: true
    });
    SimpleTableDataSource.prototype.nestedObj = function (o, s) {
        if (o && s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, ''); // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                }
                else {
                    return;
                }
            }
            return o;
        }
    };
    /** Connect function called by the table to retrieve one stream containing the data to render. */
    SimpleTableDataSource.prototype.connect = function () {
        var _this = this;
        // Listen for any changes in the base data, sorting, filtering, or pagination
        var displayDataChanges = [
            this.dataChange,
            this._sort.sortChange,
            this._filterChange,
        ];
        return merge.apply(void 0, displayDataChanges).pipe(map(function () {
            // Filter data
            _this.filteredData = _this.data.slice().filter(function (item) {
                if (!_this.filterCols)
                    return true;
                var searchStr = _this.filterCols.map(function (f) { return _this.nestedObj(item, f); }).join('').toLowerCase();
                return searchStr.indexOf(_this.filter.toLowerCase()) !== -1;
            });
            // Sort filtered data
            var sortedData = _this.sortData(_this.filteredData.slice());
            _this.renderedData = sortedData;
            return _this.renderedData;
        }));
    };
    SimpleTableDataSource.prototype.disconnect = function () {
    };
    /** Returns a sorted copy of the database data. */
    SimpleTableDataSource.prototype.sortData = function (data) {
        var _this = this;
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }
        return data.sort(function (a, b) {
            var propertyA = '';
            var propertyB = '';
            _a = [a[_this._sort.active], b[_this._sort.active]], propertyA = _a[0], propertyB = _a[1];
            var valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            var valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            return (valueA < valueB ? -1 : 1) * (_this._sort.direction === 'asc' ? 1 : -1);
            var _a;
        });
    };
    return SimpleTableDataSource;
}(DataSource));
export { SimpleTableDataSource };

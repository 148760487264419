import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRoles;
    const user = this.auth.currentUser;
    // decode the token to get its payload
    if (
      !this.auth.isAuthenticated() || !expectedRole.includes(user.role)
    ) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}

<h2 mat-dialog-title>{{title | translate}}</h2>
<form [formGroup]="dataForm.form" *ngIf="dataForm" novalidate>
  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="formTemplate; context: { form: dataForm.form }">
    </ng-container>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button color="warn" [mat-dialog-close]="closeDelete()">{{'forms.actions.delete' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="close()">{{'forms.actions.cancel' | translate}}</button>
    <button mat-button color="primary" [mat-dialog-close]="closeOk()"
            [disabled]="!dataForm.form.valid || !dataForm.form.dirty">
      {{'forms.actions.save' | translate}}
    </button>
  </div>
</form>
<div *ngIf="!dataForm" class="flex justify-center align-center">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

<div class="grid-container">
  <div id="carouselMunicipio" class="bg-white border rounded mb-4">
    <ngx-slick-carousel class="db-carousel" #munCar="slick-carousel" [config]="{'slidesToShow':1,'dots':false}">
      <div ngxSlickItem class="d-flex fin-elevation slide-mun1">
        <div fxFlex="30" #map>
          <agm-map [latitude]="municipio.LATITUD"
                   [longitude]="municipio.LONGITUD"
                   [zoom]="14"
                   [mapDraggable]="true"
                   [disableDefaultUI]="true"
                   [style.height.px]="250">
          </agm-map>
        </div>
        <div class="text-left p-0" fxFlex="40">
          <div class="p-4">
            <span class="text-lg-m">{{municipio.NOMBRE}}</span>
            <br>
            <span class="mat-caption mr-4">{{municipio.COMARCA.NOMBRE}}</span>
            <span class="mat-caption">{{municipio.COMARCA.PROVINCIA.NOMBRE}}</span>
            <div fxLayout="row" fxLayoutGap="15px" class="pt-4">
              <div>
                <mat-label class="text-sm color-grey-500">{{'dashboard.card-municipio.fechaadhesion' | translate}}
                </mat-label>
                <br>
                <span class="text-md color-grey-700">{{municipio.FECHAADHESIONPACTO | localizedDate}}</span>
              </div>
              <div>
                <mat-label class="text-sm color-grey-500">{{'dashboard.card-municipio.fechalimite' | translate}}
                </mat-label>
                <br>
                <span class="text-md color-grey-700">{{municipio.FECHALIMITEPRESENTACION | localizedDate}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left p-2" fxFlex="30" fxLayout="column">
          <div class="flex-shrink-0">
          <span
            class="text-lg-m color-grey-700">{{'dashboard.card-municipio.estadodocumentos' | translate}}</span>
          </div>
          <div class="p-4">
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutGap="10px">
                <div class="flex-shrink-0 rounded-circle w-8 h-8 mr-2 "
                     [style.background-color]="getDocumentoCheckColor(1)">
                  <mat-icon class="color-grey-0">check</mat-icon>
                </div>
                <div class="flex-grow-1">
                  <span class="text-md-b"
                        [style.color]="getDocumentoTextColor(1)">{{'extra.documentos.tipodocumentos.1' | translate}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="10px">
                <div class="flex-shrink-0 rounded-circle w-8 h-8 mr-2 "
                     [style.background-color]="getDocumentoCheckColor(3)">
                  <mat-icon class="color-grey-0">check</mat-icon>
                </div>
                <div class="flex-grow-1">
                  <span class="text-md-b"
                        [style.color]="getDocumentoTextColor(3)">{{'extra.documentos.tipodocumentos.3' | translate}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="10px">
                <div class="flex-shrink-0 rounded-circle w-8 h-8 mr-2 "
                     [style.background-color]="getDocumentoCheckColor(2)">
                  <mat-icon class="color-grey-0">check</mat-icon>
                </div>
                <div class="flex-grow-1">
                  <span class="text-md-b"
                        [style.color]="getDocumentoTextColor(2)">{{'extra.documentos.tipodocumentos.2' | translate}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="10px">
                <div class="flex-shrink-0 rounded-circle w-8 h-8 mr-2 "
                     [style.background-color]="getDocumentoCheckColor(6)">
                  <mat-icon class="color-grey-0">check</mat-icon>
                </div>
                <div class="flex-grow-1">
                  <span class="text-md-b"
                        [style.color]="getDocumentoTextColor(6)">{{'extra.documentos.tipodocumentos.6' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div id="carouselInventarioReferencia" class="bg-white border rounded mt-2 mb-4" *ngIf="inventario_referencia">
    <ngx-slick-carousel class="db-carousel" id="invCar" #invCar="slick-carousel" [config]="config()">
      <div ngxSlickItem class="d-flex slide-inv1 bg-transparent flex-column p-4" *ngIf="inventario_referencia">
        <div class="flex" fxLayoutGap="15px">
          <div class="flex-shrink-0 rounded-full w-16 h-16 bg-green-dark flex justify-center items-center shadow-sm">
            <i class="material-icons text-4xl text-white">notes</i>
          </div>
          <div class="flex-wrap flex justify-center items-center">
            <span
              class="text-xl font-semibold text-grey-darkest">{{'dashboard.card-inventario-referencia.title' | translate}}</span>
          </div>
        </div>
        <div class="d-flex flex justify-center items-center flex-fill p-4">
          <span class="text-green-dark text-5xl">{{inventario_referencia.ANYO}}</span>
        </div>
        <div class="dashboard-card-actions">
          <button
            class="bg-green-dark font-semibold text-white px-3 rounded-full shadow hover:bg-grey inline-flex items-center outline-none"
            [routerLink]="'/inventarios/'+inventario_referencia.ID">
            {{'dashboard.card-inventario-referencia.action' |
            translate}}
          </button>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv4 flex-column p-4"
           *ngIf="inventario_referencia && inventario_referencia.NUMHABITANTES">
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start bg-transparent" fxLayoutGap="10px"
             fxLayout="column">
          <div id="emisionespercapita" fxLayout="column" fxFlexAlign="center center" class="tile border rounded bg-grey-lightest w-full">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-inventario-referencia.charts.percapita.emisiones' | translate}}</span>
            <span class="align-self-center text-2xl text-green-dark font-semibold">{{(inventario_referencia.NUMHABITANTES ? (totalemisiones_ref/inventario_referencia.NUMHABITANTES) : 0) | localizedNumber:'':'1.0-2' }}</span>
            <small class="align-self-center text-mg text-grey-darkest font-semibold">tCO2/Hab.</small>
          </div>
        </div>
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start bg-transparent" fxLayoutGap="10px"
             fxLayout="column">
          <div id="consumospercapita" fxLayout="column" fxFlexAlign="center center" class="tile border rounded bg-grey-lightest w-full">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-inventario-referencia.charts.percapita.consumos' | translate}}</span>
            <span class="align-self-center text-2xl text-green-dark font-semibold">{{(inventario_referencia.NUMHABITANTES ? (totalconsumo_ref/inventario_referencia.NUMHABITANTES) : 0) | localizedNumber:'':'1.0-2' }}</span>
            <small class="align-self-center text-mg text-grey-darkest font-semibold">tCO2/Hab.</small>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv2 flex-column p-4"
           *ngIf="inventario_referencia && graficos && graficos.EMISIONESPORSUPERAMBITO_REF.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m  text-grey-darkest font-semibold">{{'dashboard.card-inventario-referencia.charts.datosporambito.title' | translate}}</span>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <ngx-charts-advanced-pie-chart [results]="graficos.EMISIONESPORSUPERAMBITO_REF" scheme="forest">
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv3 flex-column p-4"
           *ngIf="inventario_referencia && graficos && graficos.EMISIONESDPE_AYTO_REF.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m text-grey-darkest font-semibold">{{'dashboard.card-inventario-referencia.charts.emisionesdpe_ayto.title' | translate}}</span>
        </div>
        <div class="flex-grow-1">
          <ngx-charts-pie-chart [results]="graficos.EMISIONESDPE_AYTO_REF" scheme="forest" [doughnut]="true"
                                [labels]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv2 flex-column p-4"
           *ngIf="inventario_referencia && graficos && graficos.CONSUMOSPORSUPERAMBITO_REF.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m  text-grey-darkest font-semibold">{{'dashboard.card-inventario-referencia.charts.consumosporambito.title' | translate}}</span>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <ngx-charts-advanced-pie-chart [results]="graficos.CONSUMOSPORSUPERAMBITO_REF" scheme="forest">
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv3 flex-column p-4"
           *ngIf="inventario_referencia && graficos && graficos.CONSUMOSDPE_AYTO_REF.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m text-grey-darkest font-semibold">{{'dashboard.card-inventario-referencia.charts.consumosdpe_ayto.title' | translate}}</span>
        </div>
        <div class="flex-grow-1">
          <ngx-charts-pie-chart [results]="graficos.CONSUMOSDPE_AYTO_REF" scheme="forest" [doughnut]="true"
                                [labels]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex " *ngIf="!inventario_referencia"
           class="d-flex justify-content-center align-items-center flex-column full-width">
        <p class="mat-headline">{{'dashboard.card-inventario-referencia.empty' | translate}}</p>
        <a routerLink="/inventarios">{{'dashboard.card-inventario-referencia.empty_link' | translate}}</a>
      </div>
    </ngx-slick-carousel>
  </div>
  <div id="carouselInventario" class="bg-white border rounded mt-2 mb-4" *ngIf="ultimo_inventario">
    <ngx-slick-carousel class="db-carousel" id="invCar" #invCar="slick-carousel" [config]="config()">
      <div ngxSlickItem class="d-flex slide-inv1 bg-transparent flex-column p-4" *ngIf="ultimo_inventario">
        <div class="flex" fxLayoutGap="15px">
          <div class="flex-shrink-0 rounded-full w-16 h-16 bg-green-dark flex justify-center items-center shadow-sm">
            <i class="material-icons text-4xl text-white">notes</i>
          </div>
          <div class="flex-wrap flex justify-center items-center">
            <span
              class="text-xl font-semibold text-grey-darkest">{{'dashboard.card-inventarios.title' | translate}}</span>
          </div>
        </div>
        <div class="d-flex flex justify-center items-center flex-fill p-4">
          <span class="text-green-dark text-5xl">{{ultimo_inventario.ANYO}}</span>
        </div>
        <div class="dashboard-card-actions">
          <button
            class="bg-green-dark font-semibold text-white px-3 rounded-full shadow hover:bg-grey inline-flex items-center outline-none"
            [routerLink]="'/inventarios/'+ultimo_inventario.ID">
            {{'dashboard.card-inventarios.action' |
            translate}}
          </button>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv4 flex-column p-4"
           *ngIf="ultimo_inventario && ultimo_inventario.NUMHABITANTES">
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start bg-transparent" fxLayoutGap="10px"
             fxLayout="column">
          <div id="emisionespercapita" fxLayout="column" fxFlexAlign="center center" class="tile border rounded bg-grey-lightest w-full">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-inventarios.charts.percapita.emisiones' | translate}}</span>
            <span class="align-self-center text-2xl text-green-dark font-semibold">{{(ultimo_inventario.NUMHABITANTES ? (totalemisiones_last/ultimo_inventario.NUMHABITANTES) : 0) | localizedNumber:'':'1.0-2' }}</span>
            <small class="align-self-center text-mg text-grey-darkest font-semibold">tCO2/Hab.</small>
          </div>
        </div>
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start bg-transparent" fxLayoutGap="10px"
             fxLayout="column">
          <div id="consumospercapita" fxLayout="column" fxFlexAlign="center center" class="tile border rounded bg-grey-lightest w-full">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-inventarios.charts.percapita.consumos' | translate}}</span>
            <span class="align-self-center text-2xl text-green-dark font-semibold">{{(ultimo_inventario.NUMHABITANTES ? (totalconsumo_last/ultimo_inventario.NUMHABITANTES) : 0) | localizedNumber:'':'1.0-2' }}</span>
            <small class="align-self-center text-mg text-grey-darkest font-semibold">kWh/Hab.</small>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv2 flex-column p-4"
           *ngIf="ultimo_inventario && graficos && graficos.EMISIONESPORSUPERAMBITO_LAST.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m  text-grey-darkest font-semibold">{{'dashboard.card-inventarios.charts.datosporambito.title' | translate}}</span>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <ngx-charts-advanced-pie-chart [results]="graficos.EMISIONESPORSUPERAMBITO_LAST" scheme="forest">
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv3 flex-column p-4"
           *ngIf="ultimo_inventario && graficos && graficos.EMISIONESDPE_AYTO_LAST.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m text-grey-darkest font-semibold">{{'dashboard.card-inventarios.charts.emisionesdpe_ayto.title' | translate}}</span>
        </div>
        <div class="flex-grow-1">
          <ngx-charts-pie-chart [results]="graficos.EMISIONESDPE_AYTO_LAST" scheme="forest" [doughnut]="true"
                                [labels]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>

      <div ngxSlickItem class="d-flex slide-inv2 flex-column p-4"
           *ngIf="ultimo_inventario && graficos && graficos.CONSUMOSPORSUPERAMBITO_LAST.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m  text-grey-darkest font-semibold">{{'dashboard.card-inventarios.charts.consumosporambito.title' | translate}}</span>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <ngx-charts-advanced-pie-chart [results]="graficos.CONSUMOSPORSUPERAMBITO_LAST" scheme="forest">
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-inv3 flex-column p-4"
           *ngIf="ultimo_inventario && graficos && graficos.CONSUMOSDPE_AYTO_LAST.length > 0">
        <div class="flex-shrink-0">
          <span class="text-lg-m text-grey-darkest font-semibold">{{'dashboard.card-inventarios.charts.consumosdpe_ayto.title' | translate}}</span>
        </div>
        <div class="flex-grow-1">
          <ngx-charts-pie-chart [results]="graficos.CONSUMOSDPE_AYTO_LAST" scheme="forest" [doughnut]="true"
                                [labels]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex " *ngIf="!ultimo_inventario"
           class="d-flex justify-content-center align-items-center flex-column full-width">
        <p class="mat-headline">{{'dashboard.card-inventarios.empty' | translate}}</p>
        <a routerLink="/inventarios">{{'dashboard.card-inventarios.empty_link' | translate}}</a>
      </div>
    </ngx-slick-carousel>
  </div>
  <div id="carouselPlanaccion" class="bg-white border rounded  mt-2 mb-4" *ngIf="ultimo_plan && graficos">
    <ngx-slick-carousel class="db-carousel" id="planCar" #planCar="slick-carousel" [config]="config()">
      <div ngxSlickItem class="d-flex slide-plan1 bg-transparent flex-column p-4" *ngIf="ultimo_plan">
        <div class="flex" fxLayoutGap="15px">
          <div class="flex-shrink-0 rounded-full w-16 h-16 bg-red-dark flex justify-center items-center shadow-sm">
            <i class="material-icons text-4xl text-white">timeline</i>
          </div>
          <div class="flex-wrap flex justify-center items-center">
            <span
              class="text-xl font-semibold text-grey-darkest">{{'dashboard.card-planaccion.title' | translate}}</span>
          </div>
        </div>
        <div class="d-flex flex justify-center items-center flex-fill p-4">
          <span class="text-red-dark text-5xl">{{ultimo_plan.ANYO}}</span>
        </div>
        <div class="dashboard-card-actions">
          <button
            class="bg-red-dark font-semibold text-white px-3 rounded-full shadow hover:bg-grey inline-flex items-center outline-none"
            [routerLink]="'/planaccion/'+ultimo_plan.ID">
            {{'dashboard.card-planaccion.action' |
            translate}}
          </button>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-plan3 flex-column p-4"
           *ngIf="ultimo_plan && graficos && graficos.ESTADOSACCIONES.length > 0">
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start" fxLayoutGap="10px"
             fxLayout="column">
          <div id="rentabilidad" fxLayout="column" fxFlexAlign="center center" class="border rounded bg-grey-lightest">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-planaccion.charts.rentabilidad.title' | translate}}</span>
            <span class="align-self-center text-2xl text-red-dark font-semibold">{{(graficos.SUMAINVERSION ? (graficos.SUMREDUCCIONEMISIONES/graficos.SUMAINVERSION)*1000 : 0) | localizedNumber:'':'1.0-2' }}</span>
            <small class="align-self-center text-mg text-grey-darkest font-semibold">kgCO2/€</small>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-plan2 flex-column p-4"
           *ngIf="ultimo_plan && graficos && graficos.ESTADOSACCIONES.length > 0">
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start" fxLayoutGap="10px"
             fxLayout="column">
          <div fxLayout="column" fxFlexAlign="center center">
            <span class="text-md  text-grey-darkest font-semibold">{{'dashboard.card-planaccion.charts.sumadatos.redemisiones' | translate}}</span>
            <span class="text-xxl color-grey-800">{{ graficos.SUMREDUCCIONEMISIONES | localizedNumber:'':'1.0-2'}} <small>tCO2</small></span>
          </div>
          <div fxLayout="column" fxFlexAlign="center center">
            <span
              class="text-md  text-grey-darkest font-semibold">{{'dashboard.card-planaccion.charts.sumadatos.inversion' | translate}}</span>
            <span class="text-xxl color-grey-800">{{ graficos.SUMAINVERSION | localizedNumber:'':'1.0-2'}} <small>€</small></span>
          </div>
          <div fxLayout="column" fxFlexAlign="center center">
            <span
              class="text-md text-grey-darkest font-semibold">{{'dashboard.card-planaccion.charts.sumadatos.ahorro' | translate}}</span>
            <span class="text-xxl color-grey-800">{{ graficos.SUMAAHORROENERGETICO | localizedNumber:'':'1.0-2'}} <small>MWh</small></span>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-plan4 flex-column p-4"
           *ngIf="ultimo_plan && graficos.ESTADOSACCIONES">
        <div class="flex-shrink-0">
          <span
            class="text-lg-m  text-grey-darkest font-semibold">{{'dashboard.card-planaccion.charts.estadosaccion.title' | translate}}</span>
        </div>
        <div class="flex-grow-1 justify-content-center align-items-center" fxLayout="column">
          <ngx-charts-bar-horizontal [results]="graficos.ESTADOSACCIONES" scheme="flame" [yAxis]="true"
                                     [barPadding]="20" [showDataLabel]="true" [view]="[360,180]">
          </ngx-charts-bar-horizontal>
        </div>
      </div>
      <div ngxSlickItem *ngIf="!ultimo_plan" class="d-flex justify-content-center align-items-center flex-column">
        <p class="mat-headline">{{'dashboard.card-planaccion.empty' | translate}}</p>
        <a routerLink="/planaccion">{{'dashboard.card-planaccion.empty_link' | translate}}</a>
      </div>
    </ngx-slick-carousel>
  </div>
  <div id="carouselAnalisis" class="bg-white border rounded  mt-2 mb-4" *ngIf="ultimo_analisis">
    <ngx-slick-carousel class="db-carousel" id="anaCar" #anaCar="slick-carousel" [config]="config()">

      <div ngxSlickItem *ngIf="ultimo_analisis" class="d-flex slide-ana1 bg-transparent flex-column p-4">
        <div class="flex" fxLayoutGap="15px">
          <div class="flex-shrink-0 rounded-full w-16 h-16 bg-blue-dark flex justify-center items-center shadow-sm">
            <i class="material-icons text-4xl text-white">priority_high</i>
          </div>
          <div class="flex-wrap flex justify-center items-center">
            <span class="text-xl font-semibold text-grey-darkest">{{'dashboard.card-analisis.title' | translate}}</span>
          </div>
        </div>
        <div class="d-flex flex justify-center items-center flex-fill p-4">
          <span class="text-blue-dark text-5xl">{{ultimo_analisis.ANYO}}</span>
        </div>
        <div class="dashboard-card-actions">
          <button
            class="bg-blue-dark font-semibold text-white px-3 rounded-full shadow hover:bg-grey inline-flex items-center outline-none"
            [routerLink]="'/analisis/'+ultimo_analisis.ID">
            {{'dashboard.card-analisis.action' |
            translate}}
          </button>
        </div>
      </div>
      <div ngxSlickItem *ngIf="ultimo_analisis" class="d-flex slide-ana2 flex-column p-4">
        <div class="flex-grow-1 pl-4 pr-4 justify-content-center align-items-start" fxLayoutGap="10px"
             fxLayout="column">
          <div fxLayout="column" fxFlexAlign="center center" class="tile border rounded bg-grey-lightest "
               [routerLink]="'/analisis/'+ultimo_analisis.ID+'/riesgos'" style="cursor:pointer">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-analisis.charts.datos.riesgos' | translate}}</span>
            <span class="align-self-center text-2xl text-blue-dark font-semibold">{{ultimo_analisis.RIESGOS.length | localizedNumber }}</span>
          </div>
          <div fxLayout="column" fxFlexAlign="center center" class="tile border rounded bg-grey-lightest "
               [routerLink]="'/analisis/'+ultimo_analisis.ID+'/vulnerabilidades'" style="cursor:pointer">
            <span class="align-self-center text-mg text-grey-darkest font-semibold">{{'dashboard.card-analisis.charts.datos.vulnerabilidades' | translate}}</span>
            <span class="align-self-center text-2xl text-blue-dark font-semibold">{{ultimo_analisis.VULNERABILIDADES.length | localizedNumber }}</span>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-ana3 flex-column p-4"
           *ngIf="ultimo_analisis && graficos && graficos.RIESGOSPORNIVEL.length > 0">
        <div class="flex-shrink-0">
          <span
            class="text-lg-m text-grey-darkest font-semibold">{{'dashboard.card-analisis.charts.nivelriesgos.title' | translate}}</span>
        </div>
        <div class="flex-grow-1">
          <ngx-charts-pie-chart [results]="graficos.RIESGOSPORNIVEL" scheme="ocean" [labels]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>
      <div ngxSlickItem class="d-flex slide-ana4 flex-column p-4"
           *ngIf="ultimo_analisis && graficos && graficos.VULNERABILIDADESPORNIVELACTUAL.length > 0">
        <div class="flex-shrink-0">
          <span
            class="text-lg-m  text-grey-darkest font-semibold">{{'dashboard.card-analisis.charts.nivelactual.title' | translate}}</span>
        </div>
        <div class="flex-grow-1">
          <ngx-charts-pie-chart [results]="graficos.VULNERABILIDADESPORNIVELACTUAL" scheme="ocean" [labels]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>
      <div ngxSlickItem *ngIf="!ultimo_analisis" class="d-flex justify-content-center align-items-center flex-column">
        <p class="mat-headline">{{'dashboard.card-analisis.empty' | translate}}</p>
        <a routerLink="/analisis">{{'dashboard.card-analisis.empty_link' | translate}}</a>
      </div>
    </ngx-slick-carousel>
  </div>
</div>


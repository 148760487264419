<div class="bg-white border-b pl-4 pr-4 flex flex-col">
  <span class="text-2xl mb-3">{{'admin.inventario.title' | translate}}</span>
  <ul class="list-reset flex mb-0">
    <li class="tablink mr-6 active:font-bold pb-2" routerLink="/admin/inventario"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
      <span class="text-grey-dark font-semibold text-uppercase text-xs">{{'admin.inventario.ambito.title' | translate}}</span>
    </li>
    <li class="tablink mr-6 active:font-bold pb-2" routerLink="/admin/inventario/spambito"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: false}">
      <span class="text-grey-dark font-semibold text-uppercase text-xs">{{'admin.inventario.spambito.title' | translate}}</span>
    </li>
    <li class="tablink mr-6 active:font-bold pb-2" routerLink="/admin/inventario/sbambito"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: false}">
      <span class="text-grey-dark font-semibold text-uppercase text-xs">{{'admin.inventario.sbambito.title' | translate}}</span>
    </li>
    <li class="tablink mr-6 active:font-bold pb-2" routerLink="/admin/inventario/fuente"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: false}">
      <span class="text-grey-dark font-semibold text-uppercase text-xs">{{'admin.inventario.fuente.title' | translate}}</span>
    </li>
  </ul>
</div>
<router-outlet></router-outlet>

import {
  AfterViewInit,
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog, MatPaginator, MatPaginatorIntl, MatSort, Sort} from '@angular/material';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, fromEvent, merge, Observable} from 'rxjs/index';
import {debounceTime, map} from 'rxjs/operators';
import {distinctUntilChanged} from 'rxjs/internal/operators';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-simpletable',
  templateUrl: './simpletable.component.html',
  styleUrls: ['./simpletable.component.scss']
})
export class SimpletableComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() cols: any[] = [];
  @Input() data;
  @Input() datatype = 'Dato';
  @Input() datatypePlural = 'Datos';
  @Input() loading: boolean = false;
  @Input() hasExport = false;
  @Input() exporting = false;
  @Input() hasImport = false;
  @Input() importing = false;
  @Output() importCallback: EventEmitter<any> = new EventEmitter();
  @Output() exportCallback: EventEmitter<any> = new EventEmitter();
  @Output() onClickRow: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;

  dataSource: SimpleTableDataSource | null;
  columnsToDisplay = [];
  selection = new SelectionModel<number>(true, []);
  active_row_edit = null;
  original_row_edit = null;


  constructor(public dialog: MatDialog, private pagIntl: MatPaginatorIntl, private translateSrv: TranslateService, public authSrv: AuthenticationService) {
  }

  ngOnInit() {
  }

  log(val) {
  }

  hasFilter() {
    return this.cols.filter(c => c.data.filter).length > 0;
  }

  rowClick(row) {
    this.onClickRow.next(row);
  }

  ngAfterViewInit() {
    const filterEvent = fromEvent(this.filter.nativeElement, 'keyup');
    filterEvent.pipe(debounceTime(150));
    filterEvent.pipe(distinctUntilChanged());
    filterEvent.subscribe(i => {
      if (!this.dataSource) {
        return;
      }
      this.dataSource.filter = this.filter.nativeElement.value;
    });

  }

  nestedObj(o, s) {
    if (o && s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      let a = s.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    }
  }

  isAllSelected(): boolean {
    if (!this.data) {
      return false;
    }
    if (this.selection.isEmpty()) {
      return false;
    }
    return this.selection.selected.length === this.data.length;
  }

  masterToggle() {
    if (!this.data) {
      return;
    }
    if (this.isAllSelected()) {
      this.selection.clear();
    } else if (this.filter) {
      if (this.filter.nativeElement.value)
        this.dataSource.renderedData.forEach(data => this.selection.toggle(this.getDataIndex(data)));
    } else {
      this.dataSource.data.forEach(data => this.selection.toggle(this.getDataIndex(data)));
    }
  }

  getDataIndex(data) {
    return this.dataSource.data.indexOf(data);
  }

  getColValueByID(name, obj) {
    const col = this.cols.find(c => c.id === name);
    return this.nestedObj(obj, col.prop);
  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(this.getColValueByID(sort.active, a), this.getColValueByID(sort.active, b), isAsc);
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.selection.clear();
      this.dataSource = new SimpleTableDataSource(this.data, this.sort,
        this.cols
          .filter(c => c.data.filter)
          .map(c => {
              return c.data.filter;
            }
          )
          .reduce((previous, current) => {
            if (previous)
              return previous.concat(current);
            else return [].concat(current);
          }, 0)
      );
      this.translateSrv.get('editable.pagination.itemsPerPageLabel').subscribe(d => this.pagIntl.itemsPerPageLabel = d);
      this.translateSrv.get('editable.pagination.firstPageLabel').subscribe(d => this.pagIntl.firstPageLabel = d);
      this.translateSrv.get('editable.pagination.lastPageLabel').subscribe(d => this.pagIntl.lastPageLabel = d);
      this.translateSrv.get('editable.pagination.nextPageLabel').subscribe(d => this.pagIntl.nextPageLabel = d);
      this.translateSrv.get('editable.pagination.previousPageLabel').subscribe(d => this.pagIntl.previousPageLabel = d);
      this.translateSrv.get('editable.pagination.of').subscribe(d => this.pagIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length == 0 || pageSize == 0) {
          return `0 ${d} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${d} ${length}`;
      });
    }
    if (changes.cols) {
      let columns = [];
      columns = columns.concat(this.cols.map(c => c.id));
      this.columnsToDisplay = columns;
    }
  }
}


export class SimpleTableDataSource extends DataSource<any> {
  get data(): any[] {
    return this.dataChange.value;
  }

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  _filterChange = new BehaviorSubject('');
  filterCols = [];

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: any[] = [];
  renderedData: any[] = [];


  nestedObj(o, s) {
    if (o && s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      let a = s.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    }
  }

  constructor(private _data: any[],
              private _sort: MatSort,
              private _filterCols: string[]) {

    super();
    this.dataChange.next(_data);
    this.filterCols = _filterCols;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.dataChange,
      this._sort.sortChange,
      this._filterChange,
    ];

    return merge(...displayDataChanges).pipe(map(() => {
      // Filter data
      this.filteredData = this.data.slice().filter((item: any) => {
        if (!this.filterCols)
          return true;
        const searchStr = this.filterCols.map(f => this.nestedObj(item, f)).join('').toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // Sort filtered data
      const sortedData = this.sortData(this.filteredData.slice());
      this.renderedData = sortedData;
      return this.renderedData;
    }));
  }

  disconnect() {
  }

  /** Returns a sorted copy of the database data. */
  sortData(data: any[]): any[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';
      [propertyA, propertyB] = [a[this._sort.active], b[this._sort.active]];
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

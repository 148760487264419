import { Component, OnInit } from '@angular/core';
import {DialogResponse} from '../../../model/Utils';
import {AdminService} from '../../../services/admin.service';
import {Petition} from '../../../model/petition';
import {Fuente} from '../../../model/subambito';
import {ListatiposService} from '../../../services/listatipos.service';
import {AdminfuenteformComponent} from '../adminfuenteform/adminfuenteform.component';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminfuentelist',
  templateUrl: './adminfuentelist.component.html',
  styleUrls: ['./adminfuentelist.component.scss']
})
export class AdminfuentelistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE'];
  datos: Fuente[];
  form = AdminfuenteformComponent;
  list_title = 'admin.inventario.fuente.title';
  search_placeholder = 'admin.inventario.fuente.searchplaceholder';

  constructor(private dataSrv: ListatiposService,private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.fetchFuente().subscribe(value => {
        value.sort((a, b) => ('' + a.NOMBRE).localeCompare('' + b.NOMBRE));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createFuente(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateFuente(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteFuente(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

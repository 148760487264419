<h2 mat-dialog-title>{{'municipio.centros.deletemodal.title' | translate}}</h2>
<div mat-dialog-content>
  <span class="mat-caption">{{'municipio.centros.deletemodal.text' | translate}}</span>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{'municipio.centros.deletemodal.cancel' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="true">
    {{'municipio.centros.deletemodal.ok' | translate:{number: data.number}  }}
  </button>
</div>


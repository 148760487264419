<mat-sidenav-container fullscreen autosize>
  <mat-sidenav #sidenav id="sidenav" [mode]="mobile ? 'over' : 'side'" [opened]="mobile ? false : true" class="d-flex flex-column shadow-sm"
               (onOpen)="open()" [disableClose]="!mobile" style="max-width: 340px;"
               (onClose)="close()">
    <div id="sidenav-header"  fxLayout="row" class="items-center justify-content-start border-b" fxLayoutGap="15px">
      <img src="/assets/logos/logo_icon.png"  style="height: 36px;" class="px-1">
      <div class="d-flex flex-wrap">
        <span *ngIf="municipio_activo" mat-line class="flex-grow-1 text-md font-semibold text-grey-darker">{{municipio_activo.NOMBRE}}</span>
      </div>
    </div>

    <div fxLayout="row" class="align-items-center justify-content-start border-b p-2 bg-grey-lightest" fxLayoutGap="10px">
      <div class="d-flex flex-wrap px-2">
        <span *ngIf="auth.getCurrentUser()" class="flex-grow text-sm text-grey-darker">{{auth.getCurrentUser().name}}</span>
      </div>
      <div class="flex-grow"></div>
      <button class="bg-white text-grey-dark w-10 h-10 py-1 px-1 rounded-full border flex justify-center items-center outline-none mx-2 hover:text-orange-dark" [matMenuTriggerFor]="langMenu">
        <i class="material-icons text-sm">translate</i>
      </button>
      <button class="bg-white text-grey-dark w-10 h-10 py-1 px-1 rounded-full border flex justify-center items-center outline-none mx-2  hover:text-orange-dark"  [matMenuTriggerFor]="menu">
        <i class="material-icons text-md">more_horiz</i>
      </button>
    </div>
    <mat-menu #langMenu="matMenu" [overlapTrigger]="false">
      <a mat-menu-item (click)="setLang('es')">
        Castellano
      </a>
      <a mat-menu-item (click)="setLang('ca')">
        Valencià
      </a>
    </mat-menu>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <a *ngIf="municipio_activo"  mat-menu-item  routerLink="/municipio">
        <mat-icon>settings</mat-icon>
        {{'home.opciones' | translate }}
      </a>
      <a *ngIf="municipio_activo && auth.isEditable()"  mat-menu-item routerLink="/import" >
        <mat-icon>file_upload</mat-icon>
        {{'home.import' | translate }}
      </a>
      <button *ngIf="municipio_activo"  mat-menu-item (click)="openDialog()">
        <mat-icon>location_city</mat-icon>
        {{'home.cambiarmunicipio' | translate }}
      </button>
      <a mat-menu-item [href]="'/login'" >
        <mat-icon color="warn">exit_to_app</mat-icon>
        {{'home.salir' | translate }}
      </a>
    </mat-menu>
    <mat-list class="mt-1 mat-list flex-col flex-auto pb-4 border-b">
      <mat-list-item class="cursor-pointer" routerLink="/dashboard" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >home</i>
        </div>
        <h4 mat-line class="font-light">{{'home.home' | translate }}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/inventarios" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >notes</i>
        </div>
        <h4 mat-line class="font-light">{{'admin.inventario.title' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/analisis" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >priority_high</i>
        </div>
        <h4 mat-line class="font-light">{{'home.misanalisisderiesgos' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/planaccion" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >timeline</i>
        </div>
        <h4 mat-line class="font-light">{{'home.miplandeaccion' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/bi" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >bubble_chart</i>
        </div>
        <h4 mat-line class="font-light">{{'home.qlikview' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/informes" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >bar_chart</i>
        </div>
        <h4 mat-line class="font-light">{{'home.informes' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/documentos" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >folder_open</i>
        </div>
        <h4 mat-line class="font-light">{{'home.misdocumentos' | translate}}</h4>
      </mat-list-item>
      <div class="flex-grow-1"></div>
      <mat-list-item  *ngIf="isAdmin()" class="cursor-pointer" routerLink="/admin">
        <div class="rounded-full border flex justify-center items-center bg-grey-lightest mr-2 py-1 px-1">
          <i class="material-icons text-grey-darker text-md" >build</i>
        </div>
        <h4 mat-line>{{'home.admin-button' | translate}}</h4>
      </mat-list-item>
    </mat-list>
    <div fxLayout="row" fxLayoutGap="10px" class="p-2 justify-content-center align-items-center">
      <img height="42"  style="height: 42px;" src="/assets/logos/logo_pacto.jpg" alt="">
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column" class="bg-grey-lightest">
    <mat-toolbar *ngIf="mobile"  style="background: white;" class="pl-md-5  pr-md-5 pl-sm-2 pr-sm-2 mat-elevation-z2 ">
      <button mat-icon-button color="accent" *ngIf="mobile" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    <div *ngIf="municipio_activo" fxFlex class="full-height">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

import {Injectable} from '@angular/core';
import {MunicipioService} from './municipio.service';
import {InventarioService} from './inventario.service';
import {appConfig} from '../app.config';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {DatosCentro as Model} from '../model/datoscentro';

@Injectable()
export class DatoscentroService {
  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService, private invsrv: InventarioService) {
  }

  getAPIUrl(iddatosinventario) {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/InventarioEmisiones/' + this.invsrv.sel_inventario.getValue().ID + '/DatosInventario/' + iddatosinventario + '/DatosCentro/';
  }

  getAll(iddatosinventario) {
    return this.http.get<Model[]>(this.getAPIUrl(iddatosinventario));
  }

  getById(iddatosinventario, _id: number) {
    return this.http.get<Model>(this.getAPIUrl(iddatosinventario) + _id);
  }

  create(iddatosinventario, datos: Model) {
    return this.http.post<Model>(this.getAPIUrl(iddatosinventario), datos);
  }

  update(iddatosinventario, datos: Model) {
    return this.http.put<Model>(this.getAPIUrl(iddatosinventario) + datos.ID, datos);
  }

  updateAll(iddatosinventario, datos) {
    return this.http.put<Model[]>(this.getAPIUrl(iddatosinventario), datos);
  }

  delete(iddatosinventario, _id: number) {
    return this.http.delete(this.getAPIUrl(iddatosinventario) + _id);
  }

}

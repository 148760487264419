<div class="full-height row m-0" fxLayout="row" fxLayoutAlign="center start">
  <!--<div id="aside" class="col-lg-3 flex sm:hidden">-->
  <!--</div>-->
  <div class="col-lg-9 full-vheight d-flex flex-column justify-content-center align-items-center pt-4"
       style="background: white;">
    <img height="72" class="mt-4" style="height: 72px;" src="/assets/logos/logo.png" alt="">
    <div class="flex-grow-1 p-3 d-flex flex-column  justify-content-center align-items-center">
      <h1 class="mat-headline" [translate]="'login.login'"></h1>
      <div class="spacer"></div>
      <form name="form-signin" class="form text-center" (ngSubmit)="loginForm.valid && login()" [formGroup]="loginForm" novalidate>
        <mat-form-field class="full-width mb-2" [floatLabel]="'always'">
          <mat-label [translate]="'login.username'"></mat-label>
          <input matInput formControlName="username" required autofocus>
          <mat-error class="error-message"
                     *ngIf="loginForm.get('username').hasError('required') && (loginForm.get('username').dirty || loginForm.get('username').touched)"
                     [translate]="'login.username_err'">
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width mb-2" [floatLabel]="'always'">
          <mat-label [translate]="'login.password'"></mat-label>
          <input type="password" matInput formControlName="password" required>
          <mat-error class="error-message"
                     *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password').dirty || loginForm.get('password').touched)"
                     [translate]="'login.password_err'">
          </mat-error>
        </mat-form-field>

        <button
          class="bg-grey-lighter shadow-sm font-semibold text-grey-dark hover:text-orange-dark px-3 py-2 rounded-full border inline-flex items-center outline-none"
          [disabled]="loading" [translate]="'login.login_btn'">
          <span *ngIf="!loading">{{'login.login_btn' | translate}}</span>
          <mat-spinner color="warn" *ngIf="loading" [diameter]="20" value="indeterminate"></mat-spinner>
        </button>
      </form>
    </div>
    <div id="logo" class="d-flex justify-content-center align-items-center p-3 full-width"
         style="border-top: 1px solid #f2f2f2;" fxLayoutGap="20px">
      <img height="42" style="height: 42px;" src="/assets/logos/logo_azigrene.png" alt="">
      <img height="42" style="height: 42px;" src="/assets/logos/logo_pacto.jpg" alt="">
    </div>
  </div>

</div>

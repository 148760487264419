<form [formGroup]="dataForm.form">
  <app-admin-form [dataForm]="dataForm" [title]="'admin.inventario.sbambito.dialog_title'">
    <ng-template let-form="form">
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.sbambito.NOMBRE.label' | translate }}</mat-label>
          <input type="TEXT" matInput formControlName="NOMBRE" required>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.sbambito.DEFAULTFCONVERSION.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="DEFAULTFCONVERSION" required>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.sbambito.DEFAULTFEMISION.label' | translate }}</mat-label>
          <input type="number" matInput formControlName="DEFAULTFEMISION" required>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.sbambito.IDSUBAMBITO.label' | translate }}
          </mat-label>
          <mat-select formControlName="IDFUENTE" [compareWith]="compareByID">
            <mat-option *ngFor="let t of fuentes" [value]="t.ID">{{t.NOMBRE}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.sbambito.IDTIPOSUBAMBITO.label' | translate }}
          </mat-label>
          <mat-select formControlName="IDTIPOSUBAMBITO" [compareWith]="compareByID">
            <mat-option *ngFor="let t of tipo_subambito" [value]="t.ID">{{t.NOMBRE}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
  </app-admin-form>
</form>

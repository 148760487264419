<h2 mat-dialog-title>{{'inventario.formchangedmodal.title' | translate}}</h2>
<div mat-dialog-content>
  <span class="mat-caption">{{'inventario.formchangedmodal.text' | translate:{year: data.year} }}</span>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{'inventario.formchangedmodal.cancel' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="true">
    {{'inventario.formchangedmodal.ok' | translate }}
  </button>
</div>


<h2 mat-dialog-title>{{action.title | translate}}</h2>
<div mat-dialog-content>
  <div *ngIf="data_export.pending" class="flex justify-center items-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <div *ngIf="data_export.error" class="text-center">
    <mat-icon color="warn" class="large-icon ion-md-close-circle">
    </mat-icon>
    <p class="mat-body-2">{{'generico.export.import_ko' | translate}}</p>
  </div>
  <div *ngIf="data_export.success" class="text-center">
    <mat-icon color="accent" class="large-icon">
      check_circle
    </mat-icon>
    <p class="mat-body-2">{{'generico.export.import_ok' | translate}}</p>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="export_response">{{'generico.export.cancel' | translate}}</button>
  <button mat-button color="primary" [mat-dialog-close]="" [disabled]="!data_export.finished">
    {{'generico.export.ok' | translate}}
  </button>
</div>

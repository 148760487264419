import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {DialogAction, DialogResponse} from '../../../model/Utils';
import {Petition} from '../../../model/petition';
import {AdminusuarioformComponent} from '../adminusuarioform/adminusuarioform.component';
import {Usuario} from '../../../model/usuario';
import {Municipio, UsuarioMunicipio} from '../../../model/municipio';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DeleteDialog} from '../../../components/dialogs/delete.dialog';
import {PermisosService} from '../../../services/permisos.service';
import {MunicipioService} from '../../../services/municipio.service';
import {AdminusuariomunicipioformComponent} from './adminusuariomunicipioform/adminusuariomunicipioform.component';
import {Alert} from 'selenium-webdriver';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminusuariomunicipio',
  templateUrl: './adminusuariomunicipio.component.html',
  styleUrls: ['./adminusuariomunicipio.component.scss']
})
export class AdminusuariomunicipioComponent implements OnInit, OnChanges {
  @Input() user: Usuario;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  datos_peticion: Petition = new Petition();
  accion_peticion: Petition = new Petition();
  datos: UsuarioMunicipio[];
  list_title = 'admin.usuario.usuariomunicipios.title';
  search_placeholder = 'admin.usuario.usuariomunicipios.searchplaceholder';
  filterText = '';

  constructor(private dataSrv: PermisosService,private datosSrv: UserService,private munSrv: MunicipioService, private dialog: MatDialog, private alert: AlertbarService) {
    this.alert.applyToPetition(this.accion_peticion);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.user){
      this.load();
    }
  }
  load(){
    this.datosSrv.getById(this.user.ID).subscribe(value => {
        this.datos = value.USUARIOMUNICIPIOS;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onSearch(text) {
    this.filterText = text;
  }

  getDatos() {
    if (this.filterText)
      return this.datos.filter(d => {
        var proptext = this.nestedObj(d, 'MUNICIPIO.NOMBRE');
        if (proptext) {
          return this.nestedObj(d, 'MUNICIPIO.NOMBRE').toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0;
        } else {
          return true;
        }
      });
    else
      return this.datos;
  }

  ngOnInit() {
    this.datos_peticion.load();
    this.datosSrv.getById(this.user.ID).subscribe(value => {
        this.datos = value.USUARIOMUNICIPIOS;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onDialogClose(data: DialogResponse) {
    switch (data.action) {
      case DialogResponse.ADD: {
        this.accion_peticion.load();
        this.dataSrv.create(data.data).subscribe(value => {
            this.accion_peticion.ok(value);
            this.load();
            this.onChange.emit();
          },
          error2 => {
            this.accion_peticion.ko(error2);
          });
        break;
      }
      case DialogResponse.DELETE: {
        this.accion_peticion.load();
        this.dataSrv.delete(data.data).subscribe(value => {
            this.accion_peticion.ok(value);
            this.load();
            this.onChange.emit();
          },
          error2 => {
            this.accion_peticion.ko(error2);
          });
        break;
      }
    }
  }

  add(item) {
    this.openDialog(DialogAction.CREATE, item);
  }

  delete(item) {
    this.openDialog(DialogAction.DELETE, item);
  }

  openDialog(action: number, dato: UsuarioMunicipio): void {
    switch(action){
      case DialogAction.DELETE:{
        const dialogRef = this.dialog.open(DeleteDialog, {
          data: new DialogAction(action, dato),
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
          this.onDialogClose(data);
        });
      }
      break;
      case DialogAction.CREATE:{
        const um = new UsuarioMunicipio();
        um.USERID = this.user.USERID;
        const dialogRef = this.dialog.open(AdminusuariomunicipioformComponent, {
          data: new DialogAction(action, um),
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
          this.onDialogClose(data);
        });
      }
    }
  }


  nestedObj(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

}

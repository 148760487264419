<form [formGroup]="form" novalidate>
  <h2 mat-dialog-title>{{'planaccion.clonemodal.title' | translate}}</h2>
  <div mat-dialog-content fxLayout="column">
    <mat-form-field class="medium-field full-width" [floatLabel]="'always'">
      <mat-label for="anyo">{{'planaccion.clonemodal.anyo.label' | translate }}</mat-label>
      <mat-select id="anyo" formControlName="PLAN">
        <mat-option *ngFor="let m of data.planesaccion" [value]="m">{{m.ANYO}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
      <mat-label>{{'planaccion.clonemodal.anyorevision.label' | translate }}</mat-label>
      <input type="number" matInput formControlName="ANYOrevision" required>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="">
      {{'planaccion.clonemodal.cancel' | translate }}
    </button>
    <button color="primary" mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">
      {{'planaccion.clonemodal.ok' | translate }}
    </button>
  </div>
</form>


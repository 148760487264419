/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./delete.dialog";
var styles_DeleteDialog = [];
var RenderType_DeleteDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteDialog, data: {} });
export { RenderType_DeleteDialog as RenderType_DeleteDialog };
export function View_DeleteDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "mat-caption"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 13, "div", [["class", "mat-dialog-actions"], ["fxLayout", "row"], ["fxLayoutAlign", "end center"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(10, 737280, null, 0, i3.LayoutDirective, [i4.MediaMonitor, i0.ElementRef, i4.StyleUtils], { layout: [0, "layout"] }, null), i0.ɵdid(11, 737280, null, 0, i3.LayoutAlignDirective, [i4.MediaMonitor, i0.ElementRef, [6, i3.LayoutDirective], i4.StyleUtils], { align: [0, "align"] }, null), i0.ɵdid(12, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(13, 0, null, null, 4, "button", [["color", "primary"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).dialogRef.close(i0.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(14, 180224, null, 0, i6.MatButton, [i0.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(15, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(16, 0, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(18, 0, null, null, 4, "button", [["color", "warn"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).dialogRef.close(i0.ɵnov(_v, 20).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i6.MatButton, [i0.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(20, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(21, 0, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = "row"; _ck(_v, 10, 0, currVal_3); var currVal_4 = "end center"; _ck(_v, 11, 0, currVal_4); var currVal_8 = "primary"; _ck(_v, 14, 0, currVal_8); var currVal_9 = _co.close(); _ck(_v, 15, 0, currVal_9); var currVal_14 = "warn"; _ck(_v, 19, 0, currVal_14); var currVal_15 = _co.closeDelete(); _ck(_v, 20, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("generico.dialog.delete.title")); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("generico.dialog.delete.text")); _ck(_v, 7, 0, currVal_2); var currVal_5 = (i0.ɵnov(_v, 14).disabled || null); var currVal_6 = (i0.ɵnov(_v, 14)._animationMode === "NoopAnimations"); var currVal_7 = i0.ɵnov(_v, 15).ariaLabel; _ck(_v, 13, 0, currVal_5, currVal_6, currVal_7); var currVal_10 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform("generico.dialog.delete.cancel")); _ck(_v, 16, 0, currVal_10); var currVal_11 = (i0.ɵnov(_v, 19).disabled || null); var currVal_12 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); var currVal_13 = i0.ɵnov(_v, 20).ariaLabel; _ck(_v, 18, 0, currVal_11, currVal_12, currVal_13); var currVal_16 = i0.ɵunv(_v, 21, 0, i0.ɵnov(_v, 22).transform("generico.dialog.delete.ok")); _ck(_v, 21, 0, currVal_16); }); }
export function View_DeleteDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "deletedialog", [], null, null, null, View_DeleteDialog_0, RenderType_DeleteDialog)), i0.ɵdid(1, 49152, null, 0, i10.DeleteDialog, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var DeleteDialogNgFactory = i0.ɵccf("deletedialog", i10.DeleteDialog, View_DeleteDialog_Host_0, {}, {}, []);
export { DeleteDialogNgFactory as DeleteDialogNgFactory };

<mat-toolbar fxLayout="row wrap" class="pl-md-5  pr-md-5 pl-sm-3 pr-sm-3" style="background: white;">
  <button mat-icon-button [routerLink]="back_url">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div id="activo" class="pl-2">
    <span id="activotext">{{title}}</span>
  </div>
  <div class="toolbar-spacer"></div>
  <div *ngIf="authSrv.isEditable()" id="acciones" class="flex" fxLayoutGap="10px">
    <button class="hover:shadow-sm bg-grey-lightest font-semibold text-grey-dark hover:text-orange-dark px-3 rounded-full border inline-flex items-center outline-none"
            (click)="onImport.emit()">
      <i class="material-icons mr-2 text-md">cloud_upload</i>
      <span class="text-sm">{{'generico.import.btn' | translate}}</span>
    </button>
    <button class="hover:shadow-sm bg-grey-lightest  font-semibold text-grey-dark hover:text-orange-dark px-3 rounded-full border inline-flex items-center outline-none"
            (click)="onExport.emit()">
      <i class="material-icons mr-2 text-md">cloud_download</i>
      <span class="text-sm">{{'generico.export.btn' | translate}}</span>
    </button>
    <button class="hover:shadow-sm bg-grey-lightest  font-semibold text-grey-dark hover:text-orange-dark px-1 rounded-full border inline-flex items-center outline-none" [matMenuTriggerFor]="menu">
      <mat-icon>
        more_horiz
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-button color="warn" (click)="onDelete.emit()">
        <mat-icon>delete</mat-icon>
        {{delete_text}}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<app-apartado-admin [datos]="datos" [filterProps]="filter_props" [search_placeholder]="search_placeholder"
                    [list_title]="list_title" [empty]="datos_peticion.empty" [loading]="datos_peticion.pending"
                    [formDialog]="form" (onAdd)="onAdd($event)" (onDelete)="onDelete($event)" (onEdit)="onEdit($event)"
>
  <ng-template let-item="item">
    <div class="d-flex full-width" fxLayoutGap="10px">
      <div class="d-flex flex-column justify-content-center items-start">
        <span class="text-grey-darkest text-md">{{item.NOMBRE}}</span>
        <div class="flex" fxLayoutGap="10px">
          <span class="text-grey text-sm">{{item.FUENTE.NOMBRE}}</span>
          <span class="text-grey-dark text-sm font-semibold">{{item.TIPOSUBAMBITO.NOMBRE}}</span>
        </div>
      </div>
      <div class="flex-grow"></div>
      <div class="d-flex flex-column justify-content-center items-center">
        <span class="text-grey-dark flex-auto text-sm">{{'models.sbambito.DEFAULTFCONVERSION.label' | translate}}</span>
        <span class="text-grey-darkest flex-auto text-md">{{item.DEFAULTFCONVERSION}}</span>
      </div>
      <div class="d-flex flex-column justify-content-center items-center">
        <span class="text-grey-dark flex-auto text-sm">{{'models.sbambito.DEFAULTFEMISION.label' | translate}}</span>
        <span class="text-grey-darkest flex-auto text-md">{{item.DEFAULTFEMISION}}</span>
      </div>
    </div>
  </ng-template>
</app-apartado-admin>

<h2 mat-dialog-title>{{'inventario.datosinventario.createmodal.title' | translate}}</h2>
<div mat-dialog-content>
  <mat-label class="mat-form-field-label" for="tipo_ambito">{{'inventario.datosinventario.createmodal.superambito' | translate }}</mat-label>
  <br>
  <mat-radio-group [(ngModel)]="data.depende_ayto" id="tipo_ambito" class="d-flex mb-4" fxLayoutGap="10px"
                   (change)="onChangeTipo()">
    <mat-radio-button [value]="true">{{'inventario.datosinventario.createmodal.depende_ayto' | translate}}
    </mat-radio-button>
    <mat-radio-button [value]="false">{{'inventario.datosinventario.createmodal.no_depende_ayto' | translate}}
    </mat-radio-button>
  </mat-radio-group>
  <mat-form-field class="full-width">
    <mat-label for="superambitos">{{'inventario.datosinventario.createmodal.superambito' | translate }}</mat-label>
    <mat-select id="superambitos" [(value)]="data.superambitoActivo" name="superambitos"
                (selectionChange)="onChangeSuperambito()">
      <mat-option selected value="-1">{{'home.select' | translate}}</mat-option>
      <mat-option *ngFor="let m of data.superambitosSeleccionables" [value]="m">{{m.NOMBRE}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label for="ambitos">{{'inventario.datosinventario.createmodal.ambito' | translate:{ambito:
      data.superambitoActivo.NOMBRE} }}
    </mat-label>
    <mat-select [disabled]="!this.data.superambitoActivo" multiple
                id="ambitos" [(value)]="data.ambitosActivos">
      <mat-option *ngFor="let a of data.ambitosSeleccionables" [value]="a">{{a.NOMBRE}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="">{{'inventario.datosinventario.createmodal.cancel' | translate}}</button>
  <button color="accent" mat-button [mat-dialog-close]="data.ambitosActivos"
          [disabled]="data.ambitosActivos.length == 0">{{'inventario.datosinventario.createmodal.ok' |
    translate:{num:data.ambitosActivos.length} }}
  </button>
</div>

import {FormControl} from '@angular/forms';

export const forbiddenValuesValidator = (values: any[]) => {
  return (fc: FormControl) => {
    if (values.indexOf(fc.value) >= 0) {
      return ({validForbiddenValues: true});
    } else {
      return (null);
    }
  };
};
export const minValueValidator = (value: number) => {
  return (fc: FormControl) => {
    if (fc.value < value ) {
      return ({validMinValue: true});
    } else {
      return (null);
    }
  };
};
export const maxValueValidator = (value: number) => {
  return (fc: FormControl) => {
    if (value < fc.value) {
      return ({validMaxValue: true});
    } else {
      return (null);
    }
  };
};
export const betweenValueValidator = (min: number, max: number) => {
  return (fc: FormControl) => {
    if (max < fc.value || fc.value < min) {
      return ({validBetweenValue: true});
    } else {
      return (null);
    }
  };
};

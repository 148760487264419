<h2 mat-dialog-title>{{'admin.municipio.dialog.title' | translate}}</h2>
<form [formGroup]="dataForm.form" *ngIf="dataForm" novalidate>
  <div id="formulario">
    <div class="p-2 pl-3 pr-3">
      <mat-form-field class="col-lg-12" [floatLabel]="'always'">
        <mat-label class="mat-input">{{'models.usuariomunicipio.IDMUNICIPIO.label' | translate }}
        </mat-label>
        <mat-select formControlName="IDMUNICIPIO">
          <mat-option *ngFor="let t of municipios" [value]="t.ID">{{t.NOMBRE}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button [mat-dialog-close]="">{{'forms.actions.cancel' | translate}}</button>
        <button mat-button color="primary" [mat-dialog-close]="closeOk()"
                [disabled]="!dataForm.form.valid || !dataForm.form.dirty">
          {{'forms.actions.save' | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
<div *ngIf="!dataForm" class="flex justify-center align-center">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

import {Component, ContentChild, Inject, Input, OnInit, TemplateRef} from '@angular/core';
import {DialogAction, DialogResponse, ImportAction, ImportResponse} from '../../model/Utils';
import {MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, Validators} from '@angular/forms';
import {Petition} from '../../model/petition';
import {ImportacionPlantilla} from '../../model/importacionplantilla';
import {appConfig} from '../../app.config';
import {ImportService} from '../../services/import.service';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {
  file_upload = new Petition();
  import_response: ImportResponse;
  fileForm = this.fb.group({
    file: [null, Validators.required]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public action: ImportAction, private fb: FormBuilder, private impSrv: ImportService) {
  }

  ngOnInit() {
  }


  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fileForm.get('file').setValue(file);
    }
  }

  importarPlantilla() {
    this.file_upload.load();
    this.impSrv.importGeneric(this.fileForm.controls['file'].value, this.action.url).subscribe(value => {
      this.file_upload.ok(value);
      this.import_response = new ImportResponse(true, value);
    }, error2 => {
      this.file_upload.ko(error2);
      this.import_response = new ImportResponse(false, error2);
    });
  }
}

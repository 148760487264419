import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Municipio, MunicipioDB} from '../model/municipio';
import {appConfig} from '../app.config';
import {AlertService} from './alert.service';
import {BehaviorSubject} from 'rxjs';
import {RequestOptions} from '@angular/http';
import {ImportacionPlantilla} from '../model/importacionplantilla';
import {Graficas} from '../model/dashboard';

@Injectable()
export class MunicipioService {
  sel_municipio: BehaviorSubject<Municipio> = new BehaviorSubject<Municipio>(null);

  constructor(private http: HttpClient, private alertsvc: AlertService) {
  }

  getMunicipioActivo() {
    return this.sel_municipio.getValue();
  }

  getAll() {
    return this.http.get<Municipio[]>(appConfig.apiUrl + 'MUNICIPIO/all');
  }

  getById(_id: number) {
    return this.http.get<Municipio>(appConfig.apiUrl + 'MUNICIPIO/' + _id);
  }

  update(municipio: Municipio) {
    return this.http.put(appConfig.apiUrl + 'MUNICIPIO/' + municipio.ID, new MunicipioDB(municipio));
  }

  setSelectedMunicipio(id) {
    this.getById(id).subscribe(value => this.sel_municipio.next(value as Municipio), error2 => this.alertsvc.error(error2.message));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.municipio_activo = id;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  importPlantilla(fileItem) {
    const formData: FormData = new FormData();
    formData.append('files', fileItem);
    return this.http.post<ImportacionPlantilla>(appConfig.apiUrl + 'MUNICIPIO/' + this.getMunicipioActivo().ID + '/ImportarPlantilla', formData);
  }

  getGraficos(){
      return this.http.get<Graficas>(appConfig.apiUrl + 'MUNICIPIO/' + this.getMunicipioActivo().ID + '/DatosGraficos');
  }
  getGraficosReferencia(){
      return this.http.get<Graficas>(appConfig.apiUrl + 'MUNICIPIO/' + this.getMunicipioActivo().ID + '/DatosGraficosReferencia');
  }

  initialize() {
    this.sel_municipio.next(null);
  }
}

import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ImportService = /** @class */ (function () {
    function ImportService(http) {
        this.http = http;
    }
    ImportService.prototype.importGeneric = function (fileItem, url) {
        var formData = new FormData();
        formData.append('files', fileItem);
        return this.http.put(url, formData);
    };
    ImportService.prototype.exportGeneric = function (url) {
        return this.http.get(url, { 'responseType': 'blob' });
    };
    ImportService.ngInjectableDef = i0.defineInjectable({ factory: function ImportService_Factory() { return new ImportService(i0.inject(i1.HttpClient)); }, token: ImportService, providedIn: "root" });
    return ImportService;
}());
export { ImportService };

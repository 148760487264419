<h2 mat-dialog-title>{{'municipio.centros.createmodal.title' | translate}}</h2>
<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'municipio.centros.createmodal.centro' | translate }}
    </mat-label>
    <input matInput type="text" [(ngModel)]="data.centro.NOMBRE" name="nombre" min="0">
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'municipio.centros.col-cups_ele' | translate }}
    </mat-label>
    <input matInput type="text" [(ngModel)]="data.centro.CUPS_ELECTRICO" name="nombre" min="0">
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'municipio.centros.col-cups_gas' | translate }}
    </mat-label>
    <input matInput type="text" [(ngModel)]="data.centro.CUPS_GAS" name="nombre" min="0">
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'municipio.centros.col-cups_gasoleo' | translate }}
    </mat-label>
    <input matInput type="text" [(ngModel)]="data.centro.CUPS_GASOLEO" name="nombre" min="0">
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'municipio.centros.col-cups_glp' | translate }}
    </mat-label>
    <input matInput type="text" [(ngModel)]="data.centro.CUPS_GLP" name="nombre" min="0">
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'municipio.centros.col-cups_otros' | translate }}
    </mat-label>
    <input matInput type="text" [(ngModel)]="data.centro.CUPS_OTROS" name="nombre" min="0">
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="">{{'municipio.centros.createmodal.cancel' | translate}}</button>
  <button color="accent" mat-button [mat-dialog-close]="data.centro"
          [disabled]="!data.centro">{{'municipio.centros.createmodal.ok' | translate }}
  </button>
</div>

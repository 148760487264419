<form [formGroup]="dataForm.form">
  <app-admin-form [dataForm]="dataForm" [title]="'admin.municipio.dialog_title'">
    <ng-template let-form="form">
      <div class="p-2 pl-3 pr-3">
        <div class="row p-0 mt-4 mb-4">
          <mat-form-field  class="col-lg-6 col-sm-12" [floatLabel]="'always'">
            <mat-label>{{'models.municipio.NOMBRE.label' | translate }}</mat-label>
            <input type="TEXT" matInput formControlName="NOMBRE" required>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
            <mat-label>{{'models.municipio.FECHAADHESIONPACTO.label' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="FECHAADHESIONPACTO">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="row p-0 mt-4 mb-4">
          <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
            <mat-label class="mat-input">{{'models.municipio.PROVINCIA.label' | translate }}
            </mat-label>
            <mat-select formControlName="IDPROVINCIA">
              <mat-option *ngFor="let t of provincias" [value]="t.ID">{{t.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
            <mat-label class="mat-input">{{'models.municipio.COMARCA.label' | translate }}</mat-label>
            <mat-select [disabled]="form.controls['IDPROVINCIA'].value == undefined" formControlName="IDCOMARCA"
            >
              <mat-option *ngFor="let t of comarcasDisponibles()" [value]="t.ID">{{t.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
            <mat-label class="mat-input">{{'models.municipio.ZONACLIMATICA.label' | translate }}
            </mat-label>
            <mat-select formControlName="IDZONACLIMATICA" [compareWith]="compareByID">
              <mat-option *ngFor="let t of zonasclimaticas" [value]="t.ID">{{t.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row p-0 mt-4 mb-4">
          <div class="col-lg-6 hidden-sm">
            <agm-map [latitude]="form.controls['LATITUD'].value"
                     [longitude]="form.controls['LONGITUD'].value"
                     [zoom]="14"
                     [mapDraggable]="true"
                     [disableDefaultUI]="true"
                     style="height: 200px;"
                     class="mt-2 mb-2">
              <!--<agm-kml-layer url="http://global.mapit.mysociety.org/area/1029796.kml"></agm-kml-layer>-->
            </agm-map>
          </div>
          <div class="col-lg-6 col-sm-12">
            <mat-form-field class="full-width" [floatLabel]="'always'">
              <mat-label>{{'models.municipio.LATITUD.label' | translate }}</mat-label>
              <input matInput type="tel" formControlName="LATITUD" name="latitud" min="0">
            </mat-form-field>
            <mat-form-field class="full-width" [floatLabel]="'always'">
              <mat-label>{{'models.municipio.LONGITUD.label' | translate }}</mat-label>
              <input matInput type="tel" formControlName="LONGITUD" name="longitud" min="0">
            </mat-form-field>
            <mat-form-field class="full-width" [floatLabel]="'always'">
              <mat-label>{{'models.municipio.NUMHABITANTES.label' | translate }}</mat-label>
              <input type="number" matInput formControlName="NUMHABITANTES" required>
            </mat-form-field>
            <mat-form-field class="full-width" [floatLabel]="'always'">
              <mat-label>{{'models.municipio.SUPERFICIE.label' | translate }}</mat-label>
              <input type="number" matInput formControlName="SUPERFICIE" required>
              <span matSuffix>km2</span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-template>
  </app-admin-form>
</form>

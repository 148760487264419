import {Injectable} from '@angular/core';
import {TipoInventario} from '../model/inventarioemisiones';
import {appConfig} from '../app.config';
import {HttpClient} from '@angular/common/http';
import {ZonaClimatica} from '../model/zonaclimatica';
import {Comarca} from '../model/comarca';
import {Provincia} from '../model/provincia';
import {EstadoAccion} from '../model/estadoaccion';
import {PrioridadAccion} from '../model/prioridadaccion';
import {TipoAccion} from '../model/tipoaccion';
import {IndicadorAccionPred} from '../model/indicadoraccionpred';
import {Accion} from '../model/accion';
import {
  CambioRiesgo, NivelRiesgo, NivelVulnerabilidad, PeriodoRiesgo, RiesgoPred, SectorRiesgoPred,
  TipoVulnerabilidad
} from '../model/Analisis';
import {TipoDocumento} from '../model/documento';
import {RolUsuario} from '../model/rolusuario';
import {Fuente, Subambito, TipoSubambito} from '../model/subambito';
import {Superambito} from '../model/superambito';

@Injectable({
  providedIn: 'root'
})
export class ListatiposService {

  constructor(private http: HttpClient) {
  }

  fetchZonaClimatica() {
    return this.http.get<ZonaClimatica[]>(appConfig.apiUrl + 'ZonaClimatica');
  }

  fetchComarca() {
    return this.http.get<Comarca[]>(appConfig.apiUrl + 'Comarca');
  }

  fetchProvincia() {
    return this.http.get<Provincia[]>(appConfig.apiUrl + 'Provincia');
  }

  fetchTiposInventario() {
    return this.http.get<TipoInventario[]>(appConfig.apiUrl + 'TipoInventario');
  }

  fetchEstadoAccion() {
    return this.http.get<EstadoAccion[]>(appConfig.apiUrl + 'EstadoAccion');
  }

  fetchPrioridadAccion() {
    return this.http.get<PrioridadAccion[]>(appConfig.apiUrl + 'PrioridadAccion');
  }

  fetchIndicadorAccionPred() {
    return this.http.get<IndicadorAccionPred[]>(appConfig.apiUrl + 'IndicadorAccionPred');
  }

  fetchTipoAccion() {
    return this.http.get<TipoAccion[]>(appConfig.apiUrl + 'TipoAccion');
  }

  fetchAccion() {
    return this.http.get<Accion[]>(appConfig.apiUrl + 'Accion');
  }

  fetchNivelRiesgo() {
    return this.http.get<NivelRiesgo[]>(appConfig.apiUrl + 'NivelRiesgo');
  }

  fetchCambioRiesgo() {
    return this.http.get<CambioRiesgo[]>(appConfig.apiUrl + 'CambioRiesgo');
  }

  fetchPeriodoRiesgo() {
    return this.http.get<PeriodoRiesgo[]>(appConfig.apiUrl + 'PeriodoRiesgo');
  }

  fetchRiesgoPred() {
    return this.http.get<RiesgoPred[]>(appConfig.apiUrl + 'RiesgoPred');
  }

  fetchSectorRiesgoPred() {
    return this.http.get<SectorRiesgoPred[]>(appConfig.apiUrl + 'SectorRiesgoPred');
  }

  fetchNivelVulnerabilidad() {
    return this.http.get<NivelVulnerabilidad[]>(appConfig.apiUrl + 'NivelVulnerabilidad');
  }

  fetchTipoVulnerabilidad() {
    return this.http.get<TipoVulnerabilidad[]>(appConfig.apiUrl + 'TipoVulnerabilidad');
  }

  fetchTipoDocumento() {
    return this.http.get<TipoDocumento[]>(appConfig.apiUrl + 'TipoDocumento');
  }

  fetchRolUsuario() {
    return this.http.get<RolUsuario[]>(appConfig.apiUrl + 'RolUsuario');
  }

  fetchFuente() {
    return this.http.get<Fuente[]>(appConfig.apiUrl + 'Fuente');
  }
  fetchSuperambito() {
    return this.http.get<Superambito[]>(appConfig.apiUrl + 'SuperAmbito');
  }

  fetchSubambito() {
    return this.http.get<Subambito[]>(appConfig.apiUrl + 'SubAmbito');
  }

  fetchTiposSubambito() {
    return this.http.get<TipoSubambito[]>(appConfig.apiUrl + 'TipoSubAmbito');
  }
}

<h2 mat-dialog-title>{{'planaccion.deletemodal.title' | translate}}</h2>
<div mat-dialog-content>
  <span class="mat-caption">{{'planaccion.deletemodal.text' | translate:{year: data.year} }}</span>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{'planaccion.deletemodal.cancel' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="true">
    {{'planaccion.deletemodal.ok' | translate }}
  </button>
</div>


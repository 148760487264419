<h2 mat-dialog-title>{{'inventario.datosespecificos.createmodal.title' | translate}}</h2>
<div mat-dialog-content>
  <mat-label class="mat-form-field-label" for="tipo_ambito">{{'inventario.datosespecificos.createmodal.superambito' |
    translate }}
  </mat-label>
  <br>
  <mat-form-field class="full-width">
    <mat-label for="centro">{{'inventario.datosespecificos.createmodal.centro' | translate }}</mat-label>
    <mat-select id="centro" [(value)]="data.centro" name="centro">
      <mat-option selected value="-1">{{'home.select' | translate}}</mat-option>
      <mat-option *ngFor="let m of data.centros" [value]="m">{{m.NOMBRE}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="">{{'inventario.datosespecificos.createmodal.cancel' | translate}}</button>
  <button color="accent" mat-button [mat-dialog-close]="data.centro" [disabled]="!data.centro">
    {{'inventario.datosespecificos.createmodal.ok' | translate}}
  </button>
</div>

import {Component, OnInit} from '@angular/core';
import {AdminsectorformComponent} from '../adminsectorform/adminsectorform.component';
import {DialogResponse} from '../../../model/Utils';
import {AdminService} from '../../../services/admin.service';
import {Petition} from '../../../model/petition';
import {ListatiposService} from '../../../services/listatipos.service';
import {RiesgoPred} from '../../../model/Analisis';
import {AdminriesgosformComponent} from '../adminriesgosform/adminriesgosform.component';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminriesgoslist',
  templateUrl: './adminriesgoslist.component.html',
  styleUrls: ['./adminriesgoslist.component.scss']
})
export class AdminriesgoslistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE'];
  datos: RiesgoPred[];
  form = AdminriesgosformComponent;
  list_title = 'admin.analisis.riesgo.title';
  search_placeholder = 'admin.analisis.riesgo.searchplaceholder';

  constructor(private dataSrv: ListatiposService, private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.fetchRiesgoPred().subscribe(value => {
        value.sort((a, b) => ('' + a.NOMBRE).localeCompare('' + b.NOMBRE));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createRiesgoPred(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateRiesgoPred(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteRiesgoPred(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

<mat-toolbar fxLayout="row wrap" id="topbar" class="pl-md-5  pr-md-5 pl-sm-3 pr-sm-3 full-height"
             style="background: white;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex>
    <span>{{'documentos.home.title' | translate}}</span>
  </div>
  <div class="toolbar-spacer"></div>
  <button
    class="bg-grey-lightest font-semibold text-grey-dark hover:text-orange-dark px-3 rounded-full border inline-flex items-center outline-none"
    (click)="openCreateDialog()">
    <i class="material-icons mr-2 text-md">add</i>
    <span class="text-sm">{{'documentos.home.create' | translate}}</span>
  </button>
</mat-toolbar>
<div class="p-2 flex flex-col bg-white" id="panel">
  <div class="w-full flex justify-center items-center text-grey-dark p-8" *ngIf="!tipos.length && !documentos.length">
      {{'documentos.home.no-docs' | translate}}
  </div>
  <div *ngFor="let tipo of tipos" class="border m-2 flex-auto">
    <div class="flex p-2">
      <span class="text-md font-bold text-grey-darkest">{{tipo.name}}</span>
    </div>
    <div class="flex overflow-x-auto overflow-y-hidden p-2">
      <div *ngFor="let item of tipo.value"
           class="flex flex-col bg-white m-2 border hover:border-orange-lighter  rounded"
           style="max-width:130px;min-width:130px">
        <div class="flex flex-col flex-grow">
          <div class="flex justify-end items-center border-b bg-grey-lighter px-1">
            <button [matMenuTriggerFor]="menu" class="z-50 flex items-center w-8 w-8">
              <i class="material-icons text-grey-darker text-md">more_horiz</i>
            </button>
            <mat-menu #menu="matMenu" [overlapTrigger]="false">
              <a mat-menu-item (click)="openEditDialog(item)">
                {{'forms.actions.edit' | translate}}
              </a>
              <a mat-menu-item (click)="openDeleteDialog(item)">
                {{'forms.actions.delete' | translate}}
              </a>
            </mat-menu>
          </div>
          <div id="divicono" class="flex flex-col p-2 position-relative flex-auto">
            <div class="flex-auto flex justify-center align-center"
                 style="min-height:48px">
              <mat-icon *ngIf="!downloading_file_id" class="text-5xl text-grey-dark h-auto w-auto">insert_drive_file
              </mat-icon>
              <mat-spinner *ngIf="downloading_file_id == item.ID" class="text-orange" [diameter]="30"></mat-spinner>
            </div>
            <div class="flex flex-col justify-center align-center text-center">
              <span class="text-grey-dark text-xs"> {{item.FECHA_SUBIDA | date}}</span>
              <span class="text-grey-darker text-sm">{{item.NOMBRE}}</span>
            </div>
            <div id="divdescarga" class="flex justify-center items-center bg-grey-light cursor-pointer"
                 (click)="downloadFile(item)">
              <mat-icon id="icono_descarga" *ngIf="!downloading_file_id"
                        class="text-5xl text-orange-dark h-auto w-auto">
                file_download
              </mat-icon>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {appConfig} from '../app.config';
import {ImportacionPlantilla} from '../model/importacionplantilla';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private http: HttpClient) {
  }

  importGeneric(fileItem, url) {
    const formData: FormData = new FormData();
    formData.append('files', fileItem);
    return this.http.put(url, formData);
  }

  exportGeneric(url) {
    return this.http.get(url, {'responseType': 'blob'});
  }
}

import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ListatiposService} from '../../../services/listatipos.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Petition} from '../../../model/petition';
import {IndicadorVulnerabilidadformComponent} from './indicadorform.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {
  IndicadorVulnerabilidad,
  NivelVulnerabilidad, TipoVulnerabilidad, Vulnerabilidad, VulnerabilidadDB
} from '../../../model/Analisis';
import {AnalisisService} from '../../../services/analisis.service';
import {IndicadorAccion} from '../../../model/indicadoraccion';

@Component({
  selector: 'app-vulnerabilidadform',
  templateUrl: './vulnerabilidadform.component.html',
  styleUrls: ['./vulnerabilidadform.component.scss']
})
export class VulnerabilidadformComponent implements OnInit, OnChanges {
  @Input() dato: Vulnerabilidad;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  niveles: NivelVulnerabilidad[] = [];
  tipos: TipoVulnerabilidad[] = [];
  form: FormGroup;
  fetch_data: Petition = new Petition();
  editable = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dato) {
      this.load(changes.dato.currentValue);
    }
  }

  constructor(private tipoSrv: ListatiposService, private paSrv: AnalisisService, public userSrv: AuthenticationService, private formBuilder: FormBuilder, private translateService: TranslateService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    tipoSrv.fetchTipoVulnerabilidad().subscribe(value => {
      this.tipos = value;
    });
    tipoSrv.fetchNivelVulnerabilidad().subscribe(value => {
      this.niveles = value;
    });
    this.form = this.formBuilder.group({
      IDNIVELPREVISTO: null,
      IDNIVELACTUAL: null,
      IDTIPO: null,
      NOMBRE: null,
      DESCRIPCION: null,
      INDICADORVULNERABILIDAD: []
    });
    this.editable = this.userSrv.isEditable();
    if (!this.editable)
      this.form.disable();
  }

  getNivelActual(){
    return this.niveles.find(i=> i.ID === this.form.get('IDNIVELACTUAL').value) || new NivelVulnerabilidad();
  }
  getNivelPrevisto(){
    return this.niveles.find(i=> i.ID === this.form.get('IDNIVELPREVISTO').value)|| new NivelVulnerabilidad();
  }
  load(dato: Vulnerabilidad = this.dato) {
    this.fetch_data.load();
    this.paSrv.getVulnerabilidadById(dato.ID).subscribe(value => {
        this.dato = value;
        this.form.reset({
          IDNIVELPREVISTO: value.IDNIVELPREVISTO,
          IDNIVELACTUAL: value.IDNIVELACTUAL,
          IDTIPO: value.IDTIPO,
          NOMBRE: value.NOMBRE,
          DESCRIPCION: value.DESCRIPCION,
          INDICADORVULNERABILIDAD: value.INDICADORVULNERABILIDAD
        });
        this.fetch_data.ok(value);
      }
      , error2 => this.fetch_data.ko(error2));
  }

  openIndicadorDialog(dato): void {
    let accion = 'view';
    if (this.editable) {
      if (dato)
        accion = 'edit';
      else
        accion = 'create';
    }
    const dialogRef = this.dialog.open(IndicadorVulnerabilidadformComponent, {
      data: {INDICADORVULNERABILIDAD: dato, action: accion},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const indicador: IndicadorVulnerabilidad = data.data;
        let v = this.form.get('INDICADORVULNERABILIDAD').value;
        switch (data.action) {
          case 'edit':
            v[this.form.get('INDICADORVULNERABILIDAD').value.findIndex(i => i.ID == data.data.ID)] = data.data;
            break;
          case 'create':
            indicador.ID = 0;
            indicador.IDVULNERABILIDAD = this.dato.ID;
            v.push(data.data);
            break;
          case 'delete':
            v.splice(this.dato.INDICADORVULNERABILIDAD.findIndex(i => i.ID == data.data.ID), 1);
            break;
        }
        this.form.controls['INDICADORVULNERABILIDAD'].setValue(v);
        this.form.markAsDirty();
        this.load();
      }
    }, error2 => this.snackBar.open(error2, null, {duration: 3000}));
  }

  save() {
    const d = Object.assign(this.dato, this.form.value);
    let new_dato: VulnerabilidadDB = new VulnerabilidadDB(d);
    this.paSrv.updateVulnerabilidad(new_dato).subscribe(value => {
        if (value) {
          this.translateService.get('analisis.vulnerabilidades.form.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.form.markAsPristine();
          this.updated.next(value);
        }
      }
      ,
      error2 =>
        this.translateService.get('analisis.vulnerabilidades.form.update_error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}))
    );
  }

  getNivelColor(value) {
    let color = '#bdbdbd';
    switch (value) {
      case 2:
        color = '#94C14A';
        break;
      case 3:
        color = '#e3b24a';
        break;
      case 4:
        color = '#E31E1E';
        break;
      default:
        color = '#bdbdbd';
        break;
    }
    return color;
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  closeForm() {
    this.close.next();
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {forbiddenValuesValidator, maxValueValidator, minValueValidator} from '../../validators/customvalidators';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-createinventario',
  templateUrl: './createinventario.dialog.html'
})
export class CreateInventarioDialog  {
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.form = this.formBuilder.group({
      year: [null, Validators.compose(
        [
          Validators.required,
          forbiddenValuesValidator(data.years),
          minValueValidator(1999)
        ])
      ],
    });
  }
}

<form [formGroup]="dataForm.form" novalidate>
  <h2 mat-dialog-title>{{'documentos.upload.title' | translate}}</h2>
  <div mat-dialog-content fxLayout="column">
    <mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%" [floatLabel]="'always'">
      <mat-label>{{'documentos.upload.NOMBRE.label' | translate }}</mat-label>
      <input type="text" matInput formControlName="NOMBRE" required>
      <mat-error class="error-message"
                 *ngIf="dataForm.form.get('NOMBRE').hasError('required') && (dataForm.form.get('NOMBRE').dirty || dataForm.form.get('NOMBRE').touched)">
        {{'documentos.upload.NOMBRE.requiredMessage' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" [floatLabel]="'always'">
      <mat-label for="TIPODOCUMENTO">{{'documentos.upload.TIPODOCUMENTO.label' | translate }}</mat-label>
      <mat-select id="TIPODOCUMENTO" formControlName="IDTIPO" name="IDTIPO">
        <mat-option *ngFor="let m of tipos" [value]="m.ID">{{m.NOMBRE}}</mat-option>
      </mat-select>
      <mat-error class="error-message" *ngIf="dataForm.form.get('IDTIPO').hasError('required') && (dataForm.form.get('IDTIPO').dirty || dataForm.form.get('IDTIPO').touched)">
        {{'documentos.upload.TIPODOCUMENTO.requiredMessage' | translate }}
      </mat-error>
    </mat-form-field>

    <input hidden type="file" #fileInput (change)="onFileChange($event)" [max]="1" required/>
    <!--<mat-form-field class="medium-field" fxFlex.gt-md="50%" fxFlex.lt-lg="100%" [floatLabel]="'always'">-->
    <div class="d-flex flex-column mt-3 mb-3 justify-content-center">
      <mat-label *ngIf="fileInput.files.length >0" class="text-sm mb-2">{{fileInput.files[0].name}}</mat-label>
      <mat-label *ngIf="data.DOCUMENTO" class="text-sm mb-2">{{data.DOCUMENTO.FILENAME}}</mat-label>
      <button class="bg-grey-lightest font-semibold text-grey-dark hover:text-orange-dark px-2 py-2 justify-center rounded-full border inline-flex items-center outline-none"
              (click)="fileInput.click()">
        <i class="material-icons mr-2 text-md">insert_drive_file</i>
        <span class="text-sm">{{(fileInput.files.length >0 || data.action=='edit' ?
        'import.fichero.changefile' : 'import.fichero.selectfile') | translate}}</span>
      </button>
      <mat-error class="error-message"
                 *ngIf="dataForm.form.get('file').hasError('required') && (dataForm.form.get('file').dirty || dataForm.form.get('file').touched)">
        {{'documentos.upload.fichero.requiredMessage' | translate }}
      </mat-error>
    </div>
    <!--</mat-form-field>-->
  </div>
  <div mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="">
      {{'documentos.upload.cancel' | translate }}
    </button>

    <button color="primary" mat-button [mat-dialog-close]="dataForm.form.value"
            [disabled]="!dataForm.form.valid && data.action != 'edit' ">
      {{data.action == 'edit' ? ('documentos.upload.save' | translate ) : ('documentos.upload.ok' | translate )}}
    </button>
  </div>
</form>


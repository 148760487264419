import {Component, OnInit} from '@angular/core';
import {DialogAction, DialogResponse, removeDiacritics} from '../../../model/Utils';
import {Petition} from '../../../model/petition';
import {MatDialog} from '@angular/material';
import {Ambito} from '../../../model/ambito';
import {AmbitoService} from '../../../services/ambito.service';
import {AdminambitosformComponent} from '../adminambitosform/adminambitosform.component';
import {Superambito} from '../../../model/superambito';
import {AdminService} from '../../../services/admin.service';
import {AdminsuperambitosformComponent} from '../adminsuperambitosform/adminsuperambitosform.component';
import {ListatiposService} from '../../../services/listatipos.service';
import {AlertbarService} from '../../../services/alertbar.service';

@Component({
  selector: 'app-adminambitoslist',
  templateUrl: './adminambitoslist.component.html',
  styleUrls: ['./adminambitoslist.component.scss']
})
export class AdminambitoslistComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE','SUBAMBITO.NOMBRE','SUPERAMBITO.NOMBRE','SUBAMBITO.FUENTE.NOMBRE'];
  datos: Ambito[];
  form = AdminambitosformComponent;
  list_title = 'admin.inventario.ambito.title';
  search_placeholder = 'admin.inventario.ambito.searchplaceholder';

  constructor(private dataSrv: ListatiposService,private adminSrv: AdminService,private ambSrv: AmbitoService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.ambSrv.getAll().subscribe(value => {
        value.sort((a, b) => ('' + a.SUPERAMBITO.DEPENDE_AYTO).localeCompare('' + b.SUPERAMBITO.DEPENDE_AYTO));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createSuperambito(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateSuperambito(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteSuperambito(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

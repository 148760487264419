<form [formGroup]="dataForm.form">
  <app-admin-form [dataForm]="dataForm" [title]="'admin.planaccion.acciones.dialog_title'">
    <ng-template let-form="form">
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.accion.NOMBRE.label' | translate }}</mat-label>
          <input type="TEXT" matInput formControlName="NOMBRE" required>
        </mat-form-field>
        <mat-form-field class="col-lg-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.accion.CODIGO.label' | translate }}</mat-label>
          <input type="text" matInput formControlName="CODIGO" required>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-12 col-sm-12" [floatLabel]="'always'">
          <mat-label>{{'models.accion.DESCRIPCION.label' | translate }}</mat-label>
          <textarea matInput formControlName="DESCRIPCION" required></textarea>
        </mat-form-field>
      </div>
      <div class="row p-0 mt-4 mb-4">
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.accion.IDTIPO.label' | translate }}
          </mat-label>
          <mat-select formControlName="IDTIPO" [compareWith]="compareByID">
            <mat-option *ngFor="let t of tipos" [value]="t.ID">{{t.NOMBRE}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
          <mat-label class="mat-input">{{'models.accion.IDSUPERAMBITO.label' | translate }}
          </mat-label>
          <mat-select formControlName="IDSUPERAMBITO" [compareWith]="compareByID">
            <mat-option *ngFor="let t of superambitos" [value]="t.ID">{{t.NOMBRE}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
  </app-admin-form>
</form>

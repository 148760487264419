import {Component, Inject, OnInit} from '@angular/core';
import {Centro} from '../../../model/centro';
import {CentroService} from '../../../services/centro.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {MunicipioService} from '../../../services/municipio.service';
import * as FileSaver from 'file-saver';
import {Petition} from '../../../model/petition';

@Component({
  selector: 'app-mun-centros',
  templateUrl: './mun-centros.component.html',
  styleUrls: ['./mun-centros.component.scss']
})
export class MunCentrosComponent implements OnInit {

  centros: Centro[] = [];
  data_export = new Petition();
  data_update = new Petition();
  cols = [
    {
      id: 'nombre',
      title: 'municipio.centros.col-nombre',
      data: {
        prop: 'NOMBRE',
        filter: ['NOMBRE']
      },
      type: 'text',
      editable: false,
      sort: 'desc'
    },
    {
      id: 'cups',
      title: 'municipio.centros.col-cups_ele',
      data: {
        prop: 'CUPS_ELECTRICO'
      },
      type: 'text',
      editable: true
    },
    {
      id: 'cups_gas',
      title: 'municipio.centros.col-cups_gas',
      data: {
        prop: 'CUPS_GAS'
      },
      type: 'text',
      editable: true
    },
    {
      id: 'cups_gasoleo',
      title: 'municipio.centros.col-cups_gasoleo',
      data: {
        prop: 'CUPS_GASOLEO'
      },
      type: 'text',
      editable: true
    },
    {
      id: 'cups_glp',
      title: 'municipio.centros.col-cups_glp',
      data: {
        prop: 'CUPS_GLP'
      },
      type: 'text',
      editable: true
    },
    {
      id: 'cups_otros',
      title: 'municipio.centros.col-cups_otros',
      data: {
        prop: 'CUPS_OTORS'
      },
      type: 'text',
      editable: true
    }
  ];

  constructor(private munSrv: MunicipioService, private translateService: TranslateService, private dialog: MatDialog, private centroSrv: CentroService, public snackBar: MatSnackBar) {
  }

  initialize() {
    this.centros = [];
    this.loadCentros();
  }

  update(centros) {
    this.data_update.load();
    this.centroSrv.updateAll(centros).subscribe(value => {
        this.data_update.ok(value);
        this.translateService.get('municipio.centros.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.loadCentros();
      }
      , error2 => {
        this.data_update.ko(error2);
        this.translateService.get('municipio.centros.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.loadCentros();
      });
  }

  loadCentros() {
    this.centroSrv.getAll().subscribe(value => {
      if (value) {
        this.centros = value;
      }
    }, error2 => console.log(error2));
  }

  addCentro(centro) {
    const centros = this.centros.slice();
    centros.push(centro);
    this.centros = centros;
    this.update(this.centros);
  }

  deleteCentros(indexes) {
    const data = this.centros.slice();
    const sortedIndexes = indexes.sort((a, b) => b - a);
    sortedIndexes.forEach(index => data.splice(index, 1));
    this.centros = data;
    this.update(this.centros);
  }

  openNuevoCentroDialog(): void {
    let dialogRef = this.dialog.open(NuevoCentroDialog, {
      data: {centro: new Centro(this.munSrv.sel_municipio.getValue().ID)},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(centro => {
      if (centro)
        this.addCentro(centro);
    });
  }

  export() {
    this.data_export.load();
    this.centroSrv.export().subscribe((data) => {
      if (data) {
        const myBlob: Blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le'
        });
        FileSaver.saveAs(myBlob, 'Exportacion.xlsx');
        this.data_export.ok(data);
      }
    }, error2 => {
      this.data_export.ko(error2);
    });
  }

  openDeleteDialog(data): void {
    if (data) {
      const indexes = data;
      let dialogRef = this.dialog.open(DeleteCentroDialog, {
        data: {number: indexes.length},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(bool => {
        if (bool) {
          this.deleteCentros(indexes);
        }
      });
    }
  }

  ngOnInit() {
    this.initialize();
  }
}

@Component({
  selector: 'app-nuevocentrodialog',
  templateUrl: 'nuevocentro.dialog.html',
})
export class NuevoCentroDialog {
  constructor(public dialogRef: MatDialogRef<NuevoCentroDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}


@Component({
  selector: 'app-deletecentrodialog',
  templateUrl: 'deletecentro.dialog.html',
})
export class DeleteCentroDialog {
  constructor(public dialogRef: MatDialogRef<DeleteCentroDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {DialogAction, DialogResponse} from '../../model/Utils';

@Component({
  selector: 'deletedialog',
  templateUrl: 'delete.dialog.html',
})
export class DeleteDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDialog>,
              @Inject(MAT_DIALOG_DATA) public action: DialogAction) {
  }
  closeDelete(){
      return new DialogResponse(DialogResponse.DELETE, this.action.data);
  }
  close(){
    return new DialogResponse(DialogResponse.NONE, false);
  }
}

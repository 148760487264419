<app-apartado-admin [datos]="datos" [filterProps]="filter_props" [search_placeholder]="search_placeholder"
                    [list_title]="list_title" [empty]="datos_peticion.empty" [loading]="datos_peticion.pending"
                    [formDialog]="form" (onAdd)="onAdd($event)" (onDelete)="onDelete($event)" (onEdit)="onEdit($event)"
>
  <ng-template let-item="item">
    <div class="d-flex full-width" fxLayoutGap="10px">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <span class="color-grey-700 text-lg-b">{{item.NOMBRE}}</span>
      </div>
      <div class="flex-grow-1"></div>
      <div class="d-flex justify-content-end align-items-center" fxLayoutGap="10px">
        <div class="d-flex flex-column justify-content-center align-items-end">
          <span class="text-md-m color-grey-600">{{item.COMARCA.PROVINCIA.NOMBRE}} </span>
          <span class="text-md color-grey-500">{{item.COMARCA.NOMBRE }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</app-apartado-admin>

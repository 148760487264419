import {Component, OnInit} from '@angular/core';
import {MunicipioService} from '../../services/municipio.service';
import {AdminmunicipioformComponent} from '../adminmunicipio/adminmunicipioform/adminmunicipioform.component';
import {DialogAction, DialogResponse} from '../../model/Utils';
import {Petition} from '../../model/petition';
import {Municipio} from '../../model/municipio';
import {MatDialog} from '@angular/material';
import {Usuario} from '../../model/usuario';
import {UserService} from '../../services/user.service';
import {AdminusuarioformComponent} from './adminusuarioform/adminusuarioform.component';
import {AdminusuariomunicipioComponent} from './adminusuariomunicipio/adminusuariomunicipio.component';
import {AlertbarService} from '../../services/alertbar.service';

@Component({
  selector: 'app-adminusuario',
  templateUrl: './adminusuario.component.html',
  styleUrls: ['./adminusuario.component.scss']
})
export class AdminusuarioComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  accion_peticion: Petition = new Petition();
  datos: Usuario[];
  list_title = 'admin.usuario.title';
  search_placeholder = 'admin.usuario.searchplaceholder';
  filterText = '';
  selectedUser: Usuario = null;

  constructor(private dataSrv: UserService, private dialog: MatDialog, private alert: AlertbarService) {
    this.load();
    this.alert.applyToPetition(this.accion_peticion);
  }

  load(){
    this.datos_peticion.load();
    this.dataSrv.getAll().subscribe(value => {
        value.sort((a: Usuario, b: Usuario) => ('' + a.USERID).localeCompare(b.USERID));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onSearch(text) {
    this.filterText = text;
  }

  getDatos() {
    if (this.filterText)
      return this.datos.filter(d => {
        var proptext = this.nestedObj(d, 'USERID');
        if (proptext) {
          return this.nestedObj(d, 'USERID').toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0;
        } else {
          return true;
        }
      });
    else
      return this.datos;
  }

  ngOnInit() {
  }

  onDialogClose(data: DialogResponse) {
    switch (data.action) {
      case DialogResponse.ADD: {
        this.accion_peticion.load();
        this.dataSrv.create(data.data).subscribe(value => {
            this.accion_peticion.ok(value);
            this.load();
          },
          error2 => {
            this.accion_peticion.ko(error2);
          });
        break;
      }
      case DialogResponse.EDIT: {
        this.accion_peticion.load();
        this.dataSrv.update(data.data).subscribe(value => {
            this.accion_peticion.ok(value);
            this.load();
          },
          error2 => {
            this.accion_peticion.ko(error2);
          });
        break;
      }
      case DialogResponse.DELETE: {
        this.accion_peticion.load();
        this.dataSrv.delete(data.data.ID).subscribe(value => {
            this.accion_peticion.ok(value);
            this.load();
          },
          error2 => {
            this.accion_peticion.ko(error2);
          });
        break;
      }
    }
  }

  add(item) {
    this.openDialog(DialogAction.CREATE, item);
  }

  editMunicipios(item: Usuario) {
    if (this.selectedUser)
      if (this.selectedUser.USERID == item.USERID)
        this.selectedUser = null;
      else
        this.selectedUser = item;
    else this.selectedUser = item;

  }

  isAdmin(item : Usuario){
    return item.ROL == 0;
  }

  select(item) {
    this.openDialog(DialogAction.EDIT, item);
  }

  openDialog(action: number, data: Municipio): void {
    const dialogRef = this.dialog.open(AdminusuarioformComponent, {
      data: new DialogAction(action, data),
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      this.onDialogClose(data);
    });
  }

  nestedObj(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

}

<div class="full-height border ">
  <div *ngIf="fetch_data.pending && !fetch_data.finished"
       class="d-flex flex-column full-height justify-content-center align-items-center fin-elevation"
       style="background: white;">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
  <div *ngIf="fetch_data.finished && fetch_data.success" class="d-flex flex-column full-height   fin-elevation"
       style="background: white;">
    <div id="topbar" class="d-flex bg-grey-lightest" style="min-height: 48px;" fxLayoutGap="5px">
      <div class="d-flex justify-content-center align-items-center pl-2">
        <button size="small" *ngIf="form.dirty && editable"
                class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-4 rounded-full border inline-flex items-center outline-none ng-star-inserted"
                (click)="save()">
          <i class="material-icons mr-2 text-md">save</i>
          <span class="text-sm">{{'planaccion.acciones.form.save' | translate }}</span>
        </button>
      </div>
      <div class="flex-grow-1"></div>
      <div class="d-flex justify-content-center align-items-center pr-1">
        <button *ngIf="this.userSrv.isEditable()" size="small"
                class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
                [matMenuTriggerFor]="menu">
          <i class="material-icons text-md">more_horiz</i>
        </button>
        <button size="small"
                class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
                (click)="closeForm()">
          <i class="material-icons text-md">close</i>
        </button>
      </div>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="delete.next(dato)">
          <mat-icon>delete</mat-icon>
          {{'planaccion.acciones.form.delete' | translate }}
        </button>
      </mat-menu>
    </div>
    <form [formGroup]="form" novalidate class="pl-4 pr-4 pb-4" style="overflow-y: auto;">
      <div fxLayout="column" class="pt-2 mb-4">
        <div class="d-flex justify-content-start align-items-center">
          <span class="mat-title">{{dato.ACCION.NOMBRE | capitalize}}</span>
        </div>
        <div class="d-flex justify-content-start align-items-center pl-2 pr-2" fxLayoutGap="10px">
          <button mat-mini-fab class="mat-elevation-z1"
                  [style.background]="(dato.ACCION.TIPOACCION.ID == 1 ? '#e67e5e' : '#1eabd5')"
                  [style.color]="'white'">
            <mat-icon>flag</mat-icon>
          </button>
          <div class="d-flex flex-column">
            <strong id="codigo" class="mr-4 dark">{{dato.ACCION.CODIGO}}</strong>
            <span class="grey" id="tipo">{{dato.ACCION.TIPOACCION.NOMBRE | capitalize}}</span>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div class="row m-0">
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="estadoaccion">{{'planaccion.acciones.form.estadoaccion' | translate }}</mat-label>
            <mat-select id="estadoaccion" formControlName="ESTADOACCION" [compareWith]="compareByID"
                        [disabled]="!editable">
              <mat-option *ngFor="let m of estados_accion" [value]="m">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="prioridadaccion">{{'planaccion.acciones.form.prioridadaccion' | translate }}</mat-label>
            <mat-select id="prioridadaccion" formControlName="PRIORIDADACCION" [compareWith]="compareByID"
                        [disabled]="!editable">
              <mat-option *ngFor="let m of prioridades_accion" [value]="m">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="anyo">{{'planaccion.acciones.form.anyo' | translate }}</mat-label>
            <input id="anyo" matInput formControlName="ANYOINICIO" [attr.disabled]="!editable"/>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="anyoobjetivo">{{'planaccion.acciones.form.anyoobjetivo' | translate }}</mat-label>
            <input id="anyoobjetivo" matInput formControlName="ANYOOBJETIVO" [attr.disabled]="!editable"/>
          </mat-form-field>
          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="CODIGOADICIONAL">{{'planaccion.acciones.form.CODIGOADICIONAL' | translate }}</mat-label>
            <input id="CODIGOADICIONAL" matInput formControlName="CODIGOADICIONAL" [attr.disabled]="!editable"/>
          </mat-form-field>
          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="OBSERVACIONES">{{'planaccion.acciones.form.OBSERVACIONES' | translate }}</mat-label>
            <textarea id="OBSERVACIONES" matInput formControlName="OBSERVACIONES"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column">
        <p class="full-width section-title">{{'planaccion.acciones.form.seccion-inversion' | translate}}</p>
        <div class="row m-0">
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="inversionestimada">{{'planaccion.acciones.form.inversionestimada' | translate }}</mat-label>
            <input [attr.disabled]="!editable"
                   id="inversionestimada" matInput formControlName="INVERSIONESTIMADA"/>
            <span matSuffix>{{'' | localizedCurrencySymbol}}</span>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="ahorroestimado">{{'planaccion.acciones.form.ahorroestimado' | translate }}</mat-label>
            <input #ahorroestimado [attr.disabled]="!editable"
                   id="ahorroestimado" matInput formControlName="AHORROESTIMADO"/>
            <span matSuffix>{{'' | localizedCurrencySymbol}}</span>
          </mat-form-field>
          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="AYUDAS">{{'planaccion.acciones.form.AYUDAS' | translate }}</mat-label>
            <textarea id="AYUDAS" matInput formControlName="AYUDAS"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column" class="align-items-center">
        <p class="full-width section-title">{{'planaccion.acciones.form.seccion-energia' | translate}}</p>
        <div class="row m-0">
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="reduccionemisiones">{{'planaccion.acciones.form.reduccionemisiones' | translate }}
            </mat-label>
            <input [attr.disabled]="!editable"
                   id="reduccionemisiones" matInput formControlName="REDUCCIONEMISIONES"/>
            <span matSuffix>tCO2</span>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="ahorroenergeticoestimado">{{'planaccion.acciones.form.ahorroenergeticoestimado' | translate
              }}
            </mat-label>
            <input [attr.disabled]="!editable"
                   id="ahorroenergeticoestimado" matInput formControlName="AHORROENERGETICOESTIMADO"/>
            <span matSuffix>MWh</span>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="pesoemisionesambito">{{'planaccion.acciones.form.pesoemisionesambito' | translate }}
            </mat-label>
            <input [attr.disabled]="!editable"
                   id="pesoemisionesambito" matInput formControlName="PESOEMISIONESAMBITO"/>
            <span matSuffix>%</span>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="pesoemisionestotales">{{'planaccion.acciones.form.pesoemisionestotales' | translate }}
            </mat-label>
            <input [attr.disabled]="!editable"
                   id="pesoemisionestotales" matInput formControlName="PESOEMISIONESTOTALES"/>
            <span matSuffix>%</span>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="produccionenergia">{{'planaccion.acciones.form.produccionenergia' | translate }}
            </mat-label>
            <input [attr.disabled]="!editable"
                   id="produccionenergia" matInput formControlName="PRODUCCIONENERGIAESTIMADA"/>
            <span matSuffix>MWh</span>
          </mat-form-field>
        </div>

        <div id="rentabilidad" *ngIf="form.get('INVERSIONESTIMADA').value > 0" fxLayout="column"
             [style.background-color]="getRentabilidadColor()"
             fxFlexAlign="center center" class="">
          <span class="align-self-start text-md color-grey-50">{{'dashboard.card-planaccion.charts.rentabilidad.title' | translate}}</span>
          <span class="text-lg-m color-grey-0">{{(form.get('INVERSIONESTIMADA').value ? (form.get('REDUCCIONEMISIONES').value/form.get('INVERSIONESTIMADA').value)*1000 : 0) | localizedNumber }}</span>
          <small class="text-sm align-self-end color-grey-100">kgCO2/€</small>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column" class="pt-3" [style.max-height.px]="500">
        <div class="d-flex align-items-center mb-3">
          <div>
            <span class="full-width section-title">{{'planaccion.acciones.form.seccion-indicadores' | translate}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div *ngIf="editable" class="flex-shrink-0">
            <button mat-icon-button (click)="openIndicadorDialog(null)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <app-list [scrollableY]="false" [datos]="dato.INDICADORACCION">
          <mat-list-item *ngFor="let item of dato.INDICADORACCION" class="p-2 flex-grow-1 list-item"
                         (click)="openIndicadorDialog(item)">
            <div class="d-flex justify-content-start align-items-center">
              <span id="nombre">{{item.NOMBRE}}</span>
            </div>
            <div class="flex-grow-1"></div>
            <div class="d-flex flex-column justify-content-end align-items-right text-right">
              <span class="dark dato">{{item.VALOR}}</span>
              <span class="mat-caption grey">{{item.MAGNITUD}}</span>
            </div>
          </mat-list-item>
          <div *ngIf="dato.INDICADORACCION.length==0"
               class="full-height justify-content-center align-items-center p-4" fxLayout="column" fxLayoutGap="15px">
            <mat-icon class="large-icon color-grey-400">inbox</mat-icon>
            <span class="mat-caption">{{'planaccion.acciones.form.indicadores.empty_text' | translate}}</span>
            <button
              class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center"
              (click)="openIndicadorDialog(null)">
              <span class="text-md">{{'planaccion.acciones.form.indicadores.create_text' | translate}}</span>
            </button>
          </div>
        </app-list>
      </div>
    </form>
  </div>
</div>

<div class="p-3 full-height" id="panel">
  <div class="mb-3 d-flex justify-content-end">
    <button mat-button color="accent" class="align-self-end" (click)="openFactoresDialog()">
      <span>{{'inventario.datosinventario.factores' | translate}}</span>
    </button>
  </div>
  <app-editable *ngIf="datosInventarios && data_fetch.success" [cols]="cols" [data]="datosInventarios"
                (deleteCallback)="openDeleteDialog($event)" (save)="update_dato_inventario($event)" [saveone]="true"
                datatype="Dato inventario" datatypePlural="Datos inventario" [hasAdd]="true"
                (addCallback)="openNuevoDatoInventarioDialog()" [hasDelete]="true"></app-editable>
  <div *ngIf="data_fetch.pending"
       class="d-flex justify-content-center align-items-center full-height align-items-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</div>

import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ListatiposService} from '../../../services/listatipos.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Petition} from '../../../model/petition';
import {IndicadorRiesgoformComponent} from './indicadorform.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {CambioRiesgo, IndicadorRiesgo, NivelRiesgo, PeriodoRiesgo, Riesgo, RiesgoDB, RiesgoPred} from '../../../model/Analisis';
import {AnalisisService} from '../../../services/analisis.service';

@Component({
  selector: 'app-riesgoform',
  templateUrl: './riesgoform.component.html',
  styleUrls: ['./riesgoform.component.scss']
})
export class RiesgoformComponent implements OnInit, OnChanges {
  @Input() dato: Riesgo;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  nombres_pred: RiesgoPred[];
  niveles: NivelRiesgo[] = [];
  cambios: CambioRiesgo[] = [];
  periodos: PeriodoRiesgo[] = [];
  form: FormGroup;
  fetch_data: Petition = new Petition();
  editable = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dato) {
      this.load(changes.dato.currentValue);
    }
  }

  constructor(private tipoSrv: ListatiposService, private paSrv: AnalisisService, public userSrv: AuthenticationService, private formBuilder: FormBuilder, private translateService: TranslateService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    tipoSrv.fetchCambioRiesgo().subscribe(value => {
      this.cambios = value;
    });
    tipoSrv.fetchNivelRiesgo().subscribe(value => {
      this.niveles = value;
    });
    tipoSrv.fetchRiesgoPred().subscribe(value => {
      this.nombres_pred = value;
    });
    tipoSrv.fetchPeriodoRiesgo().subscribe(value => {
      this.periodos = value;
    });
    this.form = this.formBuilder.group({
      IDNIVEL: null,
      IDNIVELPREVISTO: null,
      IDCAMBIOINTENSIDAD: null,
      IDCAMBIOFRECUENCIA: null,
      IDPERIODO: null,
      SECTOR: null,
      NOMBRE: null,
      INDICADORRIESGO: [],
      OBSERVACIONES: null
    });
    this.editable = this.userSrv.isEditable();
    if (!this.editable)
      this.form.disable();
  }

  load(dato: Riesgo = this.dato) {
    this.fetch_data.load();
    this.paSrv.getRiesgoById(dato.ID).subscribe(value => {
        this.dato = value;
        this.form.reset({
          IDNIVEL: value.IDNIVEL,
          IDNIVELPREVISTO: value.IDNIVELPREVISTO,
          IDCAMBIOINTENSIDAD: value.IDCAMBIOINTENSIDAD,
          IDCAMBIOFRECUENCIA: value.IDCAMBIOFRECUENCIA,
          IDPERIODO: value.IDPERIODO,
          SECTOR: value.SECTOR,
          NOMBRE: value.NOMBRE,
          INDICADORRIESGO: value.INDICADORRIESGO,
          OBSERVACIONES: value.OBSERVACIONES,
        });
        this.fetch_data.ok(value);
      }
      , error2 => this.fetch_data.ko(error2));
  }

  getNivel() {
    return this.niveles.find(i => i.ID === this.form.get('IDNIVEL').value).NOMBRE || '';
  }
  getNivelByID(id) {
    return this.niveles.find(i => i.ID === id).NOMBRE || '';
  }

  getMarcoTemporal() {
    return this.periodos.find(i => i.ID === this.form.get('IDPERIODO').value).NOMBRE || '';
  }

  getIntensidad(): CambioRiesgo {
    return this.cambios.find(i => i.ID === this.form.get('IDCAMBIOINTENSIDAD').value) || new CambioRiesgo();
  }

  getFrecuencia(): CambioRiesgo {
    return this.cambios.find(i => i.ID === this.form.get('IDCAMBIOFRECUENCIA').value) || new CambioRiesgo();
  }

  getCambioIcon(value) {
    let icon = 'help';
    switch (value) {
      case 4:
        icon = 'trending_flat';
        break;
      case 3:
        icon = 'trending_down';
        break;
      case 2:
        icon = 'trending_up';
        break;
      default:
        icon = 'help_outline';
        break;
    }
    return icon;
  }

  openIndicadorDialog(dato): void {
    let accion = 'view';
    if (this.editable) {
      if (dato)
        accion = 'edit';
      else
        accion = 'create';
    }
    const dialogRef = this.dialog.open(IndicadorRiesgoformComponent, {
      data: {INDICADORRIESGO: dato, action: accion},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const indicador: IndicadorRiesgo = data.data;
        let v = this.form.get('INDICADORRIESGO').value;
        switch (data.action) {
          case 'edit':
            v[this.form.get('INDICADORRIESGO').value.findIndex(i => i.ID == indicador.ID)] = indicador;
            break;
          case 'create':
            indicador.ID = 0;
            indicador.IDRIESGO = this.dato.ID;
            v.push(indicador);
            break;
          case 'delete':
            v.splice(this.dato.INDICADORRIESGO.findIndex(i => i.ID == indicador.ID), 1);
            break;
        }
        this.form.controls['INDICADORRIESGO'].setValue(v);
        this.form.markAsDirty();
        // this.load();
      }
    }, error2 => this.snackBar.open(error2, null, {duration: 3000}));
  }

  save() {
    const d = Object.assign(this.dato, this.form.value);
    let new_dato: RiesgoDB = new RiesgoDB(d);
    this.paSrv.updateRiesgo(new_dato).subscribe(value => {
        if (value) {
          this.translateService.get('analisis.riesgos.form.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.form.markAsPristine();
          this.updated.next(value);
        }
      }
      ,
      error2 =>
        this.translateService.get('analisis.riesgos.form.update_error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}))
    );
  }

  getNivelColor(value) {
    let color = '#bdbdbd';
    switch (value) {
      case 2:
        color = '#94C14A';
        break;
      case 3:
        color = '#e3b24a';
        break;
      case 4:
        color = '#E31E1E';
        break;
      default:
        color = '#bdbdbd';
        break;
    }
    return color;
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  closeForm() {
    this.close.next();
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {
  MatDialog,
  MatPaginator,
  MatPaginatorIntl,
  MatSort,
  Sort
} from "@angular/material";
import { DataSource, SelectionModel } from "@angular/cdk/collections";
import { BehaviorSubject, fromEvent, merge, Observable } from "rxjs/index";
import { debounceTime, map } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { TranslateService } from "@ngx-translate/core";
import { nestedObj } from "../../model/Utils";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "app-editable",
  templateUrl: "./editable.component.html",
  styleUrls: ["./editable.component.scss"]
})
export class EditableComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() cols: any[] = [];
  @Input() data;
  @Input() datatype = "Dato";
  @Input() datatypePlural = "Datos";
  @Input() loading: boolean = false;
  @Input() selectable = true;
  @Input() hasExport = false;
  @Input() exporting = false;
  @Input() hasImport = false;
  @Input() importing = false;
  @Input() hasDelete = false;
  @Input() saveone = false;
  @Input() hasAdd = false;
  @Output() importCallback: EventEmitter<any> = new EventEmitter();
  @Output() exportCallback: EventEmitter<any> = new EventEmitter();
  @Output() addCallback: EventEmitter<any> = new EventEmitter();
  @Output() deleteCallback: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("filter") filter: ElementRef;

  dataSource: TableDataSource | null;
  columnsToDisplay = [];
  selection = new SelectionModel<number>(true, []);
  active_row_edit = null;
  original_row_edit = null;

  constructor(
    public dialog: MatDialog,
    private pagIntl: MatPaginatorIntl,
    private translateSrv: TranslateService,
    public authSrv: AuthenticationService
  ) {}

  ngOnInit() {}

  log(val) {}

  hasFilter() {
    return this.cols.filter(c => c.data.filter).length > 0;
  }

  ngAfterViewInit() {
    if (this.filter) {
      const filterEvent = fromEvent(this.filter.nativeElement, "keyup");
      filterEvent.pipe(debounceTime(150));
      filterEvent.pipe(distinctUntilChanged());
      filterEvent.subscribe(i => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
    }
  }

  isEditable() {
    return (
      this.cols.filter(c => c.editable == true).length > 0 &&
      this.authSrv.isEditable()
    );
  }

  isAllSelected(): boolean {
    if (!this.dataSource.data) {
      return false;
    }
    if (this.selection.isEmpty()) {
      return false;
    }
    return this.selection.selected.length === this.data.length;
  }

  masterToggle() {
    if (!this.dataSource.data) {
      return;
    }
    if (this.isAllSelected()) {
      this.selection.clear();
    } else if (this.filter.nativeElement.value) {
      this.dataSource.renderedData.forEach(data =>
        this.selection.toggle(this.getDataIndex(data))
      );
    } else {
      this.dataSource.data.forEach(data =>
        this.selection.toggle(this.getDataIndex(data))
      );
    }
  }

  getSelectedCount() {
    return this.selection.selected.length;
  }

  getDataIndex(data) {
    return this.dataSource.data.indexOf(data);
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  removeRows() {
    this.deleteCallback.emit(this.selection.selected);
  }

  editRow(row, index) {
    if (this.authSrv.isEditable()) {
      this.active_row_edit = row;
      this.original_row_edit = Object.assign({}, row);
    } else {
      this.active_row_edit = null;
      this.original_row_edit = Object.assign({}, null);
    }
  }

  cancelEditRow(row, index) {
    const d = this.data.slice();
    d[index] = this.original_row_edit;
    this.data = d;
    this.active_row_edit = null;
    this.original_row_edit = null;
  }

  finishEditRow(row, index) {
    const d = this.data.slice();
    d[index] = row;
    this.data = d;
    this.active_row_edit = null;
    this.original_row_edit = null;
    this.save.emit(this.saveone ? row : this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.selection.clear();
      this.dataSource = new TableDataSource(
        this.data,
        this.paginator,
        this.sort,
        this.cols
          .filter(c => c.data.filter)
          .map(c => {
            return c.data.filter;
          })
          .reduce((previous, current) => {
            if (previous) return previous.concat(current);
            else return [].concat(current);
          }, 0),
        this.cols
      );
      this.translateSrv
        .get("editable.pagination.itemsPerPageLabel")
        .subscribe(d => (this.pagIntl.itemsPerPageLabel = d));
      this.translateSrv
        .get("editable.pagination.firstPageLabel")
        .subscribe(d => (this.pagIntl.firstPageLabel = d));
      this.translateSrv
        .get("editable.pagination.lastPageLabel")
        .subscribe(d => (this.pagIntl.lastPageLabel = d));
      this.translateSrv
        .get("editable.pagination.nextPageLabel")
        .subscribe(d => (this.pagIntl.nextPageLabel = d));
      this.translateSrv
        .get("editable.pagination.previousPageLabel")
        .subscribe(d => (this.pagIntl.previousPageLabel = d));
      this.translateSrv.get("editable.pagination.of").subscribe(
        d =>
          (this.pagIntl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
          ) => {
            if (length == 0 || pageSize == 0) {
              return `0 ${d} ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex =
              startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} ${d} ${length}`;
          })
      );
    }
    if (changes.cols) {
      let columns = [];
      if (this.selectable && this.authSrv.isEditable()) {
        columns.push("select");
      }
      columns = columns.concat(this.cols.map(c => c.id));
      if (this.authSrv.isEditable()) {
        columns.push("acciones");
      }
      this.columnsToDisplay = columns;
    }
  }

  nestedObj(o, s) {
    return nestedObj(o, s);
  }
}

export class TableDataSource extends DataSource<any> {
  get data(): any[] {
    return this.dataChange.value;
  }

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  _filterChange = new BehaviorSubject("");
  filterCols = [];

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: any[] = [];
  renderedData: any[] = [];

  constructor(
    private _data: any[],
    private _paginator: MatPaginator,
    private _sort: MatSort,
    private _filterCols: string[],
    private _cols: any[]
  ) {
    super();
    this.dataChange.next(_data);
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
    this.filterCols = _filterCols;
  }

  getColValueByID(name, obj) {
    const col = this._cols.find(c => c.id === name);
    switch (col.type) {
      case "function":
        return col.data.func(obj);
    }
    return nestedObj(obj, col.data.prop);
  }

  getColByID(name) {
    const col = this._cols.find(c => c.id === name);
    return col.data.prop;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.data.slice().filter((item: any) => {
          if (!this.filterCols) return true;
          const searchStr = this.filterCols
            .map(f => nestedObj(item, f))
            .join("")
            .toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        });

        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());

        // Grab the page's slice of the filtered sorted data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this._paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  sortData(data: any[]): any[] {
    if (!this._sort.active || this._sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = "";
      let propertyB: number | string = "";
      [propertyA, propertyB] = [
        this.getColValueByID(this._sort.active, a),
        this.getColValueByID(this._sort.active, b)
      ];
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === "asc" ? 1 : -1)
      );
    });
  }
}

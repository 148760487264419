<div class="h-full row m-0" id="panel">
  <div *ngIf="datos" [class]="activeFormID ? 'col-md-7 p-3 h-full ' : 'col-md-8 p-3 offset-md-2 h-full '">
    <app-advancedlist ngClass="h-full" [datos]="datos" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canAdd]="true" (onAdd)="openNuevoDatoDialog()"   [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending" [canClick]="true" (onClick)="selectAccion($event)"
    >
      <ng-template let-item="item">
        <div class="item-hcoaccion d-flex flex-column full-width" fxLayout="column" fxLayoutGap="10px">
          <div class="d-flex" fxLayoutGap="10px">
            <div class="d-flex justify-content-start align-items-center">
              <div class="rounded-full border w-10 h-10 py-1 px-1 bg-grey-lightest flex justify-center items-center"
                      [style.color]="(item.ACCION.TIPOACCION.ID == 1 ? '#e67e5e' : '#1eabd5')">
                <mat-icon>flag</mat-icon>
              </div>
            </div>
            <div class="d-flex flex-column">
              <strong id="codigo" class="mr-4 dark">{{item.ACCION.CODIGO}}</strong>
              <span class="text-sm color-grey-600">{{item.ACCION.TIPOACCION.NOMBRE | capitalize}}</span>
            </div>
            <div class="flex-grow-1"></div>
            <mat-chip-list>
              <mat-chip class="accion-chip text-white" [style.background]="getRentabilidadColor(item)" selected>
                {{(getRentabilidadText(item) | translate) | capitalize}}
              </mat-chip>
              <!-- <mat-chip class="accion-chip" [style.background]="getChipColor(0,item.PRIORIDADACCION.ID)" selected>
                 {{item.PRIORIDADACCION.NOMBRE | capitalize}}
               </mat-chip>-->
              <mat-chip class="accion-chip  text-white" [style.background]="getChipColor(1,item.ESTADOACCION.ID)" selected>
                {{item.ESTADOACCION.NOMBRE | capitalize}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="d-flex">
            <div class="d-flex flex-column">
              <span class="text-lg-m color-grey-800 mt-2 mb-2">{{item.ACCION.NOMBRE | capitalize}}</span>
            </div>
            <div class="flex-grow-1"></div>
            <div class="d-flex justify-content-end align-items-center" fxLayoutGap="10px">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <span class="text-sm color-grey-600"> {{'planaccion.acciones.list.item.inversion' | translate}} </span>
                <span class="text-lg-m color-grey-700">{{item.INVERSIONESTIMADA | localizedCurrency}} </span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <span class="text-sm color-grey-600"> {{'planaccion.acciones.list.item.emisiones' | translate}} </span>
                <span class="text-lg-m color-grey-700">{{item.REDUCCIONEMISIONES | localizedNumber: 'tCO2' }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <span *ngIf="item.INDICADORACCION.length>0" class="text-sm color-grey-600">{{item.INDICADORACCION.length}} {{'planaccion.acciones.list.item.indicadores' | translate}} </span>
            <div class="flex-grow-1"></div>
            <div fxLayout="row" class="align-items-center justify-content-end rounded-full bg-grey-lightest border px-2 py-1 " fxLayoutGap="2px">
              <mat-icon [style.color]="getPeriodoColor(item.IDPRIORIDADACCION)">access_time</mat-icon>
              <span class="text-sm text-grey-darker">{{item.PRIORIDADACCION.NOMBRE | capitalize}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
  </div>
  <div id="side-form" *ngIf="datos && activeFormID" class="col-md-5 p-3 h-full">
    <app-accionform [dato]="getActiveDato()" (close)="closeForm()" (updated)="updateAccion($event)"
                    (delete)="openDeleteDialog($event)">
    </app-accionform>
  </div>
</div>

<!--


-->

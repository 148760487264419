import {Component, Inject, OnInit} from '@angular/core';
import {AccionForm, SuperambitoForm} from '../../Forms';
import {Ambito} from '../../../model/ambito';
import {DialogAction} from '../../../model/Utils';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Accion} from '../../../model/accion';
import {ListatiposService} from '../../../services/listatipos.service';
import {Petition} from '../../../model/petition';

@Component({
  selector: 'app-adminaccionesform',
  templateUrl: './adminaccionesform.component.html',
  styleUrls: ['./adminaccionesform.component.scss']
})
export class AdminaccionesformComponent implements OnInit {
  dato: Accion;
  dataForm: AccionForm;
  superambitos_peticion = new Petition();
  tipo_peticion = new Petition();
  superambitos = [];
  tipos = [];

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, private dataSrv: ListatiposService) {
    this.fetchSuperambitos();
    this.fetchTipos();
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new AccionForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new AccionForm(action);
    }
  }

  fetchSuperambitos(){
    this.superambitos_peticion.load();
    this.dataSrv.fetchSuperambito().subscribe(value => {
      this.superambitos = value;
      this.superambitos_peticion.ok(value);
    }, error2 => {
      this.superambitos_peticion.ko(error2);
    })
  }
  fetchTipos(){
    this.tipo_peticion.load();
    this.dataSrv.fetchTipoAccion().subscribe(value => {
      this.tipos = value;
      this.tipo_peticion.ok(value);
    }, error2 => {
      this.tipo_peticion.ko(error2);
    })
  }
  ngOnInit() {
  }


  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { appConfig } from "../app.config";
import { BiService } from "../services/bi.service";
import { Session } from "../model/session";
import { interval, Observable, Subject } from "rxjs";
import { flatMap } from "tslint/lib/utils";
import { switchMap, takeUntil } from "rxjs/internal/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthenticationService } from "../services/authentication.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-bi",
  templateUrl: "./bi.component.html",
  styleUrls: ["./bi.component.scss"]
})
export class BiComponent implements OnInit, OnDestroy {
  session: Session;
  url: SafeResourceUrl;
  private onDestroy$ = new Subject<void>();
  sesiones_activa: Session[];
  qvActive = false;

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  constructor(
    private router: Router,
    private location: Location,
    private srv: BiService,
    private sanitizer: DomSanitizer,
    public authSrv: AuthenticationService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.srv.getSession().subscribe(
      value => {
        this.session = value.sesion;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          value.redirect_url
        );
        console.log(value.redirect_url);
        if(value.redirect_url){
          this.qvActive = true;
          window.open(value.redirect_url, '_blank')
        }
        interval(5 * 60 * 1000)
          .pipe(
            switchMap(() => this.srv.mantenerSession()),
            takeUntil(this.onDestroy$)
          )
          .subscribe(
            value2 => {
              this.session = value2;
            },
            error2 => this.openDialog()
          );
      },
      error2 => {
        this.openDialog();
      }
    );
  }

  goBack() {
    this.srv.finalizarSession().subscribe();
    this.router.navigateByUrl("/");
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NoSessionsDialog, {});

    dialogRef.afterClosed().subscribe(result => {
      if (!result) this.goBack();
    });
  }
}

@Component({
  selector: "nosessions_dialog",
  templateUrl: "nosessions.dialog.html"
})
export class NoSessionsDialog {
  constructor(
    public dialogRef: MatDialogRef<NoSessionsDialog>,
    public authSrv: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

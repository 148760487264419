<div class="mb-2" [class.full-height]="fullHeight">
  <div id="list-container" class="d-flex flex-column full-height rounded border">
    <div *ngIf="!simple" class="d-flex pl-4 pr-4  border-b bg-grey-lightest" style="min-height: 48px;">
      <div class="d-flex align-items-center justify-content-start" fxLayoutGap="10px">
        <button *ngIf="add && this.authSrv.isEditable()"
                class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2"
                (click)="addCallback.next($event)">
          <i class="material-icons text-md">add</i>
        </button>
      </div>
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <span class="mat-caption text-uppercase color-grey-500">{{title | translate}}</span>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <button *ngIf="orders" mat-button color="primary" [matMenuTriggerFor]="menuSort">
          <mat-icon>sort</mat-icon>
          {{'planaccion.acciones.list.order' | translate}}
        </button>
        <div *ngIf="search" class="shadow-inner bg-white rounded-full flex -mx-2 py-1 px-2 border">
          <mat-icon class="text-grey">search</mat-icon>
          <div>
            <input #searchinput type="search" class="bg-white text-grey-darkest text-sm outline-none" matInput
                   [placeholder]="searchPlaceholder | translate" (change)="onSearch($event)">
          </div>
          <mat-icon class="text-grey cursor-pointer" *ngIf="searchText" (click)="onClearSearch(searchinput)">clear
          </mat-icon>
        </div>
        <mat-menu  #menuSort="matMenu" [overlapTrigger]="false">
          <div click-stop-propagation fxLayoutGap="5px" *ngFor="let f of orders; let i = index" [attr.data-index]="i"
               class="d-flex justify-content-center align-items-center p-2 filter-row"
               [class.active]="activeOrder.pos === i">
            <span class="flex-grow-1">{{ f.text | translate}}</span>
            <div>
              <button mat-icon-button (click)="selectOrder(f,i,1)" class="direction-btn text-center"
                      [class.active]="activeOrder.pos === i && activeOrder.dir === 1">
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button mat-icon-button (click)="selectOrder(f,i,-1)" class="direction-btn text-center"
                      [class.active]="activeOrder.pos === i && activeOrder.dir === -1">
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <mat-list id="list" class="flex-grow-1" style="overflow-y: auto;" [style.max-height.px]="maxHeight">
      <ng-content *ngIf="!loading"></ng-content>
      <div *ngIf="loading" class="full-height d-flex flex-column justify-content-center align-items-center">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </mat-list>
  </div>
</div>

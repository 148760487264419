import {Component, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {MunicipioService} from '../services/municipio.service';
import {Municipio} from '../model/municipio';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSidenav} from '@angular/material';
import {Router} from '@angular/router';
import {Accion} from '../model/accion';
import {map, startWith} from 'rxjs/internal/operators';
import {Observable} from 'rxjs/index';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  municipios: Municipio[] = [];
  municipio_activo: Municipio;
  opened: boolean;
  mobile: boolean;

  ngOnInit() {
    this.municipio_activo = null;
    this.munsrv.sel_municipio.subscribe(value => {
        if (value)
          this.municipio_activo = value;
      },
      error2 => console.log(error2));
    this.munsrv.getAll().subscribe(value => {
      if (value) {
        this.municipios = value;
        if (value.length === 1) {
          this.munsrv.setSelectedMunicipio(value[0].ID);
        } else {
          if (!this.auth.currentUser.municipio_activo)
            this.openDialog();
          else
            this.munsrv.setSelectedMunicipio(this.auth.currentUser.municipio_activo);
        }
      }
    }, error2 => {
      console.log(error2);
    });

    if (window.innerWidth < 1400) {
      this.sidenav.close();
      this.opened = false;
      this.mobile = true;
    }
    if (window.innerWidth > 1400) {
      this.sidenav.open();
      this.opened = true;
      this.mobile = false;
    }
  }

  constructor(public dialog: MatDialog, public auth: AuthenticationService, public munsrv: MunicipioService, private router: Router, private translateService: TranslateService) {


  }

  isAdmin() {
    return this.auth.isAdmin();
  }

  setLang(lang) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.sidenav.close();
      this.opened = false;
      this.mobile = true;
    }
    if (event.target.innerWidth > 768) {
      this.sidenav.open();
      this.opened = true;
      this.mobile = false;
    }
  }

  getTitle() {
    const parentRoute = this.router.url.split('/')[1];
    switch (parentRoute) {
      case 'inventarios':
        return 'home.misinventarios';
      case 'municipio':
        return 'home.municipio';
    }
  }

  logout() {
    this.auth.logout();
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }


  openDialog(): void {
    let dialogRef = this.dialog.open(SelectMunicipioDialog, {
      disableClose: true,
      data: {municipios: this.municipios ? this.municipios : [], id_municipio: this.municipio_activo ? this.municipio_activo.ID : null}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.munsrv.setSelectedMunicipio(result);
      this.router.navigateByUrl('/dashboard');
    });
  }
}

@Component({
  selector: 'selectmunicipiodialog',
  templateUrl: 'selectmunicipio.dialog.html',
})
export class SelectMunicipioDialog {
  munCtrl: FormControl;
  filtered: Observable<any[]>;
  empty = true;

  constructor(public dialogRef: MatDialogRef<SelectMunicipioDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.munCtrl = new FormControl(data.municipios ? data.municipios[0] : new Municipio());
    if (data.municipios.length > 0) {
      this.empty = false;
      data.municipios.sort((a, b) => a.NOMBRE - b.NOMBRE);
      this.filtered = this.munCtrl.valueChanges
        .pipe(
          startWith(''),
          map(state => state ? this.filter(state) : this.data.municipios.slice())
        );
    }
    else {
      this.empty = true;
    }
  }


  filter(name: string | Municipio) {
    if (typeof name == 'string')
      return this.data.municipios.filter(state =>
        state.NOMBRE.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }

  display(c): string {
    return c ? c.NOMBRE : c;
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {appConfig} from '../app.config';
import {Analisis} from '../model/Analisis';
import {Session, GetSessionResponse} from '../model/session';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BiService {

  session_id: number;
  constructor(private http: HttpClient) { }

  getAPIUrl() {
    return appConfig.apiUrl + 'SESION/';
  }

  getSession(){
    return this.http.get<GetSessionResponse>(this.getAPIUrl());
  }

  mantenerSession(){
    return this.http.put<Session>(this.getAPIUrl(), {});
  }

  finalizarSession(){
    return this.http.delete<Session>(this.getAPIUrl());
  }

}


<div [class]="selectedUser ? 'container-fluid p-3' : 'container p-3'">
  <div class="flex" fxLayoutGap="20px">
    <div class="flex-auto">
      <app-list *ngIf="!datos_peticion.empty" [datos]="datos" [simple]="false" [title]="list_title"
                (addCallback)="add($event)" [add]="true" (searchCallback)="onSearch($event)" [search]="true" [searchPlaceholder]="search_placeholder">
        <mat-list-item *ngFor="let item of getDatos()" class="p-2 flex-grow-1 list-item cursor-default">
          <div class="flex full-width align-center" fxLayoutGap="20px">
            <div class="flex flex-column justify-center align-center flex-grow">
              <span class="text-grey-darkest text-lg-b">{{item.USERID}}</span>
            </div>
            <div class="flex flex-column justify-center align-start">
              <span class="text-sm text-grey">{{'admin.usuario.item.rol' | translate}}</span>
              <span class="text-grey-darkest text-md">{{'extra.rolusuario.'+item.ROL | translate}}</span>
            </div>
            <div class="d-flex justify-content-end align-items-center" fxLayoutGap="10px">
              <div class="flex flex-column justify-center align-start">
                <span class="text-sm text-grey">{{'admin.usuario.item.permiso' | translate}}</span>
                <div class="flex items-center justify-center">
                  <span *ngIf="item.ROLUSUARIO.CANEDIT" class="text-md text-grey-darkest">{{'admin.usuario.item.permisoescritura' | translate}}</span>
                  <span *ngIf="!item.ROLUSUARIO.CANEDIT" class="text-md text-grey-darkest">{{'admin.usuario.item.permisolectura' | translate}}</span>
                </div>
              </div>
            </div>
            <button class="flex justify-center bg-grey-dark rounded shadow-sm items-center w-16 cursor-pointer hover:bg-grey-darker" (click)="select(item)">
              <mat-icon class="text-white ">edit</mat-icon>
            </button>
            <button *ngIf="!isAdmin(item)" class="flex justify-center bg-grey-dark rounded shadow-sm items-center w-16 cursor-pointer hover:bg-grey-darker" [class.background-accent]="selectedUser ? item.USERID == selectedUser.USERID : false" (click)="editMunicipios(item)">
              <span class="text-white mr-1 pt-2 font-medium">{{item.ROL == 0 ? '*' : item.USUARIOMUNICIPIOS.length}}</span>
              <mat-icon class="text-white ">location_city</mat-icon>
            </button>
            <button *ngIf="isAdmin(item)" class="flex justify-center bg-white text-grey-dark rounded items-center w-16">
              <span class="text-grey-dark mr-1 pt-2 font-medium">{{item.ROL == 0 ? '*' : item.USUARIOMUNICIPIOS.length}}</span>
              <mat-icon class="text-grey-dark">location_city</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </app-list>
    </div>
    <div class="flex-auto" *ngIf="selectedUser">
      <app-adminusuariomunicipio [user]="selectedUser" (onChange)="load()"></app-adminusuariomunicipio>
    </div>
  </div>
  <div *ngIf="datos_peticion.pending" class="full-height full-width d-flex justify-content-center align-items-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</div>

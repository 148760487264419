import {Municipio} from './municipio';
import {HCOAccion, HCOAccionDB} from './hcoaccion';
export class PlanAccion {
  ID: number;
  IDMUNICIPIO: number;
  MUNICIPIO: Municipio;
  ANYO: number;
  IDPADRE: number;
  ANYOREFERENCIAINVENTARIO: number;
  ANYOREFERENCIARIESGOS: number;
  ANYOOBJETIVO: number;
  OBJETIVOCONSUMO: number;
  OBJETIVOPRODUCCION: number;
  OBJETIVOEMISION: number;
  OBJETIVOREDUCCIONCONSUMO: number;
  OBJETIVOREDUCCIONPRODUCCION: number;
  OBJETIVOREDUCCIONEMISION: number;
  HCO_ACCION: HCOAccion[];

  constructor(obj = {}) {
    Object.assign(this, obj);
  }
  toDB() {
    return new PlanAccionDB(this);
  }
}

export class PlanAccionDB {
  ID: number;
  IDMUNICIPIO: number;
  ANYO: number;
  IDPADRE: number;
  ANYOREFERENCIAINVENTARIO: number;
  ANYOREFERENCIARIESGOS: number;
  ANYOOBJETIVO: number;
  OBJETIVOCONSUMO: number;
  OBJETIVOPRODUCCION: number;
  OBJETIVOEMISION: number;
  OBJETIVOREDUCCIONCONSUMO: number;
  OBJETIVOREDUCCIONPRODUCCION: number;
  OBJETIVOREDUCCIONEMISION: number;
  HCO_ACCION: HCOAccionDB[];

  constructor(d: PlanAccion) {
    this.ID =  d.ID;
    this.IDMUNICIPIO = d.IDMUNICIPIO;
    this.ANYO = d.ANYO;
    this.IDPADRE = d.IDPADRE;
    this.ANYOREFERENCIAINVENTARIO = d.ANYOREFERENCIAINVENTARIO;
    this.ANYOREFERENCIARIESGOS = d.ANYOREFERENCIARIESGOS;
    this.ANYOOBJETIVO = d.ANYOOBJETIVO;
    this.OBJETIVOCONSUMO = d.OBJETIVOCONSUMO;
    this.OBJETIVOPRODUCCION = d.OBJETIVOPRODUCCION;
    this.OBJETIVOEMISION = d.OBJETIVOEMISION;
    this.OBJETIVOREDUCCIONCONSUMO = d.OBJETIVOREDUCCIONCONSUMO;
    this.OBJETIVOREDUCCIONPRODUCCION = d.OBJETIVOREDUCCIONPRODUCCION;
    this.OBJETIVOREDUCCIONEMISION = d.OBJETIVOREDUCCIONEMISION;
    this.HCO_ACCION = d.HCO_ACCION ? d.HCO_ACCION.map(d => new HCOAccion(d).toDB()) : [];
  }
}

export class PlanAccionForm {
  ANYO: number;
  ANYOREFERENCIAINVENTARIO: number;
  ANYOREFERENCIARIESGOS: number;
  ANYOOBJETIVO: number;
  OBJETIVOCONSUMO: number;
  OBJETIVOPRODUCCION: number;
  OBJETIVOEMISION: number;
  OBJETIVOREDUCCIONCONSUMO: number;
  OBJETIVOREDUCCIONPRODUCCION: number;
  OBJETIVOREDUCCIONEMISION: number;

  constructor(d: PlanAccion) {
    this.ANYO = d.ANYO;
    this.ANYOREFERENCIAINVENTARIO = d.ANYOREFERENCIAINVENTARIO;
    this.ANYOREFERENCIARIESGOS = d.ANYOREFERENCIARIESGOS;
    this.ANYOOBJETIVO = d.ANYOOBJETIVO;
    this.OBJETIVOCONSUMO = d.OBJETIVOCONSUMO;
    this.OBJETIVOPRODUCCION = d.OBJETIVOPRODUCCION;
    this.OBJETIVOEMISION = d.OBJETIVOEMISION;
    this.OBJETIVOREDUCCIONCONSUMO = d.OBJETIVOREDUCCIONCONSUMO;
    this.OBJETIVOREDUCCIONPRODUCCION = d.OBJETIVOREDUCCIONPRODUCCION;
    this.OBJETIVOREDUCCIONEMISION = d.OBJETIVOREDUCCIONEMISION;
  }
}

<div class="p-4 container full-height">
  <div class="offset-lg-2 col-lg-8 col-sm-12 mt-2 border rounded bg-white" >
    <form [formGroup]="form" (ngSubmit)="form.valid && guardar(form.value)" novalidate>
      <div id="formulario">
        <div class="p-5">
          <mat-form-field [floatLabel]="'always'">
            <mat-label>{{'municipio.datosgenerales.form.fechaadhesionpacto.label' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="fechaadhesionpacto">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <div class="row p-0 mt-4 mb-4">
            <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
              <mat-label class="mat-input">{{'municipio.datosgenerales.form.provincia.label' | translate }}
              </mat-label>
              <mat-select formControlName="idprovincia" name="idprovincia">
                <mat-option *ngFor="let t of provincias" [value]="t.ID">{{t.NOMBRE}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
              <mat-label class="mat-input">{{'municipio.datosgenerales.form.comarca.label' | translate }}</mat-label>
              <mat-select [disabled]="!form.controls['idprovincia'].value || auth.isEditable()" formControlName="idcomarca" name="idcomarca">
                <mat-option *ngFor="let t of comarcasDisponibles()" [value]="t.ID">{{t.NOMBRE}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field class="col-lg-6 col-sm-12" [floatLabel]="'always'">
            <mat-label class="mat-input">{{'municipio.datosgenerales.form.zonaclimatica.label' | translate }}
            </mat-label>
            <mat-select formControlName="idzonaclimatica" name="zonaclimatica" [compareWith]="compareByID">
              <mat-option *ngFor="let t of zonasclimaticas" [value]="t.ID">{{t.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="row p-0 mt-4 mb-4">
            <div class="col-lg-6 hidden-sm">
              <agm-map [latitude]="form.controls['latitud'].value"
                       [longitude]="form.controls['longitud'].value"
                       [zoom]="12"
                       [mapDraggable]="true"
                       [disableDefaultUI]="true"
                       style="height: 200px;"
                       class="mt-2 mb-2">
                <!--<agm-kml-layer url="http://global.mapit.mysociety.org/area/1029796.kml"></agm-kml-layer>-->
              </agm-map>
            </div>
            <div class="col-lg-6 col-sm-12">
              <mat-form-field class="full-width" [floatLabel]="'always'">
                <mat-label>{{'municipio.datosgenerales.form.latitud.label' | translate }}</mat-label>
                <input matInput type="tel" formControlName="latitud" name="latitud" min="0">
              </mat-form-field>
              <mat-form-field class="full-width" [floatLabel]="'always'">
                <mat-label>{{'municipio.datosgenerales.form.longitud.label' | translate }}</mat-label>
                <input matInput type="tel" formControlName="longitud" name="longitud" min="0">
              </mat-form-field>
              <mat-form-field class="full-width" [floatLabel]="'always'">
                <mat-label>{{'municipio.datosgenerales.form.numhabitantes.label' | translate }}</mat-label>
                <input type="number" matInput formControlName="NUMHABITANTES" required>
              </mat-form-field>
              <mat-form-field class="full-width" [floatLabel]="'always'">
                <mat-label>{{'municipio.datosgenerales.form.superficie.label' | translate }}</mat-label>
                <input type="number" matInput formControlName="SUPERFICIE" required>
                <span matSuffix>km2</span>
              </mat-form-field>
            </div>
          </div>
          <div id="formchanged">
            <span *ngIf="form.dirty">{{'inventario.datosgenerales.form.dirty' | translate}}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center  mt-2">
            <button *ngIf="auth.isEditable()" class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center"
                    type="submit"
                    [disabled]="!form.valid || !form.dirty">
              <i *ngIf="!data_update.pending" class="material-icons text-md mr-2">save</i>
              <mat-spinner color="warn" class=" mr-2" *ngIf="data_update.pending" [diameter]="20" value="indeterminate"></mat-spinner>
              <span class="text-md">{{'forms.actions.save' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

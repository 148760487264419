<div class="full-height row m-0" id="panel">
  <div *ngIf="datos" [class]="activeFormID ? 'col-md-7 p-3' : 'col-md-8 p-3 offset-md-2'">
    <app-advancedlist [datos]="datos" [canSearch]="true" [searchProps]="search_props" [searchPlaceholder]="search_placeholder" [canAdd]="true" (onAdd)="openNuevoDatoDialog()"   [canEdit]="false" [canDelete]="false" [canSort]="true" [sorters]="sorters" [loading]="data_fetch.pending" [canClick]="true" (onClick)="selectAccion($event)"
    >
      <ng-template let-item="item">
        <div class="full-width" fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutGap="10px" class="align-items-center align-self-end w-full">
              <div fxLayout="row" fxLayoutGap="5px" style="min-width: 130px;" class="align-items-center">
                <div class="d-flex justify-content-center align-items-center icono-cambio border-all">
                  <mat-icon>{{getCambioIcon(item.CAMBIOFRECUENCIA.ID)}}</mat-icon>
                </div>
                <div fxLayout="column" class="align-items-start justify-content-center">
                  <span class="text-sm color-grey-500">{{'analisis.riesgos.list.item.frecuencia' | translate}}</span>
                  <span class="text-sm-m color-grey-700">{{item.CAMBIOFRECUENCIA.NOMBRE}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="5px" style="min-width: 130px;" class="align-items-center">
                <div class="d-flex justify-content-center align-items-center icono-cambio border-all">
                  <mat-icon>{{getCambioIcon(item.CAMBIOINTENSIDAD.ID)}}</mat-icon>
                </div>
                <div fxLayout="column" class="align-items-start justify-content-center">
                  <span class="text-sm color-grey-500">{{'analisis.riesgos.list.item.intensidad' | translate}}</span>
                  <span class="text-sm-m color-grey-700">{{item.CAMBIOINTENSIDAD.NOMBRE}}</span>
                </div>
              </div>
            </div>
            <div fxLayout="column">
              <span class="sector-title">{{item.SECTOR}}</span>
              <span class="text-lg-m color-grey-800">{{item.NOMBRE | capitalize}}</span>
              <span *ngIf="item.INDICADORRIESGO.length > 0" class="indicador-title">{{item.INDICADORRIESGO.length}} {{'analisis.riesgos.list.item.indicadores' | translate}}</span>
            </div>
          </div>
          <div class="flex-grow-1"></div>
          <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutGap="15px" class="justify-content-end" style="min-width: 220px;">
              <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
                <button mat-mini-fab class="mat-elevation-z1"
                        [style.background]="getNivelColor(item.IDNIVEL)"
                        [style.color]="'white'">
                  <mat-icon>priority_high</mat-icon>
                </button>
                <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
                  <span
                    class="text-sm color-grey-600">{{'analisis.vulnerabilidades.list.item.nivelactual' | translate}}</span>
                  <span class="text-sm-m color-grey-700">{{item.NIVELRIESGO.NOMBRE}}</span>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <mat-icon class="fecha-icono">arrow_forward</mat-icon>
              </div>
              <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
                <button mat-mini-fab class="mat-elevation-z1"
                        [style.background]="getNivelColor(item.IDNIVELPREVISTO)"
                        [style.color]="'white'">
                  <mat-icon>priority_high</mat-icon>
                </button>
                <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
                  <span class="text-sm color-grey-600">{{'analisis.vulnerabilidades.list.item.nivelprevisto'| translate}}</span>
                  <span class="text-sm-m color-grey-700">{{item.NIVELRIESGOPREVISTO.NOMBRE}}</span>
                </div>
              </div>
            </div>
            <div fxLayout="row" class="align-items-center justify-content-end" fxLayoutGap="2px">
              <mat-icon [style.color]="getPeriodoColor(item.IDPERIODO)">access_time</mat-icon>
              <span class="text-sm-m color-grey-500">{{item.PERIODORIESGO.NOMBRE | capitalize}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-advancedlist>
  </div>
  <div id="side-form" *ngIf="datos && activeFormID" class="col-md-5 p-3">
    <app-riesgoform [dato]="getActiveDato()" (close)="closeForm()" (updated)="updateRiesgo($event)"
                    (delete)="openDeleteDialog($event)">
    </app-riesgoform>
  </div>
</div>


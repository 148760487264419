import { appConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ListatiposService = /** @class */ (function () {
    function ListatiposService(http) {
        this.http = http;
    }
    ListatiposService.prototype.fetchZonaClimatica = function () {
        return this.http.get(appConfig.apiUrl + 'ZonaClimatica');
    };
    ListatiposService.prototype.fetchComarca = function () {
        return this.http.get(appConfig.apiUrl + 'Comarca');
    };
    ListatiposService.prototype.fetchProvincia = function () {
        return this.http.get(appConfig.apiUrl + 'Provincia');
    };
    ListatiposService.prototype.fetchTiposInventario = function () {
        return this.http.get(appConfig.apiUrl + 'TipoInventario');
    };
    ListatiposService.prototype.fetchEstadoAccion = function () {
        return this.http.get(appConfig.apiUrl + 'EstadoAccion');
    };
    ListatiposService.prototype.fetchPrioridadAccion = function () {
        return this.http.get(appConfig.apiUrl + 'PrioridadAccion');
    };
    ListatiposService.prototype.fetchIndicadorAccionPred = function () {
        return this.http.get(appConfig.apiUrl + 'IndicadorAccionPred');
    };
    ListatiposService.prototype.fetchTipoAccion = function () {
        return this.http.get(appConfig.apiUrl + 'TipoAccion');
    };
    ListatiposService.prototype.fetchAccion = function () {
        return this.http.get(appConfig.apiUrl + 'Accion');
    };
    ListatiposService.prototype.fetchNivelRiesgo = function () {
        return this.http.get(appConfig.apiUrl + 'NivelRiesgo');
    };
    ListatiposService.prototype.fetchCambioRiesgo = function () {
        return this.http.get(appConfig.apiUrl + 'CambioRiesgo');
    };
    ListatiposService.prototype.fetchPeriodoRiesgo = function () {
        return this.http.get(appConfig.apiUrl + 'PeriodoRiesgo');
    };
    ListatiposService.prototype.fetchRiesgoPred = function () {
        return this.http.get(appConfig.apiUrl + 'RiesgoPred');
    };
    ListatiposService.prototype.fetchSectorRiesgoPred = function () {
        return this.http.get(appConfig.apiUrl + 'SectorRiesgoPred');
    };
    ListatiposService.prototype.fetchNivelVulnerabilidad = function () {
        return this.http.get(appConfig.apiUrl + 'NivelVulnerabilidad');
    };
    ListatiposService.prototype.fetchTipoVulnerabilidad = function () {
        return this.http.get(appConfig.apiUrl + 'TipoVulnerabilidad');
    };
    ListatiposService.prototype.fetchTipoDocumento = function () {
        return this.http.get(appConfig.apiUrl + 'TipoDocumento');
    };
    ListatiposService.prototype.fetchRolUsuario = function () {
        return this.http.get(appConfig.apiUrl + 'RolUsuario');
    };
    ListatiposService.prototype.fetchFuente = function () {
        return this.http.get(appConfig.apiUrl + 'Fuente');
    };
    ListatiposService.prototype.fetchSuperambito = function () {
        return this.http.get(appConfig.apiUrl + 'SuperAmbito');
    };
    ListatiposService.prototype.fetchSubambito = function () {
        return this.http.get(appConfig.apiUrl + 'SubAmbito');
    };
    ListatiposService.prototype.fetchTiposSubambito = function () {
        return this.http.get(appConfig.apiUrl + 'TipoSubAmbito');
    };
    ListatiposService.ngInjectableDef = i0.defineInjectable({ factory: function ListatiposService_Factory() { return new ListatiposService(i0.inject(i1.HttpClient)); }, token: ListatiposService, providedIn: "root" });
    return ListatiposService;
}());
export { ListatiposService };

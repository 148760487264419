import {Component, OnInit} from '@angular/core';
import {MunicipioService} from '../../services/municipio.service';
import {Municipio, MunicipioDB} from '../../model/municipio';
import {Petition} from '../../model/petition';
import {AdminmunicipioformComponent} from './adminmunicipioform/adminmunicipioform.component';
import {DialogResponse} from '../../model/Utils';
import {AdminService} from '../../services/admin.service';
import {AlertbarService} from '../../services/alertbar.service';

@Component({
  selector: 'app-adminmunicipio',
  templateUrl: './adminmunicipio.component.html',
  styleUrls: ['./adminmunicipio.component.scss']
})
export class AdminmunicipioComponent implements OnInit {
  datos_peticion: Petition = new Petition();
  add_peticion: Petition = new Petition();
  edit_peticion: Petition = new Petition();
  delete_peticion: Petition = new Petition();
  filter_props = ['NOMBRE','PROVINCIA.NOMBRE','PROVINCIA.COMARCA.NOMBRE'];
  datos: Municipio[];
  form = AdminmunicipioformComponent;
  list_title = 'admin.municipio.title';
  search_placeholder = 'admin.municipio.searchplaceholder';

  constructor(private dataSrv: MunicipioService,private adminSrv: AdminService, private alert: AlertbarService) {
    this.alert.applyToPetition(this.add_peticion);
    this.alert.applyToPetition(this.edit_peticion);
    this.alert.applyToPetition(this.delete_peticion);
    this.load();
  }

  load() {
    this.datos_peticion.load();
    this.dataSrv.getAll().subscribe(value => {
        value.sort((a, b) => ('' + a.NOMBRE).localeCompare('' + b.NOMBRE));
        this.datos = value;
        this.datos_peticion.ok(value);
      },
      error2 => {
        this.datos_peticion.ko(error2);
      }
    );
  }

  onAdd(data: DialogResponse) {
    this.add_peticion.load();
    this.adminSrv.createMunicipio(data.data).subscribe(value => {
        this.add_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.add_peticion.ko(error2);
      });
  }

  onEdit(data: DialogResponse) {
    this.edit_peticion.load();
    this.adminSrv.updateMunicipio(data.data).subscribe(value => {
        this.edit_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.edit_peticion.ko(error2);
      });
  }

  onDelete(data: DialogResponse) {
    this.delete_peticion.load();
    this.adminSrv.deleteMunicipio(data.data).subscribe(value => {
        this.delete_peticion.ok(value);
        this.load();
      },
      error2 => {
        this.delete_peticion.ko(error2);
      });
  }

  ngOnInit() {
  }

}

import {CurrencyPipe, DatePipe} from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedCurrencySymbol',
  pure: false
})
export class LocalizedCurrencySymbolPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, code: string = '€'): any {
    const currencyPipe: CurrencyPipe = new CurrencyPipe(this.translateService.currentLang);
    const v = currencyPipe.transform(1, code).split(' ');
    return v[v.length-1].trim();
  }

}


<div class="container p-3">
  <div class="flex" fxLayoutGap="20px">
    <div class="flex-auto">
      <app-list *ngIf="!empty" [datos]="datos" [simple]="false" [title]="list_title"
                (addCallback)="add($event)" [add]="true" (searchCallback)="onSearch($event)" [search]="true" [searchPlaceholder]="search_placeholder">
        <mat-list-item *ngFor="let item of getDatos()" class="p-2 flex-grow-1 list-item cursor-default" (click)="clickable ? select(item) : null">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }">
          </ng-container>
        </mat-list-item>
      </app-list>
    </div>
  </div>
  <div *ngIf="loading" class="full-height full-width d-flex justify-content-center align-items-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {FuenteForm, ZonaClimaticaForm} from '../../Forms';
import {DialogAction} from '../../../model/Utils';
import {Fuente} from '../../../model/subambito';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ListatiposService} from '../../../services/listatipos.service';
import {ZonaClimatica} from '../../../model/zonaclimatica';

@Component({
  selector: 'app-adminzonaclimaticaform',
  templateUrl: './adminzonaclimaticaform.component.html',
  styleUrls: ['./adminzonaclimaticaform.component.scss']
})
export class AdminzonaclimaticaformComponent implements OnInit {
  dato: ZonaClimatica;
  dataForm: ZonaClimaticaForm;

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction) {
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new ZonaClimaticaForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new ZonaClimaticaForm(action);
    }
  }

  ngOnInit() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

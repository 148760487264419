<div
  class="p-5 d-flex align-items-center justify-content-center"
  style="height: 100vh"
>
  <mat-horizontal-stepper
    [linear]="true"
    #stepper
    class="border-all flex-grow-1  d-flex flex-column "
    style="height: 100%;"
  >
    <mat-step [stepControl]="fileForm" class="row full-height">
      <form
        [formGroup]="fileForm"
        class="offset-md-3 col-md-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center align-items-center full-height"
      >
        <ng-template matStepLabel>{{
          "import.fichero.title" | translate
        }}</ng-template>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <div
            *ngIf="!file_upload.success && !file_upload.error"
            class="text-center"
          >
            <mat-icon
              class="ionicon-large ion-ios-cloud-upload m-4"
              color="accent"
            ></mat-icon>
            <p class="mat-body-2">{{ "import.text" | translate }}</p>
            <div
              class="justify-content-center align-items-center"
              fxLayout="column"
              fxLayoutGap="15px"
            >
              <span
                class="mat-body-2 color-grey-400"
                *ngFor="let file of fileInput.files"
              >
                {{ file.name }}
              </span>
              <button
                class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center"
                (click)="fileInput.click()"
              >
                <span class="text-md">{{
                  (fileInput.files.length > 0
                    ? "import.fichero.changefile"
                    : "import.fichero.selectfile") | translate
                }}</span>
              </button>
            </div>
          </div>
          <div *ngIf="file_upload.error" class="text-center">
            <mat-icon color="warn" class="large-icon ion-md-close-circle">
            </mat-icon>
            <p class="mat-body-2">
              {{ "import.fichero.import_ko" | translate }}
            </p>
            <div class="border bg-grey-lightest p-2 mt-2">
              <p class="text-sm">{{ import_response.error }}</p>
            </div>
          </div>
          <div *ngIf="file_upload.success" class="text-center">
            <mat-icon color="accent" class="large-icon">
              check_circle
            </mat-icon>
            <p class="mat-body-2">
              {{ "import.fichero.import_ok" | translate }}
            </p>
          </div>
          <input
            type="file"
            hidden
            #fileInput
            (change)="onFileChange($event)"
            [max]="1"
          />
          <table class="mt-4 buttons-table">
            <td *ngIf="file_upload.error">
              <button mat-raised-button color="warn" (click)="reset()">
                {{ "import.reset" | translate }}
              </button>
            </td>
            <td *ngIf="fileInput.files.length > 0 && !file_upload.success">
              <button
                mat-raised-button
                color="accent"
                (click)="importarPlantilla()"
              >
                {{ "import.fichero.upload" | translate }}
              </button>
            </td>
            <td *ngIf="file_upload.success">
              <button color="accent" mat-raised-button matStepperNext>
                {{ "import.fichero.next" | translate }}
              </button>
            </td>
          </table>
        </div>
      </form>
    </mat-step>
    <mat-step
      *ngIf="file_upload.success && imported_data.CENTROS.length > 0"
      [stepControl]="centrosForm"
    >
      <form
        [formGroup]="centrosForm"
        class="text-center d-flex flex-column justify-content-center align-items-center full-height"
      >
        <ng-template matStepLabel>{{
          "import.centros.title" | translate
        }}</ng-template>

        <app-simpletable
          class="mt-4 mb-4 h-128"
          *ngIf="file_upload.success"
          [cols]="centrosCols"
          [data]="imported_data.CENTROS"
          datatype="Centro"
          datatypePlural="Centros"
        ></app-simpletable>
        <mat-checkbox color="primary" formControlName="ok">{{
          "import.data_ok" | translate
        }}</mat-checkbox>
        <button
          class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center"
          [disabled]="!centrosForm.controls['ok'].value"
          (click)="saveCentros()"
        >
          <span class="text-md" *ngIf="!centros_save.pending">{{
            "import.centros.save" | translate
          }}</span>
          <span class="text-md" *ngIf="centros_save.pending">{{
            "import.centros.saving" | translate
          }}</span>
          <mat-spinner
            color="warn"
            *ngIf="centros_save.pending"
            [diameter]="20"
            value="indeterminate"
          ></mat-spinner>
        </button>
        <table class="mt-4 buttons-table">
          <td *ngIf="!centrosForm.controls['ok'].value">
            <button mat-button color="warn" (click)="reset()">
              {{ "import.reset" | translate }}
            </button>
          </td>
          <td>
            <button mat-raised-button color="primary" matStepperPrevious>
              {{ "import.back" | translate }}
            </button>
          </td>
          <td>
            <button
              mat-raised-button
              color="accent"
              [disabled]="!centros_save.success"
              type="submit"
              matStepperNext
            >
              {{ "import.next" | translate }}
            </button>
          </td>
        </table>
      </form>
    </mat-step>
    <mat-step
      *ngIf="
        file_upload.success && imported_data.INVENTARIO_EMISIONES.length > 0
      "
      [stepControl]="invForm"
    >
      <form
        [formGroup]="invForm"
        class="text-center d-flex flex-column justify-content-center align-items-center full-height"
      >
        <ng-template matStepLabel>{{
          "import.inventario.title" | translate
        }}</ng-template>
        <app-simpletable
          *ngIf="file_upload.success && !inv_save.done"
          [cols]="invCols"
          [data]="imported_data.INVENTARIO_EMISIONES"
          datatype="Inventario emisiones"
          datatypePlural="Inventarios emisiones"
        ></app-simpletable>
        <mat-checkbox
          color="primary"
          *ngIf="!inv_save.done && !inv_save.error"
          formControlName="ok"
          >{{ "import.data_ok" | translate }}
        </mat-checkbox>

        <div *ngIf="inv_save.error" class="text-center">
          <mat-icon color="warn" class="medium-icon ion-md-close-circle">
          </mat-icon>
          <p class="mat-body-2">
            {{ "import.inventario.import_ko" | translate }}
          </p>
        </div>
        <div *ngIf="inv_save.success" class="text-center">
          <mat-icon color="accent" class="medium-icon">
            check_circle
          </mat-icon>
          <p class="mat-body-2">
            {{ "import.inventario.import_ok" | translate }}
          </p>
        </div>
        <button
          *ngIf="!inv_save.success && !inv_save.error"
          mat-raised-button
          color="accent"
          [disabled]="!invForm.controls['ok'].value"
          (click)="saveInventarios()"
        >
          <span *ngIf="!inv_save.pending">{{
            "import.inventario.save" | translate
          }}</span>
          <span *ngIf="inv_save.pending">{{
            "import.inventario.saving" | translate
          }}</span>
          <mat-spinner
            color="warn"
            *ngIf="inv_save.pending"
            [diameter]="20"
            value="indeterminate"
          ></mat-spinner>
        </button>
        <table class="mt-4 buttons-table">
          <td *ngIf="inv_save.error">
            <button mat-button (click)="reset()">
              {{ "import.reset" | translate }}
            </button>
          </td>
          <td>
            <button
              *ngIf="!inv_save.error"
              mat-raised-button
              color="primary"
              matStepperPrevious
            >
              {{ "import.back" | translate }}
            </button>
          </td>
          <td>
            <button
              *ngIf="inv_save.success"
              mat-raised-button
              color="accent"
              [disabled]="!inv_save.success"
              type="submit"
              matStepperNext
            >
              {{ "import.next" | translate }}
            </button>
          </td>
        </table>
      </form>
    </mat-step>
    <mat-step
      *ngIf="file_upload.success && imported_data.HCO_PLAN_ACCION.length > 0"
      [stepControl]="planForm"
    >
      <form
        [formGroup]="planForm"
        class="text-center d-flex flex-column justify-content-center align-items-center full-height"
      >
        <ng-template matStepLabel>{{
          "import.planaccion.title" | translate
        }}</ng-template>
        <app-simpletable
          *ngIf="file_upload.success && !plan_save.done"
          [cols]="planCols"
          class="mt-4 mb-4"
          [data]="imported_data.HCO_PLAN_ACCION"
          datatype="Plan de acción"
          datatypePlural="Planes de acción"
        ></app-simpletable>
        <mat-checkbox
          color="primary"
          *ngIf="!plan_save.done && !plan_save.error"
          formControlName="ok"
        >
          {{ "import.data_ok" | translate }}
        </mat-checkbox>

        <div *ngIf="plan_save.error" class="text-center">
          <mat-icon color="warn" class="medium-icon ion-md-close-circle">
          </mat-icon>
          <p class="mat-body-2">
            {{ "import.planaccion.import_ko" | translate }}
          </p>
        </div>
        <div *ngIf="plan_save.success" class="text-center">
          <mat-icon color="accent" class="medium-icon">
            check_circle
          </mat-icon>
          <p class="mat-body-2">
            {{ "import.planaccion.import_ok" | translate }}
          </p>
        </div>
        <button
          *ngIf="!plan_save.success"
          mat-raised-button
          color="accent"
          [disabled]="!invForm.controls['ok'].value"
          (click)="savePlanesAccion()"
        >
          <span *ngIf="!plan_save.pending">{{
            "import.planaccion.save" | translate
          }}</span>
          <span *ngIf="plan_save.pending">{{
            "import.planaccion.saving" | translate
          }}</span>
          <mat-spinner
            color="warn"
            *ngIf="plan_save.pending"
            [diameter]="20"
            value="indeterminate"
          ></mat-spinner>
        </button>
        <table class="mt-4 buttons-table">
          <td *ngIf="plan_save.error">
            <button mat-button (click)="reset()">
              {{ "import.reset" | translate }}
            </button>
          </td>
          <td>
            <button
              *ngIf="!plan_save.error"
              mat-raised-button
              color="primary"
              matStepperPrevious
            >
              {{ "import.back" | translate }}
            </button>
          </td>
          <td>
            <button
              *ngIf="!plan_save.error"
              mat-raised-button
              color="accent"
              [disabled]="!plan_save.success"
              type="submit"
              matStepperNext
            >
              {{ "import.next" | translate }}
            </button>
          </td>
        </table>
      </form>
    </mat-step>
    <mat-step [stepControl]="finishForm">
      <ng-template matStepLabel>{{
        "import.finalizar.title" | translate
      }}</ng-template>
      <div
        class=" full-height d-flex flex-column align-items-center justify-content-center"
      >
        <mat-icon
          class="ionicon-large ion-md-checkmark-circle-outline m-4"
          color="accent"
        ></mat-icon>
        <p class="mat-body-2">{{ "import.finalizar.text" | translate }}</p>
        <table>
          <td>
            <button mat-raised-button color="primary" (click)="reset()">
              {{ "import.reset" | translate }}
            </button>
          </td>
          <td>
            <button mat-raised-button color="accent" routerLink="/dashboard">
              {{ "import.finalizar.link" | translate }}
            </button>
          </td>
        </table>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

import {HCOAccion} from './hcoaccion';

export class IndicadorAccion {
  ID: number = null;
  IDPADRE: number = null;
  PADRE: IndicadorAccion = null;
  IDHCOACCION: number = null;
  HCOACCION: HCOAccion;
  NOMBRE: string = null;
  MAGNITUD: string = null;
  VALOR: number = null;
  OBSERVACIONES: string = null;

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user';
import {appConfig} from '../app.config';
import {Usuario} from '../model/usuario';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Usuario[]>(appConfig.apiUrl + 'USUARIO/all');
  }

  getById(_id: number) {
    return this.http.get<Usuario>(appConfig.apiUrl + 'USUARIO/' + _id);
  }

  create(user: Usuario) {
    return this.http.post<Usuario>(appConfig.apiUrl + 'USUARIO', user);
  }

  update(user: Usuario) {
    return this.http.put<Usuario>(appConfig.apiUrl + 'USUARIO/' + user.ID, user);
  }

  delete(_id: string) {
    return this.http.delete(appConfig.apiUrl + 'USUARIO/' + _id);
  }
}

import {Municipio} from './municipio';

export class Analisis {
  ID: number;
  IDMUNICIPIO: number;
  ANYO: number;
  RIESGOS: Riesgo[];
  VULNERABILIDADES: Vulnerabilidad[];
}

export class Riesgo {
  ID: number;
  IDANALISIS: number;
  NOMBRE: string;
  SECTOR: string;
  IDNIVEL: number;
  IDNIVELPREVISTO: number;
  IDCAMBIOINTENSIDAD: number;
  IDCAMBIOFRECUENCIA: number;
  IDPERIODO: number;
  OBSERVACIONES: string;
  NIVELRIESGO: NivelRiesgo;
  NIVELRIESGOPREVISTO: NivelRiesgo;
  CAMBIOINTENSIDAD: CambioRiesgo;
  CAMBIOFRECUENCIA: CambioRiesgo;
  PERIODORIESGO: PeriodoRiesgo;
  INDICADORRIESGO: IndicadorRiesgo[];
}

export class RiesgoDB {
  ID: number;
  IDANALISIS: number;
  NOMBRE: string;
  SECTOR: string;
  IDNIVEL: number;
  IDNIVELPREVISTO: number;
  IDCAMBIOINTENSIDAD: number;
  IDCAMBIOFRECUENCIA: number;
  IDPERIODO: number;
  OBSERVACIONES: string;
  INDICADORRIESGO: IndicadorRiesgo[];

  constructor(riesgo: Riesgo) {
    const r = Object.assign({}, riesgo);
    delete r.NIVELRIESGO;
    delete r.NIVELRIESGOPREVISTO;
    delete r.CAMBIOINTENSIDAD;
    delete r.CAMBIOFRECUENCIA;
    delete r.PERIODORIESGO;
    Object.assign(this, r);
  }
}

export class RiesgoPred {
  ID: number;
  NOMBRE: string;
}

export class IndicadorRiesgo {
  ID: number = null;
  IDRIESGO: number = null;
  RIESGO: Riesgo;
  NOMBRE: string = null;
  MAGNITUD: string = null;
  VALOR: number = null;
  OBSERVACIONES: string = null;
}

export class NivelRiesgo {
  ID: number;
  NOMBRE: string;
}

export class CambioRiesgo {
  ID: number;
  NOMBRE: string;
}

export class PeriodoRiesgo {
  ID: number;
  NOMBRE: string;
}

export class SectorRiesgoPred {
  ID: number;
  NOMBRE: string;
}

export class Vulnerabilidad {
  ID: number;
  IDANALISIS: number;
  NOMBRE: string;
  IDTIPO: number;
  TIPOVULNERABILIDAD: TipoVulnerabilidad;
  DESCRIPCION: string;
  IDNIVELPREVISTO: number;
  NIVELPREVISTO: NivelVulnerabilidad;
  IDNIVELACTUAL: number;
  NIVELACTUAL: NivelVulnerabilidad;
  INDICADORVULNERABILIDAD: IndicadorVulnerabilidad[];
}

export class VulnerabilidadDB {
  ID: number;
  IDANALISIS: number;
  NOMBRE: string;
  IDTIPO: number;
  DESCRIPCION: string;
  IDNIVELPREVISTO: number;
  IDNIVELACTUAL: number;
  INDICADORVULNERABILIDAD: IndicadorVulnerabilidad[];

  constructor(vul: Vulnerabilidad) {
    const r = Object.assign({}, vul);
    delete r.NIVELACTUAL;
    delete r.NIVELPREVISTO;
    delete r.TIPOVULNERABILIDAD;
    Object.assign(this, r);
  }
}


export class IndicadorVulnerabilidad {
  ID: number = null;
  IDVULNERABILIDAD: number = null;
  VULNERABILIDAD: Vulnerabilidad;
  NOMBRE: string = null;
}

export class NivelVulnerabilidad {
  ID: number;
  NOMBRE: string;
}

export class TipoVulnerabilidad {
  ID: number;
  NOMBRE: string;
}

export class VariableClimatica {
  ID: number;
  IDANALISIS: number;
  ANYO: number;
  TEMPERATURA: number;
  PRECIPITACIONES: number;
  VIENTO: number;
  HUMEDAD: number;
  DIASEXTREMOSTEMPERATURA: number;
  DIASSINLLUVIA: number;
  DIASLLUVIASDEBILES: number;
  DIASLLUVIASMODERADAS: number;
  DIASLLUVIASINTENSAS: number;
  DIASLLUVIASTORRENCIALES: number;
  DIASNIEVE: number;
  ANALISIS: Municipio;
}

export class VariableClimaticaDB {
  ID: number;
  IDANALISIS: number;
  ANYO: number;
  TEMPERATURA: number;
  PRECIPITACIONES: number;
  VIENTO: number;
  HUMEDAD: number;
  DIASEXTREMOSTEMPERATURA: number;
  DIASSINLLUVIA: number;
  DIASLLUVIASDEBILES: number;
  DIASLLUVIASMODERADAS: number;
  DIASLLUVIASINTENSAS: number;
  DIASLLUVIASTORRENCIALES: number;
  DIASNIEVE: number;

  constructor(private v : VariableClimatica) {
    const r = Object.assign({}, v);
    delete r.ANALISIS;
    Object.assign(this, r);
  }
}

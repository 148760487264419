import {Petition} from './petition';
import {BehaviorSubject} from 'rxjs/index';

export class PetitionMultiple {
  petitions: Petition[] = [];
  pending: boolean;
  error: boolean;
  data: any;
  finished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  success: boolean;

  doStart() {
    this.pending = true;
    this.success = false;
    this.error = false;
    this.data = null;
  }

  doFinish(data) {
    this.pending = false;
    this.error = false;
    this.data = data;
    this.finished.next(true);
    this.success = true;
  }

  doError(data) {
    this.pending = false;
    this.success = false;
    this.error = true;
    this.data = data;
    this.finished.next(false);
  }

  doReset(){
    this.pending = false;
    this.success = false;
    this.error = false;
    this.data = null;
    this.petitions = [];
  }

  doComplete() {
    if (this.petitions.filter(p => !p.success).length == 0) {
      this.doFinish(this.petitions.map(p => p.data));
    } else {
      this.doError(this.petitions.map(p => p.data));
    }
  }

  ok(id, data) {
    const p = this.getPetition(id);
    p.ok(data);
    if(this.allFinished()){
      this.doComplete();
    }
  }

  ko(id, data) {
    const p = this.getPetition(id);
    p.ko(data);
    if(this.allFinished()){
      this.doComplete();
    }
  }

  load(id) {
    const p = this.getPetition(id);
    p.load();
  }

  private allFinished(){
    return this.petitions.every(p => p.finished);
  }

  private getPetition(id) {
    return this.petitions.find(p => p.id === id);
  }

  add(id) {
    const petition = new Petition(id);
    this.petitions.push(petition);
    return petition;
  }
}

import {Injectable} from '@angular/core';
import {AlertService} from './alert.service';
import {BehaviorSubject} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {MunicipioService} from './municipio.service';
import {appConfig} from '../app.config';
import {Analisis, Riesgo, RiesgoDB, VariableClimaticaDB, Vulnerabilidad, VulnerabilidadDB} from '../model/Analisis';
import {VariableClimatica} from '../model/Analisis';

@Injectable({
  providedIn: 'root'
})
export class AnalisisService {
  selected: BehaviorSubject<Analisis> = new BehaviorSubject<Analisis>(null);
  selected_id: number;

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService) {
  }

  getInventarioActivo() {
    return this.selected.getValue();
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/';
  }

  getAPIUrlRiesgo() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/Riesgo/';
  }

  getAPIUrlVuln() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/Vulnerabilidad/';
  }

  getAPIUrlVarCli() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/VariablesClimaticas/';
  }

  getAPIUrlImport() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/import/';
  }
  getAPIUrlExport() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/export/';
  }

  getAll() {
    return this.http.get<Analisis[]>(this.getAPIUrl());
  }

  getById(_id: number) {
    return this.http.get<Analisis>(this.getAPIUrl() + _id);
  }

  create(d: Analisis) {
    return this.http.post<Analisis>(this.getAPIUrl(), d);
  }

  clone(d: Analisis, year: number) {
    return this.http.post<Analisis>(this.getAPIUrl() + d.ANYO + '/clone/' + year, {});
  }


  update(d: Analisis) {
    return this.http.put<Analisis>(this.getAPIUrl() + d.ID, d);
  }

  replace(d: Analisis) {
    return this.http.put<Analisis>(this.getAPIUrl() + 'replace', d);
  }

  delete(_id: number) {
    return this.http.delete(this.getAPIUrl() + _id);
  }

  setActive(d) {
    this.selected_id = d.ID;
    this.selected.next(d as Analisis);
  }

  initialize() {
    this.selected.next(new Analisis());
  }

  // RIESGO
  getRiesgos() {
    return this.http.get<Riesgo[]>(this.getAPIUrlRiesgo());
  }

  getRiesgoById(_id: number) {
    return this.http.get<Riesgo>(this.getAPIUrlRiesgo() + _id);
  }

  updateRiesgo(d: RiesgoDB) {
    return this.http.put<Riesgo>(this.getAPIUrlRiesgo() + d.ID, d);
  }

  deleteRiesgo(d: Riesgo) {
    return this.http.delete<Riesgo>(this.getAPIUrlRiesgo() + d.ID);
  }

  createRiesgo(d: RiesgoDB) {
    return this.http.post<Riesgo>(this.getAPIUrlRiesgo(), d);
  }

  // VULNERABILIDAD

  getVulnerabilidades() {
    return this.http.get<Vulnerabilidad[]>(this.getAPIUrlVuln());
  }

  getVulnerabilidadById(_id: number) {
    return this.http.get<Vulnerabilidad>(this.getAPIUrlVuln() + _id);
  }

  updateVulnerabilidad(d: VulnerabilidadDB) {
    return this.http.put<Vulnerabilidad>(this.getAPIUrlVuln() + d.ID, d);
  }

  deleteVulnerabilidad(d: Vulnerabilidad) {
    return this.http.delete<Vulnerabilidad>(this.getAPIUrlVuln() + d.ID);
  }

  createVulnerabilidad(d: VulnerabilidadDB) {
    return this.http.post<Vulnerabilidad>(this.getAPIUrlVuln(), d);
  }

  // VARIABLES CLIMATICAS

  getVariableClimatica() {
    return this.http.get<VariableClimatica[]>(this.getAPIUrlVarCli());
  }

  getVariableClimaticaById(_id: number) {
    return this.http.get<VariableClimatica>(this.getAPIUrlVarCli() + _id);
  }

  updateVariableClimatica(d: VariableClimaticaDB) {
    return this.http.put<VariableClimatica>(this.getAPIUrlVarCli() + d.ID, d);
  }

  deleteVariableClimatica(d: VariableClimatica) {
    return this.http.delete<VariableClimatica>(this.getAPIUrlVarCli() + d.ID);
  }

  createVariableClimatica(d: VariableClimaticaDB) {
    return this.http.post<VariableClimatica>(this.getAPIUrlVarCli(), d);
  }

}

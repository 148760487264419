import {ZonaClimatica} from './zonaclimatica';
import {Comarca} from './comarca';
import {DatosInventario} from './datosinventario';
import {InventarioEmisiones} from './inventarioemisiones';
import {Documento} from './documento';

export class Municipio {
  ID: number = 0;
  NOMBRE: string;
  IDZONACLIMATICA: number;
  IDCOMARCA: number;
  LATITUD: number;
  LONGITUD: number;
  FECHAADHESIONPACTO: Date;
  FECHALIMITEPRESENTACION: Date = null;
  COMARCA: Comarca;
  ZONACLIMATICA: ZonaClimatica;
  NUMHABITANTES: number;
  SUPERFICIE: number;
  DOCUMENTO: Documento[];
  toDB: () => MunicipioDB;

  constructor() {
    this.toDB = () => new MunicipioDB(this);
  }
}

export class MunicipioDB {
  ID: number = 0;
  NOMBRE: string;
  IDZONACLIMATICA: number;
  IDCOMARCA: number;
  LATITUD: number;
  LONGITUD: number;
  NUMHABITANTES: number;
  SUPERFICIE: number;
  FECHAADHESIONPACTO: Date;
  FECHALIMITEPRESENTACION: Date = null;

  constructor(municipio: Municipio) {
    this.ID = municipio.ID;
    this.NOMBRE = municipio.NOMBRE;
    this.IDZONACLIMATICA = municipio.IDZONACLIMATICA;
    this.IDCOMARCA = municipio.IDCOMARCA;
    this.LATITUD = municipio.LATITUD;
    this.LONGITUD = municipio.LONGITUD;
    this.FECHAADHESIONPACTO = municipio.FECHAADHESIONPACTO;
    this.FECHALIMITEPRESENTACION = municipio.FECHALIMITEPRESENTACION;
    this.NUMHABITANTES = municipio.NUMHABITANTES;
    this.SUPERFICIE = municipio.SUPERFICIE;
  }
}

export class UsuarioMunicipio{
  USERID: string;
  IDMUNICIPIO: number;
  MUNICIPIO: Municipio;
}

import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Petition } from "../../model/petition";
import { MunicipioService } from "../../services/municipio.service";
import { ImportacionPlantilla } from "../../model/importacionplantilla";
import { CentroService } from "../../services/centro.service";
import { TranslateService } from "@ngx-translate/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from "@angular/material";
import {
  InventarioEmisiones,
  InventarioEmisionesDB
} from "../../model/inventarioemisiones";
import { InventarioService } from "../../services/inventario.service";
import { InvDatosEspecificosComponent } from "../inventario/inv-datos-especificos/inv-datos-especificos.component";
import { DatosInventario } from "../../model/datosinventario";
import { DatosCentro } from "../../model/datoscentro";
import { forEach } from "@angular/router/src/utils/collection";
import { PetitionMultiple } from "../../model/petitionmultiple";
import { PlanAccion } from "../../model/planaccion";
import { PlanaccionService } from "../../services/planaccion.service";
import { ImportResponse } from "../../model/Utils";
import { Centro } from "../../model/centro";

@Component({
  selector: "app-importacionplantilla",
  templateUrl: "./importacionplantilla.component.html",
  styleUrls: ["./importacionplantilla.component.scss"]
})
export class ImportacionplantillaComponent implements OnInit {
  @ViewChild("stepper") stepper;
  @ViewChild("fileInput") fileInput;
  import_response: ImportResponse;
  file_upload = new Petition();
  imported_data: ImportacionPlantilla;
  fileForm = this.fb.group({
    file: [null, Validators.required]
  });
  centros_save = new Petition();
  centrosForm = this.fb.group({
    ok: [false, Validators.required]
  });
  centrosCols = [
    {
      id: "nombre",
      title: "municipio.centros.col-nombre",
      data: {
        prop: "NOMBRE"
      },
      type: "text",
      editable: false,
      sort: "desc"
    },
    {
      id: "cups",
      title: "municipio.centros.col-cups_ele",
      data: {
        prop: "CUPS_ELECTRICO"
      },
      type: "text",
      editable: true
    },
    {
      id: "cups_gas",
      title: "municipio.centros.col-cups_gas",
      data: {
        prop: "CUPS_GAS"
      },
      type: "text",
      editable: true
    },
    {
      id: "cups_gasoleo",
      title: "municipio.centros.col-cups_gasoleo",
      data: {
        prop: "CUPS_GASOLEO"
      },
      type: "text",
      editable: true
    },
    {
      id: "cups_glp",
      title: "municipio.centros.col-cups_glp",
      data: {
        prop: "CUPS_GLP"
      },
      type: "text",
      editable: true
    },
    {
      id: "cups_otros",
      title: "municipio.centros.col-cups_otros",
      data: {
        prop: "CUPS_OTORS"
      },
      type: "text",
      editable: true
    }
  ];
  inv_save = new PetitionMultiple();
  invForm = this.fb.group({
    ok: [false, Validators.required]
  });
  invCols = [
    {
      id: "anyo",
      title: "import.inventario.col-anyo",
      type: "text",
      data: {
        prop: "ANYO",
        filter: ["ANYO"]
      },
      sort: "desc"
    },
    {
      id: "habitantes",
      title: "import.inventario.col-habitantes",
      type: "number",
      data: {
        prop: "NUMHABITANTES",
        filter: ["NUMHABITANTES"]
      },
      sort: "desc"
    },
    {
      id: "gasolina",
      title: "import.inventario.col-gasolina",
      type: "number",
      data: {
        prop: "NUMVEHICULOSGASOLINA",
        filter: ["NUMVEHICULOSGASOLINA"]
      },
      sort: "desc"
    },
    {
      id: "diesel",
      title: "import.inventario.col-diesel",
      type: "number",
      data: {
        prop: "NUMVEHICULOSDIESEL",
        filter: ["NUMVEHICULOSDIESEL"]
      },
      sort: "desc"
    },
    {
      id: "implantaciongas",
      title: "import.inventario.col-implantaciongas",
      type: "number",
      data: {
        prop: "IMPLANTACIONGN",
        filter: ["IMPLANTACIONGN"]
      },
      sort: "desc"
    },
    {
      id: "datos_inventario",
      title: "import.inventario.col-datos_inventario",
      type: "button",
      data: {
        text: "import.inventario.datos_inventario_btn",
        color: "primary",
        callback: obj =>
          this.openDialogTabla(
            "import.inventario.col-datos_inventario",
            this.datinvCols,
            obj.DATOS_INVENTARIO,
            "Consumo y emisiones",
            "Consumos y emisiones"
          )
      },
      width: "180px",
      sort: "desc"
    },
    {
      id: "datos_especificos",
      title: "import.inventario.col-datos_especificos",
      type: "button",
      data: {
        text: "import.inventario.datos_especificos_btn",
        color: "primary",
        callback: obj =>
          this.openDialogTabla(
            "import.inventario.col-datos_especificos",
            this.datespCols,
            this.getDatosEspecificos(obj),
            "Consumos y emisiones específicos",
            "Consumos y emisiones específicos"
          )
      },
      sort: "desc",
      width: "180px"
    }
  ];
  datinvCols = [
    {
      id: "ambito",
      title: "inventario.datosinventario.col-ambito",
      type: "text",
      data: {
        prop: "AMBITO.SUPERAMBITO.NOMBRE",
        filter: ["AMBITO.SUPERAMBITO.NOMBRE"]
      },
      editable: false,
      sort: "desc",
      tooltip: "inventario.datosinventario.tooltip-ambito"
    },
    {
      id: "fuente",
      title: "inventario.datosinventario.col-fuente",
      type: "text",
      data: {
        prop: "AMBITO.NOMBRE",
        filter: ["AMBITO.NOMBRE"]
      },
      editable: false,
      sort: "desc",
      tooltip: "inventario.datosinventario.tooltip-fuente"
    },
    {
      id: "valor",
      title: "inventario.datosinventario.col-valor",
      data: {
        prop: "VALOR"
      },
      type: "number",
      editable: true,
      width: "260px",
      scope: "row",
      tooltip: "inventario.datosinventario.col-valor"
    },
    {
      id: "factorconversion",
      title: "inventario.datosinventario.col-factorconversion",
      data: {
        prop: "FACTORCONVERSION"
      },
      type: "number",
      width: "160px",
      editable: true,
      tooltip: "inventario.datosinventario.col-factorconversion"
    },
    {
      id: "factoremision",
      title: "inventario.datosinventario.col-factoremision",
      type: "number",
      data: {
        prop: "FACTOREMISION"
      },
      width: "160px",
      editable: true,
      tooltip: "inventario.datosinventario.col-factoremision"
    },
    {
      id: "emisiones",
      title: "inventario.datosinventario.col-emisiones",
      type: "function",
      data: {
        func: (obj: DatosInventario) =>
          Math.round(
            obj.VALOR *
              (obj.FACTORCONVERSION ? obj.FACTORCONVERSION : 1) *
              obj.FACTOREMISION *
              1000
          ) / 1000
      },
      width: "160px",
      editable: false,
      tooltip: "inventario.datosinventario.tooltip-emisiones"
    }
  ];
  datespCols = [
    {
      id: "centro",
      title: "inventario.datosespecificos.col-centro",
      data: {
        prop: "CENTRO.NOMBRE",
        filter: ["CENTRO.NOMBRE"]
      },
      type: "text",
      editable: false,
      sort: "desc",
      width: "180px",
      tooltip: "inventario.datosespecificos.tooltip-centro"
    },
    {
      id: "valor",
      title: "inventario.datosespecificos.col-valor",
      data: {
        prop: "VALOR"
      },
      type: "number",
      editable: true,
      width: "140px",
      scope: "row"
    },
    {
      id: "fuente",
      title: "inventario.datosinventario.col-fuente",
      data: {
        prop: "DATOS_INVENTARIO.AMBITO.NOMBRE"
      },
      type: "text",
      width: "160px",
      editable: true
    },
    {
      id: "factorconversion",
      title: "inventario.datosinventario.col-factorconversion",
      data: {
        prop: "DATOS_INVENTARIO.FACTORCONVERSION"
      },
      type: "number",
      width: "160px",
      editable: true
    },
    {
      id: "factoremision",
      title: "inventario.datosinventario.col-factoremision",
      type: "number",
      data: {
        prop: "DATOS_INVENTARIO.FACTOREMISION"
      },
      width: "160px",
      editable: true
    },
    {
      id: "emisiones",
      title: "inventario.datosespecificos.col-emisiones",
      type: "function",
      data: {
        func: (obj: DatosCentro) =>
          Math.round(
            obj.VALOR *
              (obj.DATOS_INVENTARIO.FACTORCONVERSION
                ? obj.DATOS_INVENTARIO.FACTORCONVERSION
                : 1) *
              obj.DATOS_INVENTARIO.FACTOREMISION *
              1000
          ) / 1000
      },
      width: "120px",
      editable: false,
      tooltip: "inventario.datosinventario.tooltip-emisiones"
    }
  ];
  planCols = [
    {
      id: "anyo",
      title: "planaccion.home.col-anyo",
      type: "text",
      data: {
        prop: "ANYO",
        filter: ["ANYO"]
      },
      sort: "desc",
      tooltip: "planaccion.home.tooltip-anyo"
    },
    {
      id: "anyoinventario",
      title: "planaccion.home.col-anyoinventario",
      type: "text",
      data: {
        prop: "ANYOREFERENCIAINVENTARIO",
        filter: ["ANYOREFERENCIAINVENTARIO"]
      },
      tooltip: "planaccion.home.tooltip-anyoinventario"
    },
    {
      id: "anyoriesgos",
      title: "planaccion.home.col-anyoriesgos",
      type: "text",
      data: {
        prop: "ANYOREFERENCIARIESGOS"
      },
      tooltip: "planaccion.home.tooltip-anyoriesgos"
    },
    {
      id: "anyoobjetivo",
      title: "planaccion.home.col-anyoobjetivo",
      type: "text",
      data: {
        prop: "ANYOOBJETIVO"
      },
      tooltip: "planaccion.home.tooltip-anyoobjetivo"
    },
    {
      id: "objetivoconsumo",
      title: "planaccion.home.col-objetivoconsumo",
      type: "text",
      data: {
        prop: "OBJETIVOCONSUMO"
      }
    },
    {
      id: "objetivoproduccion",
      title: "planaccion.home.col-objetivoproduccion",
      type: "text",
      data: {
        prop: "OBJETIVOPRODUCCION"
      }
    },
    {
      id: "objetivoemision",
      title: "planaccion.home.col-objetivoemision",
      type: "text",
      data: {
        prop: "OBJETIVOEMISION"
      }
    },
    {
      id: "hcoaccion",
      title: "planaccion.home.col-hcoaccion",
      type: "icon",
      data: {
        badge: (obj: PlanAccion) => obj.HCO_ACCION.length,
        callback: (obj: PlanAccion) => console.log(obj)
      }
    }
  ];
  planForm = this.fb.group({
    ok: [false, Validators.required]
  });
  plan_save = new Petition();
  finishForm = this.fb.group({
    ok: [false, Validators.required]
  });
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private munSrv: MunicipioService,
    private centroSrv: CentroService,
    private planSrv: PlanaccionService,
    private invSrv: InventarioService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fileForm.get("file").setValue(file);
    }
  }

  getDatosEspecificos(inventario: InventarioEmisiones) {
    if (this.file_upload.success) {
      if (inventario.DATOS_INVENTARIO) {
        let datos_especificos: DatosCentro[] = [];
        inventario.DATOS_INVENTARIO.forEach(d => {
          d.DATOS_CENTRO.forEach(dc => {
            const a = Object.assign({}, d);
            delete a.DATOS_CENTRO;
            dc.DATOS_INVENTARIO = a;
          });
          datos_especificos = datos_especificos.concat(d.DATOS_CENTRO);
        });
        return datos_especificos;
      }
    }
    return [];
  }

  importarPlantilla() {
    this.file_upload.load();
    this.munSrv.importPlantilla(this.fileForm.controls["file"].value).subscribe(
      value => {
        this.file_upload.ok(value);
        this.imported_data = value;
        this.import_response = new ImportResponse(true, value);
      },
      error2 => {
        this.file_upload.ko(error2);
        this.snackBar.open(error2, null, { duration: 3000 });
        this.import_response = new ImportResponse(false, error2);
      }
    );
  }

  saveCentros() {
    this.centros_save.load();
    this.centroSrv.createAll(this.imported_data.CENTROS).subscribe(
      value => {
        this.centros_save.ok(value);
        this.asignarCentroADatosCentro(value);
        this.translateService
          .get("municipio.centros.updated")
          .subscribe(d => this.snackBar.open(d, null, { duration: 3000 }));
      },
      error2 => {
        this.centros_save.ko(error2);
        this.snackBar.open(error2, null, { duration: 3000 });
      }
    );
  }

  asignarCentroADatosCentro(centros: Centro[]) {
    this.imported_data.INVENTARIO_EMISIONES.forEach(inv => {
      inv.DATOS_INVENTARIO.forEach(dinv => {
        dinv.DATOS_CENTRO.forEach(dc => {
          if (!dc.IDCENTRO)
            dc.IDCENTRO = centros.find(c => c.NOMBRE === dc.CENTRO.NOMBRE).ID;
          dc.CENTRO = null;
          //dc.CENTRO = centros.find(c => c.NOMBRE === dc.CENTRO.NOMBRE);
        });
      });
    });
  }

  savePlanesAccion() {
    this.plan_save.load();
    this.planSrv
      .replace(new PlanAccion(this.imported_data.HCO_PLAN_ACCION[0]).toDB())
      .subscribe(
        value => {
          this.plan_save.ok(value);
          this.translateService
            .get("planaccion.createmodal.updated")
            .subscribe(d => this.snackBar.open(d, null, { duration: 3000 }));
        },
        error2 => {
          this.plan_save.ko(error2);
          this.snackBar.open(error2, null, { duration: 3000 });
        }
      );
  }
  saveInventarios() {
    this.inv_save.doStart();
    this.imported_data.INVENTARIO_EMISIONES.forEach(
      (d: InventarioEmisiones, i) => {
        this.inv_save.add(i);
        this.invSrv.replace(new InventarioEmisiones(d).toDB()).subscribe(
          value => {
            this.inv_save.ok(i, value);
          },
          error2 => {
            this.inv_save.ko(i, error2);
            this.snackBar.open(error2, null, { duration: 3000 });
          }
        );
      }
    );
    this.inv_save.finished.subscribe(
      value => {
        if (value) {
          this.translateService
            .get("inventario.createmodal.updated")
            .subscribe(d => this.snackBar.open(d, null, { duration: 3000 }));
        }
      },
      error2 => {
        this.snackBar.open(error2, null, { duration: 3000 });
      }
    );
  }

  completarSaveInventarios() {
    if (this.inv_save.success) {
      this.translateService
        .get("inventario.createmodal.updated")
        .subscribe(d => this.snackBar.open(d, null, { duration: 3000 }));
    } else if (this.inv_save.error) {
      this.translateService
        .get("inventario.createmodal.error")
        .subscribe(d => this.snackBar.open(d, null, { duration: 3000 }));
    }
  }

  openDialogTabla(title, cols, data, datatype, datatypePlural): void {
    const dialogRef = this.dialog.open(TablaImportacionDialog, {
      data: {
        data: data,
        cols: cols,
        datatype: datatype,
        datatypePlural: datatypePlural,
        title: title
      },
      disableClose: false
    });
  }

  reset() {
    this.file_upload.reset();
    this.fileForm.reset();

    this.inv_save.doReset();
    this.invForm.reset();

    this.centros_save.reset();
    this.centrosForm.reset();

    this.imported_data = new ImportacionPlantilla();
    this.fileInput.nativeElement.value = "";
    this.stepper.reset();
  }

  ngOnInit() {}
}

// noinspection TsLint
@Component({
  selector: "tablaimportaciondialog",
  templateUrl: "tablaimportacion.dialog.html"
})
export class TablaImportacionDialog {
  constructor(
    public dialogRef: MatDialogRef<TablaImportacionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}

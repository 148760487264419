import {Component, Inject, OnInit} from '@angular/core';
import {Petition} from '../../../model/petition';
import {MunicipioService} from '../../../services/municipio.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {PlanAccion} from '../../../model/planaccion';
import {HCOAccion, HCOAccionDB} from '../../../model/hcoaccion';
import {PlanaccionService} from '../../../services/planaccion.service';
import {AccionesService} from '../../../services/acciones.service';
import {ListatiposService} from '../../../services/listatipos.service';
import {TipoAccion} from '../../../model/tipoaccion';
import {Accion} from '../../../model/accion';
import {FormBuilder, FormGroup} from '@angular/forms';
import {startWith, map} from 'rxjs/internal/operators';
import {Observable} from 'rxjs/index';
import {Sorter} from '../../../components/list/Util';

@Component({
  selector: 'app-planaccion-acciones',
  templateUrl: './planaccion-acciones.component.html',
  styleUrls: ['./planaccion-acciones.component.scss']
})
export class PlanaccionAccionesComponent implements OnInit {
  dato: PlanAccion = new PlanAccion();
  datos: HCOAccion[] = [];
  activeFormID: number = null;

  search_props = ['ESTADOACCION.NOMBRE','AMBITO.CODIGOADICIONAL','ACCION.TIPOACCION.NOMBRE','ACCION.CODIGO','AMBITO.NOMBRE'];
  search_placeholder = 'planaccion.acciones.list.searchplaceholder';
  sorters = [new Sorter('planaccion.acciones.list.emisiones', 'REDUCCIONEMISIONES'),
    new Sorter('planaccion.acciones.list.inversion', 'INVERSIONESTIMADA')
  ];
  data_update = new Petition();
  data_fetch = new Petition();

  constructor(private dialog: MatDialog, private parentSrv: PlanaccionService, private munsvc: MunicipioService, private dataSrv: AccionesService,
              private translateService: TranslateService, public snackBar: MatSnackBar) {
    this.parentSrv.selected.subscribe(value => {
      if (value) {
        this.dato = value;
        this.load();
      }
    }, error2 => console.log(error2));
  }

  ngOnInit() {
    this.initialize();
    const optionsEstado = this.datos.map(d => d.ESTADOACCION).filter((d, i, self) => self.indexOf(d) === i);
    const optionsTipo = this.datos.map(d => d.ACCION.TIPOACCION).filter((d, i, self) => self.indexOf(d) === i);
  }

  update(data) {
    const datos = data.map(d => new HCOAccion(d).toDB());
    if (this.dato.ID) {
      this.data_update.load();
      this.dataSrv.updateAll(datos).subscribe(value => {
          this.data_update.ok(value);
          this.translateService.get('planaccion.acciones.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
        }
        , error2 => {
          this.data_update.ko(error2);
          this.translateService.get('planaccion.acciones.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
        });
    }
  }

  selectAccion(item) {
    this.activeFormID = item.ID;
  }

  getActiveDato() {
    return this.datos.filter(d => d.ID == this.activeFormID)[0];
  }

  load() {
    this.data_fetch.load();
    this.dataSrv.getAll().subscribe(value => {
      if (value) {
        this.datos = value;
        this.data_fetch.ok(value);
      }
    }, error2 => {
      this.data_fetch.ko(error2);
    });
  }

  updateAccion(item){
    const index = this.datos.findIndex(a => a.ID == item.ID);
    this.datos[index] = item;
  }

  getRentabilidadColor(item) {
    const rentabilidad = (item.INVERSIONESTIMADA ? (item.REDUCCIONEMISIONES / item.INVERSIONESTIMADA) * 1000 : 0);
    if (rentabilidad <= 1) {
      return '#e1715f';
    } else if (rentabilidad > 1 && rentabilidad <= 10) {
      return '#d6d127';
    } else {
      return '#52d25e';
    }
  }

  getRentabilidadText(item) {
    const rentabilidad = (item.INVERSIONESTIMADA ? (item.REDUCCIONEMISIONES / item.INVERSIONESTIMADA) * 1000 : 0);
    if (rentabilidad <= 1) {
      return 'extra.pa.rentabilidad.baja';
    } else if (rentabilidad > 1 && rentabilidad <= 10) {
      return 'extra.pa.rentabilidad.media';
    } else {
      return 'extra.pa.rentabilidad.alta';
    }
  }
  getPeriodoColor(value) {
    let color = '#a4afb5';
    switch (value) {
      case 3:
        color = '#71c53f';
        break;
      case 2:
        color = '#d5ae66';
        break;
      case 1:
        color = '#e1715f';
        break;
      default:
        color = '#a4afb5';
        break;
    }
    return color;
  }
  getChipColor(type, value) {
    const linear = 'linear-gradient(154deg,';
    let color = '#a4afb5';
    switch (type) {
      case 0:
        switch (value) {
          case 3:
            color = '#d6d127';
            break;
          case 2:
            color = '#d5ae66';
            break;
          case 1:
            color = '#e1715f';
            break;
          default:
            color = '#a4afb5';
            break;
        }
        break;
      case 1:
        switch (value) {
          case 1:
            color = '#65d4f1';
            break;
          case 2:
            color = '#63debd';
            break;
          case 3:
            color = '#52d25e';
            break;
          case 4:
            color = '#686868';
            break;
          default:
            color = '#cd92b1';
            break;
        }
        break;
    }
    return color;
  }

  addDato(data) {
    const tdato = new HCOAccion();
    tdato.IDACCION = data.ACCION.ID;
    tdato.IDHCOPLANACCION = this.parentSrv.selected_id;
    this.parentSrv.createAccion(new HCOAccionDB(tdato)).subscribe(value => {
        this.data_update.ok(value);
        this.activeFormID = value.ID;
        this.translateService.get('planaccion.acciones.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      }
      , error2 => {
        this.data_update.ko(error2);
        this.translateService.get('planaccion.acciones.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      });
  }

  deleteDatos(dato) {
    this.parentSrv.deleteAccion(dato).subscribe(value => {
        if (value) {
          this.translateService.get('planaccion.acciones.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
          this.load();
          this.activeFormID = null;
        }
      }
      , error2 => {
        this.translateService.get('planaccion.acciones.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.load();
      });
  }

  initialize() {
    this.datos = [];
    this.load();
  }

  closeForm() {
    this.activeFormID = null;
  }

  openNuevoDatoDialog(): void {
    const dialogRef = this.dialog.open(NuevaAccionDialog, {
      data: {datos: this.datos},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.addDato(data);
      }
    });
  }

  openDeleteDialog(data): void {
    if (data) {
      const dato = data;
      const dialogRef = this.dialog.open(DeleteAccionDialog, {
        data: {number: 0},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(bool => {
        if (bool) {
          this.deleteDatos(dato);
        }
      });
    }
  }
}

// noinspection TsLint
@Component({
  selector: 'app-nuevaacciondialog',
  templateUrl: 'nuevaaccion.dialog.html',
})
export class NuevaAccionDialog {
  tipos_accion: TipoAccion[] = [];
  acciones: Accion[] = [];
  filtered_acciones: Observable<Accion[]>;
  available_acciones: Accion[] = [];
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<NuevaAccionDialog>, private tipoSrv: ListatiposService, private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = formBuilder.group({
      TIPOACCION: [null],
      ACCION: [null],
      ACCIONINPUT: [null]
    });

    this.form.controls['TIPOACCION'].valueChanges.subscribe(p => p ? this.onChangeTipo(p.ID) : null);
    tipoSrv.fetchTipoAccion().subscribe(value => {
      this.tipos_accion = value;
    });
    tipoSrv.fetchAccion().subscribe(value => {
      this.acciones = value;
    });
  }

  filteredAcciones(data: string | Accion) {
    if (typeof data == 'string')
      return this.available_acciones.filter(a =>
        a.NOMBRE.toLowerCase().indexOf(data.toLowerCase()) >= 0);
  }

  onChangeTipo(t) {
    this.available_acciones = this.acciones.filter(d => t ? (d.IDTIPO == t) : true);
    this.filtered_acciones = this.form.controls['ACCION'].valueChanges.pipe(
      startWith(''),
      map(p => p ? this.filteredAcciones(p) : this.available_acciones.slice())
    );
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  displayAccion(c): string {
    return c ? c.NOMBRE : c;
  }
}


// noinspection TsLint
@Component({
  selector: 'deleteacciondialog',
  templateUrl: 'deleteaccion.dialog.html',
})
export class DeleteAccionDialog {
  constructor(public dialogRef: MatDialogRef<DeleteAccionDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

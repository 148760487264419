import {FormBuilder, Validators} from '@angular/forms';
import {Municipio, UsuarioMunicipio} from '../model/municipio';
import {minValueValidator} from '../validators/customvalidators';
import {DialogAction} from '../model/Utils';
import {Usuario} from '../model/usuario';
import {Ambito} from '../model/ambito';
import {Superambito} from '../model/superambito';
import {Fuente, Subambito} from '../model/subambito';
import {Accion} from '../model/accion';
import {ZonaClimatica} from '../model/zonaclimatica';
import {RiesgoPred, SectorRiesgoPred} from '../model/Analisis';

export class MunicipioForm {
  form = new FormBuilder().group({
    ID: [0],
    NOMBRE: [null, Validators.required],
    IDCOMARCA: [null, Validators.required],
    IDPROVINCIA: [null, Validators.required],
    LATITUD: [null, Validators.required],
    LONGITUD: [null, Validators.required],
    IDZONACLIMATICA: [null, Validators.required],
    FECHAADHESIONPACTO: [null, Validators.required],
    NUMHABITANTES: [null, Validators.compose([Validators.required, minValueValidator(0)])],
    SUPERFICIE: [null, Validators.compose([Validators.required, minValueValidator(0)])],
    FECHALIMITEPRESENTACION: null
  });

  constructor(action: DialogAction, mun?: Municipio) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (mun) {
      this.setFormValues(mun);
    }
  };

  setFormValues(mun: Municipio) {
    this.form.patchValue({
      ID: mun.ID,
      NOMBRE: mun.NOMBRE,
      IDCOMARCA: mun.IDCOMARCA,
      IDPROVINCIA: mun.COMARCA.IDPROVINCIA,
      LATITUD: mun.LATITUD,
      LONGITUD: mun.LONGITUD,
      IDZONACLIMATICA: mun.IDZONACLIMATICA,
      FECHAADHESIONPACTO: mun.FECHAADHESIONPACTO,
      NUMHABITANTES: mun.NUMHABITANTES,
      SUPERFICIE: mun.SUPERFICIE,
      FECHALIMITEPRESENTACION: mun.FECHALIMITEPRESENTACION
    });
  }
}

export class UsuarioForm {
  form = new FormBuilder().group({
    ID: [0],
    USERID: [null, Validators.required],
    NOMBRE: [null, Validators.required],
    APELLIDOS: [null, Validators.required],
    ROL: [1, Validators.required],
    EMAIL: [null, Validators.required],
    PASSWORD: [null, Validators.required]
  });

  constructor(action: DialogAction, dato?: Usuario) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.form.get('USERID').disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };


  setFormValues(dato: Usuario) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE,
      USERID: dato.USERID,
      APELLIDOS: dato.APELLIDOS,
      ROL: dato.ROL,
      EMAIL: dato.EMAIL,
      PASSWORD: dato.PASSWORD
    });
  }
}

export class UsuarioMunicipioForm {
  form = new FormBuilder().group({
    USERID: [{value: null, disabled: true}, Validators.required],
    IDMUNICIPIO: [null, Validators.required]
  });

  constructor(action: DialogAction, dato?: UsuarioMunicipio) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };


  setFormValues(dato: UsuarioMunicipio) {
    this.form.patchValue({
      USERID: dato.USERID,
      IDMUNICIPIO: dato.IDMUNICIPIO
    });
  }
}

export class AmbitoForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required],
    IDSUPERAMBITO: [null, Validators.required],
    IDSUBAMBITO: [null, Validators.required],
    DEPENDE_AYTO: [true]
  });

  constructor(action: DialogAction, dato?: Ambito) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };


  setFormValues(dato: Ambito) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE,
      IDSUPERAMBITO: dato.IDSUPERAMBITO,
      IDSUBAMBITO: dato.IDSUBAMBITO
    });
  }
}

export class SuperambitoForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required],
    DEPENDE_AYTO: [true, Validators.required]
  });

  constructor(action: DialogAction, dato?: Superambito) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };


  setFormValues(dato: Superambito) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE,
      DEPENDE_AYTO: dato.DEPENDE_AYTO
    });
  }
}

export class SubambitoForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required],
    IDFUENTE: [1, Validators.required],
    DEFAULTFCONVERSION: [0],
    DEFAULTFEMISION: [0],
    IDTIPOSUBAMBITO: [1, Validators.required]
  });

  constructor(action: DialogAction, dato?: Subambito) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };

  setFormValues(dato: Subambito) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE,
      IDFUENTE: dato.IDFUENTE,
      DEFAULTFCONVERSION: dato.DEFAULTFCONVERSION,
      DEFAULTFEMISION: dato.DEFAULTFEMISION,
      IDTIPOSUBAMBITO: dato.IDTIPOSUBAMBITO
    });
  }
}

export class FuenteForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required]
  });

  constructor(action: DialogAction, dato?: Fuente) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };

  setFormValues(dato: Fuente) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE
    });
  }
}

export class ZonaClimaticaForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    REFERENCIA: [null],
    NOMBRE: [null, Validators.required]
  });

  constructor(action: DialogAction, dato?: ZonaClimatica) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };

  setFormValues(dato: ZonaClimatica) {
    this.form.patchValue({
      ID: dato.ID,
      REFERENCIA: dato.REFERENCIA,
      NOMBRE: dato.NOMBRE
    });
  }
}

export class AccionForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required],
    CODIGO: [null, Validators.required],
    DESCRIPCION: [null, Validators.required],
    IDTIPO: [1, Validators.required],
    IDSUPERAMBITO: [1, Validators.required]
  });

  constructor(action: DialogAction, dato?: Accion) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };

  setFormValues(dato: Accion) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE,
      CODIGO: dato.CODIGO,
      DESCRIPCION: dato.DESCRIPCION,
      IDTIPO: dato.IDTIPO,
      IDSUPERAMBITO: dato.IDSUPERAMBITO
    });
  }
}

export class RiesgoPredForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required]
  });

  constructor(action: DialogAction, dato?: RiesgoPred) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };

  setFormValues(dato: RiesgoPred) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE
    });
  }
}
export class SectorRiesgoPredForm {
  form = new FormBuilder().group({
    ID: [0, Validators.required],
    NOMBRE: [null, Validators.required]
  });

  constructor(action: DialogAction, dato?: SectorRiesgoPred) {
    if (action.action == DialogAction.VIEW) {
      this.form.disable();
    }
    if (dato) {
      this.setFormValues(dato);
    }
  };

  setFormValues(dato: SectorRiesgoPred) {
    this.form.patchValue({
      ID: dato.ID,
      NOMBRE: dato.NOMBRE
    });
  }
}

import { AlertService } from './alert.service';
import { BehaviorSubject } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { MunicipioService } from './municipio.service';
import { appConfig } from '../app.config';
import { Analisis } from '../model/Analisis';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
import * as i3 from "./municipio.service";
var AnalisisService = /** @class */ (function () {
    function AnalisisService(http, alertsvc, munsrv) {
        this.http = http;
        this.alertsvc = alertsvc;
        this.munsrv = munsrv;
        this.selected = new BehaviorSubject(null);
    }
    AnalisisService.prototype.getInventarioActivo = function () {
        return this.selected.getValue();
    };
    AnalisisService.prototype.getAPIUrl = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/';
    };
    AnalisisService.prototype.getAPIUrlRiesgo = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/Riesgo/';
    };
    AnalisisService.prototype.getAPIUrlVuln = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/Vulnerabilidad/';
    };
    AnalisisService.prototype.getAPIUrlVarCli = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/VariablesClimaticas/';
    };
    AnalisisService.prototype.getAPIUrlImport = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/import/';
    };
    AnalisisService.prototype.getAPIUrlExport = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/AnalisisRV/' + this.selected_id + '/export/';
    };
    AnalisisService.prototype.getAll = function () {
        return this.http.get(this.getAPIUrl());
    };
    AnalisisService.prototype.getById = function (_id) {
        return this.http.get(this.getAPIUrl() + _id);
    };
    AnalisisService.prototype.create = function (d) {
        return this.http.post(this.getAPIUrl(), d);
    };
    AnalisisService.prototype.clone = function (d, year) {
        return this.http.post(this.getAPIUrl() + d.ANYO + '/clone/' + year, {});
    };
    AnalisisService.prototype.update = function (d) {
        return this.http.put(this.getAPIUrl() + d.ID, d);
    };
    AnalisisService.prototype.replace = function (d) {
        return this.http.put(this.getAPIUrl() + 'replace', d);
    };
    AnalisisService.prototype.delete = function (_id) {
        return this.http.delete(this.getAPIUrl() + _id);
    };
    AnalisisService.prototype.setActive = function (d) {
        this.selected_id = d.ID;
        this.selected.next(d);
    };
    AnalisisService.prototype.initialize = function () {
        this.selected.next(new Analisis());
    };
    // RIESGO
    AnalisisService.prototype.getRiesgos = function () {
        return this.http.get(this.getAPIUrlRiesgo());
    };
    AnalisisService.prototype.getRiesgoById = function (_id) {
        return this.http.get(this.getAPIUrlRiesgo() + _id);
    };
    AnalisisService.prototype.updateRiesgo = function (d) {
        return this.http.put(this.getAPIUrlRiesgo() + d.ID, d);
    };
    AnalisisService.prototype.deleteRiesgo = function (d) {
        return this.http.delete(this.getAPIUrlRiesgo() + d.ID);
    };
    AnalisisService.prototype.createRiesgo = function (d) {
        return this.http.post(this.getAPIUrlRiesgo(), d);
    };
    // VULNERABILIDAD
    AnalisisService.prototype.getVulnerabilidades = function () {
        return this.http.get(this.getAPIUrlVuln());
    };
    AnalisisService.prototype.getVulnerabilidadById = function (_id) {
        return this.http.get(this.getAPIUrlVuln() + _id);
    };
    AnalisisService.prototype.updateVulnerabilidad = function (d) {
        return this.http.put(this.getAPIUrlVuln() + d.ID, d);
    };
    AnalisisService.prototype.deleteVulnerabilidad = function (d) {
        return this.http.delete(this.getAPIUrlVuln() + d.ID);
    };
    AnalisisService.prototype.createVulnerabilidad = function (d) {
        return this.http.post(this.getAPIUrlVuln(), d);
    };
    // VARIABLES CLIMATICAS
    AnalisisService.prototype.getVariableClimatica = function () {
        return this.http.get(this.getAPIUrlVarCli());
    };
    AnalisisService.prototype.getVariableClimaticaById = function (_id) {
        return this.http.get(this.getAPIUrlVarCli() + _id);
    };
    AnalisisService.prototype.updateVariableClimatica = function (d) {
        return this.http.put(this.getAPIUrlVarCli() + d.ID, d);
    };
    AnalisisService.prototype.deleteVariableClimatica = function (d) {
        return this.http.delete(this.getAPIUrlVarCli() + d.ID);
    };
    AnalisisService.prototype.createVariableClimatica = function (d) {
        return this.http.post(this.getAPIUrlVarCli(), d);
    };
    AnalisisService.ngInjectableDef = i0.defineInjectable({ factory: function AnalisisService_Factory() { return new AnalisisService(i0.inject(i1.HttpClient), i0.inject(i2.AlertService), i0.inject(i3.MunicipioService)); }, token: AnalisisService, providedIn: "root" });
    return AnalisisService;
}());
export { AnalisisService };

import { Injectable } from '@angular/core';
import {AlertService} from './alert.service';
import {DatosInventario} from '../model/datosinventario';
import {HttpClient} from '@angular/common/http';
import {MunicipioService} from './municipio.service';
import {appConfig} from '../app.config';
import {InventarioService} from './inventario.service';
import {PlanaccionService} from './planaccion.service';
import {Accion} from '../model/accion';
import {HCOAccion} from '../model/hcoaccion';

@Injectable({
  providedIn: 'root'
})
export class AccionesService {

  constructor(private http: HttpClient, private alertsvc: AlertService, private munSrv: MunicipioService, private dataSrv: PlanaccionService) {
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munSrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.dataSrv.selected.getValue().ID + '/Accion/';
  }
  getAPIUrlById(id) {
    return appConfig.apiUrl + 'MUNICIPIO/' + this.munSrv.sel_municipio.getValue().ID + '/PlanAccion/' + id + '/Accion/';
  }

  getAll() {
    return this.http.get<HCOAccion[]>(this.getAPIUrl());
  }

  getAllById(id) {
    return this.http.get<HCOAccion[]>(this.getAPIUrlById(id));
  }

  getById(_id: number) {
    return this.http.get<HCOAccion>(this.getAPIUrl() + _id);
  }

  create(datos: HCOAccion) {
    return this.http.post<HCOAccion>(this.getAPIUrl(), datos);
  }

  update(datos: HCOAccion) {
    return this.http.put<HCOAccion>(this.getAPIUrl() + datos.ID, datos);
  }

  updateAll(datos) {
    return this.http.put<HCOAccion[]>(this.getAPIUrl(), datos);
  }

  delete(_id: number) {
    return this.http.delete(this.getAPIUrl() + _id);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {AmbitoService} from '../../../services/ambito.service';
import {DialogAction, DialogResponse} from '../../../model/Utils';
import {Ambito} from '../../../model/ambito';
import {Petition} from '../../../model/petition';
import {AmbitoForm, SuperambitoForm} from '../../Forms';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ListatiposService} from '../../../services/listatipos.service';

@Component({
  selector: 'app-adminsuperambitosform',
  templateUrl: './adminsuperambitosform.component.html',
  styleUrls: ['./adminsuperambitosform.component.scss']
})
export class AdminsuperambitosformComponent implements OnInit {
  dato: Ambito;
  dataForm: SuperambitoForm;

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction) {
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new SuperambitoForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new SuperambitoForm(action);
    }
  }

  ngOnInit() {
  }


  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

import { HttpClient } from '@angular/common/http';
import { appConfig } from '../app.config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BiService = /** @class */ (function () {
    function BiService(http) {
        this.http = http;
    }
    BiService.prototype.getAPIUrl = function () {
        return appConfig.apiUrl + 'SESION/';
    };
    BiService.prototype.getSession = function () {
        return this.http.get(this.getAPIUrl());
    };
    BiService.prototype.mantenerSession = function () {
        return this.http.put(this.getAPIUrl(), {});
    };
    BiService.prototype.finalizarSession = function () {
        return this.http.delete(this.getAPIUrl());
    };
    BiService.ngInjectableDef = i0.defineInjectable({ factory: function BiService_Factory() { return new BiService(i0.inject(i1.HttpClient)); }, token: BiService, providedIn: "root" });
    return BiService;
}());
export { BiService };

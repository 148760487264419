import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Documento, TipoDocumento } from '../../model/documento';
import { ListatiposService } from '../../services/listatipos.service';
import { DocumentoForm } from '../Forms';
import { DialogAction } from '../../components/advancedlist/util';

@Component({
  selector: 'app-uploaddocumento',
  templateUrl: './upload.dialog.html'
})
export class UploadComponentDialog implements OnInit, OnChanges {
  dataForm: DocumentoForm;
  tipos: TipoDocumento[] = [];

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.dataForm.form.get('file').setValue(file);
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataSrv: ListatiposService
  ) {
    this.fetch();
  }

  fetch() {
    this.dataSrv.fetchTipoDocumento().subscribe(value => (this.tipos = value));
  }

  ngOnInit() {
    if (
      (this.data.action == 'edit' || this.data.action == 'view') &&
      this.data.DOCUMENTO
    ) {
      const dato: Documento = this.data.DOCUMENTO;
      this.dataForm = new DocumentoForm(
        new DialogAction(DialogAction.NONE),
        dato
      );
    }
    this.dataForm = new DocumentoForm(new DialogAction(DialogAction.CREATE));
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }

  ngOnChanges(changes: SimpleChanges): void {}
}

import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {UserService} from '../../services/user.service';
import {DialogAction, DialogResponse, nestedObj, removeDiacritics} from '../../model/Utils';
import {Petition} from '../../model/petition';
import {Usuario} from '../../model/usuario';
import {Municipio} from '../../model/municipio';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-apartado-admin',
  templateUrl: './apartado-admin.component.html',
  styleUrls: ['./apartado-admin.component.scss']
})
export class ApartadoAdminComponent implements OnInit {
  @Input() datos: any[] = [];
  @Input() loading: boolean = false;
  @Input() empty: boolean = true;
  @Input() clickable: boolean = true;
  @Input() filterProps: string[] = [];
  @Input() list_title: string = '';
  @Input() search_placeholder: string = '';
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild(TemplateRef)
  @Input() itemTemplate: TemplateRef<any>;
  @Input() formDialog: any;
  filterText = '';

  ngOnInit() {
  }

  constructor(private dialog: MatDialog) {
  }

  onSearch(text) {
    this.filterText = text;
  }

  filter() {
    return this.datos.filter(d => this.filterProps.filter(c => nestedObj(d, c) ? removeDiacritics(nestedObj(d, c)).toLowerCase().indexOf(removeDiacritics(this.filterText).toLowerCase()) >= 0 : false).length > 0);
  }

  getDatos() {
    if (this.filterText)
      return this.filter();
    else
      return this.datos;
  }

  onDialogClose(data: DialogResponse) {
    switch (data.action) {
      case DialogResponse.ADD: {
        this.onAdd.emit(data);
        break;
      }
      case DialogResponse.EDIT: {
        this.onEdit.emit(data);
        break;
      }
      case DialogResponse.DELETE: {
        this.onDelete.emit(data);
        break;
      }
    }
  }

  add(item) {
    this.openDialog(DialogAction.CREATE, item);
  }

  select(item) {
    this.openDialog(DialogAction.EDIT, item);
  }

  openDialog(action: number, data: any): void {
    const dialogRef = this.dialog.open(this.formDialog, {
      data: new DialogAction(action, data),
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(data => {
      this.onDialogClose(data);
    });
  }

}

import {Injectable} from '@angular/core';
import {Ambito} from '../model/ambito';
import {HttpClient} from '@angular/common/http';
import {MunicipioService} from './municipio.service';
import {appConfig} from '../app.config';
import {AlertService} from './alert.service';

@Injectable()
export class AmbitoService {

  constructor(private http: HttpClient, private alertsvc: AlertService, private munsrv: MunicipioService) {
  }

  getAPIUrl() {
    return appConfig.apiUrl + 'Ambito/';
  }
  getAPIADMINUrl() {
    return appConfig.apiUrl + 'ADMIN/Ambito/';
  }

  getAll() {
    return this.http.get<Ambito[]>(this.getAPIUrl());
  }

  getById(_id: string) {
    return this.http.get<Ambito>(this.getAPIUrl() + _id);
  }

  create(ambito: Ambito) {
    return this.http.post<Ambito>(this.getAPIADMINUrl(), ambito);
  }

  update(ambito: Ambito) {
    return this.http.put<Ambito>(this.getAPIADMINUrl() + ambito.ID, ambito);
  }

  delete(_id: string) {
    return this.http.delete(this.getAPIADMINUrl() + _id);
  }
}

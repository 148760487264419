import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Petition} from '../model/petition';
import {TranslateService} from '@ngx-translate/core';
import {load} from '@angular/core/src/render3/instructions';
import {SimpleSnackBar} from '@angular/material/snack-bar/typings/simple-snack-bar';
import {MatSnackBarRef} from '@angular/material/snack-bar/typings/snack-bar-ref';

@Injectable({
  providedIn: 'root'
})
export class AlertbarService {

  constructor(private snackBar: MatSnackBar, private translateSrv: TranslateService) {
  }

  applyToPetition(petition: Petition) {
    let load_snack: MatSnackBarRef<SimpleSnackBar> = null;
    petition.subject.subscribe(value => {
      switch (value) {
        case Petition.LOAD:
          this.translateSrv.get('generico.petition_snack.load').subscribe(value2 => {
            load_snack = this.snackBar.open(value2);
          });
          break;
        case Petition.OK:
          if (load_snack) {
            load_snack.dismiss();
          }
          this.translateSrv.get('generico.petition_snack.ok').subscribe(value2 => {
            this.snackBar.open(value2, null, {duration: 2500});
          });
          break;
        case Petition.KO:
          if (load_snack) {
            load_snack.dismiss();
          }
          this.translateSrv.get('generico.petition_snack.ko').subscribe(value2 => {
            this.snackBar.open(value2, null, {duration: 2500});
          });
          break;
      }
    });
  }

  open(text: string, duration = 2) {
    this.translateSrv.get('generico.petition.ok').subscribe(value2 => {
      this.snackBar.open(value2, null, {duration: duration});
    });
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {enableProdMode, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {AlertComponent} from './directives/alert.component';
import {AuthGuard} from './guards/auth.guard';

import {AppComponent} from './app.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LoginComponent} from './login/login.component';

import {routing} from './app.routing';
import {AlertService} from './services/alert.service';
import {AuthenticationService} from './services/authentication.service';
import {UserService} from './services/user.service';
import {HomeComponent, SelectMunicipioDialog} from './home/home.component';
import {AdminComponent} from './admin/admin.component';
import {JwtInterceptorProvider} from './helpers/jwt.interceptor';
import {ErrorInterceptorProvider} from './helpers/error.interceptor';
import {RoleGuard} from './guards/role.guard';
import {RedirectRoleGuard} from './guards/redirectrole.guard';
import {IniComponent} from './ini/ini.component';
import {MunicipioService} from './services/municipio.service';
import {DeleteInventarioDialog, FormChangedDialog, InventarioComponent} from './home/inventario/inventario.component';
import {InvCreateComponent} from './home/inventario/inv-create/inv-create.component';
import {
  DeleteDatoInventarioDialog, FactoresDialog, InvDatosComponent,
  NuevoDatoInventarioDialog
} from './home/inventario/inv-datos/inv-datos.component';
import {InventarioService} from './services/inventario.service';
import {AmbitoService} from './services/ambito.service';
import {EditableComponent} from './components/editable/editable.component';
import {DatosInventarioService} from './services/datosinventario.service';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatButtonModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule,
  MatMenuModule, MatNativeDateModule,
  MatProgressSpinnerModule, MatRadioModule,
  MatSelectModule,
  MatSidenavModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule,
  MatBadgeModule, MatCheckbox, MatCheckboxModule, MatPaginatorModule, MatGridListModule, MatCardModule, MatStepperModule, MatChipsModule,
  MatTreeModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CreateInventarioDialog} from './home/inventario/createinventario.component';
import {FormsService} from './services/forms.service';
import {
  DeleteDatoCentroDialog, InvDatosEspecificosComponent,
  NuevoDatoCentroDialog
} from './home/inventario/inv-datos-especificos/inv-datos-especificos.component';
import {TextMaskModule} from 'angular2-text-mask';
import {MunicipioComponent} from './home/municipio/municipio.component';
import {MunDatosgeneralesComponent} from './home/municipio/mun-datosgenerales/mun-datosgenerales.component';
import {DeleteCentroDialog, MunCentrosComponent, NuevoCentroDialog} from './home/municipio/mun-centros/mun-centros.component';
import {CentroService} from './services/centro.service';
import {DatoscentroService} from './services/datoscentro.service';
import {AgmCoreModule} from '@agm/core';
import {InvHomeComponent} from './home/inventario/inv-home/inv-home.component';
import {DashboardComponent} from './home/dashboard/dashboard.component';
import {SimpletableComponent} from './components/simpletable/simpletable.component';
import {ImportacionplantillaComponent, TablaImportacionDialog} from './home/importacionplantilla/importacionplantilla.component';
import {ClonePlanAccionDialog, DeletePlanAccionDialog, PlanaccionHomeComponent} from './home/planaccion/planaccionhome.component';
import {CreatePlanAccionDialog} from './home/planaccion/createplanaccion.component';
import {DeletePlanDialog, PlanaccionComponent} from './home/planaccion/planaccion/planaccion.component';
import {PlanaccionDatosgeneralesComponent} from './home/planaccion/planaccion-datosgenerales/planaccion-datosgenerales.component';
import {
  DeleteAccionDialog, NuevaAccionDialog,
  PlanaccionAccionesComponent
} from './home/planaccion/planaccion-acciones/planaccion-acciones.component';
import {CapitalizePipe} from './pipes/capitalize.pipe';
import {LOCALE_ID} from '@angular/core';
import {LocalizedDatePipe} from './pipes/localizeddate.pipe';
import {LocalizedCurrencyPipe} from './pipes/localizedcurrency.pipe';
import {LocalizedCurrencySymbolPipe} from './pipes/localizedcurrencysymbol.pipe';
import {LocalizedNumberPipe} from './pipes/localizednumber.pipe';
import {registerLocaleData} from '@angular/common';
import {ClickStopPropagationDirective} from './directives/click-stop-propagation.directive';
import {ListComponent} from './components/list/list.component';
import {AccionformComponent} from './home/planaccion/planaccion-acciones/accionform.component';
import {IndicadorformComponent} from './home/planaccion/planaccion-acciones/indicadorform.component';
import {AnalisisComponent, DeleteAnalisisDialog} from './home/analisis/analisis.component';
import {AnalisisdetalleComponent} from './home/analisis/analisisdetalle/analisisdetalle.component';
import {DeleteRiesgoDialog, NuevoRiesgoDialog, RiesgosComponent} from './home/analisis/riesgos/riesgos.component';
import {
  DeleteVulnerabilidadDialog, NuevaVulnerabilidadDialog,
  VulnerabilidadesComponent
} from './home/analisis/vulnerabilidades/vulnerabilidades.component';
import {VariablesclimaticasComponent} from './home/analisis/variablesclimaticas/variablesclimaticas.component';
import {AnalisisService} from './services/analisis.service';
import {CreateAnalisisDialog} from './home/analisis/createanalisis.component';
import {RiesgoformComponent} from './home/analisis/riesgos/riesgoform.component';
import {VulnerabilidadformComponent} from './home/analisis/vulnerabilidades/vulnerabilidadform.component';
import {IndicadorRiesgoformComponent} from './home/analisis/riesgos/indicadorform.component';
import {IndicadorVulnerabilidadformComponent} from './home/analisis/vulnerabilidades/indicadorform.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {CarouselComponent} from './components/carousel/carousel.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {DeleteDocumentoDialog, DocumentosComponent} from './home/documentos/documentos.component';
import {UploadComponentDialog} from './home/documentos/upload.component';
import { InformesComponent } from './home/informes/informes.component';

import es from '@angular/common/locales/es';
import ca from '@angular/common/locales/ca';
import { AdminmunicipioComponent } from './admin/adminmunicipio/adminmunicipio.component';
import { AdminusuarioComponent } from './admin/adminusuario/adminusuario.component';
import { AdminmunicipioformComponent } from './admin/adminmunicipio/adminmunicipioform/adminmunicipioform.component';
import { AdminusuarioformComponent } from './admin/adminusuario/adminusuarioform/adminusuarioform.component';
import { AdminusuariomunicipioComponent } from './admin/adminusuario/adminusuariomunicipio/adminusuariomunicipio.component';
import {DeleteDialog} from './components/dialogs/delete.dialog';
import { AdminusuariomunicipioformComponent } from './admin/adminusuario/adminusuariomunicipio/adminusuariomunicipioform/adminusuariomunicipioform.component';
import {PermisosService} from './services/permisos.service';
import { AdminmunicipiohomeComponent } from './admin/adminmunicipio/adminmunicipiohome/adminmunicipiohome.component';
import { AdminusuariohomeComponent } from './admin/adminusuario/adminusuariohome/adminusuariohome.component';
import { AdmininventarioComponent } from './admin/admininventario/admininventario.component';
import { AdminplanaccionComponent } from './admin/adminplanaccion/adminplanaccion.component';
import { AdmindocumentosComponent } from './admin/admindocumentos/admindocumentos.component';
import { AdminaccioneslistComponent } from './admin/adminplanaccion/adminaccioneslist/adminaccioneslist.component';
import { AdminaccionesformComponent } from './admin/adminplanaccion/adminaccionesform/adminaccionesform.component';
import { AdminambitoslistComponent } from './admin/admininventario/adminambitoslist/adminambitoslist.component';
import { AdminambitosformComponent } from './admin/admininventario/adminambitosform/adminambitosform.component';
import { ApartadoAdminComponent } from './components/apartado-admin/apartado-admin.component';
import { AdminsuperambitoslistComponent } from './admin/admininventario/adminsuperambitoslist/adminsuperambitoslist.component';
import { AdminsuperambitosformComponent } from './admin/admininventario/adminsuperambitosform/adminsuperambitosform.component';
import { AdminFormComponent } from './components/admin-form/admin-form.component';
import { AdminsubambitolistComponent } from './admin/admininventario/adminsubambitolist/adminsubambitolist.component';
import { AdminsubambitoformComponent } from './admin/admininventario/adminsubambitoform/adminsubambitoform.component';
import { AdminfuentelistComponent } from './admin/admininventario/adminfuentelist/adminfuentelist.component';
import { AdminfuenteformComponent } from './admin/admininventario/adminfuenteform/adminfuenteform.component';
import { AdminzonaclimaticalistComponent } from './admin/adminmunicipio/adminzonaclimaticalist/adminzonaclimaticalist.component';
import { AdminzonaclimaticaformComponent } from './admin/adminmunicipio/adminzonaclimaticaform/adminzonaclimaticaform.component';
import { AdminanalisisComponent } from './admin/adminanalisis/adminanalisis.component';
import { AdminriesgoslistComponent } from './admin/adminanalisis/adminriesgoslist/adminriesgoslist.component';
import { AdminriesgosformComponent } from './admin/adminanalisis/adminriesgosform/adminriesgosform.component';
import { AdminsectorlistComponent } from './admin/adminanalisis/adminsectorlist/adminsectorlist.component';
import { AdminsectorformComponent } from './admin/adminanalisis/adminsectorform/adminsectorform.component';
import { ImportDialogComponent } from './components/import-dialog/import-dialog.component';
import { ExportDialogComponent } from './components/export-dialog/export-dialog.component';
import { AdvancedlistComponent } from './components/advancedlist/advancedlist.component';
import {BiComponent, NoSessionsDialog} from './bi/bi.component';
import {AdminsesionesComponent, CloseSessionDialog} from './admin/adminsesiones/adminsesiones.component';
import { SecciontopbarComponent } from './components/secciontopbar/secciontopbar.component';
import { AdminbiComponent } from './admin/adminbi/adminbi.component';
import { AdminhomeComponent } from './admin/adminhome/adminhome.component';
import { AdmininventariolistComponent } from './admin/admininventario/admininventariolist/admininventariolist.component';
import { ListitemAdminmunicipioComponent } from './admin/adminmunicipio/listitem-adminmunicipio/listitem-adminmunicipio.component';
registerLocaleData(es);
registerLocaleData(ca);
enableProdMode();
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    LoginComponent,
    HomeComponent,
    AdminComponent,
    IniComponent,
    InventarioComponent,
    InvCreateComponent,
    InvDatosComponent,
    EditableComponent,
    SelectMunicipioDialog,
    NuevoDatoInventarioDialog,
    CreateInventarioDialog,
    DeleteInventarioDialog,
    FormChangedDialog,
    DeleteDatoInventarioDialog,
    InvDatosEspecificosComponent,
    MunicipioComponent,
    MunDatosgeneralesComponent,
    MunCentrosComponent,
    NuevoCentroDialog,
    DeleteCentroDialog,
    NuevoDatoCentroDialog,
    DeleteDatoCentroDialog,
    DeleteDialog,
    InvHomeComponent,
    DashboardComponent,
    SimpletableComponent,
    ImportacionplantillaComponent,
    TablaImportacionDialog,
    PlanaccionHomeComponent,
    DeletePlanAccionDialog,
    CreatePlanAccionDialog,
    PlanaccionComponent,
    PlanaccionDatosgeneralesComponent,
    PlanaccionAccionesComponent,
    AccionformComponent,
    DeleteAccionDialog, NuevaAccionDialog, CapitalizePipe, LocalizedDatePipe, 
    LocalizedCurrencyPipe, LocalizedNumberPipe, ClickStopPropagationDirective, ListComponent, LocalizedCurrencySymbolPipe,
    IndicadorformComponent,
    ClonePlanAccionDialog,
    AnalisisComponent,
    AnalisisdetalleComponent,
    RiesgosComponent,
    VulnerabilidadesComponent,
    VariablesclimaticasComponent,
    CreateAnalisisDialog,
    DeleteAnalisisDialog,
    RiesgoformComponent,
    IndicadorRiesgoformComponent,
    DeleteRiesgoDialog,
    NuevoRiesgoDialog,
    VulnerabilidadformComponent,
    DeleteVulnerabilidadDialog,
    NuevaVulnerabilidadDialog,
    IndicadorVulnerabilidadformComponent,
    CarouselComponent,
    DocumentosComponent,
    UploadComponentDialog,
    DeleteDocumentoDialog,
    FactoresDialog,
    DeletePlanDialog,
    InformesComponent,
    AdminmunicipioComponent,
    AdminusuarioComponent,
    AdminmunicipioformComponent,
    AdminusuarioformComponent,
    AdminusuariomunicipioComponent,
    AdminusuariomunicipioformComponent,
    AdminmunicipiohomeComponent,
    AdminusuariohomeComponent,
    AdmininventarioComponent,
    AdminplanaccionComponent,
    AdmindocumentosComponent,
    AdminaccioneslistComponent,
    AdminaccionesformComponent,
    AdminambitoslistComponent,
    AdminambitosformComponent,
    ApartadoAdminComponent,
    AdminsuperambitoslistComponent,
    AdminsuperambitosformComponent,
    AdminFormComponent,
    AdminsubambitolistComponent,
    AdminsubambitoformComponent,
    AdminfuentelistComponent,
    AdminfuenteformComponent,
    AdminzonaclimaticalistComponent,
    AdminzonaclimaticaformComponent,
    AdminanalisisComponent,
    AdminriesgoslistComponent,
    AdminriesgosformComponent,
    AdminsectorlistComponent,
    AdminsectorformComponent,
    AdminbiComponent,
    AdminhomeComponent,
    AdmininventariolistComponent,
    ListitemAdminmunicipioComponent,
    ImportDialogComponent,
    ExportDialogComponent,
    AdvancedlistComponent,
    BiComponent,
    NoSessionsDialog,
    AdminsesionesComponent,
    CloseSessionDialog,
    SecciontopbarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatStepperModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatBadgeModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTableModule,
    TextMaskModule,
    MatTabsModule,
    MatRadioModule,
    MatDividerModule,
    MatIconModule,
    MatSortModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatTreeModule,
    NgxChartsModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    routing,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAHD5M4U2jTDRPq03t-N-PhoAbvWRfSDKY'
    }),
    MatGridListModule,
    MatCardModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    RedirectRoleGuard,
    AlertService,
    AuthenticationService,
    MunicipioService,
    DatosInventarioService,
    InventarioService,
    AmbitoService,
    UserService,
    FormsService,
    CentroService,
    MatBadgeModule,
    DatoscentroService,
    JwtInterceptorProvider,
    ErrorInterceptorProvider,
    AnalisisService,
    PermisosService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SelectMunicipioDialog,
    NuevoDatoInventarioDialog,
    CreateInventarioDialog,
    DeleteInventarioDialog,
    FormChangedDialog,
    DeleteDatoInventarioDialog,
    NuevoCentroDialog,
    DeleteCentroDialog,
    NuevoDatoCentroDialog,
    DeleteDatoCentroDialog,
    InvDatosEspecificosComponent,
    TablaImportacionDialog,
    DeletePlanAccionDialog,
    CreatePlanAccionDialog,
    NuevaAccionDialog,
    DeleteAccionDialog,
    IndicadorformComponent,
    ClonePlanAccionDialog,
    CreateAnalisisDialog,
    DeleteAnalisisDialog,
    DeleteRiesgoDialog,
    NuevoRiesgoDialog,
    IndicadorRiesgoformComponent,
    DeleteVulnerabilidadDialog,
    NuevaVulnerabilidadDialog, IndicadorVulnerabilidadformComponent,
    UploadComponentDialog,
    DeleteDocumentoDialog,
    FactoresDialog,
    DeletePlanDialog,
    AdminmunicipioformComponent,
    AdminusuarioformComponent,
    DeleteDialog,
    AdminusuariomunicipioformComponent,
    AdminusuariomunicipioComponent,
    AdminambitosformComponent,
    AdminsuperambitosformComponent,
    AdminsubambitoformComponent,
    AdminfuenteformComponent,
    AdminaccionesformComponent,
    AdminzonaclimaticaformComponent,
    AdminsectorformComponent,
    AdminriesgosformComponent,
    ImportDialogComponent,
    ExportDialogComponent,
    NoSessionsDialog,
    CloseSessionDialog
  ]
})

export class AppModule {
}

<div class="p-4 full-height" id="panel">
  <!--<div class="mb-2">-->
  <!--<span class="title-active" [innerHTML]="'municipio.centros.title-active' | translate:{year: inventarioActivo.ANYO}"></span>-->
  <!--</div>-->
  <!--<h3 class="titulo-apartado mb-4">{{'municipio.centros.title' | translate}}</h3>-->
  <div class="buttons-header d-flex mb-2">
    <div class="d-flex flex-grow-1 justify-content-end">
    </div>
  </div>
  <app-editable [cols]="cols" [data]="centros" (deleteCallback)="openDeleteDialog($event)" (save)="update($event)" datatype="Centro" datatypePlural ="Centros" (addCallback)="openNuevoCentroDialog()" [hasAdd]="true" [hasDelete]="true" [exporting]="data_export.pending"
  (exportCallback)="export()" [hasExport]="true"></app-editable>
</div>

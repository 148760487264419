<!--<div class="full-height d-flex flex-column">-->
<!--<mat-toolbar fxLayout="row wrap" id="topbar" class="pl-md-5 border-b  pr-md-5 pl-sm-3 pr-sm-3 full-height flex-shrink-0"-->
<!--style="background: white;">-->
<!--<div fxLayout="row" fxLayoutGap="15px" fxFlex>-->
<!--<mat-icon style="color:#4F4F4F">build</mat-icon>-->
<!--<span>{{'admin.home.title' | translate}}</span>-->
<!--</div>-->
<!--<div class="flex-grow"></div>-->
<!--<div>-->
<!--<button mat-raised-button color="primary" (click)="null">-->
<!--{{'admin.home.accesobi' | translate}}-->
<!--</button>-->
<!--</div>-->
<!--</mat-toolbar>-->
<!--<div fxFlex class="content pt-2 flex-grow-1">-->
<!--<router-outlet></router-outlet>-->
<!--</div>-->
<!--</div>-->
<mat-sidenav-container fullscreen autosize>
  <mat-sidenav #sidenav id="sidenav" [mode]="mobile ? 'over' : 'side'" [opened]="mobile ? false : true"
               class="d-flex flex-column shadow-lg"
               (onOpen)="open()" [disableClose]="!mobile" style="max-width: 340px;"
               (onClose)="close()">
    <div class="p-4">
      <span class="text-lg font-semibold
">{{'admin.home.title' | translate}}</span>
    </div>
    <mat-list class="mt-1 flex flex-col flex-auto pb-4">
      <mat-list-item class="cursor-pointer" routerLink="/admin/municipio" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-orange-lighter mr-2">
          <mat-icon class="text-orange-dark" mat-list-icon>location_city</mat-icon>
        </div>
        <h4 mat-line class="font-light">{{'admin.municipio.title' | translate }}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/admin/usuario" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-indigo-lighter mr-2">
          <mat-icon class="text-indigo-dark" mat-list-icon>people</mat-icon>
        </div>
        <h4 mat-line>{{'admin.usuario.title' | translate }}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/admin/inventario" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-green-light mr-2">
          <mat-icon class="text-green-dark" mat-list-icon>assignment</mat-icon>
        </div>
        <h4 mat-line>{{'admin.inventario.title' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/admin/planaccion" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-red-lighter mr-2">
          <mat-icon class="text-red-dark" mat-list-icon>assistant</mat-icon>
        </div>
        <h4 mat-line>{{'admin.planaccion.title' | translate}}</h4>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/admin/analisis" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-blue-lighter mr-2">
          <mat-icon class="text-blue-dark" mat-list-icon>assignment_late</mat-icon>
        </div>
        <h4 mat-line>{{'admin.analisis.title' | translate}}</h4>
      </mat-list-item>
      <!--mat-list-item class="cursor-pointer" routerLink="/bi" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-teal mr-2">
          <mat-icon class="text-white" mat-list-icon>bubble_chart</mat-icon>
        </div>
        <h4 mat-line>{{'admin.accesobi.title' | translate}}</h4>
      </mat-list-item-->
      <mat-list-item class="cursor-pointer" routerLink="/admin/sesiones" routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: false}">
        <div class="rounded flex justify-center items-center bg-teal mr-2">
          <mat-icon class="text-white" mat-list-icon>how_to_reg</mat-icon>
        </div>
        <h4 mat-line>{{'admin.sesionesbi.title' | translate}}</h4>
      </mat-list-item>
      <div class="flex-grow-1"></div>
      <mat-list-item class="cursor-pointer" routerLink="/">
        <div class="rounded flex justify-center items-center bg-orange mr-2">
          <mat-icon class="text-orange-darkest" mat-list-icon>account_balance</mat-icon>
        </div>
        <h4 mat-line>{{'admin.user-button' | translate}}</h4>
      </mat-list-item>
    </mat-list>

  </mat-sidenav>
  <mat-sidenav-content fxLayout="column">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

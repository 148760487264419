<div *ngIf="activo" fxLayout="column" fxFlexFill>
  <app-secciontopbar [title]="'planaccion.seleccionado' | translate:{year: activo.ANYO}" back_url="/planaccion"
                     [delete_text]="'planaccion.delete' | translate" (onImport)="openImportDialog($event)"
                     (onExport)="openExportDialog($event)" (onDelete)="openDeleteDialog($event)"
  >
  </app-secciontopbar>
  <nav mat-tab-nav-bar class="pl-md-5 pr-md-5 pl-sm-3 pr-sm-3 d-flex justify-content-center" style="background: white;">
    <a mat-tab-link [routerLink]="'/planaccion/'+this.id_activo+'/acciones'"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'planaccion.acciones.title' | translate}}</a>
    <a mat-tab-link [routerLink]="'/planaccion/'+this.id_activo+'/datosgenerales'" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: false}">{{'planaccion.datosgenerales.title' | translate}} </a>
  </nav>
  <div fxFlex fxLayout="column" class="full-height" style="overflow-y: auto;">
    <router-outlet *ngIf="id_activo"></router-outlet>
  </div>
</div>


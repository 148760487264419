import {Component, Inject, OnInit} from '@angular/core';
import {Ambito} from '../../../model/ambito';
import {Superambito} from '../../../model/superambito';
import {DatosInventario} from '../../../model/datosinventario';
import {AmbitoService} from '../../../services/ambito.service';
import {InventarioService} from '../../../services/inventario.service';
import {MunicipioService} from '../../../services/municipio.service';
import {DatosInventarioService} from '../../../services/datosinventario.service';
import {InventarioEmisiones} from '../../../model/inventarioemisiones';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import * as uniqBy from 'lodash/uniqBy';
import {DatoscentroService} from '../../../services/datoscentro.service';
import {DatosCentro} from '../../../model/datoscentro';
import {NuevoDatoInventarioDialog} from '../inv-datos/inv-datos.component';
import {CentroService} from '../../../services/centro.service';
import {Centro} from '../../../model/centro';
import {Petition} from '../../../model/petition';

@Component({
  selector: 'app-inv-datos-especificos',
  templateUrl: './inv-datos-especificos.component.html',
  styleUrls: ['./inv-datos-especificos.component.scss']
})
export class InvDatosEspecificosComponent implements OnInit {
  centros: Centro[] = [];
  datos_centro: DatosCentro[] = [];
  dato_inventario: DatosInventario;
  cols = [
    {
      id: 'centro',
      title: 'inventario.datosespecificos.col-centro',
      data: {
        prop: 'CENTRO.NOMBRE',
        filter: ['CENTRO.NOMBRE']
      },
      type: 'text',
      editable: false,
      sort: 'desc',
      width: '180px',
      tooltip: 'inventario.datosespecificos.tooltip-ambito'
    },
    {
      id: 'fuente',
      title: 'inventario.datosinventario.col-fuente',
      type: 'text',
      width: '180px',
      data: {
        prop: 'DATOS_INVENTARIO.AMBITO.SUBAMBITO.FUENTE.NOMBRE',
        filter: ['DATOS_INVENTARIO.AMBITO.SUBAMBITO.FUENTE.NOMBRE']
      },
      editable: false,
      sort: 'desc',
      tooltip: 'inventario.datosinventario.tooltip-fuente'
    },
    {
      id: 'valor',
      title: 'inventario.datosinventario.col-valor',
      data: {
        prop: 'VALOR'
      },
      type: 'number',
      editable: true,
      width: '140px',
      scope: 'row',
      tooltip: 'inventario.datosespecificos.col-valor'
    },
    {
      id: 'emisiones',
      title: 'inventario.datosespecificos.col-emisiones',
      type: 'function',
      data: {
        func: (obj: DatosCentro) => Math.round((obj.VALOR * obj.DATOS_INVENTARIO.FACTORCONVERSION * obj.DATOS_INVENTARIO.FACTOREMISION) * 1000) / 1000
      },
      width: '120px',
      editable: false,
      tooltip: 'inventario.datosinventario.tooltip-emisiones'
    }
  ];
  data_update = new Petition();
  alerts = [];

  constructor(private dialog: MatDialog, private invsrv: InventarioService, private munsvc: MunicipioService, private datcenSrv: DatoscentroService
    , private translateService: TranslateService, public snackBar: MatSnackBar, private centroSrv: CentroService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dato_inventario = data.dato_inventario;
  }

  ngOnInit() {
    this.initialize();
  }

  loadDatosCentro() {
    this.datcenSrv.getAll(this.data.dato_inventario.ID).subscribe(value => {
      if (value) {
        this.datos_centro = value;
      }
    }, error2 => console.log(error2));
  }

  loadCentros() {
    this.centroSrv.getAll().subscribe(value => {
      if (value) {
        this.centros = value;
      }
    }, error2 => console.log(error2));
  }

  update(data) {
    this.data_update.load();
    const datos_centro = data.map(d => new DatosCentro(d).toDB());
    this.datcenSrv.updateAll(this.data.dato_inventario.ID, datos_centro).subscribe(value => {
        this.data_update.ok(value);
        this.translateService.get('inventario.datosespecificos.updated').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.loadDatosCentro();
      }
      , error2 => {
        this.data_update.ko(error2);
        this.translateService.get('inventario.datosespecificos.error').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.loadDatosCentro();
      });
  }

  initialize() {
    this.datos_centro = [];
    this.alerts = [];

    this.loadDatosCentro();
    this.loadCentros();
  }

  openNuevoDatoCentroDialog(): void {
    let dialogRef = this.dialog.open(NuevoDatoCentroDialog, {
      data: {centros: this.centros},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(centros => {
      if (centros)
        this.addDatoCentro(centros);
    });
  }

  addDatoCentro(centro: Centro) {
    const datos_centro = this.datos_centro.slice();
    const dc = new DatosCentro({});
    dc.IDCENTRO = centro.ID;
    dc.CENTRO = centro;
    dc.IDDATOSINVENTARIO = this.data.dato_inventario.ID;
    dc.DATOS_INVENTARIO = this.data.dato_inventario;
    dc.VALOR = 0;
    datos_centro.push(dc);
    this.datos_centro = datos_centro;
    this.update(this.datos_centro);
  }

  deleteDatoCentro(index) {
    const data = this.datos_centro.slice();
    data.splice(index, 1);
    this.datos_centro = data;
    this.update(this.datos_centro);
  }

  openDeleteDialog(data): void {
    if (data) {
      const row = data.row;
      const index = data.index;
      let dialogRef = this.dialog.open(DeleteDatoCentroDialog, {
        data: {},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(bool => {
        if (bool)
          this.deleteDatoCentro(index);
      });
    }
  }

}

@Component({
  selector: 'app-nuevodatoinventariodialog',
  templateUrl: 'nuevodatocentro.dialog.html',
})
export class NuevoDatoCentroDialog {

  constructor(public dialogRef: MatDialogRef<NuevoDatoCentroDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

@Component({
  selector: 'deletedatoinventariodialog',
  templateUrl: 'deletedatocentro.dialog.html',
})
export class DeleteDatoCentroDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDatoCentroDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}


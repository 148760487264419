import {Component, OnInit} from '@angular/core';
import {DatosInventario} from '../../../model/datosinventario';
import {Petition} from '../../../model/petition';
import {Router} from '@angular/router';
import {InventarioService} from '../../../services/inventario.service';
import {InventarioEmisiones} from '../../../model/inventarioemisiones';
import {CreateInventarioDialog} from '../createinventario.component';
import {MunicipioService} from '../../../services/municipio.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {DeleteInventarioDialog} from '../inventario.component';
import {Sorter} from '../../../components/list/Util';

@Component({
  selector: 'inv-home',
  templateUrl: './inv-home.component.html',
  styleUrls: ['./inv-home.component.css']
})
export class InvHomeComponent implements OnInit {
  search_props = ['ANYO','TIPOINVENTARIO.NOMBRE'];
  search_placeholder = 'inventario.home.list.searchplaceholder';
  sorters = [new Sorter('models.inventario.ANYO.label', 'ANYO')];
  cols = [
    {
      id: 'anyo',
      title: 'inventario.home.col-anyo',
      type: 'text',
      data: {
        prop: 'ANYO',
        filter: ['ANYO']
      },
      sort: 'desc',
      tooltip: 'inventario.home.tooltip-anyo'
    },
    {
      id: 'tipoinventario',
      title: 'inventario.home.col-tipoinventario',
      type: 'text',
      data: {
        prop: 'TIPOINVENTARIO.NOMBRE',
        filter: ['TIPOINVENTARIO.NOMBRE']
      },
      sort: 'desc',
    },
    {
      id: 'fechaultimamodificacion',
      title: 'inventario.home.col-fechaultimamodificacion',
      type: 'date',
      data: {
        prop: 'FECHAULTIMAMODIFICACION',
        filter: ['FECHAULTIMAMODIFICACION']
      },
      sort: 'desc',
      tooltip: 'inventario.home.tooltip-fechaultimamodificacion'
    },
    {
      id: 'delete',
      title: '',
      type: 'icon',
      width: '40px',
      data: {
        icon: 'delete',
        callback: (obj: InventarioEmisiones) => this.openDeleteDialog(obj),
      }
    },
    {
      id: 'select',
      title: '',
      type: 'icon',
      width: '40px',
      data: {
        icon: 'search',
        callback: (obj: InventarioEmisiones) => this.selectInventario(obj),
        color: 'accent'
      },
      showonhover: true
    },
  ];
  data_fetch = new Petition();
  inventarios: InventarioEmisiones[] = [];
  years = [];

  constructor(private router: Router, private invSrv: InventarioService, private munSrv: MunicipioService, private snackBar: MatSnackBar,
              private translateService: TranslateService, private dialog: MatDialog) {
    this.fetchInventarios();
  }

  fetchInventarios() {
    this.data_fetch.load();
    this.invSrv.getAll().subscribe(value => {
        if (value) {
          value.sort((a,b)=>b.ANYO - a.ANYO);
          this.inventarios = value;
          this.years = value.map(v => v.ANYO);
        }
        this.data_fetch.ok(value);
      },
      error2 =>
        this.data_fetch.ko(error2)
    );
  }

  selectInventario(inventario) {
    this.invSrv.setSelectedInventario(inventario);
    this.router.navigateByUrl('inventarios/'+inventario.ID)
  }

  ngOnInit() {
  }

  openCreateDialog(): void {
    let dialogRef = this.dialog.open(CreateInventarioDialog, {
      data: {years: this.years},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.year)
        this.create(result.year);
    });
  }

  create(year) {
    let inventarioNuevo = new InventarioEmisiones();
    inventarioNuevo.IDTIPO = 1;
    inventarioNuevo.IDMUNICIPIO = this.munSrv.getMunicipioActivo().ID;
    inventarioNuevo.ANYO = year;
    this.invSrv.create(inventarioNuevo.toDB()).subscribe(value => {
      if (value) {
        this.translateService.get('inventario.createmodal.created').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
        this.fetchInventarios();
      }
    }, error2 => console.log(error2));
  }


  openDeleteDialog(inventario): void {
    let dialogRef = this.dialog.open(DeleteInventarioDialog, {
      data: {year: inventario.ANYO},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.deleteInventario(inventario);
    });
  }

  deleteInventario(inventario) {
    this.invSrv.delete(inventario.ID).subscribe(value => {
      this.translateService.get('inventario.createmodal.deleted').subscribe(d => this.snackBar.open(d, null, {duration: 3000}));
      this.fetchInventarios();
    }, error2 => console.log(error2));
  }
}

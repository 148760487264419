import {Component, Inject, OnInit} from '@angular/core';
import {SectorRiesgoPredForm} from '../../Forms';
import {DialogAction} from '../../../model/Utils';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ListatiposService} from '../../../services/listatipos.service';
import {SectorRiesgoPred} from '../../../model/Analisis';

@Component({
  selector: 'app-adminsectorform',
  templateUrl: './adminsectorform.component.html',
  styleUrls: ['./adminsectorform.component.scss']
})
export class AdminsectorformComponent implements OnInit {
  dato: SectorRiesgoPred;
  dataForm: SectorRiesgoPredForm;

  constructor(@Inject(MAT_DIALOG_DATA) public action: DialogAction, public dataSrv: ListatiposService) {
    if (action.action == DialogAction.EDIT || action.action == DialogAction.VIEW) {
      this.dato = action.data;
      this.dataForm = new SectorRiesgoPredForm(action, action.data);
    }
    if (action.action == DialogAction.CREATE) {
      this.dataForm = new SectorRiesgoPredForm(action);
    }
  }

  ngOnInit() {
  }

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.ID === f2.ID;
  }
}

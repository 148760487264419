<div class="full-height  border rounded">
  <div *ngIf="fetch_data.pending && !fetch_data.finished"
       class="d-flex flex-column full-height justify-content-center align-items-center  fin-elevation"
       style="background: white;">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
  <div *ngIf="fetch_data.finished && fetch_data.success" class="d-flex flex-column full-height  fin-elevation"
       style="background: white;">
    <div id="topbar" class="d-flex bg-grey-lightest" style="min-height: 48px;" fxLayoutGap="5px">
      <div class="d-flex justify-content-center align-items-center pl-2">
        <button size="small" *ngIf="form.dirty && editable" class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-4 rounded-full border inline-flex items-center outline-none ng-star-inserted" (click)="save()">
          <i class="material-icons mr-2 text-md">save</i>
          <span class="text-sm">
          {{'analisis.vulnerabilidades.form.save' | translate }}</span>
        </button>
      </div>
      <div class="flex-grow-1"></div>
      <div class="d-flex justify-content-center align-items-center pr-1">
        <button *ngIf="this.userSrv.isEditable()" size="small" class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2" [matMenuTriggerFor]="menu">
          <i class="material-icons text-md">more_horiz</i>
        </button>
        <button size="small" class="hover:shadow-sm bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-1 rounded-full border inline-flex items-center outline-none ng-star-inserted mx-2" (click)="closeForm()">
          <i class="material-icons text-md">close</i>
        </button>
      </div>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="delete.next(dato)">
          <mat-icon>delete</mat-icon>
          {{'analisis.vulnerabilidades.form.delete' | translate }}
        </button>
      </mat-menu>
    </div>
    <form [formGroup]="form" novalidate class="pl-4 pr-4 pb-4" style="overflow-y: auto;">
      <div fxLayout="column" class="pt-2 mb-4">
        <mat-form-field class="col-md-12 title-input">
          <input id="NOMBRE" matInput formControlName="NOMBRE"/>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="15px" class=" pl-2 pr-2">
          <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
            <button mat-mini-fab class="mat-elevation-z1"
                    [style.background]="getNivelColor(getNivelActual().ID)"
                    [style.color]="'white'">
              <mat-icon>healing</mat-icon>
            </button>
            <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
              <span class="nivel-vulnerabilidad">{{getNivelActual().NOMBRE}}</span>
              <span
                class="tiponivel-vulnerabilidad">{{'analisis.vulnerabilidades.list.item.nivelactual' | translate}}</span>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <mat-icon class="fecha-icono">arrow_forward</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutGap="5px" class="align-items-center">
            <button mat-mini-fab class="mat-elevation-z1"
                    [style.background]="getNivelColor(getNivelPrevisto().ID)"
                    [style.color]="'white'">
              <mat-icon>healing</mat-icon>
            </button>
            <div fxLayout="column" class="align-items-center justify-content-center" fxLayoutGap="2px">
              <span class="nivel-vulnerabilidad">{{getNivelPrevisto().NOMBRE}}</span>
              <span
                class="tiponivel-vulnerabilidad">{{'analisis.vulnerabilidades.list.item.nivelprevisto'| translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div class="row m-0">
          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="DESCRIPCION">{{'analisis.vulnerabilidades.form.DESCRIPCION' | translate }}</mat-label>
            <textarea id="DESCRIPCION" matInput formControlName="DESCRIPCION"></textarea>
          </mat-form-field>

          <mat-form-field class="col-md-12" [floatLabel]="'always'">
            <mat-label for="IDTIPO">{{'analisis.vulnerabilidades.form.IDTIPO' | translate }}</mat-label>
            <mat-select id="IDTIPO" formControlName="IDTIPO">
              <mat-option *ngFor="let m of tipos" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column">
        <p class="full-width section-title">{{'analisis.vulnerabilidades.form.seccion-niveles' | translate}}</p>
        <div class="row m-0">
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDNIVELACTUAL">{{'analisis.vulnerabilidades.form.IDNIVELACTUAL' | translate }}</mat-label>
            <mat-select id="IDNIVELACTUAL" formControlName="IDNIVELACTUAL">
              <mat-option *ngFor="let m of niveles" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-md-12" [floatLabel]="'always'">
            <mat-label for="IDNIVELPREVISTO">{{'analisis.vulnerabilidades.form.IDNIVELPREVISTO' | translate }}</mat-label>
            <mat-select id="IDNIVELPREVISTO" formControlName="IDNIVELPREVISTO">
              <mat-option *ngFor="let m of niveles" [value]="m.ID">{{m.NOMBRE}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div fxLayout="column" class="pt-3" [style.max-height.px]="500">
        <div class="d-flex align-items-center mb-3">
          <div>
            <span class="full-width section-title">{{'analisis.vulnerabilidades.form.seccion-indicadores' | translate}}</span>
          </div>
          <div class="flex-grow-1"></div>
          <div *ngIf="editable" class="flex-shrink-0">
            <button mat-icon-button (click)="openIndicadorDialog(null)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <app-list [scrollableY]="false" [datos]="dato.INDICADORVULNERABILIDAD">
          <mat-list-item *ngFor="let item of dato.INDICADORVULNERABILIDAD" class="p-2 flex-grow-1 list-item"
                         (click)="openIndicadorDialog(item)">
            <div class="d-flex justify-content-start align-items-center">
              <span class="nombre">{{item.NOMBRE}}</span>
            </div>
          </mat-list-item>
          <div *ngIf="dato.INDICADORVULNERABILIDAD.length==0"
               class="full-height justify-content-center align-items-center p-4" fxLayout="column" fxLayoutGap="15px">
            <mat-icon class="large-icon color-grey-400">inbox</mat-icon>
            <span class="mat-caption">{{'analisis.vulnerabilidades.form.indicadores.empty_text' | translate}}</span>
            <button class="bg-white font-semibold text-grey-dark hover:text-orange-dark py-1 px-3 rounded-full border inline-flex items-center"  (click)="openIndicadorDialog(null)">
              <span class="text-md">{{'analisis.vulnerabilidades.form.indicadores.create_text' | translate}}</span>
            </button>
          </div>
        </app-list>
      </div>
    </form>
  </div>
</div>

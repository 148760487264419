import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {minValueValidator} from '../../validators/customvalidators';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-createanalisis',
  templateUrl: './createanalisis.dialog.html'
})
export class CreateAnalisisDialog  {
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.form = this.formBuilder.group({
      ANYO: [null, Validators.compose(
        [
          Validators.required,
          minValueValidator(1999)
        ])
      ]
    });
  }
}

import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { MunicipioService } from './municipio.service';
import { appConfig } from '../app.config';
import { PlanaccionService } from './planaccion.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
import * as i3 from "./municipio.service";
import * as i4 from "./planaccion.service";
var AccionesService = /** @class */ (function () {
    function AccionesService(http, alertsvc, munSrv, dataSrv) {
        this.http = http;
        this.alertsvc = alertsvc;
        this.munSrv = munSrv;
        this.dataSrv = dataSrv;
    }
    AccionesService.prototype.getAPIUrl = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munSrv.sel_municipio.getValue().ID + '/PlanAccion/' + this.dataSrv.selected.getValue().ID + '/Accion/';
    };
    AccionesService.prototype.getAPIUrlById = function (id) {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munSrv.sel_municipio.getValue().ID + '/PlanAccion/' + id + '/Accion/';
    };
    AccionesService.prototype.getAll = function () {
        return this.http.get(this.getAPIUrl());
    };
    AccionesService.prototype.getAllById = function (id) {
        return this.http.get(this.getAPIUrlById(id));
    };
    AccionesService.prototype.getById = function (_id) {
        return this.http.get(this.getAPIUrl() + _id);
    };
    AccionesService.prototype.create = function (datos) {
        return this.http.post(this.getAPIUrl(), datos);
    };
    AccionesService.prototype.update = function (datos) {
        return this.http.put(this.getAPIUrl() + datos.ID, datos);
    };
    AccionesService.prototype.updateAll = function (datos) {
        return this.http.put(this.getAPIUrl(), datos);
    };
    AccionesService.prototype.delete = function (_id) {
        return this.http.delete(this.getAPIUrl() + _id);
    };
    AccionesService.ngInjectableDef = i0.defineInjectable({ factory: function AccionesService_Factory() { return new AccionesService(i0.inject(i1.HttpClient), i0.inject(i2.AlertService), i0.inject(i3.MunicipioService), i0.inject(i4.PlanaccionService)); }, token: AccionesService, providedIn: "root" });
    return AccionesService;
}());
export { AccionesService };

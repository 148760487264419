import { AlertService } from './alert.service';
import { appConfig } from '../app.config';
import { MunicipioService } from './municipio.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
import * as i3 from "./municipio.service";
var DocumentosService = /** @class */ (function () {
    function DocumentosService(http, alertsvc, munsrv) {
        this.http = http;
        this.alertsvc = alertsvc;
        this.munsrv = munsrv;
    }
    DocumentosService.prototype.getAPIUrl = function () {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/Documento/';
    };
    DocumentosService.prototype.getAPIUrlDownload = function (id) {
        return appConfig.apiUrl + 'MUNICIPIO/' + this.munsrv.sel_municipio.getValue().ID + '/Documento/' + id + '/download';
    };
    DocumentosService.prototype.getAll = function () {
        return this.http.get(this.getAPIUrl());
    };
    DocumentosService.prototype.getById = function (_id) {
        return this.http.get(this.getAPIUrl() + _id);
    };
    DocumentosService.prototype.create = function (d, fileItem) {
        var formData = new FormData();
        formData.append('files', fileItem);
        formData.append('doc', JSON.stringify(d));
        return this.http.post(this.getAPIUrl(), formData);
    };
    DocumentosService.prototype.update = function (d, fileItem) {
        var formData = new FormData();
        formData.append('files', fileItem);
        formData.append('doc', JSON.stringify(d));
        return this.http.put(this.getAPIUrl() + d.ID, formData);
    };
    DocumentosService.prototype.delete = function (_id) {
        return this.http.delete(this.getAPIUrl() + _id);
    };
    DocumentosService.prototype.downloadInforme = function (url) {
        return this.http.get(url, { 'responseType': 'blob' });
    };
    DocumentosService.prototype.download = function (_id) {
        return this.http.get(this.getAPIUrlDownload(_id), { 'responseType': 'blob' });
    };
    DocumentosService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentosService_Factory() { return new DocumentosService(i0.inject(i1.HttpClient), i0.inject(i2.AlertService), i0.inject(i3.MunicipioService)); }, token: DocumentosService, providedIn: "root" });
    return DocumentosService;
}());
export { DocumentosService };
